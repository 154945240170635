import { BiReset } from 'react-icons/bi'
import Button from '@mui/material/Button'

import { useDispatchContext, useStateContext } from './NewFieldContext'

export function ResetFieldsMgmtBtn() {
  const dispatch = useDispatchContext()
  const { importedFeatures } = useStateContext()

  return (
    <Button
      disabled={!importedFeatures.length}
      size="small"
      startIcon={<BiReset />}
      variant="outlined"
      onClick={() => dispatch({ type: 'CLEAR_IMPORTED_FIELDS' })}
    >
      Clear imported fields
    </Button>
  )
}

import { IoMdAdd } from 'react-icons/io'
import { Link as RouterLink, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useCanAccessBasedOnPerms } from 'lib/queries.user'

import { useV1FieldsRetrieve } from 'components/fields/fields-mgmt/queries.fields-mgmt.gets'
import type { FmtRouteParams } from 'components/fields/routes'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'

import { CollectionsList } from './CollectionsList'

export function CollectionsListByField() {
  const { fieldId = '' } = useParams<FmtRouteParams>()
  const query = useV1FieldsRetrieve(fieldId)
  const { data: field, isInitialLoading, isError } = query
  const canSeeCreateCollnBtn = useCanAccessBasedOnPerms(['add_soilcollection'])

  let Collections = null

  if (isInitialLoading || isError || !field) {
    Collections = <ReactQueryStates {...query} />
  } else {
    Collections = <CollectionsList collectionIds={field.properties.collections} />
  }

  return (
    <>
      {canSeeCreateCollnBtn && (
        <Box display="flex" justifyContent="center">
          <Button component={RouterLink} startIcon={<IoMdAdd />} to="new" variant="contained">
            Create new collection
          </Button>
        </Box>
      )}
      {Collections}
    </>
  )
}

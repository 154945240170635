import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type {
  GeolocLinePathState,
  GeolocLinePathStatus,
} from 'components/reusable/maps/map-ctrls/gps-line-path'

type State = {
  actions: {
    setGeolocLinePathStatus: (state: GeolocLinePathStatus) => void
    toggleGeolocLinePath: () => void
    appendGeolocVertex: (coordinates: [number, number]) => void
    stopAndClearGeolocLinePath: () => void
    setGeolocLineColor: (color: string) => void
  }
  /** "GPS Tracks" tool */
  geolocLinePath: GeolocLinePathState
  /** "GPS Tracks" line color */
  geolocLineColor: string
}

// DV-4940 - DRY out with `useSsaLayoutStore`
export const useFmtStore = create<State>()(
  persist(
    (set) => ({
      geolocLineColor: '#00ffff', // cyan
      geolocLinePath: {
        showLayer: true,
        status: 'OFF',
        coordinates: [],
      },
      actions: {
        setGeolocLineColor: (color) => set(() => ({ geolocLineColor: color })),
        appendGeolocVertex: (coordinates) => {
          return set((store) => ({
            geolocLinePath: {
              ...store.geolocLinePath,
              coordinates: [...store.geolocLinePath.coordinates, coordinates],
            },
          }))
        },
        setGeolocLinePathStatus: (status) => {
          return set((store) => ({ geolocLinePath: { ...store.geolocLinePath, status } }))
        },
        stopAndClearGeolocLinePath: () => {
          return set(() => ({
            geolocLinePath: {
              coordinates: [],
              showLayer: true,
              status: 'OFF',
            },
          }))
        },
        toggleGeolocLinePath: () => {
          return set((store) => ({
            geolocLinePath: { ...store.geolocLinePath, showLayer: !store.geolocLinePath.showLayer },
          }))
        },
      },
    }),
    {
      name: 'fmt',
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => key !== 'actions')),
    }
  )
)

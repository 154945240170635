import type { SyntheticEvent } from 'react'
import { BiLogInCircle } from 'react-icons/bi'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import { useLoginMutation } from 'lib/queries.login'

import { usePageTitle } from 'components/layout/hooks'

const PASSWD_RESET_URL = import.meta.env.VITE_PASSWD_RESET_URL

type LoginErrorProps = {
  errorText: string
}

function LoginError({ errorText }: LoginErrorProps) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Alert
        data-cy="login-error-msg"
        severity="error"
        variant="filled"
        style={{
          textAlign: 'left',
          maxWidth: 325,
          marginBottom: 'var(--sp-3)',
        }}
      >
        <AlertTitle>Could not sign in</AlertTitle>
        {errorText}
      </Alert>
    </div>
  )
}

export function LoginForm() {
  const mutation = useLoginMutation()
  const { isLoading, error } = mutation
  const queryClient = useQueryClient()

  usePageTitle('Sign in')

  function handleSubmit(e: SyntheticEvent) {
    e.preventDefault()

    const { email, password } = e.target as typeof e.target & {
      email: { value: string }
      password: { value: string }
    }

    // NOTE: Cypress does NOT work if this is in the body of the component. If
    // that is needed for some reason, maybe try this solution:
    // https://stackoverflow.com/a/69357598/1048518
    queryClient.clear()

    // TODO: how to obfuscate this? It's plain text 😱
    // https://earthoptics.atlassian.net/browse/DV-1816
    mutation.mutate({ email: email.value, password: password.value })
  }

  return (
    <>
      <img alt="company logo" src="/eo-logo-white.svg" />
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mb={3}>
          <TextField
            fullWidth
            required
            disabled={isLoading}
            helperText="Email is case-sensitive"
            id="email"
            label="Email"
            name="email"
            size="small"
            variant="filled"
          />
          <TextField
            fullWidth
            required
            disabled={isLoading}
            id="password"
            label="Password"
            name="password"
            size="small"
            type="password"
            variant="filled"
            helperText={
              <Link href={PASSWD_RESET_URL} target="blank">
                Forgot password?
              </Link>
            }
          />
        </Box>
        {error && <LoginError errorText={error?.message} />}
        <Button
          data-cy="login-btn"
          disabled={isLoading}
          endIcon={<BiLogInCircle />}
          size="large"
          type="submit"
          variant="contained"
        >
          Sign in
        </Button>
      </form>
    </>
  )
}

import React from 'react'
import { BsCloudMinus } from 'react-icons/bs'
import { TbFaceIdError } from 'react-icons/tb'
import { VscLayersActive, VscLayersDot } from 'react-icons/vsc'
import { CircularProgress } from '@mui/material'
import type { SoilCollectionDetail } from 'lib/api/django/model'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import type { LayersCapabilities } from 'components/tillmapper/types'

import { LayersAvatarLinksInline } from './LayersAvatarLinksInline'

// TODO: EPIC: unit test
function getPopulatedLayersKeys(layers?: SoilCollectionDetail['layers']) {
  if (!layers) {
    return []
  }

  return Object.entries(layers)
    .filter(([, v]) => v)
    .map(([k]) => k) as (keyof LayersCapabilities)[]
}

export function getCollnLayersStatusAndIcon(
  isLoading: boolean,
  isError: boolean,
  collection?: SoilCollectionDetail
) {
  const populatedLayersKeys = getPopulatedLayersKeys(collection?.layers)

  let icon = <VscLayersDot />
  let secondaryElem: React.ReactNode = 'Layer creation in progress'
  let color = 'default.main'

  if (isLoading) {
    icon = <CircularProgress size={24} />
    secondaryElem = 'Loading collection...'
  } else if (isError) {
    icon = <TbFaceIdError />
    secondaryElem = 'Could not get collection details'
  } else if (populatedLayersKeys?.length) {
    icon = <VscLayersActive />
    secondaryElem = <LayersAvatarLinksInline collection={collection} />

    color = 'success.main'
  } else if (collection?.status !== 'SUBMITTED') {
    icon = <BsCloudMinus />
    secondaryElem = 'Collection not yet submitted'
  } else {
    icon = <VscLayersDot />
    secondaryElem = 'Layer creation in progress'
    color = 'info.main'
  }

  return {
    icon,
    secondaryElem,
    color,
  }
}

export const fmtToastsConfig = {
  // Literally only in this file because it's a `.tsx` and the consumer at this
  // time is a hooks `.ts` file.
  collnCreateSuccess: (
    <ToastContentWithTitle
      content="Click to view in SoilCollector (opens in a new tab)."
      title="Collection created"
    />
  ),
}

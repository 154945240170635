/**
 * Login-related API queries
 */
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { API_BASE_PATH, apiEndpoints, axiosInstance, localStgAuthKeys } from 'lib/config/api'
import { topLevelAbsoluteAppRoutes, topLevelSearchParams } from 'lib/config/routes'

import { useAuth } from 'components/AuthProvider'

// API returns this object on successful POST to auth token endpoint
type LoginResponse = { access: string; refresh: string }

type Payload = {
  email: string
  password: string
}

type UseLoginMutation = UseMutationResult<
  AxiosResponse<LoginResponse>,
  { message: string },
  Payload,
  unknown
>

function getIntendedPageFromSearchParams(): string {
  const searchParams = new URLSearchParams(window.location.search)
  const from = searchParams.get(topLevelSearchParams.from)

  return decodeURIComponent(from || '')
}

export const useLoginMutation = (): UseLoginMutation => {
  const navigate = useNavigate()
  const auth = useAuth()

  return useMutation(
    (login: Payload) => axios.post(API_BASE_PATH + apiEndpoints.getAccessToken, login),
    {
      onSuccess: (res) => {
        auth.login()

        const intendedPage = getIntendedPageFromSearchParams()

        // Set Axios default Auth header, and access/refresh tokens in local storage, so
        // interceptors can use them for future requests
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.data.access}`

        localStorage.setItem(localStgAuthKeys.access, res.data.access)
        localStorage.setItem(localStgAuthKeys.refresh, res.data.refresh)

        // Redirect user if they came from anywhere but `/login`, e.g. from a SoilMapper link. There
        // should be a `from` property on the state since this is set in <PrivateRoute>.
        if (intendedPage) {
          navigate(intendedPage, { replace: true })
        } else {
          navigate(topLevelAbsoluteAppRoutes.home, { replace: true })
        }

        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User logged in',
          level: 'info',
        })
      },
    }
  )
}

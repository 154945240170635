import { useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import type { GridCsvExportOptions, GridExportDisplayOptions } from '@mui/x-data-grid-premium'
import { GridCsvExportMenuItem, GridExcelExportMenuItem } from '@mui/x-data-grid-premium'

import { BasicMenuItem } from 'components/reusable/handy-templates/BasicMenuItem'

interface ExportOptions extends GridCsvExportOptions, GridExportDisplayOptions {}

const FILENAME_PREFIX = 'earthoptics'
const BUTTON_TITLE = 'Table export options'

const OPTIONS: ExportOptions = {
  fileName: `${FILENAME_PREFIX}.${new Date().toISOString().replace(/ /, '').replace(/Z/, '')}`,
}

export function TableExportMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => setAnchorEl(null)

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title={BUTTON_TITLE}>
        <IconButton
          aria-controls={open ? 'table-export-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          aria-label={BUTTON_TITLE}
          color="primary"
          id="table-export-menu-button"
          onClick={handleBtnClick}
        >
          <AiOutlineDownload />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="table-export-menu"
        MenuListProps={{ 'aria-labelledby': 'table-export-menu' }}
        open={open}
        onClose={handleClose}
      >
        <BasicMenuItem
          handleClose={handleClose}
          subHeaderText={BUTTON_TITLE}
          uniqueKey="table-export-menu-header"
        />
        <GridCsvExportMenuItem options={OPTIONS} />
        <GridExcelExportMenuItem options={OPTIONS} />
      </Menu>
    </>
  )
}

import type { RouteConfigWithHandle } from 'lib/types'

import { routesConfig as dashboardRoutesConfig } from 'components/dashboard/views'
import { routesConfig as fmtRoutesConfig } from 'components/fields/views'
import { Layout } from 'components/layout/Layout'
import { routesConfig as soilCollectorRoutesConfig } from 'components/soil-sampling/views'
import { routesConfig as tillMapperRoutesConfig } from 'components/tillmapper/views'
import { routesConfig as uploadsRoutesConfig } from 'components/upload/views'

import { RouteErrorBoundary } from './alerts-and-messages/RouteErrorBoundary'

/**
 * User must be authenticated to access any of these routes. The check is handled in `<Layout />`.
 */
export const routesConfig: RouteConfigWithHandle[] = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <RouteErrorBoundary />,
    children: [
      ...dashboardRoutesConfig,
      ...tillMapperRoutesConfig,
      ...fmtRoutesConfig,
      // Anything from here down has top-level group checks
      ...soilCollectorRoutesConfig,
      ...uploadsRoutesConfig,
    ],
  },
]

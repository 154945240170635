import Typography from '@mui/material/Typography'

import { ExpandableToastContent } from './ExpandableToastContent'

export type Props = {
  /**
   * The title of the toast.
   */
  title: string
  /**
   * The main content of the toast, shown below the title and above the expandable content (if any).
   */
  content: React.ReactNode
  /**
   * If provided, this will be shown in a collapsible section below the content. The use case at the
   * time of writing is to show the error details.
   */
  expandableContent?: React.ReactNode
  /**
   * If provided, a button will be shown to copy this text to the clipboard. The use case at the
   * time of writing is to copy the error details to the clipboard.
   */
  copyableDetails?: string
}

export function ToastContentWithTitle(props: Props) {
  const { title, content, expandableContent, copyableDetails } = props

  return (
    <>
      <Typography
        component="span"
        display="block"
        fontWeight="bold"
        lineHeight={1.5}
        mb="4px"
        variant="subtitle1"
      >
        {title}
      </Typography>
      <Typography component="div" variant="caption">
        {content}
      </Typography>
      {!!expandableContent && (
        <ExpandableToastContent
          copyableDetails={copyableDetails}
          expandableContent={expandableContent}
        />
      )}
    </>
  )
}

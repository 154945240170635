import { useCallback } from 'react'
import type { DropzoneOptions, FileRejection } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import type { SxProps } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useV1SoilSamplingCollectionsFilesCreate } from 'lib/api/django/v1/v1'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'

import { SamplePlanSuccessToastContent } from './SamplePlanSuccessToastContent'

type Props = {
  collnId: string
  isOpen: boolean
  cancelBtn: React.ReactNode
  onClose: () => void
}

const REASONABLE_TRANSITION = 'all 0.3s ease'

const dzOptions: DropzoneOptions = {
  noClick: false,
  noKeyboard: true,
  multiple: false,
  maxFiles: 1,
  accept: ['.json', '.geojson'],
}

const commonWrapStyle: SxProps = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: REASONABLE_TRANSITION,
}

const rootSx: SxProps = {
  ...commonWrapStyle,
  cursor: 'pointer',
  userSelect: 'none',
  width: '100%',
  height: '100%',
  '&:hover': {
    color: 'text.primary',
  },
}

const contentSx: SxProps = {
  ...commonWrapStyle,
  borderRadius: 10,
  borderStyle: 'dashed',
  mb: 4,
}

function handleRejectedFile(e: FileRejection[]) {
  const firstReject = e[0]
  // YES there could be more. NO, don't care at this time. Making assumptions the user knows what
  // the format is.
  const firstError = firstReject.errors[0]

  toast.error(<ToastContentWithTitle content={firstError.message} title={firstError.code} />)
}

function handleMutationError(e: unknown) {
  const msg = e instanceof Error ? `ERROR: ${e.message}` : ''

  toast.error(() => (
    <ToastContentWithTitle
      content={msg || 'Check the format to make sure it is correct.'}
      title="Could not create samples from file"
    />
  ))
}

function useOnDrop(collnId: string, onClose: () => void) {
  const mutation = useV1SoilSamplingCollectionsFilesCreate()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const firstFile = acceptedFiles[0]

      function handleSuccess() {
        toast.success(
          (p) => (
            <SamplePlanSuccessToastContent
              closeToast={p.closeToast}
              collnId={collnId}
              filename={firstFile.name}
            />
          ),
          { closeOnClick: false, autoClose: 8000 }
        )
      }

      mutation.mutate(
        {
          id: collnId,
          data: {
            // TODO: rm these when OpenAPI doesn't expect readonly props
            id: '',
            collection_id: '',
            file_size: '',
            file_name: '',
            file_type: 'SAMPLING_PLAN',
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            file: firstFile,
            // @ts-ignore
            SAMPLING_PLAN: firstFile,
            /* eslint-enable @typescript-eslint/ban-ts-comment */
          },
        },
        {
          onError: handleMutationError,
          onSuccess: handleSuccess,
        }
      )

      onClose()
    },
    [collnId, mutation, onClose]
  )

  return onDrop
}

export function UploadSamplePlanDropzone(props: Props) {
  const { collnId, isOpen, cancelBtn, onClose } = props
  const onDrop = useOnDrop(collnId, onClose)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dzOptions,
    onDrop,
    onDropRejected: handleRejectedFile,
  })

  return (
    <Backdrop
      open={isOpen}
      sx={{
        color: '#fff',
        transition: REASONABLE_TRANSITION,
        bgcolor: `rgba(0, 0, 0, ${isDragActive ? 0.97 : 0.95})`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        aria-label="upload soil sampling plan"
        id="sample-plan-files"
        role="button"
        sx={rootSx}
        {...getRootProps()}
      >
        <input required name="sample-plan-files" {...getInputProps()} />
        <Box p={isDragActive ? 9 : 7} sx={contentSx}>
          <Typography
            color={isDragActive ? '#fff' : '#eee'}
            component="h3"
            mb={2}
            sx={{ transition: REASONABLE_TRANSITION }}
            variant="h2"
          >
            Drop sampling plan here, or click to browse
          </Typography>
          <Typography color="text.secondary" component="p" mb={3} variant="h6">
            Upload <code>*.json</code> or <code>*.geojson</code> files. Be sure to follow the
            required schema.
          </Typography>
        </Box>
        {cancelBtn}
      </Box>
    </Backdrop>
  )
}

import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { UseQueryResult } from '@tanstack/react-query'
import { useIsMutating, useQueryClient } from '@tanstack/react-query'
import type { PatchedSoilCollectionWriter, SoilCollectionReader } from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsListQueryKey,
  getV1SoilSamplingCollectionsRetrieveQueryKey,
  v1SoilSamplingCollectionsRetrieve,
} from 'lib/api/django/v1/v1'
import { useCurrentUser } from 'lib/queries.user'
import { isPromiseFulfilled, isPromiseRejected } from 'lib/utils'

import {
  collnDwnldQueryParams,
  localQueryKeys,
  MUTATION_KEYS,
  PRE_COLLECTION_STATUSES,
  UNDOWNLOADED_QUERY_KEY,
} from 'components/soil-sampling/config.ssa'
import { useCollections, useLocalCollnsKeys } from 'components/soil-sampling/hooks.ssa'
import {
  useCreateLocalCollnMutation,
  useLocalCollnMutation,
} from 'components/soil-sampling/mutations.ssa-local'
import {
  useRemoteCollnMutation,
  useRemoteSampleMutation,
} from 'components/soil-sampling/queries.ssa-remote'
import type { SoilCollection, SoilSample } from 'components/soil-sampling/types.ssa'

import { DownloadCollnsToastContent } from './DownloadCollnsToastContent'
import type { QueuedUpwardSample } from './types.colln-sync'
import { getCollnKeysToDownload } from './utils'

const MULTI_COLLN_DWNLD_TOAST_ID = 'multi-colln-dwnld'

/**
 * Hook to overwrite all safe/whitelisted collection properties that are read-only on device, and
 * add/remove any samples that were created/deleted in backend into the local collection.
 *
 * @returns `useQuery` for the current collection's remote detail GET
 */
export function useSafeDownwardSync() {
  const { mutate } = useLocalCollnMutation(true, false, false)

  const mutationCb = useCallback(
    (payload: SoilCollection) => {
      async function mutateLocal() {
        // @ts-expect-error the payload WAS fine until annotations were allowed in the payload. The
        // OpenAPI is really messed up for those, so not gonna fight it here.
        mutate({ id: payload.short_id, data: payload })
      }

      mutateLocal()
    },
    [mutate]
  )

  return mutationCb
}

/**
 * Hook to get `short_id`s of collections that need to be downloaded.
 *
 * @returns `useQuery` instance with array of strings returned by `select`.
 */
function useMyUndownloadedCollnsKeys(): UseQueryResult<string[]> {
  const { isLoading, isError, data: user } = useCurrentUser()
  const currentUserId = user?.id
  const localCollnsKeysQuery = useLocalCollnsKeys()
  const localKeys = localCollnsKeysQuery.data

  const enabled =
    !isLoading &&
    !isError &&
    !localCollnsKeysQuery.isLoading &&
    !localCollnsKeysQuery.isError &&
    !!localKeys

  // Putting this into a callback is an attempt to prevent the `select` function from running more
  // than once. Sometimes, when it is run more than once, it somehow triggers the "You have
  // collections to download" popup which, when clicked, causes all the collections on the device to
  // be overwritten. The only known way at time of writing to determine if it's working is to log in
  // the console. With the callback it only runs once, and without it runs at least twice.
  const select = useCallback(
    (data: SoilCollectionReader[]) => {
      return getCollnKeysToDownload(data, localKeys, currentUserId)
    },
    [currentUserId, localKeys]
  )

  const params = {
    ...collnDwnldQueryParams,
    assignee: currentUserId,
  }

  const { remote: remoteCollnsQuery } = useCollections(
    { enabled: false },
    {
      enabled,
      select,
      queryKey: UNDOWNLOADED_QUERY_KEY,
    },
    params
  )

  return remoteCollnsQuery
}

export function useSaveCollectionsToDevice() {
  const navigate = useNavigate()
  const undownloadedCollnKeysQuery = useMyUndownloadedCollnsKeys()
  const rqc = useQueryClient()
  const { mutateAsync: mutateAsyncLocalNew } = useCreateLocalCollnMutation()
  const { mutateAsync: mutateAsyncRemote } = useRemoteCollnMutation()
  const { data: undownloadedCollnKeys, isInitialLoading, isError } = undownloadedCollnKeysQuery
  const notReady = isInitialLoading || isError || !undownloadedCollnKeys?.length

  useEffect(() => {
    if (notReady) {
      return
    }

    // CRED: (ish) for this nightmare: https://stackoverflow.com/a/72365741/1048518
    async function handleClick() {
      const localMutations: Promise<SoilCollection>[] = []
      const collnsIds = undownloadedCollnKeys || []
      const remoteCollns: Promise<SoilCollection>[] = []
      const remoteMutations: Promise<PatchedSoilCollectionWriter>[] = []
      // Not sure how else to say which collections should become DOWNLOADED 🤷
      const idsOfCollnsWhereDownloadIsNext: string[] = []

      for (let index = 0; index < collnsIds.length; index += 1) {
        const collnId = collnsIds[index]
        const queryKey = getV1SoilSamplingCollectionsRetrieveQueryKey(collnId)

        const remoteData = rqc.fetchQuery(queryKey, () =>
          v1SoilSamplingCollectionsRetrieve(collnId)
        )

        remoteCollns.push(remoteData)
      }

      await Promise.all(remoteCollns)
        .then((collns) => {
          collns.forEach((colln) => {
            const collnId = colln.short_id as string
            const downloadedIsNext = PRE_COLLECTION_STATUSES.includes(colln.status)

            const mutation = mutateAsyncLocalNew({
              id: collnId,
              data: {
                ...colln,
                ...(downloadedIsNext && { status: 'DOWNLOADED' }),
              },
            })

            localMutations.push(mutation)

            if (downloadedIsNext) {
              idsOfCollnsWhereDownloadIsNext.push(collnId)
            }
          })
        })
        .catch((err) => {
          toast.error(`Could not update collections on server: ${err.message}`, {
            position: 'bottom-left',
          })
        })

      await Promise.all(localMutations).then(() => {
        // Prevent edge cases where things happen out of order, e.g. user deleted their local
        // collection and then re-downloaded it
        idsOfCollnsWhereDownloadIsNext.forEach((short_id) => {
          remoteMutations.push(
            mutateAsyncRemote({
              id: short_id,
              data: { status: 'DOWNLOADED' },
            })
          )
        })
      })

      await Promise.all(remoteMutations)
        .then(() => {
          // By not supplying any params, it will invalidate any active queries matching the
          // `[`/v1/soil_sampling/collections/`]` pattern. If more granularity is needed, change it
          // here.
          const remoteQueryKey = getV1SoilSamplingCollectionsListQueryKey()

          rqc.invalidateQueries(localQueryKeys.root)

          // Instant gratification: see all the statuses change to "Downloaded"
          rqc.invalidateQueries(remoteQueryKey)

          toast.success(
            `${collnsIds.length} collection${collnsIds.length > 1 ? 's' : ''} downloaded.`
          )
        })
        .catch((err) => {
          toast.error(`Problem downloading or syncing collections: ${err.message}`, {
            position: 'bottom-left',
          })
        })
    }

    toast.info(
      ({ closeToast }) => (
        <DownloadCollnsToastContent
          closeToast={closeToast}
          count={undownloadedCollnKeys.length}
          onClick={handleClick}
        />
      ),

      {
        autoClose: false,
        toastId: MULTI_COLLN_DWNLD_TOAST_ID,
        position: 'bottom-left',
      }
    )
  }, [
    undownloadedCollnKeys,
    rqc,
    mutateAsyncRemote,
    navigate,
    notReady,
    mutateAsyncLocalNew,
  ])
}

export function useUpwardSampleSync() {
  const { mutateAsync } = useRemoteSampleMutation()
  const isMutating = useIsMutating({ mutationKey: MUTATION_KEYS.remoteSample })

  const mutationCb = useCallback(
    (upwardPayloads: QueuedUpwardSample[], onSuccess?: () => void) => {
      // Jump ship to avoid multi-PATCH
      if (isMutating) {
        return
      }

      const promises: Promise<SoilSample>[] = []

      async function mutateSamples() {
        upwardPayloads.forEach(({ sampleId, payload }) => {
          const mutation = mutateAsync({ id: sampleId, data: payload })

          promises.push(mutation)
        })

        await Promise.allSettled(promises).then((settledResult) => {
          const successes = settledResult.filter(isPromiseFulfilled)
          const failures = settledResult.filter(isPromiseRejected)

          if (onSuccess) {
            onSuccess()
          }

          if (successes.length) {
            // eslint-disable-next-line no-console
            console.log('🗒️', successes)
          }

          if (failures.length) {
            // eslint-disable-next-line no-console
            console.log('🗒️', failures)
          }
        })
      }

      mutateSamples()
    },
    [isMutating, mutateAsync]
  )

  return mutationCb
}

export function useUpwardCollnSync() {
  const { mutate } = useRemoteCollnMutation()
  const isMutating = useIsMutating({ mutationKey: MUTATION_KEYS.remoteColln })

  const mutationCb = useCallback(
    (collnShortId: string, field_notes: string) => {
      if (isMutating) {
        return
      }

      mutate({ id: collnShortId, data: { field_notes } })
    },
    [isMutating, mutate]
  )

  return mutationCb
}

// Paranoid checks to disable button while things are happening. Probably not possible to end up in
// these states, but there's so much cross-talk between local and remote mutations and queries,
// doesn't hurt to check.
export function useIsCollnSyncing(
  localFetching: boolean,
  remoteFetching: boolean,
  remoteSettled: boolean
) {
  const queryIsFetching = localFetching || remoteFetching
  const localCollnIsMutating = useIsMutating({ mutationKey: MUTATION_KEYS.localColln })
  const localSampleIsMutating = useIsMutating({ mutationKey: MUTATION_KEYS.localSample })
  const localIsMutating = localCollnIsMutating || localSampleIsMutating

  const isLoading =
    queryIsFetching ||
    !!localCollnIsMutating ||
    !!localSampleIsMutating ||
    !!localIsMutating ||
    !remoteSettled

  return isLoading
}

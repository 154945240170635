import { Navigate, Outlet, useMatches } from 'react-router-dom'
import { useCurrentUser } from 'lib/queries.user'
import type { Handle } from 'lib/types'
import { checkUserAccessByGroup } from 'lib/utils/auth'

import { CenteredLoadingSpinner } from 'components/reusable/loaders-and-transitions'
import { segments } from 'components/reusable/routes'

type RedirectStatus = 'loading' | 'ok' | 'unauthorized'

/**
 * At time of writing, this is an access check based entirely group checks.
 *
 * @returns `loading` if the user query is still loading, `ok` if the user is authorized to view the
 * page, `unauthorized` if they are not.
 */
function useRedirectFate(): RedirectStatus {
  const userQuery = useCurrentUser()
  const { data: user } = userQuery
  const matches = useMatches()

  if (userQuery.isLoading) {
    return 'loading'
  }

  let hasAccess = false

  // TODO: find a better way to make this work for nested routes where the user lacks access to that
  // route but not the parent or other ancestors.
  matches.forEach((match) => {
    const { handle } = match as { handle?: Handle }

    if (handle?.allowedGroups) {
      hasAccess = checkUserAccessByGroup(handle.allowedGroups, user?.groups)
    }
  })

  return hasAccess ? 'ok' : 'unauthorized'
}

/**
 * At time of writing, this is an access check based entirely group checks. It does not check to see
 * if the user is logged in- that is handled in Layout.
 *
 * @returns loading spinner if loading, or a redirect to the unauthorized page if the user is not
 * authorized to view the page, otherwise the route outlet. Routelet? 😆
 */
export function PrivateRouteViaHandle() {
  const redirectFate = useRedirectFate()

  if (redirectFate === 'loading') {
    return <CenteredLoadingSpinner /> // still waiting on user query
  }

  if (redirectFate === 'unauthorized') {
    return <Navigate replace to={`/${segments.unauthorized}`} />
  }

  return <Outlet />
}

import { darken } from '@mui/material'
import type { CircleLayer, FillLayer, Layer, LineLayer, SymbolLayer, SymbolLayout } from 'mapbox-gl'

import {
  EXISTING_SYMB_COLOR,
  IMPORTED_SYMB_COLOR,
  labelsLayoutCommonToAllGeoms,
  POINT_FILTER,
  pointsLabelsLayout,
  pointsLabelsPaint,
  POLY_OR_MULTIPOLY_FILTER,
  selectedFieldOutlinePaint,
} from './config.symbology'

export const IMPORTED_FIELDS_SRC_ID = 'imported-fields'
export const EXISTING_FIELDS_SRC_ID = 'existing-fields'
export const SELECTED_FIELD_SRC_ID = 'selected-field'
export const ACB_LAYER_ID = 'acb'
export const ACB_SOURCE_ID = 'colln-acb'
export const SELECTED_ACB_ID = `${ACB_LAYER_ID}-selected`

const EXISTING_SYMB_DARK = darken(EXISTING_SYMB_COLOR, 0.6)
const VECTOR_TILES_ORG_NAME_KEY = 'org_name' // flattened from `org` or `owned_by` or whatever

export const COMMON_FIELD_LAYER_PROPS: Pick<Layer, 'source-layer' | 'source'> = {
  source: 'public.fields_owner',
  'source-layer': 'default',
}

const FONT_SCALE = 0.7
const LABELS_MIN_ZOOM = 6.5

export const existingFieldsLabelLayout: SymbolLayout = {
  ...labelsLayoutCommonToAllGeoms,
  'text-field': [
    'format',
    ['get', 'name'],
    {},
    '\n',
    {},
    ['get', VECTOR_TILES_ORG_NAME_KEY],
    { 'font-scale': FONT_SCALE },
  ],
}

// Transparent but need a fill so it's still clickable. (TBD)
const common2Dfill: Pick<FillLayer, 'filter' | 'paint' | 'type'> = {
  type: 'fill',
  filter: POLY_OR_MULTIPOLY_FILTER,
  paint: { 'fill-opacity': 0 },
}

export const imported2Dfill: FillLayer = {
  ...common2Dfill,
  id: 'new-field-boundaries',
  source: IMPORTED_FIELDS_SRC_ID,
}

export const imported2Doutline: LineLayer = {
  id: 'new-field-boundaries-outline',
  source: IMPORTED_FIELDS_SRC_ID,
  type: 'line',
  filter: POLY_OR_MULTIPOLY_FILTER,
  paint: {
    'line-color': IMPORTED_SYMB_COLOR,
    'line-width': 2,
  },
}

export const importedPoints: CircleLayer = {
  id: 'new-field-points',
  source: IMPORTED_FIELDS_SRC_ID,
  type: 'circle',
  filter: POINT_FILTER,
  paint: { 'circle-radius': 8, 'circle-color': IMPORTED_SYMB_COLOR },
}

export const existing2Dfill: FillLayer = {
  ...common2Dfill,
  id: 'existing-field-boundaries',
  source: EXISTING_FIELDS_SRC_ID,
}

export const existing2Doutline: LineLayer = {
  id: 'existing-field-boundaries-outline',
  source: EXISTING_FIELDS_SRC_ID,
  type: 'line',
  filter: POLY_OR_MULTIPOLY_FILTER,
  paint: {
    'line-color': EXISTING_SYMB_COLOR,
    'line-width': 1.5,
  },
}

export const existingPoints: CircleLayer = {
  id: 'existing-field-points',
  source: EXISTING_FIELDS_SRC_ID,
  type: 'circle',
  filter: POINT_FILTER,
  paint: {
    'circle-color': EXISTING_SYMB_COLOR,
    'circle-stroke-color': EXISTING_SYMB_DARK,
    'circle-stroke-width': 1.5,
    'circle-radius': [
      'interpolate',
      ['exponential', 0.5], // set the exponential rate of change to 0.5
      ['zoom'],
      5, // when zoom is at least this, circle-radius is value on next line
      3,
      9.5, // but when zoom is this or higher, circle-radius is value on next line
      7,
    ],
  },
}

export const existingLabelsLayerProps: SymbolLayer = {
  id: 'existing-labels',
  source: EXISTING_FIELDS_SRC_ID,
  type: 'symbol',
  minzoom: LABELS_MIN_ZOOM,
  paint: pointsLabelsPaint,
  layout: {
    ...existingFieldsLabelLayout,
    ...pointsLabelsLayout,
  },
}

export const selectedPolyOutline: LineLayer = {
  id: 'selected-field-boundary-outline',
  source: SELECTED_FIELD_SRC_ID,
  type: 'line',
  filter: POLY_OR_MULTIPOLY_FILTER,
  paint: selectedFieldOutlinePaint,
}

export const selectedPoint: CircleLayer = {
  id: 'selected-field-points',
  source: SELECTED_FIELD_SRC_ID,
  type: 'circle',
  filter: POINT_FILTER,
  paint: {
    'circle-radius': 8,
    'circle-stroke-width': 1.5,
  },
}

export const selectedImportPoint: CircleLayer = {
  ...selectedPoint,
  id: 'selected-imported-field-point',
  source: IMPORTED_FIELDS_SRC_ID,
  filter: POINT_FILTER,
  paint: {
    ...selectedPoint.paint,
    'circle-color': IMPORTED_SYMB_COLOR,
  },
}

export const selectedImportOutline: LineLayer = {
  ...selectedPolyOutline,
  id: 'selected-imported-boundary-outline',
  source: IMPORTED_FIELDS_SRC_ID,
  paint: {
    ...selectedPolyOutline.paint,
    'line-color': IMPORTED_SYMB_COLOR,
  },
}

export const importedLabelsLayerProps: SymbolLayer = {
  ...existingLabelsLayerProps,
  id: 'imported-field-labels',
}

export const interactiveNewFieldLayerIds = [
  existing2Dfill.id,
  imported2Dfill.id,
  existingPoints.id,
  importedPoints.id,
  existingLabelsLayerProps.id,
  importedLabelsLayerProps.id,
] as string[]

import { useState } from 'react'
import type { Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import type { Contact } from 'lib/api/django/model'
import { useV1ContactsList } from 'lib/api/django/v1/v1'
import { sortArrOfObjects } from 'lib/utils'

import { useCommonProps } from 'components/fields/contacts/hooks.contacts'
import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

type Props = {
  control?: Control<FieldsMgmtFormSchema>
  disabled?: boolean
  helperText?: string
  initialValue?: Contact | null
  onChange?: (contact: Contact | null) => void
  /** Org to filter on, e.g. to make sure it is the same as that of Field */
  orgId?: string
  placeholder?: string
  required?: boolean
}

// TODO: wire ContactPersonFormModal back up (see Slack link in ticket)
// https://earthoptics.atlassian.net/browse/DV-2383
export function ContactsAutocomplete(props: Props) {
  const { initialValue, onChange, orgId, control } = props
  const [localState, setLocalState] = useState<Contact | null>(initialValue || null)

  const handleChange = (newValue: Contact | null) => {
    setLocalState(newValue)

    if (onChange) onChange(newValue)
  }

  const { data, isLoading, isError } = useV1ContactsList({
    query: {
      select: (d) => {
        const sorted = d.sort(sortArrOfObjects('name'))

        return orgId ? sorted.filter(({ org }) => org.id === orgId) : sorted
      },
    },
  })

  const commonProps = useCommonProps(props, isLoading, data)

  if (isError) return <div>Problem fetching Contacts list</div>

  if (control) {
    return (
      <Controller
        control={control}
        name="onsite_contact"
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...commonProps}
            onChange={(_, val) => {
              field.onChange(val)

              if (onChange) onChange(val)
            }}
          />
        )}
      />
    )
  }

  return (
    <Autocomplete {...commonProps} value={localState} onChange={(_, val) => handleChange(val)} />
  )
}

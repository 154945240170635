import { Offline, Online } from 'react-detect-offline'
import { FiLogOut } from 'react-icons/fi'
import { ImWarning } from 'react-icons/im'
import { Link as RouterLink } from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { POLLING_CONFIG } from 'lib/config'

import { segments } from 'components/reusable/routes'

const MIN_ICON_WIDTH = 24

export function LogoutBtn() {
  return (
    <>
      {/* Prevent logout if offline, it will destroy user stuff */}
      <Offline polling={POLLING_CONFIG}>
        <ListItem sx={{ color: 'error.main' }}>
          <ListItemIcon sx={{ minWidth: MIN_ICON_WIDTH, color: 'error.main' }}>
            <ImWarning />
          </ListItemIcon>
          <ListItemText primary="Cannot sign out while offline" />
        </ListItem>
      </Offline>
      <Online polling={POLLING_CONFIG}>
        <ListItemButton
          component={RouterLink}
          data-cy="sign-out-btn"
          data-testid="sign-out-btn"
          to={`/${segments.logout}`}
        >
          <ListItemIcon sx={{ minWidth: MIN_ICON_WIDTH }}>
            <FiLogOut />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItemButton>
      </Online>
    </>
  )
}

// TODO: 1) make options an object, it's getting meaty 2) unit test new options
export const getPrettyDate = (
  rawDateString: string,
  omitTime?: boolean,
  showSeconds?: boolean,
  omitDate?: boolean
): string => {
  const dateAsDate = new Date(rawDateString)

  return dateAsDate.toLocaleString('default', {
    month: omitDate ? undefined : 'numeric',
    day: omitDate ? undefined : 'numeric',
    year: omitDate ? undefined : 'numeric',
    hour: omitTime ? undefined : 'numeric',
    hour12: true,
    minute: omitTime ? undefined : '2-digit',
    second: showSeconds ? '2-digit' : undefined,
  })
}

// CRED: https://stackoverflow.com/a/15764763/1048518
export function getMonthDayYear(dateString?: string): string {
  const date = dateString ? new Date(dateString) : new Date()
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${month}/${day}/${year}`
}

/**
 * Get `Date` in UTC time zone from a date string in YYYY-MM-DD format. Avoids the issue of showing
 * the user's local date instead of UTC.
 *
 * @param origDateString date string in YYYY-MM-DD format
 * @returns Date in American locale but UTC time
 */
export function getUTCdateFromYYYYMMDD(origDateString: string): Date {
  const date = new Date(origDateString)
  const utcDate = date.toLocaleString('en', { timeZone: 'UTC' })

  return new Date(utcDate)
}

// TODO: DV-4647 - unit tests
// TODO: handle future
// TODO: unit test
// CRED: https://stackoverflow.com/a/6109105/1048518
export function timeDifference(current: Date, previous: Date, abbrev?: boolean): string {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365
  const elapsed = Number(current) - Number(previous)

  if (elapsed < msPerMinute) {
    const seconds = Math.round(elapsed / 1000)
    const text = abbrev ? 's' : `second${seconds > 1 ? 's' : ''}`

    return `${seconds} ${text} ago`
  }

  if (elapsed < msPerHour) {
    const minutes = Math.round(elapsed / msPerMinute)
    const text = abbrev ? 'min' : `minute${minutes > 1 ? 's' : ''}`

    return `${minutes} ${text} ago`
  }

  if (elapsed < msPerDay) {
    const hours = Math.round(elapsed / msPerHour)
    const text = abbrev ? 'hr' : `hour${hours > 1 ? 's' : ''}`

    return `${hours} ${text} ago`
  }

  if (elapsed < msPerMonth) {
    const days = Math.round(elapsed / msPerDay)
    const text = abbrev ? 'd' : ` day${days > 1 ? 's' : ''}`

    return `${days}${text} ago`
  }

  if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth)

    return `${months} month${months > 1 ? 's' : ''} ago`
  }

  const years = Math.round(elapsed / msPerYear)
  const text = abbrev ? 'yr' : `year${years > 1 ? 's' : ''}`

  return `${years} ${text} ago`
}

export const getUTCdateFromUnixInt = (dateInt: number): Date => new Date(dateInt * 1000)

// 1534723200 becomes August 20, 2018
export function unixTimeToPrettyDate(dateInt: number): string {
  const dtFormat = new Intl.DateTimeFormat('en-us', {
    timeZone: 'UTC',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  })

  return dtFormat.format(new Date(dateInt * 1000))
}

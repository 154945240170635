import { ConfMessage } from './ConfMessage'

type Props = {
  error?: unknown
}

export function QueryErrorMsg({ error }: Props) {
  // CRED: https://stackoverflow.com/a/64452744/1048518
  const text = error instanceof Error ? error.message : 'Try reloading the page'

  return <ConfMessage fullWidth severity="error" text={text} title="Problem fetching data" />
}

import { useState } from 'react'
import type { Org } from 'lib/api/django/model'

import { OrgAutocomplete } from 'components/reusable/OrgAutocomplete'

import { FORM_INPUTS } from './config.sampling-plans'

export function MultiThingOrgAutocomplete() {
  const [org, setOrg] = useState<Org | null>(null)

  return (
    <>
      <input name={FORM_INPUTS.org} type="hidden" value={org?.id || ''} />
      <OrgAutocomplete
        omitDefaultHelperText
        required
        onChange={(selectedOrg) => setOrg(selectedOrg)}
      />
    </>
  )
}

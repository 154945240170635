/**
 * Convert slug-like text to lowercase text with spaces.
 *
 * @example slugToLowercaseSentence('NOT_READY') // returns 'not ready'
 * @param text "slug" text to convert
 * @returns lowercase original text with spaces instead of underscores
 */
export function slugToLowercaseSentence(text: string): string {
  return text.toLowerCase().replace(/_/g, ' ')
}

export function snakeToSentenceCase(text: string): string {
  const firstCharCapitalized = text.charAt(0).toUpperCase()

  return firstCharCapitalized + slugToLowercaseSentence(text).slice(1)
}

// TODO: unit test
// CRED: https://gist.github.com/codeguy/6684588
export function slugify(text = ''): string {
  let str = text.replace(/^\s+|\s+$/g, '') // trim

  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'

  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

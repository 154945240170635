import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import { queryClient } from 'lib/config/react-query'
import { isPromiseFulfilled, isPromiseRejected } from 'lib/utils'

import { FMTbulkErrorsModal } from 'components/fields/fields-mgmt/FMTbulkErrorsModal'
import { getV1FieldsListQueryKey } from 'components/fields/fields-mgmt/queries.fields-mgmt.gets'
import { useV1FieldsCreate } from 'components/fields/fields-mgmt/queries.fields-mgmt.post'
import { showSuccessToast } from 'components/fields/fields-mgmt/utils.fields-mgmt-form'
import type { ImportError } from 'components/fields/types.fields-mgmt'
import {
  getBulkCreateFieldsMutations,
  prepBulkCreateFieldsFailReasons,
} from 'components/fields/utils.fields-mgmt'
import { ConfMessage } from 'components/reusable/alerts-and-messages'
import { FormModal } from 'components/reusable/handy-templates/FormModal'

import { useDispatchContext, useStateContext } from './NewFieldContext'

const listQueryKey = getV1FieldsListQueryKey()

function FieldsMissingNameWarning({ count }: { count: number }) {
  if (count === 0) return null

  return (
    <ConfMessage
      severity="warning"
      text="Each field needs a name in order to import. Close this dialog and enter the names, or click Submit to submit fields with a valid name."
      title={`Name missing in ${count} field(s)`}
      variant="outlined"
    />
  )
}

function PreSubmitConfirmation({ count }: { count: number }) {
  if (!count) return null

  const Msg = (
    <ConfMessage
      severity="info"
      title="A note about submission errors"
      variant="outlined"
      text={
        <ul style={{ paddingLeft: 0 }}>
          <li>
            If a submitted Field is rejected (e.g. because a Field with that name and Org already
            exists in the system), the error and Field name will be displayed in a subsequent dialog
            summary.
          </li>
          <li>The failed Fields will remain in the table for inspection.</li>
          <li>
            Although the Fields are submitted in order, a failed submission for one Field does not
            "block" that of the next. So, it is possible to have both successes and failures in the
            same submission.
          </li>
        </ul>
      }
    />
  )

  return (
    <>
      <Typography mb={3}>
        You are about to create {count} Field{count > 1 ? 's' : ''}. Any Fields without a name will
        not be included. If you wish to submit them, close this dialog and supply a name first. When
        you are ready to submit, click the <b>SUBMIT</b> button below.
      </Typography>
      {Msg}
    </>
  )
}

function getModalTitle(fieldsCount: number) {
  let modalTitle = 'Submit fields'

  if (fieldsCount) {
    modalTitle = `Submit ${fieldsCount} field${fieldsCount > 1 ? 's' : ''}`
  }

  return modalTitle
}

export function NewFieldsSubmitModal(props: { onSubmit?: () => void }) {
  const { onSubmit } = props
  const { importedFeatures } = useStateContext()
  const dispatch = useDispatchContext()
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false)
  const [errors, setErrors] = useState<ImportError[]>([])
  const fieldsWithoutName = importedFeatures.filter((f) => !f.properties.name)
  const fieldsWithName = importedFeatures.filter((f) => f.properties.name)
  const modalTitle = getModalTitle(fieldsWithName.length)

  const mutation = useV1FieldsCreate({
    mutation: {
      onSuccess: (res, { properties: { id } }) => {
        // Clean up after ourselves
        dispatch({ type: 'DELETE_IMPORTED_FEAT_BY_ID', payload: [id] })
      },
    },
  })

  async function handleSubmit() {
    const allMutations = getBulkCreateFieldsMutations(fieldsWithName, mutation.mutateAsync)

    await Promise.allSettled(allMutations).then((settledResult) => {
      const successes = settledResult.filter(isPromiseFulfilled)
      const failures = settledResult.filter(isPromiseRejected)
      const failureReasons = failures.map(prepBulkCreateFieldsFailReasons)

      if (successes.length) {
        queryClient.invalidateQueries(listQueryKey)

        showSuccessToast('created', `${successes.length} Fields`, '')
      }

      if (failureReasons.length) {
        setErrors(failureReasons)
        setIsErrorsModalOpen(true)
      }
    })
  }

  return (
    <>
      <LoadingButton
        disabled={!fieldsWithName.length}
        loading={mutation.isLoading}
        variant="contained"
        onClick={() => {
          setIsSubmitModalOpen(true)
        }}
      >
        Continue to confirmation
      </LoadingButton>
      <FMTbulkErrorsModal
        errors={errors}
        isOpen={isErrorsModalOpen}
        setIsOpen={setIsErrorsModalOpen}
        title="Problems importing these fields"
        confMsgOptions={{
          text: 'They will remain in the Imports table for inspection.',
          title: 'The following fields could not be created',
        }}
      />
      <FormModal
        slideUp
        disableSubmit={!fieldsWithName.length || mutation.isLoading}
        elemId="new-fields-submit"
        isOpen={isSubmitModalOpen}
        setIsOpen={setIsSubmitModalOpen}
        title={modalTitle}
        content={
          <>
            <FieldsMissingNameWarning count={fieldsWithoutName.length} />
            <PreSubmitConfirmation count={fieldsWithName.length} />
          </>
        }
        onSubmit={onSubmit || handleSubmit}
      />
    </>
  )
}

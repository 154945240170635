export function getAbsoluteValue(value: number): string {
  return `${Math.abs(value)}`
}

export function getAverage(
  sum: number,
  lengthOfData: number,
  roundToTenths = true
): number | typeof NaN {
  if (roundToTenths) return Math.round((sum / lengthOfData) * 100) / 100

  return ((sum / lengthOfData) * 100) / 100
}

// CRED: power move from Jamie https://tinyurl.com/y9xta5px
export function roundNumber(value: number, numberOfDecimalPlaces: number): number {
  const power = 10 ** numberOfDecimalPlaces

  return Math.round(value * power) / power
}

import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { CollectionFilesTable } from './CollectionFilesTable'

export function FmtCollnExistingFilesView() {
  const { collnId } = useParams<{ collnId: string }>()

  return (
    <Box bottom={0} left={0} position="absolute" right={0} sx={{ overflow: 'hidden' }} top={0}>
      <CollectionFilesTable fullCollnId={collnId} wrapperSx={{ height: '100%' }} />
    </Box>
  )
}

import { useState } from 'react'
import { Detector } from 'react-detect-offline'
import { FaTrash } from 'react-icons/fa'
import { RiSignalWifiOffLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useV1SoilSamplingCollectionsAnnotationsDestroy } from 'lib/api/django/v1/v1'
import type { ErrorType } from 'lib/api/django-axios-instance'
import { POLLING_CONFIG } from 'lib/config'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { FormModal } from 'components/reusable/handy-templates/FormModal'

export type DeleteAnnoBtnAndModalProps = {
  annotationId: string
  /** Full id, which must be split so the queries can invalidate 😞 */
  collectionId: string
  disabled?: boolean
  /**
   * If anno is deleted from detail view, it needs to navigate back to the list view, for example.
   * But in the case of _table_ view, it's fine to stay put, so pass `false` in that case.
   *
   * @default '..'
   */
  navigateOnSuccessTo?: string | false
  onSuccessHandler?: () => void
  /** Icon-only looks better in table view, for example */
  showFullBtn?: boolean
}

export function DeleteAnnoBtnAndModal(props: DeleteAnnoBtnAndModalProps) {
  const { annotationId, collectionId, showFullBtn } = props
  const { navigateOnSuccessTo = '..', onSuccessHandler, disabled } = props
  const mutation = useV1SoilSamplingCollectionsAnnotationsDestroy()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const navigate = useNavigate()

  function handleClick() {
    setModalIsOpen(true)
  }

  function onSuccess() {
    toast.success('Annotation deleted successfully.')

    if (onSuccessHandler) {
      onSuccessHandler()
    }

    if (navigateOnSuccessTo) {
      navigate(navigateOnSuccessTo)
    }
  }

  function onError(err: ErrorType<unknown>) {
    const defaultErrMsgText = 'Refresh the page then try again.'

    toast.error(
      <ToastContentWithTitle
        content={err instanceof Error ? err.message : defaultErrMsgText}
        title="Annotation not deleted"
      />
    )
  }

  function handleSubmit() {
    mutation.mutate({ annotationId, collectionId }, { onSuccess, onError })
  }

  let Btn = null

  if (showFullBtn) {
    Btn = (
      <Detector
        polling={POLLING_CONFIG}
        render={({ online }) => (
          <Button
            disabled={!online || disabled}
            startIcon={online ? <FaTrash /> : <RiSignalWifiOffLine />}
            onClick={handleClick}
          >
            delete
          </Button>
        )}
      />
    )
  } else {
    Btn = (
      <Detector
        polling={POLLING_CONFIG}
        render={({ online }) => (
          <IconButton
            aria-label="delete"
            disabled={!online || disabled}
            size="small"
            onClick={handleClick}
          >
            {online ? <FaTrash /> : <RiSignalWifiOffLine />}
          </IconButton>
        )}
      />
    )
  }

  return (
    <>
      {Btn}
      <FormModal
        content="You cannot undo this operation. Are you sure you wish to proceed?"
        elemId="delete-annotation"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        submitBtnText="Delete annotation"
        title="Delete annotation"
        onSubmit={handleSubmit}
      />
    </>
  )
}

import { useState } from 'react'
import { BiReset } from 'react-icons/bi'
import { FaListAlt } from 'react-icons/fa'
import { MdArrowDropDown } from 'react-icons/md'

import { BasicMenu } from 'components/reusable/handy-templates/BasicMenu'
import { FormModal } from 'components/reusable/handy-templates/FormModal'
import type { MenuItemConfig } from 'components/reusable/handy-templates/types'
import type { TableToolbarProps } from 'components/reusable/tables/types'

import { useCurrentUserMostRecentViews, useHandleResetBtnClick } from './hooks'
import { NewSavedViewFormModal } from './NewSavedViewFormModal'
import { TableViewsTable } from './TableViewsTable'
import type { TableViewsTableProps } from './types'
import { getCoreMenuItems } from './utils'

interface Props
  extends Pick<TableToolbarProps, 'initialState' | 'tableSlug'>,
    Partial<Pick<TableViewsTableProps, 'parentTableApiRef' | 'parentTableBaseRoute'>> {}

export function SavedViewsMenu(props: Props) {
  const { tableSlug, initialState, parentTableApiRef, parentTableBaseRoute } = props
  const views = useCurrentUserMostRecentViews(tableSlug)
  const [showManageViewsModal, setShowManageViewsModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const shouldShowCoreItems = !!tableSlug && !!parentTableApiRef && !!parentTableBaseRoute
  const handleResetBtnClick = useHandleResetBtnClick(initialState)
  const count = views.length

  function handleSaveClick() {
    setShowSaveModal(true)
  }

  function handleManageClick() {
    setShowManageViewsModal(true)
  }

  let coreItems: MenuItemConfig[] = []

  if (shouldShowCoreItems) {
    coreItems = getCoreMenuItems(handleSaveClick, handleManageClick, !count)
  }

  const TheMenu = (
    <BasicMenu
      btnText="views"
      elemId="saved-table-views"
      btnProps={{
        size: 'small',
        startIcon: <FaListAlt />,
        endIcon: <MdArrowDropDown />,
      }}
      items={[
        ...coreItems,
        {
          uniqueKey: 'reset',
          text: 'Reset to default view',
          icon: <BiReset />,
          onClick: handleResetBtnClick,
        },
        ...views,
      ]}
    />
  )

  return (
    <>
      {TheMenu}
      {tableSlug && (
        <NewSavedViewFormModal
          isOpen={showSaveModal}
          setIsOpen={setShowSaveModal}
          tableSlug={tableSlug}
        />
      )}
      <FormModal
        isNotForm
        elemId="manage-views"
        isOpen={showManageViewsModal}
        setIsOpen={setShowManageViewsModal}
        title="Manage saved views"
        content={
          shouldShowCoreItems && (
            <TableViewsTable
              parentTableApiRef={parentTableApiRef}
              parentTableBaseRoute={parentTableBaseRoute}
              tableSlug={tableSlug}
              onSavedViewActivated={() => setShowManageViewsModal(false)}
            />
          )
        }
        dialogProps={{
          fullScreen: true,
        }}
      />
    </>
  )
}

import { ReusableTable } from 'components/reusable/tables/ReusableTable'

import { config as columns } from './config.columns.dz-files'

type Props = {
  acceptedFiles: File[]
}

export function DropzoneFilesTable(props: Props) {
  const { acceptedFiles } = props

  return (
    <ReusableTable
      columns={columns}
      data={acceptedFiles}
      tableSlug={false}
      tableProps={{
        getRowId: ({ name }) => name, // need this! Default is `id`.
      }}
    />
  )
}

import Box from '@mui/material/Box'
import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import { NoAccessSpan } from 'components/reusable/alerts-and-messages'

import { useDispatchContext } from './create-fields-steps/NewFieldContext'
import { NewFieldsSubmitModal } from './create-fields-steps/NewFieldsSubmitModal'
import { ResetFieldsMgmtBtn } from './create-fields-steps/ResetFieldsMgmtBtn'
import type { Props as FieldsMgmtColumnMappingProps } from './fields-mgmt/FieldsMgmtColumnMapping'
import { FieldsMgmtColumnMapping } from './fields-mgmt/FieldsMgmtColumnMapping'
import { useImportsReducer } from './hooks.fields-mgmt'
import { ProducerFieldsDropzone } from './ProducerFieldsDropzone'

export function ImportFieldsPanel() {
  const fieldsMgmtDispatch = useDispatchContext()
  const [state, setState] = useImportsReducer()
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])

  function onFilesAccepted() {
    setState({ type: 'TOGGLE_IMPORTS_COL_MAP_MODAL', payload: true })
  }

  function onFilesProcessed(feats: GeoJSON.Feature[], requiresLatLonCols?: boolean) {
    setState({ type: 'SET_RAW_IMPORT_FEATURES', payload: feats })

    setState({
      type: 'SET_REQUIRES_LAT_LON_COLS',
      payload: !!requiresLatLonCols,
    })
  }

  function onColMapModalClose() {
    setState({ type: 'TOGGLE_IMPORTS_COL_MAP_MODAL', payload: false })
    setState({ type: 'CLEAR_RAW_IMPORT_FEATURES' })
  }

  const onColMapModalSubmit: FieldsMgmtColumnMappingProps['onSubmit'] = (
    org,
    colMappings,
    latLonColumns,
    extraColumns,
    secGroups
  ) => {
    const { rawFeatures: features } = state

    fieldsMgmtDispatch({
      type: 'SET_IMPORTED_FEATURES',
      payload: {
        org,
        colMappings,
        latLonColumns,
        features,
        extraColumns,
        secGroups,
      },
    })

    onColMapModalClose()
  }

  if (!userHasWritePerms) {
    return NoAccessSpan
  }

  return (
    <>
      <ProducerFieldsDropzone
        onFilesAccepted={onFilesAccepted}
        onFilesProcessed={onFilesProcessed}
      />
      <Box display="flex" gap={3} justifyContent="center" mb={2}>
        <ResetFieldsMgmtBtn />
        <FieldsMgmtColumnMapping
          isOpen={state.modalOpen}
          rawFeatures={state.rawFeatures}
          requiresLatLonCols={state.requiresLatLonCols}
          onClose={onColMapModalClose}
          onSubmit={onColMapModalSubmit}
        />
        <NewFieldsSubmitModal />
      </Box>
    </>
  )
}

import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import type { CurrentUser } from 'lib/api/django/model'

const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID

// No mention of setting this to an ENV var in the docs, so evidently cool?
// eslint-disable-next-line padding-line-between-statements
const SENTRY_DSN = 'https://ba1825684cab4d1a86e83d407eb18ec3@o1052350.ingest.sentry.io/6036019'

export function useSentrySetup(): void {
  // Initialize Sentry with the browser tracing integration.
  useEffect(() => {
    if (import.meta.env.VITE_DISABLE_SENTRY) {
      return
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT, // set in CI or local `.env` file
      replaysSessionSampleRate: 0, // non-error sessions seem to fill quota. Don't think we need.
      replaysOnErrorSampleRate: 1, // if entire session is not sampled, use this for error samples
      // 0.2 = capture 20% of transactions for performance monitoring. No idea what's best, no
      // recommendations found 🤷
      tracesSampleRate: 0.2,
      // `profilesSampleRate: 1.0` would profile EVERY transaction. Since `profilesSampleRate` is
      // relative to `tracesSampleRate`, the final profiling rate can be computed as
      // `tracesSampleRate * profilesSampleRate`. For example, a `tracesSampleRate` of 0.5 and
      // `profilesSampleRate` of 0.5 would result in 25% of transactions being profiled
      // (0.5*0.5=0.25)
      profilesSampleRate: 0.5,
      ignoreErrors: [
        // 😞 MB madness: https://earthoptics.atlassian.net/browse/DV-1458
        'Fetch is aborted',
        // 😞 MUI pinned cols: https://earthoptics.atlassian.net/browse/DV-1458
        // Chrome:
        "Uncaught TypeError: Cannot read properties of null (reading 'querySelectorAll')",
        // Firefox explains a bit better:
        'Uncaught TypeError: apiRef.current.windowRef.current is null',
      ],
      integrations: [
        new Sentry.Replay(),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
    })
  }, [])
}

/**
 * Google Analytics should only be set up when there is a `GA_MEASUREMENT_ID`. This is only supplied
 * in production ENV vars since we don't care about tracking usage in other environments.
 *
 * @param isLoading Is the current-user query loading
 * @param data Current user's data. We need user id, Org name, and staff-ness.
 */
export function useGoogleAnalyticsSetup(isLoading: boolean, data?: CurrentUser): void {
  useEffect(() => {
    if (isLoading || !data || !GA_MEASUREMENT_ID) {
      return
    }

    ReactGA.initialize(GA_MEASUREMENT_ID, { gaOptions: { userId: data.id } })

    ReactGA.event({
      category: 'org-name',
      action: 'set-org-name',
      label: data.org.name,
    })

    ReactGA.event({
      category: 'is-staff',
      action: 'set-is-staff',
      label: data.is_staff ? 'Y' : 'N',
    })
  }, [isLoading, data])
}

/**
 * Set up LaunchDarkly feature flag service, including a user identify step.
 *
 * @param isLoading Is current-user query loading. Can't do much if so.
 * @param data Current user's data object, if available yet (query done loading)
 */
export function useLaunchDarklySetup(isLoading: boolean, data?: CurrentUser): void {
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient || isLoading || !data) {
      return
    }

    const { id, email, first_name, last_name } = data || {}

    // v3 switched from `LDUser` to `LDContext`. Not much impact since we just started using LD, but
    // docs instruct adding `kind: user` to the object.
    ldClient.identify({
      key: id,
      kind: 'user',
      email,
      name: `${first_name} ${last_name}`,
    })
  }, [isLoading, data, ldClient])
}

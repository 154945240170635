import { snakeToSentenceCase } from 'lib/utils'

import { fmtDocs } from 'components/fields/fields-mgmt/docs.fields-mgmt'
import type { FieldWriterSerializerGeoJSONStatuses as Status } from 'components/fields/fields-mgmt/types.fields-mgmt-api'
import type { ColumnConfig } from 'components/fields/types.fields-mgmt'

export const columnsMappingConfig: ColumnConfig[] = [
  {
    name: 'Field name (customer)',
    id: 'name',
    helperText: fmtDocs.fieldName,
  },
  {
    name: 'Field name (producer)',
    id: 'producer_field_name',
    helperText: fmtDocs.producer_field_name,
  },
  {
    name: 'Requested acres',
    id: 'requested_acres',
    helperText: fmtDocs.requested_acres,
  },
  {
    name: 'Farm',
    id: 'farm',
    helperText: fmtDocs.farm,
  },
  {
    name: 'Notes/description',
    id: 'description',
    helperText: fmtDocs.description,
  },
  {
    name: 'External ID',
    id: 'external_id',
    helperText: fmtDocs.external_id,
  },
]

export const fieldGeomTypeOptions: GeoJSON.Geometry['type'][] = [
  'Point',
  'Polygon',
  'MultiPolygon',
]

export const fieldGeomStatuses: {
  value: keyof typeof Status
  label: string
}[] = [
  {
    value: 'READY_FOR_USE',
    label: snakeToSentenceCase('READY_FOR_USE'),
  },
  {
    value: 'NOT_READY',
    label: snakeToSentenceCase('NOT_READY'),
  },
]

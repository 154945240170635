import { useMemo } from 'react'
import type { Control, FieldErrors } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import type { AutocompleteProps } from '@mui/material/Autocomplete'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useQueryClient } from '@tanstack/react-query'
import type { Contact } from 'lib/api/django/model'
import { getV1ContactsListQueryKey } from 'lib/api/django/v1/v1'

import { showSuccessToast } from 'components/fields/fields-mgmt/utils.fields-mgmt-form'
import { segments } from 'components/fields/routes'
import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

import { ContactsTextInput } from './ContactsTextInput'

interface Props {
  control?: Control<FieldsMgmtFormSchema>
  disabled?: boolean
  formErrors?: FieldErrors<FieldsMgmtFormSchema>
  helperText?: string
  initialValue?: Contact | null
  onChange?: (contact: Contact | null) => void
  /** Org to filter on, e.g. to make sure it is the same as that of Field */
  orgId?: string
  placeholder?: string
  required?: boolean
}

const contactsQueryKey = getV1ContactsListQueryKey()

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: ({ name }: Contact) => name,
})

/**
 * Get common props for repeated instances of the main component.
 *
 * @param props Same props as component
 * @param isLoading Is the react-query in a loading state
 * @param data Array of Contacts
 * @returns Memoized Autocomplete options
 */
export function useCommonProps(
  props: Props,
  isLoading: boolean,
  data?: Contact[]
): AutocompleteProps<Contact, false, false, false> {
  const { disabled, required, helperText, placeholder, formErrors } = props

  return useMemo(() => {
    return {
      id: 'contact-autocomplete',
      disabled,
      filterOptions,
      fullWidth: true,
      getOptionLabel: ({ name }) => name,
      groupBy: ({ org }) => org.name,
      isOptionEqualToValue: ({ id }, val) => id === val.id,
      loading: isLoading,
      options: data || [],
      size: 'small',
      renderInput: (params) => (
        <ContactsTextInput
          helperText={formErrors?.onsite_contact?.message || helperText}
          params={params}
          placeholder={placeholder}
          required={required}
        />
      ),
    }
  }, [
    disabled,
    isLoading,
    data,
    formErrors?.onsite_contact?.message,
    helperText,
    placeholder,
    required,
  ])
}

/**
 * Hook to get the default success handler for Contact POST/PATCH.
 *
 * @param isEditing Is the form in `edit` mode, as opposed to `create`
 * @returns function that takes in a Contact, sets a toast, then
 */
export function useOnSuccessDefault(isEditing: boolean) {
  const rqc = useQueryClient()
  const navigate = useNavigate()

  function onSuccess({ name, id }: Contact) {
    const detailNavPath = `${segments.base}/${segments.contactsEditExisting}/${id}`

    rqc.invalidateQueries(contactsQueryKey)
    navigate(`${segments.base}/${segments.contacts}`)

    showSuccessToast(
      isEditing ? 'updated' : 'created',
      name,
      isEditing ? 'Click to view again.' : 'Click to view.',
      () => navigate(detailNavPath)
    )
  }

  return onSuccess
}

import type { TextFieldProps } from '@mui/material/TextField'
import { object, string } from 'yup'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

import { fmtDocs } from './docs.fields-mgmt'

export type FieldMgmtFormControlConfig = {
  name: keyof FieldsMgmtFormSchema
  props: TextFieldProps
  /** Some just don't make sense, namely... "name" stuff */
  excludeFromBulkEdit?: boolean
}

export const FIELD_MGMT_FORM_DEFAULTS: FieldsMgmtFormSchema = {
  name: '',
  address: '',
  description: '',
  external_id: '',
  farm: '',
  producer_field_name: '',
  onsite_contact: null,
  org: null,
  status: 'READY_FOR_USE', // per Kim/Nicole, this is the assumption
  requested_acres: 0,
  geometry_source: 'UNKNOWN', // override it in Draw/Edit, but start w/UNKNOWN
  security_groups: [],
}

export const newFieldValidation = object({
  name: string().required(),
  // Messy: only need this in order to get `owned_by`. Easy to just grab that pre-POST though, as
  // opposed to making an empty input just for owned_by.
  org: object().required('Org is required'),
  onsite_contact: object({
    org: object({ id: string().required() }).required(),
  })
    // Note that this test was created before we made the decision to clear the contact when the
    // Field's Org is changed in Draw New mode. So, between the clearing and the "filter contacts
    // list based on Field's Org" for any of the modes (except bulk edit), there really is no way
    // this test should be capable of being triggered. But we can leave it in just in case.
    .test('org-equality', 'Org must be same as that of Field', (value, context) => {
      const contactOrg = value?.org?.id
      const fieldOrg = context.parent.org?.id

      if (!contactOrg) return true // no contact = no contact org = no problem

      return contactOrg === fieldOrg
    })
    .nullable(),
}).required()

export const fieldsMgmtFormControls: FieldMgmtFormControlConfig[] = [
  {
    name: 'name',
    excludeFromBulkEdit: true,
    props: {
      label: 'Field name (official)',
      placeholder: 'Field name',
      required: true,
      helperText: fmtDocs.fieldName,
      sx: { order: -2 },
    },
  },
  {
    name: 'producer_field_name',
    excludeFromBulkEdit: true,
    props: {
      label: 'Producer field name',
      placeholder: 'Producer field name',
      helperText: fmtDocs.producer_field_name,
      sx: { order: -1 },
    },
  },
  {
    name: 'requested_acres',
    props: {
      label: 'Requested acres',
      helperText: fmtDocs.requested_acres,
      type: 'number',
      sx: { gridColumn: '1/3' }, // just because helperText is huge
    },
  },
  {
    name: 'farm',
    props: {
      label: 'Farm',
      placeholder: 'Enter farm name',
      helperText: fmtDocs.farm,
      sx: { gridColumn: '1/3' },
    },
  },
  {
    name: 'external_id',
    excludeFromBulkEdit: true, // wouldn't make sense to bulk edit
    props: {
      label: 'External ID',
      placeholder: 'Enter id, if relevant',
      helperText: fmtDocs.external_id,
      sx: { gridColumn: '1/3' },
    },
  },
  {
    name: 'address',
    excludeFromBulkEdit: true, // wouldn't make sense to bulk edit
    props: {
      label: 'Address/directions',
      placeholder:
        'Any additional info that may help someone reach the field when lat/lon are not enough',
      helperText: fmtDocs.address,
      multiline: true,
      minRows: 3,
      maxRows: 5,
      sx: { gridColumn: '1/3' },
    },
  },
  {
    name: 'description',
    props: {
      label: 'Description',
      placeholder: 'Enter any miscellaneous notes about the field.',
      multiline: true,
      minRows: 3,
      maxRows: 5,
      helperText: fmtDocs.description,
      sx: { gridColumn: '1/3' },
    },
  },
]

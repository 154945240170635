// CRED: (for entire file):
// https://github.com/vesteraas/nmea-checksum/blob/master/index.js
function getChecksum(srcText: string): string {
  let checksum = 0

  const noMoneyNoProblems = srcText[0] === '$' ? srcText.slice(1) : srcText
  const stringLength = noMoneyNoProblems.length

  for (let i = 0; i < stringLength; i += 1) {
    const unicodeOfThisChar = noMoneyNoProblems.charCodeAt(i)

    // Not sure how to convert this, but it's a "Bitwise XOR assignment (^=)"
    // (whatever that means): https://mzl.la/3kl9d6Q
    // eslint-disable-next-line no-bitwise
    checksum ^= unicodeOfThisChar
  }

  let hex = Number(checksum).toString(16).toUpperCase()

  if (hex.length < 2) hex = `00${hex}`.slice(-2)

  return hex
}

const rmDollarAndChecksumAnswer = (srcText: string): string => srcText.slice(1).slice(0, -3)

export function isValidChecksum(srcText: string): boolean {
  const checksum = srcText.slice(-2)
  const recordMeat = rmDollarAndChecksumAnswer(srcText)

  return checksum === getChecksum(recordMeat)
}

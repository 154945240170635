import type { DropzoneOptions } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { BiNoEntry } from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { FILES_FORM_INPUT_NAME } from './config.dropzone'

export type Props = {
  /**
   * Collapse the dropzone, typically after files are dragged in, e.g. in order
   * to show a table instead.
   */
  minimize?: boolean
  // TODO: how to focus the input when user hits Submit and there are no files
  // present? Ideally would just disable Submit, but that's super tricky w/o
  // react-hook-form or other shenanigans.
  required?: boolean
  /**
   * Force the accepted file types to be an array of strings
   */
  dropzoneOptions?: Omit<DropzoneOptions, 'accept'> & {
    accept?: string[]
  }
}

const rootSx: SxProps = {
  alignItems: 'center',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 1,
  color: 'text.secondary',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  maxHeight: 600,
  maxWidth: 1200,
  mx: 'auto',
  my: 'auto',
  p: 2,
  textAlign: 'center',
  transition: 'color 0.24s ease-in-out',
  userSelect: 'none',
  width: '100%',
  '&:hover': {
    color: 'text.primary',
  },
}

// Used by both minimized/full. Rm this if the UI/UX changes.
const sharedProps = {
  id: 'files-dropzone',
  role: 'button',
  'aria-label': 'file upload',
}

export function FileDropzone(props: Props) {
  const { dropzoneOptions = {}, minimize, required } = props
  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions)
  const { accept: acceptedTypes, multiple } = dropzoneOptions
  const Input = <input name={FILES_FORM_INPUT_NAME} {...getInputProps()} />

  // TODO: rm/replace this if the UI/UX changes.
  if (minimize) {
    return (
      <Box display="none" {...sharedProps} {...getRootProps()}>
        {Input}
      </Box>
    )
  }

  return (
    <Box sx={rootSx} {...sharedProps} {...getRootProps()}>
      <input name={FILES_FORM_INPUT_NAME} required={required} {...getInputProps()} />
      <Box>
        <Typography mb={2} variant="h2">
          Drop files or click to open file browser
        </Typography>
        <Typography
          component="div"
          display="flex"
          gap={1}
          justifyContent="center"
          variant="subtitle1"
        >
          <Box
            component="span"
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              svg: {
                color: multiple ? 'success.main' : 'error.main',
              },
            }}
          >
            {multiple ? <FaCheck /> : <BiNoEntry />}
            <b>Multi-file support</b>
          </Box>
          •
          <span>
            <b>Supported extensions:</b>{' '}
            {acceptedTypes ? <code>{acceptedTypes.join(', ')}</code> : 'Any'}
          </span>
        </Typography>
      </Box>
    </Box>
  )
}

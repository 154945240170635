import { BiTrash } from 'react-icons/bi'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import type { UITableView } from 'lib/api/django/model'
import {
  getV1UiTableViewsTableviewsListQueryKey,
  useV1UiTableViewsTableviewsPartialUpdate,
} from 'lib/api/django/v1/v1'
import { useCurrentUser } from 'lib/queries.user'

import { ConfMessage, ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { ButtonAndModal } from 'components/reusable/buttons/ButtonAndModal'
import { CenteredLoadingSpinner } from 'components/reusable/loaders-and-transitions'

type Props = {
  view: UITableView
}

const PublicViewDisclaimer = (
  <ConfMessage
    fullWidth
    severity="warning"
    text="If any users are relying on this view, it will no longer work for them."
    title="This is a public view"
  />
)

const DEFAULT_TEXT = 'You cannot undo this operation. Are you sure you wish to proceed?'

export function DeleteTableViewBtn(props: Props) {
  const { view } = props
  const { data: user, isLoading, isError } = useCurrentUser()
  const mutation = useV1UiTableViewsTableviewsPartialUpdate()
  const queryClient = useQueryClient()

  if (mutation.isLoading) {
    return <CenteredLoadingSpinner size={12} />
  }

  function handleSubmit() {
    mutation.mutate(
      { id: view.short_id, data: { deleted: true } },
      {
        onSuccess: () => {
          toast.success(
            <ToastContentWithTitle
              content={`"${view.name}" was deleted successfully.`}
              title="Saved view deleted"
            />
          )

          queryClient.invalidateQueries(getV1UiTableViewsTableviewsListQueryKey())
        },
        onError: () => {
          toast.error(`Problem deleting ${view.name} table view`)
        },
      }
    )
  }

  let Content: React.ReactNode = DEFAULT_TEXT

  if (!view.is_private) {
    Content = (
      <>
        {PublicViewDisclaimer}
        {DEFAULT_TEXT}
      </>
    )
  }

  return (
    <ButtonAndModal
      iconButtonProps={{
        children: <BiTrash />,
        size: 'small',
        disabled: isLoading || isError || view.created_by?.id !== user?.id,
      }}
      modalProps={{
        elemId: 'delete-saved-view',
        onSubmit: handleSubmit,
        submitBtnText: 'delete saved view',
        title: 'Delete saved view',
        content: Content,
      }}
      tooltipProps={{
        dark: true,
        placement: 'right',
        title: 'Delete saved view',
      }}
    />
  )
}

import type { DeleteAnnoBtnAndModalProps } from 'components/reusable/maps/draw-and-annotate/DeleteAnnoBtnAndModal'
import { DeleteAnnoBtnAndModal } from 'components/reusable/maps/draw-and-annotate/DeleteAnnoBtnAndModal'
import { useCanSomewhatEditColln, useCollection } from 'components/soil-sampling/hooks.ssa'

import { useRemoteAnnoSuccessHandler } from './hooks.ssa-annos'

type Props = DeleteAnnoBtnAndModalProps

/**
 * Just a wrapper around `DeleteAnnoBtnAndModal` with a common `onSuccessHandler`. By default it
 * will be disabled based on the result of `useCanSomewhatEditColln` and will use a common success
 * handler, but both of those can be overridden.
 *
 * @param props same as `DeleteAnnoBtnAndModalProps`
 */
export function SsaDeleteAnnoBtn(props: Props) {
  const { collectionId } = props
  const handleAnnoSuccess = useRemoteAnnoSuccessHandler(collectionId.split('-')[0], true)
  const { defaultQuery } = useCollection()
  const canDeleteAnnos = useCanSomewhatEditColln(defaultQuery.data, true)

  return (
    <DeleteAnnoBtnAndModal
      disabled={!canDeleteAnnos}
      onSuccessHandler={handleAnnoSuccess}
      {...props}
    />
  )
}

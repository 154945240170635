import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import { panelContentSx, panelOutletWrapSx } from 'components/fields/styles'

import { FieldsMgmtHeadings } from './FieldsMgmtHeadings'

type Props = {
  /**
   * Use case is to pass in an `<ErrorBoundary>`. In this case there won't really be an `Outlet` to
   * render, but if there is one then the `children` will show above it.
   */
  children?: React.ReactNode
}

export function PanelOutletWrap(props: Props) {
  const { children } = props

  return (
    <Box sx={panelOutletWrapSx}>
      <FieldsMgmtHeadings />
      <Box position="relative" sx={panelContentSx}>
        {children}
        <Outlet />
      </Box>
    </Box>
  )
}

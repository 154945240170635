import { useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import Typography from '@mui/material/Typography'

import { useDispatchContext } from 'components/fields/create-fields-steps/NewFieldContext'
import { fieldMgmtIcons } from 'components/fields/fields-mgmt/config.fields-mgmt-icons'
import type { Action } from 'components/fields/types.fields-mgmt'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'
import { FilesDropzoneWrap } from 'components/soil-sampling/collection-detail/files/FilesDropzoneWrap'
import { UploadFilesToCollnDropzone } from 'components/soil-sampling/collection-detail/files/UploadFilesToCollnDropzone'
import { useCollection } from 'components/soil-sampling/hooks.ssa'

import { CollnDetailLayersPanelContent } from './CollnDetailLayersPanelContent'
import { FmtCollnDetailAccordionHeading } from './FmtCollnDetailAccordionHeading'
import { FmtCollnDetailPanelInstrs } from './FmtCollnDetailPanelInstrs'
import { AcbMgmtBtnGroup, LinkToSoilCollectorColln, useCanViewSoilCollector } from '.'

export function CollectionDetail() {
  const { collnId: collnFullId } = useParams<{ collnId: string }>()
  const dispatch = useDispatchContext()
  const canViewSoilCollector = useCanViewSoilCollector()
  const { remote: remoteCollnQuery } = useCollection(undefined, { enabled: true }, collnFullId)
  const { data, isInitialLoading, isError } = remoteCollnQuery
  const hasBeenSubmitted = data?.status === 'SUBMITTED'
  const initialState = hasBeenSubmitted ? 'layers' : 'boundary'
  const [expanded, setExpanded] = useState<string | false>(initialState)

  if (isInitialLoading || isError) {
    return <ReactQueryStates {...remoteCollnQuery} />
  }

  if (!data) {
    return (
      <Typography>
        No collections found for id <code>{collnFullId}</code>.
      </Typography>
    )
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  function dispatchFn(action: unknown) {
    dispatch(action as Action)
  }

  return (
    <>
      {canViewSoilCollector && collnFullId && (
        <Box style={{ display: 'flex', justifyContent: 'center' }} sx={{ mb: 2 }}>
          <LinkToSoilCollectorColln asButton shortId={collnFullId.split('-')[0]} />
        </Box>
      )}
      <Accordion
        defaultExpanded={!hasBeenSubmitted}
        expanded={expanded === 'boundary'}
        onChange={handleChange('boundary')}
      >
        <AccordionSummary
          aria-controls="boundary-panel-content"
          expandIcon={<MdExpandMore />}
          id="boundary-panel-header"
        >
          <FmtCollnDetailAccordionHeading icon={fieldMgmtIcons.draw} text="Collection Boundary" />
        </AccordionSummary>
        <AccordionDetails>
          <FmtCollnDetailPanelInstrs>
            <li>
              Click <b>USE FIELD</b> to set the collection boundary to that of the field. This is
              not available for point-only fields.
            </li>
            <li>
              Click <b>DRAW</b> to draw the collection boundary in the map.
            </li>
            <li>
              Click <b>CLEAR</b> to clear it, if present.
            </li>
            <li>Collection boundaries cannot be edited once a collection has been submitted.</li>
          </FmtCollnDetailPanelInstrs>
          {/* TODO: EPIC permissions for the ACB stuff? Hide status? */}
          <AcbMgmtBtnGroup collection={data} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={!hasBeenSubmitted}
        expanded={expanded === 'files'}
        onChange={handleChange('files')}
      >
        <AccordionSummary
          aria-controls="files-panel-content"
          expandIcon={<MdExpandMore />}
          id="files-panel-header"
        >
          <FmtCollnDetailAccordionHeading icon={fieldMgmtIcons.collnFiles} text="Files" />
        </AccordionSummary>
        <AccordionDetails>
          <FmtCollnDetailPanelInstrs>
            <li>
              Files with spatial data will be shown in the map at the time they are added, not
              afterwards (e.g. after page refresh).
            </li>
            <li>Files cannot be removed.</li>
            <li>Files cannot be added once a collection has been submitted.</li>
          </FmtCollnDetailPanelInstrs>
          <FilesDropzoneWrap
            collnId={data.id}
            dropzone={
              <UploadFilesToCollnDropzone
                collnStatus={data.status}
                dispatchFn={dispatchFn}
                fullCollnId={data.id}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        defaultExpanded={!hasBeenSubmitted}
        expanded={expanded === 'layers'}
        onChange={handleChange('layers')}
      >
        <AccordionSummary
          aria-controls="layers-panel-content"
          expandIcon={<MdExpandMore />}
          id="layers-panel-header"
        >
          <FmtCollnDetailAccordionHeading icon={fieldMgmtIcons.layers} text="Layers" />
        </AccordionSummary>
        <AccordionDetails>
          <FmtCollnDetailPanelInstrs>
            <li>A collection must have at least one file in order to be submitted.</li>
            <li>A list of layers will appear here once they are generated and have passed QA.</li>
          </FmtCollnDetailPanelInstrs>
          <CollnDetailLayersPanelContent collection={data} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

import { DEFAULT_MAP_ID as DEFAULT_ID, MB_TOKEN } from 'lib/config/map'

export enum Baselayer {
  Dark = 'dark-v10',
  Light = 'light-v10',
  Outdoors = 'outdoors-v11',
  Satellite = 'satellite-v9',
  SatStreets = 'satellite-streets-v11',
  Streets = 'streets-v11',
}

export const MB_STYLES_PREFIX = 'mapbox://styles/mapbox'

const lebanonKansas = {
  latitude: 39.8282,
  longitude: -98.5696,
}

export const initialViewState = {
  ...lebanonKansas,
  zoom: 3.5,
}

export const mapDefaults = {
  clickTolerance: 4,
  mapboxAccessToken: MB_TOKEN,
}

export const DEFAULT_MAP_ID = DEFAULT_ID

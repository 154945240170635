/**
 * @file Hooks for reusable tables
 */

import { useCallback } from 'react'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import { useTableStatesStore } from './store'

/**
 * Hook to handle table state change and send it to the global store.
 *
 * @param tableSlug slug of table to get from `tableStates` in global store
 * @param apiRef table's `apiRef` object
 * @param isLoading is the table's query loading. If so, exit (fewer renders)
 * @returns callback function that dispatches the new state
 */
export function useOnStateChange(
  tableSlug: string | null,
  apiRef: React.MutableRefObject<GridApiPremium>,
  isLoading: boolean
): () => void {
  const setTableState = useTableStatesStore((state) => state.actions.setState)

  const handleChange = useCallback(() => {
    // If it's still loading, it triggers a LOT of dispatches. And if there's no `apiRef.current`,
    // it can cause errors when transitioning to a new page.
    if (isLoading || !apiRef.current || !tableSlug || !setTableState) {
      return
    }

    // For some reason, row density is not included in `exportState`, but we can get it here:
    const { density } = apiRef.current.state
    const state = apiRef.current.exportState()

    setTableState(tableSlug, { ...state, density: density.value })
  }, [
    apiRef,
    isLoading,
    setTableState,
    tableSlug,
  ])

  return handleChange
}

type SortingFunction<T> = (a: T, b: T) => number

// CRED: https://stackoverflow.com/a/38641281/1048518
export function compareCaseInsensitiveStrNumMix(a: string, b: string): number {
  return a.toLowerCase().localeCompare(b.toLowerCase(), undefined, {
    numeric: true,
    sensitivity: 'base',
  })
}

// CRED: https://stackoverflow.com/a/9645447/1048518
export const sortCaseInsensitive = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase())

/**
 * Sort an array of objects based on the supplied key
 *
 * {@link https://github.com/Language-Mapping/language-map/commit/1af5f56cde67bcfd56daaa7a331b2e90887ea724 ELA map repo commit}
 *
 * @param key property of the object to use as the basis of sorting
 * @param mayContainNumbers if true, `BD1, BD2, BD10`, not `BD1, BD10, BD2`
 * @returns comparator function for use with `Array.sort()`
 */
export function sortArrOfObjects<T>(key: keyof T, mayContainNumbers?: boolean): SortingFunction<T> {
  function comparator(a: T, b: T): number {
    let comparison = 0

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      if (mayContainNumbers) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // not today 😞
        return compareCaseInsensitiveStrNumMix(a[key], b[key])
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // not today 😞
      return sortCaseInsensitive(a[key], b[key])
    }

    if (a[key] > b[key]) comparison = 1
    else if (a[key] < b[key]) comparison = -1

    return comparison
  }

  return comparator
}

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { GPS_ACCURACY_CLASSES } from 'components/reusable/maps/map-ctrls/geolocation/config'

// Same toast can be used for both local and remote annotation success. Collection managers who are
// not assigned to the collection can still create annotations, so they will only ever see the
// remote, and in most scenarios with good connectivity, so will the assignee since the local will
// only flash briefly (and in a bottom-center position, you may not even see that).
export const ANNO_SUCCESS_TOAST_ID = 'annoSuccess'

/**
 * At time of writing, does not include ALL the SC toasts' content, mostly just the ones that were
 * in an already long/messy block of code, e.g. query success/error handling.
 */
export const soilCollectorToasts = {
  localCollnFail: 'Could not retrieve collection from device',
  remoteSamplePatchFail: 'Sample could not be updated on server',
  remoteCollnPatchSuccess: 'Collection updated',
  remoteCollnPatchFail: 'Collection could not be updated on server',
  geolocErr: (errMsg: string) => {
    return `Something went wrong with geolocation: ${errMsg}`
  },
  collnStarted: (
    <>
      Collection status moved from <code>DOWNLOADED</code> to <code>STARTED</code> on device.
    </>
  ),
  collnSubmitted: (
    <ToastContentWithTitle
      content="If you need to unsubmit, please contact your PM or collection manager."
      title="Collection submitted"
    />
  ),
  remoteSamplePatchSuccess: (bag_label: string) => (
    <ToastContentWithTitle
      content="Sample was successfully sent to server."
      title={`${bag_label} updated`}
    />
  ),
  localSampleMutationErr: (errMsg: string) => (
    <>
      Sample was not updated. {errMsg}
      <br />
      <br />
      Click to acknowledge and close.
    </>
  ),
  localAnnotationSuccess: (name: string) => (
    <ToastContentWithTitle
      content="If you are offline, this annotation will be sent to the server as soon as possible."
      title={`${name} stored on device`}
    />
  ),
  remoteAnnotationSuccess: (name: string) => (
    <ToastContentWithTitle
      content="The annotation was stored successfully."
      title={`${name} created`}
    />
  ),
  qrSampleStoredOnDevice: (
    <ToastContentWithTitle
      content="The sample will be sent to the server as soon as possible."
      title="Sample location updated on device"
    />
  ),
  poorGpsAccuracy: (gps_accuracy: number) => (
    <ToastContentWithTitle
      title={`Poor GPS accuracy: ${gps_accuracy}m`}
      content={
        <>
          Your GPS accuracy is above {GPS_ACCURACY_CLASSES.warning}m. Ensure the following are true
          and then try again.
          <ul style={{ paddingLeft: 24 }}>
            <li>GPS button is active in the map.</li>
            <li>If using an external antenna, it is powered on and connected via Bluetooth.</li>
          </ul>
        </>
      }
    />
  ),
  /**
   * Toast content for when the user is too far from the Rx point.
   *
   * @param roundedDistance current distance from Rx point
   * @returns `ToastContentWithTitle` instance
   */
  tooFarFromRxPoint: (roundedDistance: number) => {
    return (
      <ToastContentWithTitle
        title={`Too far away: ${roundedDistance} meters`}
        content={
          <>
            You must be within 5 meters of the prescribed sample location. Move closer and try
            again. If you are unable to get closer, move to an alternate prescribed location (if
            available) and <b>provide an explanation in the sample notes</b>.
          </>
        }
      />
    )
  },
  sampleNotFoundInCurrentColln: (qrResult: string) => (
    <ToastContentWithTitle
      title="Sample not found in collection"
      content={
        <>
          Sample with id <code>{qrResult}</code> was not found in the current collection. The error
          has been sent to the EarthOptics support team. Here are some troubleshooting steps you can
          try:
          <ul style={{ paddingLeft: 24 }}>
            <li>Be sure you are on the correct field and collection.</li>
            <li>Click the "sync" button.</li>
            <li>
              Refresh the page: click the 3-line "hamburger" menu in the top-left corner, then
              "Refresh page".
            </li>
            <li>
              Close the current tab if you are in a browser, and any other active SoilCollector
              tabs.
            </li>
            <li>Close and reopen the browser or the "installed" app, whichever you are viewing.</li>
            <li>If you are online, try logging out and back in.</li>
            <li>
              If you are online and have at least one bar of service, try deleting the collection
              via the 3-dot menu to the right of the "Notes" button in the collection detail view.
              Then refresh the page and re-download the collection.
            </li>
            <li>Restart your device.</li>
            <li>Try a different device.</li>
            <li>Try a different browser or a "Private tab".</li>
          </ul>
          <p>
            If none of these steps work, please notify your EarthOptics contact or support team with
            any additional details you can provide.
          </p>
        </>
      }
    />
  ),
  sampleSurveyLocalPatchSuccess: (
    <ToastContentWithTitle
      content="The sample will be sent to the server as soon as possible."
      title="Sample survey updated"
    />
  ),
  sampleSurveyRemoteCorePatchSuccess: (
    <ToastContentWithTitle
      content="The core was successfully updated on the server."
      title="Sample core updated"
    />
  ),
  sampleSurveyRemoteBulkSamplesPatchSuccess: (
    <ToastContentWithTitle
      content="Each sample in this core was successfully updated on the server."
      title="Updated all samples in core"
    />
  ),
  sampleSurveyRemoteBulkError: 'Error updating samples in core',
  sampleSurveyRemoteCoreError: 'Error updating core',
  /**
   * The user has submitted the request to reprocess the collection's layers, but the request has
   * not received a response from the server yet. Let them know _something_ is happening.
   *
   * @returns toast with content and title
   */
  layerProcessingStartRequested: () => (
    <ToastContentWithTitle
      content="Please remain on the page until you receive a confirmation message that the request has begun processing."
      title="Reprocessing request sent"
    />
  ),
  /**
   * The request to begin reprocessing the collection's layers has been received by the server.
   *
   * @returns toast with content and title
   */
  layerProcessingStarted: () => (
    <ToastContentWithTitle
      content="It may take 10-15 minutes to create the new layers. Please do not reprocess this collection again during that time."
      title="Reprocessing has begun"
    />
  ),
  layerProcessingFailed: (errMsg: string) => (
    <ToastContentWithTitle content={errMsg} title="SoilMapper reprocessing failed" />
  ),
  trackingNumbersSuccess: (
    <ToastContentWithTitle content="Click to view again." title="Tracking numbers updated" />
  ),
  trackingNumbersError: (errMsg: string) => (
    <ToastContentWithTitle
      content={errMsg || 'Please check your internet connection and try again.'}
      title="Could not update tracking numbers"
    />
  ),
}

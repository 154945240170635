import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import { segments } from 'components/fields/routes'

import { MultiComboMadness } from './collections/sampling-plans/MultiComboMadness'
import { fieldMgmtIcons } from './config.fields-mgmt-icons'

export function FieldsPanelLanding() {
  const userIsInFieldMgrGroup = useCanAccessBasedOnGroups(['field_manager'])

  let Content = null

  if (!userIsInFieldMgrGroup) {
    Content = (
      <>
        Click a field in the table below to view details and see a list of links to its SoilMapper
        results, when available.'
      </>
    )
  } else {
    Content = (
      <Box display="flex" gap={1.5} justifyContent="center" mb={3}>
        <Button
          component={RouterLink}
          startIcon={fieldMgmtIcons.import}
          to={`${segments.base}/${segments.fieldsImport}`}
          variant="contained"
        >
          Import fields
        </Button>
        <Button
          component={RouterLink}
          startIcon={fieldMgmtIcons.draw}
          to={`${segments.base}/${segments.fieldsDrawNew}`}
          variant="contained"
        >
          Draw new
        </Button>
        <MultiComboMadness />
      </Box>
    )
  }

  return Content
}

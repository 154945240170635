import type { CurrentUser, CurrentUserPermissions, Group } from 'lib/api/django/model'
import { GroupEnum } from 'lib/api/django/model'
import type { DecodedJwtAccessToken, Permission } from 'lib/api/types.api'
import type { Handle } from 'lib/types'

const SUPERUSER_GROUP = GroupEnum.superuser

// This will change over time, but basically its purpose is to determine whether a user can access a
// route based on their staff/superuser statuses, the Django groups they are in, or some combination
// thereof.
export function checkUserAccessByGroup(
  allowedGroups: Handle['allowedGroups'],
  userGroups?: Group[]
): boolean {
  if (!allowedGroups) {
    return true // any logged-in user can access
  }

  const hasAccessViaGroup = userGroups?.find((group) => {
    // Superusers can always access. Don't want to have to manually put that into the route config
    // every time, so add it here.
    return [SUPERUSER_GROUP, ...allowedGroups].includes(group.name)
  })

  return !!hasAccessViaGroup
}

// Cheap way to reduce staff-vs-not and superuser checks in routes. Note that the `id` value is
// completely arbitrary. And once we move to a user-level permissions model, this function will be
// obsolete.
export function appendPseudoGroupsToUser(data: CurrentUser): CurrentUser {
  let pseudoGroups: Group[] = []

  // For some reason, this and the superuser group were getting doubled up. May be something to do
  // with `initialData` in the query, but either way this fixes it.
  const alreadyHasStaffUserGroup = data.groups.some(({ name }) => name === 'staff_user')
  const alreadyHasSuperuserGroup = data.groups.some(({ name }) => name === 'superuser')

  if (data.is_staff && !alreadyHasStaffUserGroup) {
    pseudoGroups = [
      {
        id: 2,
        permissions: [],
        name: 'staff_user',
      },
    ]
  }

  if (data.is_superuser && !alreadyHasSuperuserGroup) {
    pseudoGroups = [
      ...pseudoGroups,
      {
        id: 1,
        permissions: [],
        name: 'superuser',
      },
    ]
  }

  const user = { ...data, groups: [...data.groups, ...pseudoGroups] }

  return user
}

export function checkUserAccessByPerms(
  permsToCheck: Permission[],
  userPerms?: CurrentUserPermissions,
  mustHaveAll?: boolean
): boolean {
  if (!userPerms) {
    return false
  }

  if (mustHaveAll) {
    return permsToCheck.every((p) => Object.prototype.hasOwnProperty.call(userPerms, p))
  }

  return permsToCheck.some((p) => Object.prototype.hasOwnProperty.call(userPerms, p))
}

/**
 * Check whether the JWT token is expired. Based on all known info, this should work for either the
 * refresh token or the access token since they share the same schema.
 *
 * ## Assumptions
 * Note that this does NOT validate that the token is legit in the first place. Is assumed that the
 * value being passed in is a valid JWT token.
 *
 * @param token JWT token string
 * @returns true if token is expired
 */
export function getIsTokenExpired(token: string): boolean {
  // Expiry date in token is expressed in seconds, while now() returns ms
  const now = Math.ceil(Date.now() / 1000)
  const refreshTokenParts: DecodedJwtAccessToken = JSON.parse(atob(token.split('.')[1]))

  return now > refreshTokenParts.exp
}

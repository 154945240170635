import { GoInfo } from 'react-icons/go'
import Typography from '@mui/material/Typography'

import { BasicBtnWithPopover } from 'components/reusable/handy-templates/BasicBtnWithPopover'

type Props = {
  text: string
  info?: string
}

export function ColMapSectionHeading(props: Props) {
  const { text, info } = props

  return (
    <Typography alignItems="center" component="h2" display="flex" gap="4px" mb="12px" variant="h6">
      {text}
      {!!info && (
        <BasicBtnWithPopover
          btnProps={{ size: 'small' }}
          btnText={text}
          elemId={text.replace(/ /g, '-').toLowerCase()}
          icon={<GoInfo />}
          type="icon"
        >
          <Typography variant="body2">{info}</Typography>
        </BasicBtnWithPopover>
      )}
    </Typography>
  )
}

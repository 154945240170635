import type { LinePaint } from 'mapbox-gl'

import { CircleSymbol } from './CircleSymbol'
import { LineSymbol } from './LineSymbol'

export const linePaintCommon: LinePaint = {
  'line-dasharray': [0, 2],
  'line-width': 2,
  'line-opacity': 1,
}

export const soilMapperPointsPaint = {
  'circle-stroke-width': 2,
  'circle-stroke-color': 'hsla(0, 0%, 100%, 0.85)',
  'circle-color': 'hsl(340, 71%, 48%)',
}

export const cbdLabPaint = {
  'circle-stroke-width': 2,
  'circle-stroke-color': 'hsla(0, 0%, 100%, 0.85)',
  'circle-color': 'hsl(520, 41%, 38%)',
}

export const emiPaint = {
  ...linePaintCommon,
  'line-color': 'hsl(205, 85%, 62%)',
}

export const gprPaint = {
  ...linePaintCommon,
  'line-color': 'hsl(20, 85%, 62%)',
}

export const filesSymbols = {
  dcp: (
    <CircleSymbol
      borderColor={soilMapperPointsPaint['circle-stroke-color']}
      color={soilMapperPointsPaint['circle-color']}
      radius={8}
      strokeWidth={2}
    />
  ),
  emi: (
    <LineSymbol
      borderTopStyle="dashed"
      color={emiPaint['line-color']}
      lineWidth={linePaintCommon['line-width'] as number}
    />
  ),
  gpr: (
    <LineSymbol
      borderTopStyle="dashed"
      color={gprPaint['line-color']}
      lineWidth={linePaintCommon['line-width'] as number}
    />
  ),
  lab: (
    <CircleSymbol
      borderColor={cbdLabPaint['circle-stroke-color']}
      color={cbdLabPaint['circle-color']}
      radius={8}
      strokeWidth={2}
    />
  ),
}

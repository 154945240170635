export const fmtDocs = {
  external_id: 'ID from another source; e.g. provided by source file as unique identifier',
  address: 'Address of and/or directions to reach the field access location',
  acres: 'Field acreage calculated from the boundary (N/A for points)',
  description: 'Optional notes or description of the field',
  farm: 'Name of farm the field is on',
  producer_field_name: 'What the producer calls the field',
  fieldName: 'Official name of field from customer',
  fieldStatus: 'Is the field ready for collections',
  requested_acres: 'Requested acreage to be mapped. Defaults to boundary acreage (N/A for points)',
  geometry_source: "Source of field's geometry",
  state: 'State/province/region in which the field is located', // API: `Field.Location.region`
  county: 'County/district/parish in which the field is located', // API: `Field.Location.district`
  geomSources: {
    drawn: 'Drawn in Field Management Tool',
    file: 'Imported from file in Field Management Tool',
    uploader: 'Created as a result of boundary drawn in Uploader',
    unknown: 'Geometry source is unknown',
  },
}

export const contacts = {
  name: 'Full name of contact',
  preferred: 'Ideal communication method',
  email: "Contact's email address",
  phone: "Contact's primary phone number",
  alt_phone: "Contact's backup phone number",
  notes: 'Miscellaneous info',
}

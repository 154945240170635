import { BiLogInCircle } from 'react-icons/bi'
import { Link as RouterLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useClearUserAndAuthStuff } from 'lib/queries.user'

import { usePageTitle } from 'components/layout/hooks'
import { segments } from 'components/reusable/routes'

export function LogoutPage() {
  usePageTitle('Signed out')
  useClearUserAndAuthStuff()

  return (
    <>
      <img alt="company logo" src="/eo-logo-white.svg" />
      <Typography component="p" sx={{ mb: 4 }} variant="h5">
        Signed out successfully.
      </Typography>
      <Button
        component={RouterLink}
        endIcon={<BiLogInCircle />}
        size="large"
        to={`/${segments.login}`}
        type="submit"
        variant="contained"
      >
        sign back in
      </Button>
    </>
  )
}

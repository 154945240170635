import { BsQuestionLg } from 'react-icons/bs'
import { FaFaucet, FaParking, FaTools, FaTruckPickup } from 'react-icons/fa'
import { GiCage, GiRanchGate } from 'react-icons/gi'
import { IoWarning } from 'react-icons/io5'
import { LuRectangleVertical, LuUtilityPole } from 'react-icons/lu'
import { PiCylinder } from 'react-icons/pi'
import { TbFence, TbMapPinPin } from 'react-icons/tb'
import { darken } from '@mui/material'
import type { TypeEnum as AnnoTypeAkaCategory } from 'lib/api/django/model'
import type { CirclePaint, FillPaint, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl'

type AnnoSymbolConfig = {
  [key in AnnoTypeAkaCategory]: {
    name: string
    icon: React.ReactElement
    /**
     * Kinda gross and specific to SoilCollector, but if `true`, then only collection managers can
     * see this option in the dropdown. It _only_ applies to the dropdown, though, not the map or
     * table views, etc.
     */
    collnMgrOnly?: boolean
  }
}

export const ANNOS_SRC_ID = 'annotations'
export const ANNOS_LABELS_SRC_ID = 'annotations-labels'

export const annosLabelLayerIds = {
  pointLabels: 'anno-point-labels',
  lineLabels: 'anno-line-labels',
  polygonLabels: 'anno-poly-label',
}

export const annosNonLabelLayerIds = {
  pointIcon: 'anno-point-icon',
  pointBg: 'anno-point-bg',
  line: 'anno-line',
  polygon: 'anno-polygon',
  polyOutline: 'anno-poly-outline',
}

export const annosLayerIds = {
  ...annosLabelLayerIds,
  ...annosNonLabelLayerIds,
}

export const interactiveLayerIds = [
  annosLayerIds.pointIcon,
  annosLayerIds.pointBg,
  annosLayerIds.pointLabels,
  annosLayerIds.line,
  annosLayerIds.lineLabels,
  annosLayerIds.polygon,
  annosLayerIds.polygonLabels,
  annosLayerIds.polyOutline,
]

// NOTE: at the time of writing, 07/21/2023, these are specific to SoilCollector. If they are to
// be used for other maps, then they are in the right spot. Otherwise, they should be moved out of
// here and into an SC-specific file/folder.
export const annoSymbolConfig: AnnoSymbolConfig = {
  ACCESS_POINT: {
    name: 'Access point',
    icon: <FaParking />,
  },
  ALT_SAMPLE_POINT: {
    collnMgrOnly: true, // only CM's can create alternates
    name: 'Alternate sample',
    icon: <TbMapPinPin />,
  },
  CAGE: {
    name: 'Cage/pen',
    icon: <GiCage />,
  },
  CULVERT: {
    name: 'Culvert/pipe',
    icon: <PiCylinder />,
  },
  FENCELINE: {
    name: 'Fenceline',
    icon: <TbFence />,
  },
  FIELD_BOUNDARY: {
    name: 'Field boundary',
    icon: <LuRectangleVertical />,
  },
  GATE: {
    name: 'Gate',
    icon: <GiRanchGate />,
  },
  HAZARD: {
    name: 'Hazard/obstacle',
    icon: <IoWarning />,
  },
  IRRIGATION: {
    name: 'Irrigation',
    icon: <FaFaucet />,
  },
  POWER_LINE: {
    name: 'Powerline',
    icon: <LuUtilityPole />,
  },
  TRUCK: {
    name: 'Truck/vehicle',
    icon: <FaTruckPickup />,
  },
  UTILITIES: {
    name: 'Utilites',
    icon: <FaTools />,
  },
  OTHER: {
    name: 'Other',
    icon: <BsQuestionLg />,
  },
  // TODO: restore if needed, otherwise rm if we go w/something else.
  // PHOTO: { name: 'Photo', icon: <MdOutlinePhotoCamera /> },
}

export const ANNO_BASE_COLOR = '#6b398a' // plum looks good relative to colln colors. Yay ChatGPT 🙌

const ANNO_LINE_COLOR_DARK = darken(ANNO_BASE_COLOR, 0.3) // bit of contrast around the circles

export const pointAnnoBgCirclePaint: CirclePaint = {
  'circle-color': ANNO_BASE_COLOR,
  'circle-radius': 13,
  'circle-stroke-width': 2,
  'circle-stroke-color': ANNO_LINE_COLOR_DARK,
}

export const pointAnnoIconPaint: SymbolPaint = {
  'icon-color': '#fff',
}

export const pointAnnoIconLayout: SymbolLayout = {
  'icon-allow-overlap': true,
  'icon-image': ['get', 'type'],
  'icon-size': 0.5,
}

// This and many of the others may apply to both lines and (multi)polygons.
export const annoLinePaint: LinePaint = {
  'line-color': ANNO_BASE_COLOR,
  'line-width': 2,
  'line-dasharray': [2, 1],
}

// Not showing anything but still needs to exist so the interior is clickable
export const annoPolyFillPaint: FillPaint = {
  'fill-color': 'transparent',
  'fill-opacity': 0,
} as const

// For consistency and to avoid confusion with lines. Override via spread if needed.
export const polyLinePaint = annoLinePaint

import { useState } from 'react'
import { RiContactsBookUploadFill } from 'react-icons/ri'
import Button from '@mui/material/Button'

import { UploadSamplePlanDropzone } from './UploadSamplePlanDropzone'

type Props = {
  collnId: string
}

export function OpenUploadSamplePlanDropzoneBtn(props: Props) {
  const { collnId } = props
  const [isOpen, setIsOpen] = useState(false)

  const CancelBtn = (
    <Button
      size="large"
      startIcon={null}
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation() // don't want dropzone clicked

        setIsOpen(false)
      }}
    >
      cancel
    </Button>
  )

  return (
    <>
      <Button
        size="small"
        startIcon={<RiContactsBookUploadFill />}
        variant="contained"
        onClick={() => setIsOpen(true)}
      >
        upload sampling plan
      </Button>
      <UploadSamplePlanDropzone
        cancelBtn={CancelBtn}
        collnId={collnId}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

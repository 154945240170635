// CRED: https://kentcdodds.com/blog/how-to-use-react-context-effectively
import { createContext, useContext, useReducer } from 'react'
import type { Org } from 'lib/api/django/model'

import type { LayoutAction, LayoutDispatch, LayoutState } from './types'

type Props = {
  children: React.ReactNode
}

const SOLE_USER_MENU_ORG_LOCAL_STG_KEY = 'userMenuOrg'

/**
 * This mess is so we can show a logo when a single Org (with a logo) is filtered by staff on the
 * dashboard. This used to make slightly more sense when we only had the "impersonate" Org feature,
 * but now we have a multi-Org filter and it's super confusing.
 */
export const layoutLocalStorage = {
  getSoleSelectedOrg: () => {
    if (typeof localStorage === 'undefined') {
      return null
    }

    const org = localStorage.getItem(SOLE_USER_MENU_ORG_LOCAL_STG_KEY)

    if (org) {
      return JSON.parse(org) as Org
    }

    return null
  },
  setSoleSelectedOrg: (org: Org) => {
    localStorage.setItem(SOLE_USER_MENU_ORG_LOCAL_STG_KEY, JSON.stringify(org))
  },
  clearSoleSelectedOrg: () => {
    localStorage.removeItem(SOLE_USER_MENU_ORG_LOCAL_STG_KEY)
  },
}

const initialState: LayoutState = {
  showMapLegend: false, // init hidden so mobile users don't see then not see
  showSidePanel: true,
  userMenuOrg: layoutLocalStorage.getSoleSelectedOrg(),
}

const StateContext = createContext<LayoutState | undefined>(undefined)
const DispatchContext = createContext<LayoutDispatch | undefined>(undefined)

function reducer(state: LayoutState, action: LayoutAction): LayoutState {
  switch (action.type) {
    case 'HIDE_SIDE_PANEL':
      return { ...state, showSidePanel: action.payload }
    case 'SET_USER_MENU_ORG':
      return { ...state, userMenuOrg: action.payload }
    case 'CLEAR_USER_MENU_ORG':
      return { ...state, userMenuOrg: null }
    case 'TOGGLE_MAP_LEGEND':
      return { ...state, showMapLegend: action.payload }
    default: {
      return state
    }
  }
}

export function LayoutProviders(props: Props) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useLayoutState(): LayoutState {
  const context = useContext(StateContext)

  if (context !== undefined) return context

  throw new Error('useLayoutState must be used within a LayoutProvider')
}

function useLayoutDispatch(): LayoutDispatch {
  const context = useContext(DispatchContext)

  if (context !== undefined) return context

  throw new Error('useLayoutDispatch must be used within a LayoutProvider')
}

export { useLayoutState, useLayoutDispatch }

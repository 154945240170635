export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0B'

  const K = 1024

  const sizes = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(K))

  // TODO: confirm that this is equivalent of Math.pow(k, i)
  return `${parseFloat((bytes / K ** i).toFixed(2))}${sizes[i]}`
}

export const getFileExtension = (filename: string, includePeriod = true): string => {
  const extension = filename.split('.').pop() as string

  return includePeriod ? `.${extension}` : extension
}

/**
 * Hacky (yet widely accepted) way to download a file using a temporary `<a>` element.
 *
 * @param href URL of the file, or a data URI string with the file's contents
 * @param filename name of the output file
 */
export const downloadViaAnchorElem = (href: string, filename: string): void => {
  const anchorElem = document.createElement('a')

  anchorElem.setAttribute('href', href)
  anchorElem.setAttribute('download', filename)
  document.body.appendChild(anchorElem) // required for firefox
  anchorElem.click()
  anchorElem.remove()
}

/**
 * Hack to download a file's contents by creating and clicking an <a> element.
 *
 * {@link https://jetrockets.com/blog/l8dadq8oac-how-to-download-files-with-axios JetRockets: How to download files with Axios}
 *
 * @param data We are assuming it's a string. The first use case was a zip, which was returned as a
 * string from the exportRx endpoint.
 * @param filename Name of the output file, including extension.
 */
export function downloadZipFile(data: string, filename: string): void {
  const downloadUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }))

  downloadViaAnchorElem(downloadUrl, filename)
}

export function getEncodedJsonDataString(data: unknown): string {
  return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`
}

// CRED: https://stackoverflow.com/a/30800715/1048518
export function downloadJson(
  data: unknown,
  exportName = 'eo-json-export',
  onDone?: () => void,
  fileExtension = 'json'
): void {
  const dataStr = getEncodedJsonDataString(data)

  downloadViaAnchorElem(dataStr, `${exportName}.${fileExtension}`)

  if (onDone) {
    onDone()
  }
}

import type { ImportError } from 'components/fields/types.fields-mgmt'
import { ConfMessage } from 'components/reusable/alerts-and-messages'
import { FormModal } from 'components/reusable/handy-templates/FormModal'

export type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<boolean>
  errors: ImportError[]
  title: string
  confMsgOptions: {
    text: string
    title: string
  }
}

export function FMTbulkErrorsModal(props: Props) {
  const { isOpen, setIsOpen, errors, title, confMsgOptions } = props

  return (
    <FormModal
      isNotForm
      elemId="fields-errors"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      content={
        <>
          <ConfMessage
            severity="error"
            text={confMsgOptions.text}
            title={confMsgOptions.title}
            variant="outlined"
          />
          <ul>
            {errors.map((error) => (
              <li key={error.name}>
                <b>{error.name}: </b>
                {error.problems.join(';')}
              </li>
            ))}
          </ul>
        </>
      }
    />
  )
}

// TypeScript-friendly way to find/filter fulfilled in `Promise.allSettled`. This means re-casting
// via `as` is not necessary.
// CRED: https://stackoverflow.com/a/69451755/1048518
export function isPromiseFulfilled<T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled'
}

// TypeScript-friendly way to find/filter rejected in `Promise.allSettled`. This means re-casting
// via `as` is not necessary.
// CRED: https://stackoverflow.com/a/69451755/1048518
export function isPromiseRejected<T>(
  input: PromiseSettledResult<T>
): input is PromiseRejectedResult {
  return input.status === 'rejected'
}

import { FaListAlt, FaRegSave } from 'react-icons/fa'
import { toast } from 'react-toastify'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import type { UITableView } from 'lib/api/django/model'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import type { MenuItemConfig } from 'components/reusable/handy-templates/types'

/**
 * Get the core menu items for the "Saved views" menu. These are only applicable to tables that have
 * a `UITableView` stored in the backend.
 *
 * @param onSaveClick click handler for the "Save current view" menu item
 * @param onManageClick click handler for the "Manage saved views" menu item, if enabled
 * @param disableManage disable the "Manage saved views" menu item, e.g. if user has none
 * @returns array of `MenuItemConfig` objects
 */
export function getCoreMenuItems(
  onSaveClick: () => void,
  onManageClick: () => void,
  disableManage: boolean
): MenuItemConfig[] {
  return [
    {
      uniqueKey: 'new',
      text: 'Save current view',
      icon: <FaRegSave />,
      onClick: onSaveClick,
    },
    {
      uniqueKey: 'manage',
      text: 'Manage saved views',
      icon: <FaListAlt />,
      onClick: onManageClick,
      disabled: disableManage,
    },
  ]
}

/**
 * Create a menu item config array from an array of `UITableView` objects.
 *
 * @param views array of `UITableView` objects from backend
 * @param onClick handler to activate a saved view
 * @returns array of `MenuItemConfig` objects
 */
export function createMenuItemsFromViews(
  views: UITableView[],
  onClick: (savedViewName: string, state: GridInitialStatePremium) => void
): MenuItemConfig[] {
  if (!views.length) {
    return []
  }

  const menuItems = views.map((view) => {
    return {
      uniqueKey: view.short_id,
      text: view.name,
      onClick: () => {
        if (view.state) {
          onClick(view.name, view.state)
        } else {
          toast.warn('View settings not found')
        }
      },
    }
  })

  return [
    ...(menuItems.length ? [{ uniqueKey: 'divider-after-core', isDivider: true }] : []),
    {
      uniqueKey: 'my-views-heading',
      subHeaderText: 'My recently created views',
    },
    ...menuItems,
  ]
}

export function showActiveViewSuccessToast(viewName: string) {
  toast.success(
    <ToastContentWithTitle
      content='To restore the default state, click "Views" and then "Reset to default view".'
      title={`"${viewName}" was applied`}
    />
  )
}

export function showResetViewSuccessToast() {
  toast.success(
    <ToastContentWithTitle
      content="Columns, filters, sorting, and grouping are back to their initial settings."
      title="Table view was reset to default"
    />
  )
}

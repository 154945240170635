import MenuItem from '@mui/material/MenuItem'
import type { TextFieldProps } from '@mui/material/TextField'
import type { Contact, PreferredEnum } from 'lib/api/django/model'

import { contacts as docs } from 'components/fields/fields-mgmt/docs.fields-mgmt'

type Control = {
  name: keyof Contact
  props: TextFieldProps
  /**
   * Kinda gross but it works for the "MenuItem does not accept empty fragment
   * as a child" warning.
   */
  children?: React.ReactNode[]
}

export const PREFERRED_CONTACT_METHODS: PreferredEnum[] = [
  'not_set',
  'do_no_contact',
  'text_primary_phone',
  'call_primary_phone',
  'text_alternate_phone',
  'call_alternate_phone',
  'email',
]

export const CONTACT_FORM_DEFAULTS: Contact = {
  id: '',
  short_id: '',
  alt_phone: '',
  email: '',
  name: '',
  notes: '',
  org: { name: '', id: '' },
  phone: '',
  preferred: 'not_set',
}

export const contactsFormControls: Control[] = [
  {
    name: 'name',
    props: {
      label: 'Name',
      placeholder: 'Contact name',
      required: true,
      helperText: docs.name,
    },
  },
  {
    name: 'preferred',
    children: PREFERRED_CONTACT_METHODS.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )),
    props: {
      label: 'Preferred contact method',
      select: true,
      helperText: docs.preferred,
      required: true,
    },
  },
  {
    name: 'email',
    props: {
      label: 'Email',
      placeholder: 'Contact email',
      type: 'email',
      helperText: docs.email,
    },
  },
  {
    name: 'phone',
    props: {
      label: 'Primary phone',
      type: 'tel',
      helperText: docs.phone,
    },
  },
  {
    name: 'alt_phone',
    props: {
      label: 'Alternate phone',
      type: 'tel',
      helperText: docs.alt_phone,
    },
  },
  {
    name: 'notes',
    props: {
      label: 'Notes',
      placeholder: 'Misc. notes...',
      multiline: true,
      minRows: 4,
      maxRows: 6,
      helperText: docs.notes,
      sx: { gridColumn: '1/3' },
    },
  },
]

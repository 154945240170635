import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { topLevelAbsoluteAppRoutes } from 'lib/config'

type Props = {
  shortId: string
  asButton?: boolean
}

const LINK_TEXT = 'View in SoilCollector'

export function LinkToSoilCollectorColln(props: Props) {
  const { shortId, asButton } = props

  const href = [
    topLevelAbsoluteAppRoutes.soilCollector,
    shortId,
  ].join('/')

  if (asButton) {
    return (
      <Button component="a" href={href} target="_blank" variant="contained">
        {LINK_TEXT}
      </Button>
    )
  }

  return (
    <Link href={href} sx={{ fontSize: 'caption.fontSize' }} target="_blank">
      {LINK_TEXT}
    </Link>
  )
}

import type { Control, FormState } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

import type { FieldMgmtFormControlConfig } from './config.fields-mgmt-form'
import { fieldsMgmtFormControls } from './config.fields-mgmt-form'

type Props = {
  children: React.ReactNode
  control: Control<FieldsMgmtFormSchema>
  /** Can be used to disable all form inputs, e.g. during mutation. */
  disableAll: boolean
  formState: FormState<FieldsMgmtFormSchema>
}

type FormControlProps = Props & {
  ctrlConfig: FieldMgmtFormControlConfig
}

function FieldMgmtFormControl(props: FormControlProps) {
  const { control, formState, disableAll, ctrlConfig } = props
  const { props: inputProps, name } = ctrlConfig

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          {...field}
          size="small"
          {...inputProps}
          disabled={disableAll || inputProps.disabled}
          error={!!formState.errors[name]}
          helperText={formState.errors[name]?.message || inputProps.helperText}
          required={inputProps.required}
        />
      )}
    />
  )
}

export function FieldMgmtFormControls(props: Props) {
  const { children } = props

  return (
    <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" justifyContent="center" mb={2}>
      {children}
      {fieldsMgmtFormControls.map((ctrlConfig) => (
        <FieldMgmtFormControl key={ctrlConfig.name} ctrlConfig={ctrlConfig} {...props} />
      ))}
    </Box>
  )
}

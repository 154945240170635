import { create } from 'zustand'

type Store = {
  isEditingFiles: boolean
  // CRED: https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
  actions: {
    setIsEditingFiles: (yes: boolean) => void
  }
}

export const useEditCollnFilesStore = create<Store>((set) => ({
  isEditingFiles: false,
  actions: {
    setIsEditingFiles: (yes) => set(() => ({ isEditingFiles: yes })),
  },
}))

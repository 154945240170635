import { Link as RouterLink } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'

import type { MenuItemConfig } from './types'

export const SUBHEADER_CLASSNAME = 'basic-menu-subheader'

interface Props extends MenuItemConfig {
  handleClose: () => void
}

export function BasicMenuItem(props: Props) {
  const { isDivider, subHeaderText } = props

  if (isDivider) {
    return <Divider />
  }

  if (subHeaderText) {
    return (
      <ListItem
        className={SUBHEADER_CLASSNAME}
        sx={{ fontWeight: 'bold', fontSize: 'h6.fontSize' }}
      >
        {subHeaderText}
      </ListItem>
    )
  }

  const { to, uniqueKey, text, onClick, icon, disabled, handleClose, ...rest } = props

  const Content = (
    <>
      {!!icon && <ListItemIcon sx={{ color: rest.color, minWidth: 32 }}>{icon}</ListItemIcon>}
      <ListItemText sx={{ color: rest.color }}>{text}</ListItemText>
    </>
  )

  const common = {
    ...rest,
    disabled,
    dense: true,
  }

  if (to) {
    return (
      <ListItemButton component={RouterLink} to={to} {...common}>
        {Content}
      </ListItemButton>
    )
  }

  return (
    <MenuItem
      key={uniqueKey}
      {...common}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        handleClose()
      }}
    >
      {Content}
    </MenuItem>
  )
}

import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles'

// Fighting the chaos of fullscreen PWAs. See this tutorial for details:
// https://css-tricks.com/the-notch-and-css/
const pwaEnvs = {
  top: 'env(safe-area-inset-top)',
  bottom: 'env(safe-area-inset-bottom)',
  left: 'env(safe-area-inset-left)',
  right: 'env(safe-area-inset-right)',
}

let theme = createTheme({
  // TODO: make it ours: breakpoints: {},
  palette: {
    mode: 'dark',
    primary: {
      dark: '#2f6d30',
      main: '#449d45',
      light: '#69b06a',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        // Color prop has no effect on AppBar appearance in dark mode
        enableColorOnDark: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorTop: {
          paddingTop: pwaEnvs.top,
        },
        paperAnchorBottom: {
          paddingBottom: pwaEnvs.bottom,
        },
        paperAnchorLeft: {
          paddingTop: pwaEnvs.top,
          paddingBottom: pwaEnvs.bottom,
          paddingLeft: pwaEnvs.left,
        },
        paperAnchorRight: {
          paddingTop: pwaEnvs.top,
          paddingBottom: pwaEnvs.bottom,
          paddingRight: pwaEnvs.right,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          paddingTop: pwaEnvs.top,
          paddingBottom: pwaEnvs.bottom,
          paddingRight: pwaEnvs.right,
          paddingLeft: pwaEnvs.left,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          paddingTop: pwaEnvs.top,
          paddingBottom: pwaEnvs.bottom,
          paddingRight: pwaEnvs.right,
          paddingLeft: pwaEnvs.left,
        },
      },
    },
  },
})

// Typography: https://mui.com/material-ui/react-typography/#component
// Default theme: https://mui.com/material-ui/customization/default-theme/
theme = responsiveFontSizes(theme) // CRED: https://bit.ly/3wsGikE

export function AppThemeAndStyles({ children }: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

import { Navigate } from 'react-router-dom'
import type { RouteConfigWithHandle } from 'lib/types'

import { RouteErrorBoundary } from 'components/reusable/alerts-and-messages/RouteErrorBoundary'
import { FmtCollnExistingFilesView } from 'components/soil-sampling/collection-detail/files/FmtCollnFilesView'
import { SoilMapperBatchesByFieldAccordion } from 'components/tillmapper/SoilMapperBatchesByFieldAccordion'

import { ContactsLandingPanel } from './contacts/ContactsLandingPanel'
import { CreateNewContactPanel } from './contacts/CreateNewContactPanel'
import { EditExistingContactPanel } from './contacts/EditExistingContactPanel'
import { CollectionDetail } from './fields-mgmt/collections/CollectionDetail'
import { CollectionsListByField } from './fields-mgmt/collections/CollectionsListByField'
import { CreateCollnForm } from './fields-mgmt/collections/CreateCollnForm'
import { DrawNewFieldPanel } from './fields-mgmt/DrawNewFieldPanel'
import { EditExistingFieldPanel } from './fields-mgmt/EditExistingFieldPanel'
import { FieldsMgmtImportFieldsPanel } from './fields-mgmt/FieldsMgmtImportFieldsPanel'
import { FieldsPanelLanding } from './fields-mgmt/FieldsPanelLanding'
import { PanelOutletWrap } from './shared/PanelOutletWrap'
import { ImportFieldsPanel } from './ImportFieldsPanel'
import { FMT_BASEPATH, segments, wildcards } from './routes'

const COLLN_DETAIL_MATCH = [
  segments.fields,
  wildcards.fieldId,
  segments.collnsRelative,
  wildcards.collnId,
].join('/')

const imports: RouteConfigWithHandle[] = [
  {
    path: segments.fieldsImport,
    element: <ImportFieldsPanel />,
  },
  {
    path: `${segments.fieldsImport}/${wildcards.fieldId}`,
    element: <FieldsMgmtImportFieldsPanel />,
  },
]

const contacts: RouteConfigWithHandle[] = [
  {
    path: segments.contactsAddNewSingle,
    element: <CreateNewContactPanel />,
  },
  {
    path: `${segments.contactsEditExisting}/${wildcards.contactId}`,
    element: <EditExistingContactPanel />,
  },
  {
    path: segments.contacts,
    element: <ContactsLandingPanel />,
  },
]

const redirectToBase: RouteConfigWithHandle = {
  element: <Navigate replace to={segments.fields} />,
  index: true,
}

export const routesConfig: RouteConfigWithHandle[] = [
  {
    path: FMT_BASEPATH,
    lazy: () => import('./FieldsMgmtLanding'),
    children: [
      {
        element: <PanelOutletWrap />,
        errorElement: (
          <PanelOutletWrap>
            <RouteErrorBoundary />
          </PanelOutletWrap>
        ),
        children: [
          redirectToBase,
          ...contacts,
          ...imports,
          {
            path: segments.fields,
            element: <FieldsPanelLanding />,
          },
          {
            path: segments.fieldsDrawNew,
            element: <DrawNewFieldPanel />,
          },
          {
            path: `${segments.fields}/${wildcards.fieldId}`,
            element: <EditExistingFieldPanel />,
          },
          {
            path: COLLN_DETAIL_MATCH,
            element: <CollectionDetail />,
          },
          {
            path: [segments.fields, wildcards.fieldId, segments.collnsRelative].join('/'),
            element: <CollectionsListByField />,
          },
          {
            element: <SoilMapperBatchesByFieldAccordion />,
            path: [
              COLLN_DETAIL_MATCH,
              segments.collnLayersRelative,
            ].join('/'),
          },
          {
            element: <FmtCollnExistingFilesView />,
            path: [
              COLLN_DETAIL_MATCH,
              segments.collnFilesRelative,
            ].join('/'),
          },
          {
            element: <CreateCollnForm />,
            path: [
              segments.fields,
              wildcards.fieldId,
              segments.createCollnRelative,
            ].join('/'),
          },
        ],
      },
    ],
  },
]

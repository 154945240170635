import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import { MenuCloseBtn } from 'components/reusable/buttons'

import { SaneMarkdownWrapper } from './SaneMarkdownWrapper'

export type Props = {
  /** The anchor element that the Popover will open relative to. */
  anchorEl: HTMLButtonElement | null
  /** Not the best prop name: it's for converting an html string to Markdown */
  html?: string
  /** The anchorEl state-setter. Closes Popover on "X" and exterior clicks. */
  setAnchorEl: React.Dispatch<HTMLButtonElement | null>
  /** Optional Popover title */
  title?: React.ReactNode
  /**
   * Basically any content that you don't want converted into Markdown. Note
   * that the two are not mutually exclusive.
   * TODO: make Markdown the responsibility of the consumer
   */
  nonHtmlContent?: React.ReactNode
}

export function InfoPopover(props: Props) {
  const { setAnchorEl, anchorEl, html = '', title, nonHtmlContent } = props

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    handleClose()
  }

  const isOpen = !!anchorEl
  const id = isOpen ? 'info-popover' : undefined

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={id}
      open={isOpen}
      onClose={handleClose}
    >
      <Box
        p={2}
        width={320}
        sx={{
          ul: { pl: '20px' }, // TODO: de-heavy-hand it
        }}
      >
        {!!title && (
          <Typography mb={1} variant="h6">
            {title}
          </Typography>
        )}
        <MenuCloseBtn handleClick={handleCloseClick} />
        {!!html && <SaneMarkdownWrapper html={html} />}
        {/* "Nesting" errors galore if this is a paragraph component. */}
        {!!nonHtmlContent && (
          <Typography component="div" variant="body2">
            {nonHtmlContent}
          </Typography>
        )}
      </Box>
    </Popover>
  )
}

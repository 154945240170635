import { useEffect, useRef } from 'react'
import type { Control, FieldValues, Resolver } from 'react-hook-form'
import type { UseMutationResult } from '@tanstack/react-query'
import { useCanAccessBasedOnGroups, useCurrentUser } from 'lib/queries.user'

import { FieldAccessAutocomplete } from 'components/fields/access-control'
import type { FieldMgmtMode, FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'
import { ConfMessage } from 'components/reusable/alerts-and-messages'
import { FormResetAndSubmitBtns } from 'components/reusable/forms/FormResetAndSubmitBtns'
// NOTE: not working last time I tried, 03/26/23
// Uncomment the import and add the component to use this handy RHF dev tool:
// eslint-disable-next-line import/no-extraneous-dependencies
// import { DevTool } from '@hookform/devtools' // devDeps just like it should be
import type { PartialFormState } from 'components/reusable/forms/utils.forms'
import {
  getDirtyFormValues,
  ReversePartialFormStateProvider,
} from 'components/reusable/forms/utils.forms'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'
import { OrgAutocomplete } from 'components/reusable/OrgAutocomplete'

import * as config from './config.fields-mgmt-form'
import { ContactsAutocomplete } from './ContactsAutocomplete'
import { FieldMgmtExtras } from './FieldMgmtExtras'
import { FieldMgmtFormControls } from './FieldMgmtFormControls'
import { FieldSourceDropdownController } from './FieldMgmtSourceDropdown'
import { FieldStatusDropdownController } from './FieldMgmtStatusDropdown'
import { FormBoundaryBtns } from './FormBoundaryBtns'
import { FormHeaderBtns } from './FormHeaderBtns'
import type { FieldAttributes } from './types.fields-mgmt-api'
import { useFieldMgmtHookForm } from './utils.fields-mgmt-form'

type Props = {
  onSubmit: (
    allFormData: FieldsMgmtFormSchema,
    changedValues?: Partial<FieldsMgmtFormSchema>
  ) => void
  /**
   * Helps to make a variety of decisions such as hiding or disabling specific controls or
   * components, e.g. boundary editing buttons.
   */
  mode: FieldMgmtMode
  defaultValues?: FieldsMgmtFormSchema
  extras?: FieldAttributes['extras']
  mutation?: UseMutationResult
  /** Callback when form's `Reset` button is pushed */
  onReset?: () => void
  resolver?: Resolver<FieldsMgmtFormSchema>
  /**
   * Show next to the boundary button(s). At time of writing, it's just collection buttons.
   */
  headerChildren?: React.ReactNode
  initialGeom: GeoJSON.Geometry | null
}

const TurnOffMeasureWarning = (
  <ConfMessage
    severity="warning"
    text="Finish measuring, then close the tool if you need to submit. Note that if you are creating a new field and have already drawn the boundary, the Measure tool will clear it and you will need to redraw."
    title="Cannot submit while Measure tool is active"
    variant="outlined"
  />
)

export function FieldMgmtForm(props: Props) {
  const { onSubmit: submitHandler, mutation, mode, onReset } = props
  const { headerChildren, initialGeom, extras } = props
  const { data: currentUser, isLoading } = useCurrentUser()
  const { defaultValues = config.FIELD_MGMT_FORM_DEFAULTS, resolver } = props
  const { drawnFeatureGeom, activeToolbarID } = useDrawToolsStateContext()
  const formStateRef = useRef<PartialFormState>()
  const form = useFieldMgmtHookForm(defaultValues, resolver)
  const { control, formState, handleSubmit, reset, setValue } = form
  const { isValid, dirtyFields } = formState
  const greatSuccess = formState.isSubmitSuccessful && !!mutation?.isSuccess
  const disableAllInputs = mutation?.isLoading || !!greatSuccess
  const orgId = form.watch('org.id')
  const drawnFeatSerialized = JSON.stringify(drawnFeatureGeom)
  const defaultGeomSerialized = JSON.stringify(initialGeom)
  const geomsAreEqual = drawnFeatSerialized === defaultGeomSerialized
  const showBoundaryMsg = mode === 'draw' && !drawnFeatureGeom
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])
  const nothingChangedInForm = !Object.keys(dirtyFields).length
  const defaultsAsString = JSON.stringify(defaultValues) // for the effect dep
  const disableSubmitBtn = (mode === 'draw' && !initialGeom) || !isValid || nothingChangedInForm
  const measureModeIsActive = activeToolbarID === 'measure'

  const disableResetBtn =
    nothingChangedInForm ||
    greatSuccess ||
    (initialGeom !== null && drawnFeatureGeom !== null && geomsAreEqual)

  // Reset, otherwise the form hangs onto previous values if react-query has the data cached from a
  // previous query.
  useEffect(() => {
    reset(defaultValues)
    // IMPORTANT: don't use the `defaultValues` object as a dep! Must be a string, otherwise it
    // resets the form when "edit boundary" is clicked 🙀
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultsAsString, reset]) // TODO: how to deal w/this re-enabling SUBMIT?

  if (isLoading || !currentUser) {
    return <div style={{ gridColumn: '1/3' }} />
  }

  const onSubmit = (allFormData: FieldsMgmtFormSchema) => {
    const { dirtyFields: dirties } = formStateRef.current || {}

    if (dirties) {
      const changedValues = getDirtyFormValues(dirties, allFormData)

      submitHandler(allFormData, changedValues)
    }
  }

  function handleReset() {
    form.reset()

    if (onReset) {
      onReset()
    }
  }

  const FormContent = (
    <fieldset disabled={!userHasWritePerms} style={{ padding: 0, border: 'none' }}>
      <ReversePartialFormStateProvider
        // No thanks, spent hours on this, couldn't get it 😢
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        control={control}
        // @ts-ignore
        formStateRef={formStateRef}
        /* eslint-enable @typescript-eslint/ban-ts-comment */
      />
      <FieldMgmtFormControls control={control} disableAll={disableAllInputs} formState={formState}>
        <OrgAutocomplete
          required
          control={control as unknown as Control<FieldValues>} // 🙄
          disabled={!userHasWritePerms || mode !== 'draw'}
          initialValue={defaultValues.org}
          onChange={() => {
            // Clear the contact. Only applies to "Draw New" flow since Org is disabled in all other
            // FMT instances. Nicole preferred this over validation + error message. Changing Org
            // after a Contact is already selected seems like an edge case anyway?
            form.setValue('onsite_contact', null, {
              shouldTouch: true,
              shouldValidate: true,
              shouldDirty: true,
            })
          }}
        />
        <ContactsAutocomplete
          control={control}
          disabled={!userHasWritePerms || disableAllInputs || !orgId}
          initialValue={defaultValues.onsite_contact}
          orgId={orgId}
        />
        {/* NOTE: there is a janky bug that will enable SUBMIT btn after the
        boundary is changed even though the boundary is no longer part of the
        form. It is because the security_groups trigger a change, likely
        because of the default values hack, which makes form dirty. Not a big
        deal since the SG values are same as before, and the Submit btn is all
        the way at the bottom of form anyway. Plus, worst case, user hits Submit
        again, the payload will just be the current SG's. Waste of a PATCH, but
        nothing else. Really do NOT want to put time into this since we are
        pursuing a legit solution for preventing users from locking themselves
        out of their own field by not selecting the right SG.
         */}
        <FieldAccessAutocomplete
          disabled={!userHasWritePerms || disableAllInputs}
          hookFormProps={{ control, setValue }}
          defaultValue={
            // TODO: EPIC: confirm this craziness for Draw and Edit forms
            // QUESTION: should this be an empty array instead?
            defaultValues.security_groups.length ? defaultValues.security_groups : undefined
            // QUESTION: how to prevent constant warnings when there's nothing in the
            // `security_groups`? Or is this only happening in local dev garbage environment?
          }
        />
        <FieldStatusDropdownController control={control} disabled={!userHasWritePerms} />
        <FieldSourceDropdownController control={control} disabled={!userHasWritePerms} />
      </FieldMgmtFormControls>
      {userHasWritePerms && !measureModeIsActive && (
        <FormResetAndSubmitBtns
          // Might as well always enable for import, things are getting really screwy now that the
          // boundary is separate. There is really no harm in NOT checking for dirty changes. So
          // many headaches trying to maintain that, and for so little benefit. Just enable the
          // Continue button already!
          disableReset={disableResetBtn}
          disableSubmit={mode !== 'edit-import' && disableSubmitBtn}
          handleReset={handleReset}
          isLoading={!!mutation?.isLoading}
          submitBtnText={mode === 'edit-import' ? 'Continue' : undefined}
        />
      )}
      {userHasWritePerms && measureModeIsActive && TurnOffMeasureWarning}
      <FieldMgmtExtras extras={extras} />
    </fieldset>
  )

  // NOTE: not working last time I tried, 03/26/23
  // Add this and uncomment the import to use this handy RHF dev tool:
  // return <>{Form}<DevTool control={control} /></>

  return (
    <>
      <FormHeaderBtns>
        {headerChildren}
        {userHasWritePerms && <FormBoundaryBtns showWarning={!!showBoundaryMsg} />}
      </FormHeaderBtns>
      <form onSubmit={handleSubmit(onSubmit)}>{FormContent}</form>
    </>
  )
}

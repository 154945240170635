import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useCanAccessBasedOnPerms } from 'lib/queries.user'

import { segments } from 'components/fields/routes'

type Props = {
  collectionsCount?: number
}

export function CollnNavBtns(props: Props) {
  const { collectionsCount } = props
  const canSeeCreateCollnBtn = useCanAccessBasedOnPerms(['add_soilcollection'])
  const countIsNumeric = typeof collectionsCount === 'number'
  const btnTextSuffix = countIsNumeric ? ` (${collectionsCount})` : ''

  return (
    <Box alignItems="center" display="flex" gap={1}>
      <Button component={RouterLink} size="small" to={segments.collnsRelative}>
        view collections{btnTextSuffix}
      </Button>
      {canSeeCreateCollnBtn && (
        <Button component={RouterLink} size="small" to={segments.createCollnRelative}>
          add new
        </Button>
      )}
    </Box>
  )
}

import { API_BASE_PATH, apiEndpoints } from './api'

export const MB_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN as string

// Unique element ID selector for the map element. At time of writing, only used as a basis of
// `container` for any Dialog, BasicMenu, or Popover in the map.
export const DEFAULT_MAP_ID = 'interactive-map'

// Needs more than just this. Ends with `/fields`, for example, and has a `user_id` query param.
export const VECTOR_TILES_PATH = API_BASE_PATH + apiEndpoints.vectorTiles

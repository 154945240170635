import { useParams } from 'react-router-dom'
import { useV1ContactsRetrieve } from 'lib/api/django/v1/v1'
import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import type { FmtRouteParams } from 'components/fields/routes'
import { NoAccessSpan } from 'components/reusable/alerts-and-messages'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'

import { ContactPersonForm } from './ContactPersonForm'

// Grab the id from the url and send it to the API
export function EditExistingContactPanel() {
  const { contactId = '' } = useParams<FmtRouteParams>()
  const query = useV1ContactsRetrieve(contactId)
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])

  if (!userHasWritePerms) {
    return NoAccessSpan
  }

  if (query.isLoading || query.isError) {
    return <ReactQueryStates loadingText="Loading contact info" {...query} />
  }

  return <ContactPersonForm defaultValues={query.data} type="edit" />
}

import { BsArrow90DegUp } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { useIsAtLeastPhablet } from 'lib/hooks'
import { getUpOneLevelPath, pathCantGetMuchHigher } from 'lib/utils'

import { RouteLinkWithQueryParams } from 'components/reusable/routing/RouteLinkWithQueryParams'

type Props = {
  id?: string
  /**
   * How many levels at which it is considered "top-level". `/` is always top.
   *
   * @example `/soilcollector/collections` with a maxDepth of 1 will return true
   */
  maxDepth?: number
  /**
   * Catch-all in case the default route is not the top-level route.
   *
   * @example `/soilcollector/collections` is the default route, so we want to
   * disable the button there but not at `/soilcollector/preferences`.
   */
  addlPathsToDisable?: string[]
}

export function IconBtnBreadcrumb(props: Props) {
  const { id, maxDepth, addlPathsToDisable } = props
  const { pathname } = useLocation()
  const isTopLevel = pathCantGetMuchHigher(pathname, maxDepth)
  const isAtLeastPhablet = useIsAtLeastPhablet()

  return (
    // NOTE: absolutely no idea why TS hates this here but not on other components that use the
    // `RouteLinkWithQueryParams`. Giving up on this 👎
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IconButton
      aria-label="up one level"
      component={RouteLinkWithQueryParams}
      disabled={isTopLevel || addlPathsToDisable?.includes(pathname)}
      id={id}
      size={isAtLeastPhablet ? 'medium' : 'small'}
      to={getUpOneLevelPath(pathname)}
    >
      <BsArrow90DegUp />
    </IconButton>
  )
}

import { forwardRef } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

type Props = {
  children: React.ReactNode
  to: string
}

// CRED: for preventing more `forwardRef` errors:
// https://github.com/reactjs/reactjs.org/issues/2120#issuecomment-671129556
// eslint-disable-next-line react/display-name
export const RouteLinkWithQueryParams = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: Props, ref) => {
    const { search } = useLocation()
    const { to, children } = props

    return (
      <RouterLink {...props} to={to + search}>
        {children}
      </RouterLink>
    )
  }
)

import { BiExpand } from 'react-icons/bi'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  onClick: () => void
}

// TODO: do fullscreen right, maybe in a modal or something? It's not accessible via `Esc` key, and
// it's kind of a shabby un-animated approach.
export function ToggleFullscreenTableBtn(props: Props) {
  const { onClick } = props

  return (
    <Tooltip
      arrow
      enterDelay={400}
      enterNextDelay={400}
      placement="top"
      title="View table in fullscreen"
    >
      <IconButton aria-label="fullscreen dialog" color="primary" onClick={onClick}>
        <BiExpand />
      </IconButton>
    </Tooltip>
  )
}

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as turf from '@turf/turf'
import { squareMetersToAcres } from 'lib/utils'

import { EditFeatureGeomBtn } from 'components/fields/create-fields-steps/EditFeatureGeomBtn'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'

type Props = {
  showWarning: boolean
}

const BoundaryMsg = (
  <Typography color="error" variant="body2">
    Boundary required
  </Typography>
)

export function FormBoundaryBtns(props: Props) {
  const { showWarning } = props
  const { drawnFeatureGeom, activeToolbarID } = useDrawToolsStateContext()
  const measureModeIsActive = activeToolbarID === 'measure'

  let acres = 0

  if (drawnFeatureGeom) {
    acres = squareMetersToAcres(turf.area(drawnFeatureGeom))
  }

  return (
    <Box alignItems="center" display="flex" gap={1.5}>
      <EditFeatureGeomBtn />
      {/* Prevent the measure tool from showing the interim acres */}
      {!!acres && !measureModeIsActive && (
        <Typography variant="body2">Size: {acres} acres</Typography>
      )}
      {showWarning && BoundaryMsg}
    </Box>
  )
}

import type { PollingConfig } from 'react-detect-offline'

import { CORE_HEALTH_URL } from './api'

// Polling avoids false-positive flicker of "offline" indication 😞
const unsupportedAgentsPttn = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/
const inBrowser = typeof navigator !== 'undefined'
const enabled = inBrowser && unsupportedAgentsPttn.test(navigator.userAgent)

export const POLLING_CONFIG: PollingConfig = {
  enabled,
  interval: 30000,
  timeout: 5000,
  url: CORE_HEALTH_URL,
}

import { createContext, useContext, useReducer } from 'react'

import type { Action, Dispatch, State } from './types'

const initialState: State = {
  allowedModes: ['draw_point', 'draw_line_string', 'draw_polygon'],
  activeToolbarID: null,
  drawnFeatureGeom: null,
  mode: 'draw_point',
  toolbarVisible: false,
}

const StateContext = createContext<State | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'STOP_EDITING':
      return { ...initialState, mode: state.mode }
    case 'CLEAR_DRAWN_FEAT_GEOM':
      return { ...state, drawnFeatureGeom: null }
    case 'SET_DRAWN_FEAT_GEOM':
      return { ...state, drawnFeatureGeom: action.payload }
    case 'SET_GEOMETRY_MODE':
      return { ...state, mode: action.payload }
    case 'SET_ALLOWED_MODES':
      return { ...state, allowedModes: action.payload }
    case 'TOGGLE_TOOLBAR_VISIBILITY':
      return { ...state, toolbarVisible: action.payload }
    case 'SET_ACTIVE_TOOLBAR_ID':
      return { ...state, activeToolbarID: action.payload }
    case 'CLEAR_ACTIVE_TOOLBAR_ID':
      return { ...state, activeToolbarID: null }
    default: {
      return state
    }
  }
}

export function DrawToolsContext(props: { children: React.ReactNode }) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useDrawToolsStateContext(): State {
  const context = useContext(StateContext)

  if (context === undefined) {
    throw new Error('Must use within DrawToolsProvider!')
  }

  return context
}

function useDrawToolDispatchContext(): Dispatch {
  const context = useContext(DispatchContext)

  if (context === undefined) {
    throw new Error('Must use within DrawToolsProvider!')
  }

  return context
}

export { useDrawToolsStateContext, useDrawToolDispatchContext }

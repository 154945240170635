import Box from '@mui/material/Box'

import type { NonHookFormProps } from 'components/fields/access-control'
import { FieldAccessAutocomplete } from 'components/fields/access-control'
import { ColMapSectionHeading } from 'components/fields/fields-mgmt/column-mapping/ColMapSectionHeading'

import { columnsMappingConfig } from './config.fields-mgmt'
import type { Props as CrosswalkColumnDropdownProps } from './CrosswalkColumnDropdown'
import { CrosswalkColumnDropdown } from './CrosswalkColumnDropdown'

type Props = {
  availableCols: string[]
  orgMenu: React.ReactNode
  onColMapDropdownChange: CrosswalkColumnDropdownProps['onChange']
  colMappings: CrosswalkColumnDropdownProps['colMappings']
  secGroupsDropdownProps: NonHookFormProps
}

const colMapExplanation = `These dropdown menus each correspond with a desired "target" column that will be included in the final import step. If the source data contains a column that corresponds with one of the target columns, choose it from the dropdown for that target column. You may reuse the same column more than once, e.g. "field_name" could correspond with both "Field name (customer)" and "Field name (producer)".\n\nAt a minimum, try to find a column that corresponds with "Field name (customer)" as this will be required in the next step. If you cannot find a corresponding column, you may enter a Name manually in the next step.`

const orgAndAccessExplanation = `The Org must be set and cannot be changed later except by an administrator. All Fields in the import will use the Org you choose here.

Access control will default to the current user's default security group.`

export function CrosswalkSection(props: Props) {
  const { availableCols, colMappings, orgMenu, onColMapDropdownChange } = props
  const { secGroupsDropdownProps } = props

  return (
    // Because Kim can't see everything on laptop 😃
    <Box height="100%" maxWidth={275} sx={{ overflowY: 'auto' }}>
      <Box mb={2}>
        <ColMapSectionHeading info={orgAndAccessExplanation} text="Choose Org and access" />
        {orgMenu}
      </Box>
      <Box mb={2}>
        <FieldAccessAutocomplete nonHookFormProps={secGroupsDropdownProps} />
      </Box>
      <ColMapSectionHeading info={colMapExplanation} text="Column mappings" />
      <Box display="flex" flexDirection="column" gap={2}>
        {columnsMappingConfig.map((col) => {
          return (
            <CrosswalkColumnDropdown
              key={col.name}
              {...col}
              availableCols={availableCols}
              colMappings={colMappings}
              onChange={onColMapDropdownChange}
            />
          )
        })}
      </Box>
    </Box>
  )
}

import { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useUploadsFromField } from 'lib/queries.fields'
import { getPrettyDate } from 'lib/utils'

import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'
import { CompactionScoreTableCell } from 'components/reusable/CompactionScoreTableCell'
import type { Batch } from 'components/upload/common/types'
import { SoilMapperLayerStatuses } from 'components/upload/landing/SoilMapperLayerStatuses'

function SingleAccordion({ batch, index }: { batch: Batch; index: number }) {
  const prettyDate = getPrettyDate(batch.created_at)
  const [jobId, setJobId] = useState('')

  function handleChange(event: React.SyntheticEvent, visible: boolean) {
    // Easy way to prevent `useLayerCapabilities` query from running for every
    // single batch even when the accordion isn't open.
    if (visible && batch.latest_viewer_token_job_id) {
      setJobId(batch.latest_viewer_token_job_id)
    } else {
      setJobId('')
    }
  }

  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary
        aria-controls={`panel${index}a-content`}
        expandIcon={<IoIosArrowDown />}
        id={`panel${index}a-header`}
      >
        <div>
          <Typography variant="subtitle1">
            {batch.metadata.field}
            <Typography ml={1} variant="caption">
              {prettyDate}
            </Typography>
          </Typography>
          <Box alignItems="center" display="flex" gap={2}>
            {!!batch.latest_viewer_token_object?.compaction_score && (
              <Box alignItems="center" display="flex" gap={1}>
                <Typography lineHeight="1" variant="caption">
                  <b>Compaction:</b>
                </Typography>
                <CompactionScoreTableCell
                  score={batch.latest_viewer_token_object?.compaction_score}
                />
              </Box>
            )}
            {!!batch.latest_viewer_token_object?.mean_vwc && (
              <Box alignItems="center" display="flex">
                <Typography lineHeight="1" variant="caption">
                  <b>Mean VWC:</b> {batch.latest_viewer_token_object.mean_vwc}
                </Typography>
              </Box>
            )}
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" justifyContent="center">
          <SoilMapperLayerStatuses
            batchId={batch.id}
            fieldId={batch.metadata.field_id}
            jobId={jobId}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export function SoilMapperBatchesByFieldAccordion() {
  const { fieldId = '' } = useParams<{ fieldId: string }>()
  const query = useUploadsFromField(fieldId)
  const { data, isInitialLoading, isError } = query

  if (isInitialLoading || isError) {
    return <ReactQueryStates {...query} />
  }

  if (!data?.length) {
    // Technically they are "batches". This will be confusing either way.
    return <Typography>No collections found for this field.</Typography>
  }

  return (
    <div>
      {data?.map((batch, i) => {
        return <SingleAccordion key={batch.id} batch={batch} index={i} />
      })}
    </div>
  )
}

export const FMT_BASEPATH = 'fields-mgmt'

export const FMT_RELATIVE_FIELDS_PATH = 'fields'

export const wildcards = {
  collnId: ':collnId',
  fieldId: ':fieldId',
  contactId: ':contactId',
} as const

type ParamsKey = keyof typeof wildcards

export type FmtRouteParams = {
  [key in ParamsKey]: string
}

/**
 * FMT routes. Most are intentionally relative, since the usage may vary.
 */
export const segments = {
  base: `/${FMT_BASEPATH}`, // the only absolute route
  collnsRelative: 'collections',
  collnLayersRelative: 'layers',
  collnFilesRelative: 'files',
  createCollnRelative: 'collections/new',
  contacts: 'contacts',
  contactsAddNewSingle: 'contacts/new',
  contactsEditExisting: 'contacts/edit',
  fields: FMT_RELATIVE_FIELDS_PATH,
  fieldsDrawNew: 'new-field',
  fieldsImport: 'import-fields',
} as const

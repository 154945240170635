import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import type { GridColDef, GridSingleSelectColDef } from '@mui/x-data-grid-premium'
import { formatFileSize } from 'lib/utils/files'

import { ReusableTable } from 'components/reusable/tables'

export type Row = {
  filename: string
  fileSize: number
  progress: number
}

type Props = {
  data: Row[]
}

type TypedColumnName = keyof Row

type TypedColumn = {
  field: TypedColumnName
}

type ColumnDef = Omit<GridColDef<Row>, 'field'> & TypedColumn

type SingleSelectColumnDef = Omit<GridSingleSelectColDef<Row>, 'field'> & TypedColumn

const TABLE_SLUG = 'upload-files-progress'

const COLUMNS: (ColumnDef | SingleSelectColumnDef)[] = [
  {
    field: 'filename',
    headerName: 'File',
    width: 400, // some meaties out there, e.g. "BruceGibbens_C2_20230914_123247_geophex_1.eo.csv"
    description: 'Name of the file',
  },
  {
    field: 'fileSize',
    headerName: 'Size',
    width: 105,
    description: 'File size',
    type: 'number',
    renderCell: ({ value }) => formatFileSize(value ?? 0),
  },
  {
    field: 'progress',
    headerName: 'Progress',
    width: 250,
    description: 'Upload progress',
    type: 'number',
    valueGetter: ({ row }) => row.progress * 100,
    renderCell: ({ value }) => {
      return (
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          justifyContent="space-between"
          px={1.5}
          width="100%"
        >
          <LinearProgress sx={{ flex: 1 }} value={value} variant="determinate" />
          {value.toFixed(0)}%
        </Box>
      )
    },
  },
]

export function UploadFilesProgressTable(props: Props) {
  const { data } = props

  return (
    <ReusableTable
      columns={COLUMNS}
      data={data}
      tableSlug={TABLE_SLUG}
      containerSx={{
        width: '100%',
        height: '60vh',
        overflowX: 'hidden',
      }}
      tableProps={{
        getRowId: ({ filename }) => filename,
      }}
    />
  )
}

import { VscSourceControl } from 'react-icons/vsc'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { buildInfo } from 'lib/config'
import { getLocalhostness, getPrettyDate } from 'lib/utils'

type VersionInfo = {
  branchName: string
  buildTime: string
  commitHash: string
  commitHashShort: string
  commitMsg: string
}

type SimpleVersionMetaProps = Pick<VersionInfo, 'buildTime' | 'commitHashShort'>

type Props = {
  expanded?: boolean
}

const PORTAL_UI_GH_BASE_URL = 'https://github.com/EarthOptics/portal-ui/commit/'
const IS_LOCAL_HOST = getLocalhostness()

// For staff users
function FullVersionMeta(props: VersionInfo) {
  const { commitHashShort, buildTime, commitHash, commitMsg } = props
  const { branchName } = props

  let Content = null

  // Link to GH doesn't make much sense if it hasn't been pushed yet 🙃
  if (IS_LOCAL_HOST) {
    Content = <code>{commitHashShort}</code>
  } else {
    Content = (
      <Link href={PORTAL_UI_GH_BASE_URL + commitHash} target="_blank">
        {commitHashShort}
      </Link>
    )
  }

  // TODO: https://earthoptics.atlassian.net/browse/DV-3173 - add backend version (see stash with a
  // good start)
  return (
    <Typography
      component="div"
      display="inline-flex"
      flexDirection="column"
      gap={1}
      variant="caption"
      whiteSpace="pre-line"
    >
      <div>
        <Typography component="span" mr="4px" variant="subtitle2">
          Version
        </Typography>{' '}
        {Content}
      </div>
      <div>
        <Typography component="span" mr="4px" variant="subtitle2">
          Updated
        </Typography>{' '}
        {getPrettyDate(buildTime)}
      </div>
      <div>
        <Typography component="span" variant="subtitle2">
          Branch
        </Typography>
        <br />
        {branchName}
      </div>
      <div>
        <Typography component="span" variant="subtitle2">
          Commit Message
        </Typography>
        <br />
        {commitMsg}
      </div>
    </Typography>
  )
}

// Non-staff users don't get to see all the goodies.
function SimpleVersionMeta(props: SimpleVersionMetaProps) {
  const { commitHashShort, buildTime } = props

  return (
    <Typography component="div" variant="caption">
      Version: <code>{commitHashShort}</code>
      <br />
      Updated: {getPrettyDate(buildTime)}
    </Typography>
  )
}

// Wrapper around heading and content
export function AppVersionInfo(props: Props) {
  const { expanded } = props

  const Heading = (
    <Typography
      alignItems="center"
      color="text.secondary"
      display="flex"
      gap={1}
      mb={1}
      variant="overline"
    >
      <VscSourceControl />
      Version info
    </Typography>
  )

  let Content = null

  if (!expanded) Content = <SimpleVersionMeta {...buildInfo} />
  else Content = <FullVersionMeta {...buildInfo} />

  return (
    <Box pb={2} pt={1} px={2}>
      {Heading}
      {Content}
    </Box>
  )
}

import { FaMountain, FaSatelliteDish } from 'react-icons/fa'
import { FiMoon, FiSun } from 'react-icons/fi'
import { ImRoad } from 'react-icons/im'
import { MdTextFields } from 'react-icons/md'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { Baselayer } from 'components/reusable/maps/config'
import { useMapLayersContext } from 'components/reusable/maps/layers/MapLayersContextStore'

import '@mui/material'

import { MapTerrainToggle } from './MapTerrainToggle'

// Add a value prop to Tooltip in order to make TS happy when using Tooltip with ToggleButton. If a
// value isn't provided, the ToggleButton will not appear selected.
// CRED: https://github.com/mui/material-ui/issues/12921#issuecomment-1255734453
declare module '@mui/material' {
  export interface TooltipProps {
    value?: number | string
  }
}

const layerBtnsConfig = [
  {
    text: 'Imagery',
    value: Baselayer.Satellite,
    icon: <FaSatelliteDish />,
  },
  {
    text: 'Imagery (with labels)',
    value: Baselayer.SatStreets,
    icon: <MdTextFields />,
  },
  {
    text: 'Streets',
    value: Baselayer.Streets,
    icon: <ImRoad />,
  },
  {
    text: 'Topographic',
    value: Baselayer.Outdoors,
    icon: <FaMountain />,
  },
  {
    text: 'Light',
    value: Baselayer.Light,
    icon: <FiSun />,
  },
  {
    text: 'Dark',
    value: Baselayer.Dark,
    icon: <FiMoon />,
  },
]

export function BaselayerToggles() {
  const baselayer = useMapLayersContext((store) => store.baselayer)
  const showTerrain = useMapLayersContext((store) => store.showTerrain)
  const setBaselayer = useMapLayersContext((store) => store.actions.setBaselayer)
  const setShowTerrain = useMapLayersContext((store) => store.actions.setShowTerrain)

  const handleBaselayerChange = (
    evt: React.MouseEvent<HTMLElement>,
    nextView: Baselayer | null
  ) => {
    // Enforce having one layer always selected
    if (nextView !== null) {
      setBaselayer(nextView)
    }
  }

  const BaseMapToggles = (
    <ToggleButtonGroup
      exclusive
      sx={{ width: '100%' }}
      value={baselayer} // can't use local state because it gets destroyed on parent unmount
      onChange={handleBaselayerChange}
    >
      {layerBtnsConfig.map(({ value, text, icon }) => (
        <Tooltip key={value} title={text} value={value}>
          <ToggleButton aria-label={text} value={value}>
            {icon}
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  )

  return (
    <>
      <MapTerrainToggle checked={showTerrain} onChange={() => setShowTerrain(!showTerrain)} />
      <Box alignItems="center" display="flex" gap={1.5} mb={1.5}>
        <Typography component="h3" mb={0} variant="overline">
          Background
        </Typography>
        {BaseMapToggles}
      </Box>
    </>
  )
}

import { useRouteError } from 'react-router-dom'

import { ConfMessage } from 'components/reusable/alerts-and-messages'

const rootStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
}

export function RouteErrorBoundary() {
  const error = useRouteError()
  const message = error instanceof Error ? error.message : 'Something went wrong.'

  return (
    <div style={rootStyle}>
      <ConfMessage
        severity="error"
        text="Try refreshing the page or logging out and back in. If the problem persists, please contact support."
        title={message}
        variant="outlined"
      />
    </div>
  )
}

import type { SxProps } from '@mui/material'

export const toolbarRootSx: SxProps = {
  // TODO: container query instead of device width
  gap: {
    xs: 1,
    sm: 1.5,
  },
  px: 0,
  pb: {
    xs: 0,
    sm: 1,
  },
  'a, button': {
    minWidth: 'auto',
    whiteSpace: 'nowrap',
  },
}

export const toolbarBtnsWrapSx: SxProps = {
  flex: {
    xs: 'unset',
    sm: 1,
  },
  overflowX: {
    xs: 'auto',
    sm: 'unset',
  },
  width: {
    xs: '100%',
    sm: 'auto',
  },
}

export const toolbarBtnsWrapStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
}

export const toolbarSearchMobileSx: SxProps = {
  flex: 1,
  width: '100%',
  mb: 2,
}

import { legendConfig } from 'components/tillmapper/accordion/config.legend'
import { SOILMAPPER_BASEPATH } from 'components/tillmapper/routes'
import type {
  AccordionPanelConfig,
  LayersCapabilities,
  TillMapLayerSourceId,
} from 'components/tillmapper/types'

import type { ProductName } from './types.products'

const TILLMAPPER_LAYERS: TillMapLayerSourceId[] = ['rx', 'compaction']
const CMAPPER_LAYERS: TillMapLayerSourceId[] = ['carbon', 'bulkDensity']

const NUTRIENTMAPPER_LAYERS: TillMapLayerSourceId[] = [
  'nutrient_cec',
  'nutrient_ppm_k',
  'nutrient_ppm_p',
  'nutrient_soilph',
  'buffer_ph',
]

// Get the most ideal layer to show when the tab is activated. If there is a need to show the "most
// recently selected" layer, this may change.
export function getDefaultProductLayerId(
  productName: ProductName,
  layers?: LayersCapabilities
): TillMapLayerSourceId | null {
  if (productName === 'tillmapper') {
    if (layers?.rx) return 'rx'
    if (layers?.compaction) return 'compaction'
  }

  if (productName === 'c-mapper') {
    if (layers?.carbon) return 'carbon'
    if (layers?.bulkDensity) return 'bulkDensity'
  }

  if (productName === 'nutrientmapper') {
    if (layers?.nutrient_cec) return 'nutrient_cec'
    if (layers?.nutrient_ppm_k) return 'nutrient_ppm_k'
    if (layers?.nutrient_ppm_p) return 'nutrient_ppm_p'
    if (layers?.nutrient_soilph) return 'nutrient_soilph'
    if (layers?.buffer_ph) return 'buffer_ph'
  }

  if (productName === 'other') {
    if (layers?.conductivity) return 'conductivity'
    if (layers?.moisture) return 'moisture'

    return 'ssurgo'
  }

  return null
}

export function getProductNameByLayerName(layerName: TillMapLayerSourceId): ProductName {
  if (TILLMAPPER_LAYERS.includes(layerName)) return 'tillmapper'
  if (CMAPPER_LAYERS.includes(layerName)) return 'c-mapper'
  if (NUTRIENTMAPPER_LAYERS.includes(layerName)) return 'nutrientmapper'

  return 'other'
}

/**
 * Get absolute path to a SoilMapper v1 layer.
 *
 * @param fieldId field UUID
 * @param batchId batch UUID
 * @param productName id/name of the product
 * @param activeViewId currently active layer/view
 * @returns absolute path to the SoilMapper v1 layer
 */
export function getSoilMapperV1layerPath(
  fieldId: string,
  batchId: string,
  productName: ProductName,
  activeViewId: TillMapLayerSourceId
): string {
  return [
    `/${SOILMAPPER_BASEPATH}`,
    fieldId,
    `${batchId}/${productName}?activeViewId=${activeViewId}`,
  ].join('/')
}

/**
 * Prep the config and links for SoilMapper layers, e.g. for use in FMT collections list.
 *
 * @param batchId batch UUID
 * @param fieldId field UUID
 * @param layersCapabilities layers "capabilities" object
 * @returns array of accordion panel config objects with `href` for links and an `isAvailable` flag
 */
export function getSoilMapperV1statusesConfig(
  batchId: string,
  fieldId?: string,
  layersCapabilities?: LayersCapabilities
): (AccordionPanelConfig & { href: string; isAvailable: boolean })[] {
  if (!fieldId || !layersCapabilities) {
    return []
  }

  // SSURGO is always indeterminate, no sense in including it. Perhaps we could include it with a
  // disclaimer and some 🎲🎲, but doesn't seem worth it.
  const LAYERS_TO_EXCLUDE: TillMapLayerSourceId[] = ['ssurgo']

  return Object.values(legendConfig)
    .filter((item) => !LAYERS_TO_EXCLUDE.includes(item.sourceId))
    .map((item) => {
      const asLayersCapKey = item.sourceId as keyof LayersCapabilities
      const productName = getProductNameByLayerName(item.sourceId)
      const href = getSoilMapperV1layerPath(fieldId, batchId, productName, item.sourceId)

      return {
        ...item,
        isAvailable: !!layersCapabilities[asLayersCapKey],
        href,
      }
    }, {})
}

import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { MutationCache, QueryClient } from '@tanstack/react-query'
import type { AnnotationCreate, PatchedSoilCore } from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey as collnDetailQueryKey,
  v1SoilSamplingCollectionsAnnotationsCreate,
  v1SoilSamplingCoresPartialUpdate,
  v1SoilSamplingSamplesBulkUpdatePartialUpdate,
} from 'lib/api/django/v1/v1'
import localforage from 'localforage'

import { localQueryKeys } from 'components/soil-sampling/config.ssa'
import type { SoilSample } from 'components/soil-sampling/types.ssa'

/* eslint-disable padding-line-between-statements */
// Need these for persister and mutation defaults
export const ANNOTATION_MUTATION_KEY = ['annotations']
export const SOIL_CORE_MUTATION_KEY = ['soil-core']
export const BULK_SAMPLES_MUTATION_KEY = ['bulk-samples']
/* eslint-enable padding-line-between-statements */

const reactQueryStoreLocalForage = localforage.createInstance({
  name: 'react-query-storage',
  storeName: 'all-rq-storage',
  description: 'react-query persistent storage via localforage (IndexedDB)',
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // fresh for 5 minutes
    },
    // 24 hours (no clue if needed). Could not make persistent mutations NOT happen except by
    // setting the `maxAge` in the `PersistQueryClientProvider` in App.tsx.
    // mutations: { cacheTime: 1000 * 60 * 60 * 24 }
  },
  mutationCache: new MutationCache(),
})

// At time of writing (06/17/2024), we are only creating annotations offline. All other
// bidirectional syncing is handled elsewhere, so only annotations are affected here.
queryClient.setMutationDefaults(ANNOTATION_MUTATION_KEY, {
  mutationFn: async (payload: {
    collectionId: string
    data: GeoJSON.Feature<GeoJSON.Geometry, Omit<AnnotationCreate, 'geometry'>>
  }) => {
    // Avoid clashes with our optimistic update when an offline mutation continues
    const remoteCollnQueryKey = collnDetailQueryKey(payload.collectionId) // it's the short_id
    const localCollnQueryKey = localQueryKeys.collnDetail(payload.collectionId)

    await queryClient.cancelQueries({ queryKey: remoteCollnQueryKey })
    await queryClient.cancelQueries({ queryKey: localCollnQueryKey })

    return v1SoilSamplingCollectionsAnnotationsCreate(payload.collectionId, {
      name: payload.data.properties.name,
      description: payload.data.properties.description,
      type: payload.data.properties.type,
      geometry: payload.data.geometry as unknown as string,
    })
  },
})

// Soil cores are a little special: at time of writing, they are the only nested object in samples,
// and they are also PATCHable via a separate endpoint. So, instead of trying to do a bunch of
// syncing logic, it is much easier to simply lean on react-query to handle the retries when a user
// leaves the app and comes back when online again.
queryClient.setMutationDefaults(SOIL_CORE_MUTATION_KEY, {
  mutationFn: async (payload: { id: string; data: PatchedSoilCore }) => {
    // Avoid clashes with our optimistic update when an offline mutation continues
    const collnShortId = payload.data.collection?.split('-')[0] || '' // should exist
    const remoteCollnQueryKey = collnDetailQueryKey(collnShortId)
    const localCollnQueryKey = localQueryKeys.collnDetail(collnShortId)

    await queryClient.cancelQueries({ queryKey: remoteCollnQueryKey })
    await queryClient.cancelQueries({ queryKey: localCollnQueryKey })

    return v1SoilSamplingCoresPartialUpdate(payload.id, payload.data)
  },
})

queryClient.setMutationDefaults(BULK_SAMPLES_MUTATION_KEY, {
  mutationFn: async (payload: { data: Partial<SoilSample>[] }) => {
    // Avoid clashes with our optimistic update when an offline mutation continues
    const collnShortId = payload.data[0].collection_id?.split('-')[0] || '' // should exist
    const remoteCollnQueryKey = collnDetailQueryKey(collnShortId)
    const localCollnQueryKey = localQueryKeys.collnDetail(collnShortId)

    await queryClient.cancelQueries({ queryKey: remoteCollnQueryKey })
    await queryClient.cancelQueries({ queryKey: localCollnQueryKey })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error // OpenAPI says it's a single object, but it's an array
    return v1SoilSamplingSamplesBulkUpdatePartialUpdate(payload.data)
  },
})

export const asyncStoragePersister = createAsyncStoragePersister({
  serialize: (client) => {
    // If react-query v4 was available when SC was created, we could've probably used this for
    // everything, but since we're fully baked into a hand-rolled local/server storage/sync thing,
    // we will only use this for annotations for now as they are the only entity (so far) that may
    // be CREATED offline.
    const mutationsWeWantToPersist = client.clientState.mutations.filter(({ mutationKey }) => {
      const key = mutationKey?.toString()

      if (!key) {
        return false
      }

      return [
        ANNOTATION_MUTATION_KEY.toString(),
        SOIL_CORE_MUTATION_KEY.toString(),
        BULK_SAMPLES_MUTATION_KEY.toString(),
      ].includes(key)
    })

    return JSON.stringify({
      ...client, // should just be a timestamp and buster
      clientState: {
        mutations: mutationsWeWantToPersist,
        queries: [], // if/when we ever redo or native-fy SC, persistent queries would be amazing!
      },
    })
  },
  storage: {
    getItem: async (key: string) => {
      return reactQueryStoreLocalForage.getItem(key)
    },
    setItem: async (key: string, value: string) => {
      return reactQueryStoreLocalForage.setItem(key, value)
    },
    removeItem: async (key: string) => {
      return reactQueryStoreLocalForage.removeItem(key)
    },
  },
})

import { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import { useQueryClient } from '@tanstack/react-query'
import type { CollectionFiles, CollectionStatusEnum } from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsFilesListQueryKey,
  v1SoilSamplingCollectionsFilesCreate,
} from 'lib/api/django/v1/v1'
import { isPromiseFulfilled, isPromiseRejected } from 'lib/utils'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { FormModal } from 'components/reusable/handy-templates'
import { isSupportedSpatialFile } from 'components/reusable/maps/layers'
import { AFTER_FIELDWORK_STATUSES } from 'components/soil-sampling/config.ssa'
import dropzoneStyles from 'components/upload/common/Upload.module.css'

import { useEditCollnFilesStore } from './store.colln-files'
import type { Row as FileUploadStatusRow } from './UploadFilesProgressTable'
import { UploadFilesProgressTable } from './UploadFilesProgressTable'

type Props = {
  fullCollnId: string
  collnStatus: CollectionStatusEnum
  /**
   * What to send out after the files are set.
   *
   * @param action probably an Action of some kind, just don't know how to TS 🤷
   */
  dispatchFn?: (action: unknown) => void
  /**
   * Nice way to preview stuff in the map w/o making the commitment to actually
   * uploading the files.
   */
  preventUpload?: boolean
}

const TOAST_ID = 'initiated'

export function UploadFilesToCollnDropzone(props: Props) {
  const { fullCollnId, dispatchFn, preventUpload, collnStatus } = props
  const isEditing = useEditCollnFilesStore((state) => state.isEditingFiles)
  const rqc = useQueryClient()
  const [progressModalOpen, setProgressModalOpen] = useState(false)
  const [progressData, setProgressData] = useState<FileUploadStatusRow[]>([])

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (accepted) => {
      if (!dispatchFn) {
        return
      }

      const spatialFiles = accepted.filter(({ name }) => isSupportedSpatialFile(name))

      dispatchFn({ type: 'SET_COLLN_SPATIAL_FILES', payload: spatialFiles })

      if (preventUpload) {
        return
      }

      const promises: Promise<CollectionFiles>[] = []

      // TODO: break this mess out
      async function uploadFiles() {
        accepted.forEach((file, i) => {
          // Show "in-progress" toast. It's only around for a flash if it's just one file, but it's
          // nice to let user know something's happening when there are multiple files.
          if (i === 0) {
            toast(
              <ToastContentWithTitle
                content="You will be notified when all files have finished uploading."
                title="Upload in progress, click for details"
              />,
              {
                type: 'info',
                toastId: TOAST_ID,
                hideProgressBar: true,
                closeOnClick: false,
                autoClose: false,
                closeButton: false,
                draggable: false,
                onClick: () => setProgressModalOpen(true),
              }
            )
          }

          // Shove a new row into the progress table
          setProgressData((prev) => [
            ...prev,
            {
              filename: file.name,
              fileSize: file.size,
              progress: 0,
            },
          ])

          const theMutation = v1SoilSamplingCollectionsFilesCreate(
            fullCollnId,
            // NOTE: no params required except `file`, despite the TS claim
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore // not sure how to TS `File` in OpenAPI
            { file },
            {
              onUploadProgress: ({ progress = 0 }) => {
                setProgressData((prev) =>
                  prev.map((row) => (row.filename === file.name ? { ...row, progress } : row))
                )
              },
            }
          )

          promises.push(theMutation)
        })

        await Promise.allSettled(promises).then((settledResult) => {
          const successes = settledResult.filter(isPromiseFulfilled)
          const failures = settledResult.filter(isPromiseRejected)

          toast.dismiss(TOAST_ID)

          if (successes.length) {
            toast(
              <ToastContentWithTitle
                content="File(s) successfully added to the collection."
                title={`${successes.length} file(s) uploaded.`}
              />,
              { type: 'success' }
            )
          }

          if (failures.length) {
            toast.error(
              <ToastContentWithTitle
                content="File(s) could not be added to the collection."
                title={`Problems uploading ${failures.length} file(s)`}
              />
            )
          }

          rqc.invalidateQueries(getV1SoilSamplingCollectionsFilesListQueryKey(fullCollnId))
        })
      }

      uploadFiles()
    },
  })

  // At time of writing only SC has the ability to add/rm files after submission
  if (AFTER_FIELDWORK_STATUSES.includes(collnStatus) && !isEditing) {
    return null
  }

  const DropzoneContents = (
    <div>
      <div
        aria-label="File Upload"
        className={dropzoneStyles.dropzone}
        id="files"
        role="button"
        {...getRootProps()}
      >
        <input name="files" {...getInputProps()} />
        <Typography component="p" variant="body2">
          Drag and drop files, or click to open your file browser. Note that files must be{' '}
          <strong>uniquely named</strong> (per collection) and <strong>not empty</strong>.
        </Typography>
      </div>
    </div>
  )

  return (
    <>
      {DropzoneContents}
      <FormModal
        isNotForm
        content={<UploadFilesProgressTable data={progressData} />}
        dialogProps={{ fullScreen: true }}
        elemId="files-progress"
        isOpen={progressModalOpen}
        setIsOpen={setProgressModalOpen}
        title="File Upload Progress"
      />
    </>
  )
}

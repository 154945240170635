import type { LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import type { LinkProps } from '@mui/material/Link'
import Link from '@mui/material/Link'

type Props = {
  content: React.ReactNode
  to: string
  linkProps?: LinkProps
  /**
   * Any overrides to the router Link, e.g. `relative`
   *
   * @see https://reactrouter.com/en/main/components/link
   */
  routerLinkProps?: Omit<RouterLinkProps, 'to'>
}

export function RouterLink(props: Props) {
  const { to, content, linkProps, routerLinkProps } = props

  return (
    <Link component={ReactRouterLink} to={to} {...routerLinkProps} {...linkProps}>
      {content}
    </Link>
  )
}

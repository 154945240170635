import * as React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { RIGHTHAND_ITEM_ELEM_ID, useRouteMatch } from 'lib/hooks'
import { useCurrentUser } from 'lib/queries.user'

import { segments } from 'components/dashboard/routes'
import { useLayoutState } from 'components/layout/LayoutContext'
import { LogoutBtn, MenuCloseBtn } from 'components/reusable/buttons'
import { RememberTableStatesToggle } from 'components/reusable/tables'
import { SOILMAPPER_BASEPATH } from 'components/tillmapper/routes'

import { GroupsOfCurrentUser, OrgLogo, UserIcon, UserMenuUserInfo } from '.'

import styles from './UserMenu.module.css'

const USER_MENU_ID = 'user-menu'

const commonBtnProps = {
  'aria-controls': USER_MENU_ID,
  'aria-haspopup': 'true' as const, // 🤷‍♂️
  'data-cy': 'user-btn',
}

const Preferences = (
  <Box mb={2}>
    <Typography component="h3" mb={1} variant="h6">
      Preferences
    </Typography>
    <RememberTableStatesToggle />
  </Box>
)

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const { userMenuOrg } = useLayoutState()

  // Did not use `isLoading` just in case someone needs to access the menu to sign out or hit Debug
  // button while it's loading. Highly unlikely but it's such a fast query any way, not to mention
  // it's probably in `localStorage` already, so probably not worth the loader. Also the way this
  // component is currently structured, it would take some refactoring in order to show a spinner
  // since there are so many things dependent on this query.
  // eslint-disable-next-line padding-line-between-statements
  const { data: currentUser, error } = useCurrentUser()
  const isStaff = currentUser?.is_staff
  const handleClose = () => setAnchorEl(null)

  const currentPageIsLogoAble = useRouteMatch([
    segments.base,
    `${segments.base + SOILMAPPER_BASEPATH}/*`,
  ])

  if (error) {
    const errText = error instanceof Error ? error.message : ''

    toast.error(errText || 'Failed to get user info', {
      // Attempt to prevent a million "Network error" toasts if offline:
      toastId: 'current-user',
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  let Logo = null

  if (currentUser?.org.logo && !isStaff) {
    Logo = <OrgLogo logoUrl={currentUser.org.logo} orgName={currentUser.org.name} />
  } else if (userMenuOrg?.logo && isStaff && currentPageIsLogoAble) {
    Logo = <OrgLogo logoUrl={userMenuOrg.logo} orgName={userMenuOrg.name} />
  }

  const UserMenuIcon = <UserIcon data={currentUser} />

  const ButtonWithText = (
    <Button
      {...commonBtnProps}
      aria-expanded={open ? 'true' : undefined}
      className={styles.buttonWithText}
      id="user-button"
      size="small"
      startIcon={UserMenuIcon}
      onClick={handleClick}
    >
      <span>{currentUser?.first_name}</span>
    </Button>
  )

  const IconOnlyButton = (
    <IconButton
      {...commonBtnProps}
      aria-expanded={open ? 'true' : undefined}
      className={styles.iconOnlyButton}
      edge="end"
      onClick={handleClick}
    >
      {UserMenuIcon}
    </IconButton>
  )

  return (
    <div className={styles.root} id={RIGHTHAND_ITEM_ELEM_ID}>
      <Box display="flex" gap={0.5}>
        {ButtonWithText}
        {IconOnlyButton}
        {Logo}
      </Box>
      <Drawer
        anchor="right"
        classes={{ paper: styles.userMenuPaperRootIsh }}
        id={USER_MENU_ID}
        open={open}
        sx={{ cursor: 'pointer' }}
        onClose={handleClose}
      >
        <UserMenuUserInfo data={currentUser} icon={UserMenuIcon} />
        <div className={styles.userMenuBody}>
          {Preferences}
          {isStaff && <GroupsOfCurrentUser />}
        </div>
        <MenuCloseBtn handleClick={handleClose} />
        <footer className={styles.userMenuFooter}>
          <LogoutBtn />
        </footer>
      </Drawer>
    </div>
  )
}

import { FiUserPlus } from 'react-icons/fi'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import { segments } from 'components/fields/routes'
import { NoAccessSpan } from 'components/reusable/alerts-and-messages'

export function ContactsLandingPanel() {
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])

  let Content = NoAccessSpan

  if (userHasWritePerms) {
    Content = (
      <Button
        component={RouterLink}
        size="large"
        startIcon={<FiUserPlus />}
        to={`${segments.base}/${segments.contactsAddNewSingle}`}
        variant="contained"
      >
        Create new contact
      </Button>
    )
  }

  return (
    <Box display="flex" gap={2} justifyContent="center">
      {Content}
    </Box>
  )
}

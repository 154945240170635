import { useState } from 'react'
import { toast } from 'react-toastify'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { useQueryClient } from '@tanstack/react-query'
import {
  getV1UiTableViewsTableviewsListQueryKey,
  useV1UiTableViewsTableviewsCreate,
} from 'lib/api/django/v1/v1'

import { FormModal } from 'components/reusable/handy-templates/FormModal'
import { useTableStatesStore } from 'components/reusable/tables/store'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  tableSlug: string
}

export function NewSavedViewFormModal(props: Props) {
  const { tableSlug, isOpen, setIsOpen } = props
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const mutation = useV1UiTableViewsTableviewsCreate()
  const rqc = useQueryClient()

  const currentStateInStorage = useTableStatesStore((state) =>
    tableSlug ? state.tableStates[tableSlug] : null
  )

  // Prevent Tab key from closing the dialog // CRED: https://stackoverflow.com/a/56285545/1048518
  const stopPropagationForTab = (event?: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleSubmit = () => {
    if (!currentStateInStorage) {
      return
    }

    mutation.mutate(
      {
        data: {
          name,
          description,
          ui_table: tableSlug,
          is_private: isPrivate,
          state: currentStateInStorage as unknown as { [key: string]: unknown }, // 🙄
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore // even though it's readonly, backend needs it 🤷
          table_slug: tableSlug,
        },
      },
      {
        onSuccess: (data) => {
          toast.success(`Table view "${data.name}" created successfully`)

          rqc.invalidateQueries(getV1UiTableViewsTableviewsListQueryKey())
        },
        onError: (data) => {
          toast.error(`Problem creating "${data.name}" table view`)
        },
      }
    )
  }

  const Content = (
    <>
      <TextField
        fullWidth
        required
        helperText={`Short, meaningful name that's easy to recognize, e.g. "In progress"`}
        label="View name"
        margin="dense"
        value={name}
        variant="outlined"
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        fullWidth
        multiline
        helperText="Additional detail on what this view is all about"
        label="Description (optional)"
        margin="normal"
        placeholder="Optional description or summary that describes this view."
        rows={2}
        size="small"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <FormControlLabel
        label="Private"
        sx={{ fontSize: 'typography.caption.fontSize' }}
        control={
          <Checkbox checked={isPrivate} name="private" onChange={() => setIsPrivate(!isPrivate)} />
        }
      />
    </>
  )

  return (
    <FormModal
      content={Content}
      elemId="new-table-view"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      submitBtnText="save view"
      title="Save current view"
      dialogProps={{
        onKeyDown: stopPropagationForTab,
      }}
      onSubmit={handleSubmit}
    />
  )
}

import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

type Props = {
  rotationAngle: number
  onChange: (angle: number, isVerticalSlider: boolean) => void
  isVertical?: boolean
}

const HALF_CIRCLE_IN_DEGREES = 180
const LARGEST_ANGLE_INVERTED = HALF_CIRCLE_IN_DEGREES * -1 // flipped because slider is inverted
const ARIA_LABEL_ELEM_ID = 'grid-rotation'

// Negative values so 0 can be at the top of the slider, just need to multiply by -1 when using it.
const HORIZONTAL_SLIDER_MARKS = [
  { value: 0, label: 0 },
  { value: 30, label: 30 },
  { value: 60, label: 60 },
  { value: 90, label: 90 },
  { value: 120, label: 120 },
  { value: 150, label: 150 },
  { value: 180, label: 180 },
]

const VERTICAL_SLIDER_MARKS = HORIZONTAL_SLIDER_MARKS.map((mark) => ({
  ...mark,
  value: mark.value * -1,
}))

const horizontalSliderProps = {
  marks: HORIZONTAL_SLIDER_MARKS,
  min: 0,
  max: HALF_CIRCLE_IN_DEGREES,
  width: 200,
}

const verticalSliderProps = {
  orientation: 'vertical' as 'horizontal' | 'vertical',
  track: 'inverted' as 'inverted' | 'normal',
  marks: VERTICAL_SLIDER_MARKS,
  min: LARGEST_ANGLE_INVERTED,
  max: 0,
  sx: {
    height: 260,
    mt: 2,
  },
}

function getSliderValueByVerticalness(
  isVertical: boolean | undefined,
  rotationAngle: number
): number[] | number | undefined {
  return isVertical ? rotationAngle * -1 : rotationAngle
}

export function GridRotationSlider(props: Props) {
  const { rotationAngle, onChange, isVertical } = props

  return (
    <>
      <Typography
        component="h2"
        fontSize="h5.fontSize"
        fontWeight="bold"
        id={ARIA_LABEL_ELEM_ID}
        sx={{ mb: 1 }}
      >
        Rotation angle
      </Typography>
      <Box px={0.5}>
        <Slider
          {...(isVertical ? verticalSliderProps : horizontalSliderProps)}
          aria-labelledby={ARIA_LABEL_ELEM_ID}
          step={1}
          value={getSliderValueByVerticalness(isVertical, rotationAngle)}
          valueLabelDisplay="auto"
          onChange={(evt: Event, newValue: number[] | number) => {
            onChange(newValue as number, !!isVertical)
          }}
        />
      </Box>
    </>
  )
}

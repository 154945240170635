/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Portal API
 * API for the EarthOptics platform
 * OpenAPI spec version: v2.0.1 (77f41ae0)
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'
import type {
  CurrentUser,
  TokenObtainPair,
  TokenRefresh,
  PaginatedBatchList,
  V1BatchesListParams,
  BatchItem,
  Job,
  Batch,
  Contact,
  PatchedContact,
  V1FieldsRetrieveParams,
  FieldWriterSerializerGeoJSON,
  V1FieldsUploadsListParams,
  V1FieldsRetrieve2Params,
  PatchedFieldWriterSerializerGeoJSON,
  Org,
  SoilCollectionReader,
  V1SoilSamplingCollectionsListParams,
  SoilCollectionCreatorResponse,
  SoilCollectionCreator,
  Annotation,
  AnnotationCreate,
  CollectionFiles,
  SoilCollectionDetail,
  V1SoilSamplingCollectionsRetrieveParams,
  SoilCollectionWriter,
  PatchedSoilCollectionWriter,
  S3PresignedUrlList,
  BulkExportResponse,
  V1SoilSamplingCollectionsLayersBulkExportListParams,
  BulkExportCreator,
  BulkExportWriter,
  PatchedBulkExportWriter,
  S3PresignedUrl,
  V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveParams,
  V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveParams,
  V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveParams,
  V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveParams,
  V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveParams,
  V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveParams,
  V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveParams,
  SoilCore,
  PatchedSoilCore,
  SoilLab,
  SoilSample,
  PatchedSoilSample,
  Staff,
  V1StaffListParams,
  UITableView,
  PatchedUITableView,
  UploadLabsBody,
  V1VectorTilesFieldsRetrieveParams,
} from '.././model'
import { djangoAxiosInstance } from '../../django-axios-instance'
import type { ErrorType } from '../../django-axios-instance'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
  ? 1
  : 2
  ? A
  : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

export const v1AuthCurrentUserRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<CurrentUser>(
    { url: `/v1/auth/current-user/`, method: 'get', signal },
    options
  )
}

export const getV1AuthCurrentUserRetrieveQueryKey = () => [`/v1/auth/current-user/`] as const

export const getV1AuthCurrentUserRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1AuthCurrentUserRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>> = ({
    signal,
  }) => v1AuthCurrentUserRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1AuthCurrentUserRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>
>
export type V1AuthCurrentUserRetrieveQueryError = ErrorType<unknown>

export const useV1AuthCurrentUserRetrieve = <
  TData = Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1AuthCurrentUserRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1AuthCurrentUserRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.
 */
export const v1AuthTokenCreate = (
  tokenObtainPair: NonReadonly<TokenObtainPair>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<TokenObtainPair>(
    {
      url: `/v1/auth/token/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tokenObtainPair,
    },
    options
  )
}

export const getV1AuthTokenCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1AuthTokenCreate>>,
    TError,
    { data: NonReadonly<TokenObtainPair> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1AuthTokenCreate>>,
  TError,
  { data: NonReadonly<TokenObtainPair> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1AuthTokenCreate>>,
    { data: NonReadonly<TokenObtainPair> }
  > = (props) => {
    const { data } = props ?? {}

    return v1AuthTokenCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1AuthTokenCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1AuthTokenCreate>>
>
export type V1AuthTokenCreateMutationBody = NonReadonly<TokenObtainPair>
export type V1AuthTokenCreateMutationError = ErrorType<unknown>

export const useV1AuthTokenCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1AuthTokenCreate>>,
    TError,
    { data: NonReadonly<TokenObtainPair> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1AuthTokenCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const v1AuthTokenRefreshCreate = (
  tokenRefresh: NonReadonly<TokenRefresh>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<TokenRefresh>(
    {
      url: `/v1/auth/token/refresh/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tokenRefresh,
    },
    options
  )
}

export const getV1AuthTokenRefreshCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1AuthTokenRefreshCreate>>,
    TError,
    { data: NonReadonly<TokenRefresh> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1AuthTokenRefreshCreate>>,
  TError,
  { data: NonReadonly<TokenRefresh> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1AuthTokenRefreshCreate>>,
    { data: NonReadonly<TokenRefresh> }
  > = (props) => {
    const { data } = props ?? {}

    return v1AuthTokenRefreshCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1AuthTokenRefreshCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1AuthTokenRefreshCreate>>
>
export type V1AuthTokenRefreshCreateMutationBody = NonReadonly<TokenRefresh>
export type V1AuthTokenRefreshCreateMutationError = ErrorType<unknown>

export const useV1AuthTokenRefreshCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1AuthTokenRefreshCreate>>,
    TError,
    { data: NonReadonly<TokenRefresh> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1AuthTokenRefreshCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesList = (
  params?: V1BatchesListParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<PaginatedBatchList>(
    { url: `/v1/batches/`, method: 'get', params, signal },
    options
  )
}

export const getV1BatchesListQueryKey = (params?: V1BatchesListParams) =>
  [`/v1/batches/`, ...(params ? [params] : [])] as const

export const getV1BatchesListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1BatchesList>>,
  TError = ErrorType<unknown>
>(
  params?: V1BatchesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1BatchesList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1BatchesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1BatchesList>>> = ({ signal }) =>
    v1BatchesList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1BatchesListQueryResult = NonNullable<Awaited<ReturnType<typeof v1BatchesList>>>
export type V1BatchesListQueryError = ErrorType<unknown>

export const useV1BatchesList = <
  TData = Awaited<ReturnType<typeof v1BatchesList>>,
  TError = ErrorType<unknown>
>(
  params?: V1BatchesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1BatchesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesItemsList = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<BatchItem[]>(
    { url: `/v1/batches/${batchId}/items/`, method: 'get', signal },
    options
  )
}

export const getV1BatchesItemsListQueryKey = (batchId: string) =>
  [`/v1/batches/${batchId}/items/`] as const

export const getV1BatchesItemsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1BatchesItemsList>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1BatchesItemsListQueryKey(batchId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1BatchesItemsList>>> = ({ signal }) =>
    v1BatchesItemsList(batchId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!batchId, ...queryOptions }
}

export type V1BatchesItemsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesItemsList>>
>
export type V1BatchesItemsListQueryError = ErrorType<unknown>

export const useV1BatchesItemsList = <
  TData = Awaited<ReturnType<typeof v1BatchesItemsList>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1BatchesItemsListQueryOptions(batchId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesItemsCreate = (
  batchId: string,
  batchItem: NonReadonly<BatchItem>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<BatchItem>(
    {
      url: `/v1/batches/${batchId}/items/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: batchItem,
    },
    options
  )
}

export const getV1BatchesItemsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesItemsCreate>>,
    TError,
    { batchId: string; data: NonReadonly<BatchItem> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1BatchesItemsCreate>>,
  TError,
  { batchId: string; data: NonReadonly<BatchItem> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1BatchesItemsCreate>>,
    { batchId: string; data: NonReadonly<BatchItem> }
  > = (props) => {
    const { batchId, data } = props ?? {}

    return v1BatchesItemsCreate(batchId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1BatchesItemsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesItemsCreate>>
>
export type V1BatchesItemsCreateMutationBody = NonReadonly<BatchItem>
export type V1BatchesItemsCreateMutationError = ErrorType<unknown>

export const useV1BatchesItemsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesItemsCreate>>,
    TError,
    { batchId: string; data: NonReadonly<BatchItem> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1BatchesItemsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesItemsRetrieve = (
  batchId: string,
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<BatchItem>(
    { url: `/v1/batches/${batchId}/items/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1BatchesItemsRetrieveQueryKey = (batchId: string, id: string) =>
  [`/v1/batches/${batchId}/items/${id}/`] as const

export const getV1BatchesItemsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1BatchesItemsRetrieveQueryKey(batchId, id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>> = ({ signal }) =>
    v1BatchesItemsRetrieve(batchId, id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!(batchId && id), ...queryOptions }
}

export type V1BatchesItemsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>
>
export type V1BatchesItemsRetrieveQueryError = ErrorType<unknown>

export const useV1BatchesItemsRetrieve = <
  TData = Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesItemsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1BatchesItemsRetrieveQueryOptions(batchId, id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesJobsList = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Job[]>(
    { url: `/v1/batches/${batchId}/jobs/`, method: 'get', signal },
    options
  )
}

export const getV1BatchesJobsListQueryKey = (batchId: string) =>
  [`/v1/batches/${batchId}/jobs/`] as const

export const getV1BatchesJobsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1BatchesJobsList>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesJobsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1BatchesJobsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1BatchesJobsListQueryKey(batchId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1BatchesJobsList>>> = ({ signal }) =>
    v1BatchesJobsList(batchId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!batchId, ...queryOptions }
}

export type V1BatchesJobsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesJobsList>>
>
export type V1BatchesJobsListQueryError = ErrorType<unknown>

export const useV1BatchesJobsList = <
  TData = Awaited<ReturnType<typeof v1BatchesJobsList>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesJobsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1BatchesJobsListQueryOptions(batchId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1BatchesReingestCreate = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/batches/${batchId}/reingest/`, method: 'post' },
    options
  )
}

export const getV1BatchesReingestCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesReingestCreate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1BatchesReingestCreate>>,
  TError,
  { batchId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1BatchesReingestCreate>>,
    { batchId: string }
  > = (props) => {
    const { batchId } = props ?? {}

    return v1BatchesReingestCreate(batchId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1BatchesReingestCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesReingestCreate>>
>

export type V1BatchesReingestCreateMutationError = ErrorType<unknown>

export const useV1BatchesReingestCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesReingestCreate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1BatchesReingestCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1BatchesReprocessCreate = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/batches/${batchId}/reprocess/`, method: 'post' },
    options
  )
}

export const getV1BatchesReprocessCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesReprocessCreate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1BatchesReprocessCreate>>,
  TError,
  { batchId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1BatchesReprocessCreate>>,
    { batchId: string }
  > = (props) => {
    const { batchId } = props ?? {}

    return v1BatchesReprocessCreate(batchId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1BatchesReprocessCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesReprocessCreate>>
>

export type V1BatchesReprocessCreateMutationError = ErrorType<unknown>

export const useV1BatchesReprocessCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesReprocessCreate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1BatchesReprocessCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1BatchesSoilCollectionsAddPartialUpdate = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/batches/${batchId}/soil_collections/add/`, method: 'patch' },
    options
  )
}

export const getV1BatchesSoilCollectionsAddPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsAddPartialUpdate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1BatchesSoilCollectionsAddPartialUpdate>>,
  TError,
  { batchId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsAddPartialUpdate>>,
    { batchId: string }
  > = (props) => {
    const { batchId } = props ?? {}

    return v1BatchesSoilCollectionsAddPartialUpdate(batchId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1BatchesSoilCollectionsAddPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesSoilCollectionsAddPartialUpdate>>
>

export type V1BatchesSoilCollectionsAddPartialUpdateMutationError = ErrorType<unknown>

export const useV1BatchesSoilCollectionsAddPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsAddPartialUpdate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1BatchesSoilCollectionsAddPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1BatchesSoilCollectionsRemovePartialUpdate = (
  batchId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/batches/${batchId}/soil_collections/remove/`, method: 'patch' },
    options
  )
}

export const getV1BatchesSoilCollectionsRemovePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsRemovePartialUpdate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1BatchesSoilCollectionsRemovePartialUpdate>>,
  TError,
  { batchId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsRemovePartialUpdate>>,
    { batchId: string }
  > = (props) => {
    const { batchId } = props ?? {}

    return v1BatchesSoilCollectionsRemovePartialUpdate(batchId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1BatchesSoilCollectionsRemovePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesSoilCollectionsRemovePartialUpdate>>
>

export type V1BatchesSoilCollectionsRemovePartialUpdateMutationError = ErrorType<unknown>

export const useV1BatchesSoilCollectionsRemovePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1BatchesSoilCollectionsRemovePartialUpdate>>,
    TError,
    { batchId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1BatchesSoilCollectionsRemovePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1BatchesRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Batch>({ url: `/v1/batches/${id}/`, method: 'get', signal }, options)
}

export const getV1BatchesRetrieveQueryKey = (id: string) => [`/v1/batches/${id}/`] as const

export const getV1BatchesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1BatchesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1BatchesRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1BatchesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1BatchesRetrieve>>> = ({ signal }) =>
    v1BatchesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1BatchesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1BatchesRetrieve>>
>
export type V1BatchesRetrieveQueryError = ErrorType<unknown>

export const useV1BatchesRetrieve = <
  TData = Awaited<ReturnType<typeof v1BatchesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1BatchesRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1BatchesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1ContactsList = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Contact[]>({ url: `/v1/contacts/`, method: 'get', signal }, options)
}

export const getV1ContactsListQueryKey = () => [`/v1/contacts/`] as const

export const getV1ContactsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1ContactsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1ContactsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1ContactsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1ContactsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1ContactsList>>> = ({ signal }) =>
    v1ContactsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1ContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof v1ContactsList>>>
export type V1ContactsListQueryError = ErrorType<unknown>

export const useV1ContactsList = <
  TData = Awaited<ReturnType<typeof v1ContactsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1ContactsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1ContactsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1ContactsCreate = (
  contact: NonReadonly<Contact>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<Contact>(
    {
      url: `/v1/contacts/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contact,
    },
    options
  )
}

export const getV1ContactsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1ContactsCreate>>,
    TError,
    { data: NonReadonly<Contact> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1ContactsCreate>>,
  TError,
  { data: NonReadonly<Contact> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1ContactsCreate>>,
    { data: NonReadonly<Contact> }
  > = (props) => {
    const { data } = props ?? {}

    return v1ContactsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1ContactsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1ContactsCreate>>
>
export type V1ContactsCreateMutationBody = NonReadonly<Contact>
export type V1ContactsCreateMutationError = ErrorType<unknown>

export const useV1ContactsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1ContactsCreate>>,
    TError,
    { data: NonReadonly<Contact> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1ContactsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Readonly endpoint to retrieve a Contact
 */
export const v1ContactsRetrieve = (
  id: unknown,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Contact>(
    { url: `/v1/contacts/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1ContactsRetrieveQueryKey = (id: unknown) => [`/v1/contacts/${id}/`] as const

export const getV1ContactsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1ContactsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1ContactsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1ContactsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1ContactsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1ContactsRetrieve>>> = ({ signal }) =>
    v1ContactsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1ContactsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1ContactsRetrieve>>
>
export type V1ContactsRetrieveQueryError = ErrorType<unknown>

export const useV1ContactsRetrieve = <
  TData = Awaited<ReturnType<typeof v1ContactsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1ContactsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1ContactsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Patch endpoint to update a Contact
 */
export const v1ContactsPartialUpdate = (
  id: unknown,
  patchedContact: NonReadonly<PatchedContact>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<Contact>(
    {
      url: `/v1/contacts/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedContact,
    },
    options
  )
}

export const getV1ContactsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1ContactsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedContact> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1ContactsPartialUpdate>>,
  TError,
  { id: unknown; data: NonReadonly<PatchedContact> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1ContactsPartialUpdate>>,
    { id: unknown; data: NonReadonly<PatchedContact> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1ContactsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1ContactsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1ContactsPartialUpdate>>
>
export type V1ContactsPartialUpdateMutationBody = NonReadonly<PatchedContact>
export type V1ContactsPartialUpdateMutationError = ErrorType<unknown>

export const useV1ContactsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1ContactsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedContact> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1ContactsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Allows the JWT token to get passed in post form data so that we can use default browser file download functionality.
 */
export const v1DownloadRxCreate = (options?: SecondParameter<typeof djangoAxiosInstance>) => {
  return djangoAxiosInstance<void>({ url: `/v1/download_rx/`, method: 'post' }, options)
}

export const getV1DownloadRxCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1DownloadRxCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1DownloadRxCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1DownloadRxCreate>>,
    TVariables
  > = () => {
    return v1DownloadRxCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1DownloadRxCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1DownloadRxCreate>>
>

export type V1DownloadRxCreateMutationError = ErrorType<unknown>

export const useV1DownloadRxCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1DownloadRxCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1DownloadRxCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * GET LIST endpoint to list all Fields the user can see
 */
export const v1FieldsRetrieve = (
  params?: V1FieldsRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>({ url: `/v1/fields/`, method: 'get', params, signal }, options)
}

export const getV1FieldsRetrieveQueryKey = (params?: V1FieldsRetrieveParams) =>
  [`/v1/fields/`, ...(params ? [params] : [])] as const

export const getV1FieldsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1FieldsRetrieve>>,
  TError = ErrorType<unknown>
>(
  params?: V1FieldsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1FieldsRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1FieldsRetrieve>>> = ({ signal }) =>
    v1FieldsRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1FieldsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof v1FieldsRetrieve>>>
export type V1FieldsRetrieveQueryError = ErrorType<unknown>

export const useV1FieldsRetrieve = <
  TData = Awaited<ReturnType<typeof v1FieldsRetrieve>>,
  TError = ErrorType<unknown>
>(
  params?: V1FieldsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1FieldsRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1FieldsCreate = (
  fieldWriterSerializerGeoJSON: NonReadonly<FieldWriterSerializerGeoJSON>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<FieldWriterSerializerGeoJSON>(
    {
      url: `/v1/fields/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: fieldWriterSerializerGeoJSON,
    },
    options
  )
}

export const getV1FieldsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    TError,
    { data: NonReadonly<FieldWriterSerializerGeoJSON> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1FieldsCreate>>,
  TError,
  { data: NonReadonly<FieldWriterSerializerGeoJSON> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    { data: NonReadonly<FieldWriterSerializerGeoJSON> }
  > = (props) => {
    const { data } = props ?? {}

    return v1FieldsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1FieldsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof v1FieldsCreate>>>
export type V1FieldsCreateMutationBody = NonReadonly<FieldWriterSerializerGeoJSON>
export type V1FieldsCreateMutationError = ErrorType<unknown>

export const useV1FieldsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    TError,
    { data: NonReadonly<FieldWriterSerializerGeoJSON> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1FieldsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Enforces object-level permissions in ``rest_framework.viewsets.ViewSet``,
deriving the permission type from the particular action to be performed..

As with ``rules.contrib.views.AutoPermissionRequiredMixin``, this only works when
model permissions are registered using ``rules.contrib.models.RulesModelMixin``.
 */
export const v1FieldsUploadsList = (
  fieldId: string,
  params?: V1FieldsUploadsListParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<PaginatedBatchList>(
    { url: `/v1/fields/${fieldId}/uploads/`, method: 'get', params, signal },
    options
  )
}

export const getV1FieldsUploadsListQueryKey = (
  fieldId: string,
  params?: V1FieldsUploadsListParams
) => [`/v1/fields/${fieldId}/uploads/`, ...(params ? [params] : [])] as const

export const getV1FieldsUploadsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1FieldsUploadsList>>,
  TError = ErrorType<unknown>
>(
  fieldId: string,
  params?: V1FieldsUploadsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsUploadsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1FieldsUploadsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1FieldsUploadsListQueryKey(fieldId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1FieldsUploadsList>>> = ({ signal }) =>
    v1FieldsUploadsList(fieldId, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!fieldId, ...queryOptions }
}

export type V1FieldsUploadsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1FieldsUploadsList>>
>
export type V1FieldsUploadsListQueryError = ErrorType<unknown>

export const useV1FieldsUploadsList = <
  TData = Awaited<ReturnType<typeof v1FieldsUploadsList>>,
  TError = ErrorType<unknown>
>(
  fieldId: string,
  params?: V1FieldsUploadsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsUploadsList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1FieldsUploadsListQueryOptions(fieldId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1FieldsRetrieve2 = (
  id: string,
  params?: V1FieldsRetrieve2Params,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/fields/${id}/`, method: 'get', params, signal },
    options
  )
}

export const getV1FieldsRetrieve2QueryKey = (id: string, params?: V1FieldsRetrieve2Params) =>
  [`/v1/fields/${id}/`, ...(params ? [params] : [])] as const

export const getV1FieldsRetrieve2QueryOptions = <
  TData = Awaited<ReturnType<typeof v1FieldsRetrieve2>>,
  TError = ErrorType<unknown>
>(
  id: string,
  params?: V1FieldsRetrieve2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve2>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve2>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1FieldsRetrieve2QueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1FieldsRetrieve2>>> = ({ signal }) =>
    v1FieldsRetrieve2(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1FieldsRetrieve2QueryResult = NonNullable<
  Awaited<ReturnType<typeof v1FieldsRetrieve2>>
>
export type V1FieldsRetrieve2QueryError = ErrorType<unknown>

export const useV1FieldsRetrieve2 = <
  TData = Awaited<ReturnType<typeof v1FieldsRetrieve2>>,
  TError = ErrorType<unknown>
>(
  id: string,
  params?: V1FieldsRetrieve2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1FieldsRetrieve2>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1FieldsRetrieve2QueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * PATCH endpoint to update an existing Field
 */
export const v1FieldsPartialUpdate = (
  id: unknown,
  patchedFieldWriterSerializerGeoJSON: NonReadonly<PatchedFieldWriterSerializerGeoJSON>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<FieldWriterSerializerGeoJSON>(
    {
      url: `/v1/fields/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedFieldWriterSerializerGeoJSON,
    },
    options
  )
}

export const getV1FieldsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedFieldWriterSerializerGeoJSON> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
  TError,
  { id: unknown; data: NonReadonly<PatchedFieldWriterSerializerGeoJSON> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    { id: unknown; data: NonReadonly<PatchedFieldWriterSerializerGeoJSON> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1FieldsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1FieldsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1FieldsPartialUpdate>>
>
export type V1FieldsPartialUpdateMutationBody = NonReadonly<PatchedFieldWriterSerializerGeoJSON>
export type V1FieldsPartialUpdateMutationError = ErrorType<unknown>

export const useV1FieldsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedFieldWriterSerializerGeoJSON> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1FieldsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Subscriber to JobUpdated SNS to rebroadcast this joyful event
Inspired by https://gist.github.com/gaqzi/d3cf5e73afb42e535376
 */
export const v1JobsUpdatedSubscriptionCreate = (
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/jobs-updated-subscription/`, method: 'post' },
    options
  )
}

export const getV1JobsUpdatedSubscriptionCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1JobsUpdatedSubscriptionCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1JobsUpdatedSubscriptionCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1JobsUpdatedSubscriptionCreate>>,
    TVariables
  > = () => {
    return v1JobsUpdatedSubscriptionCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1JobsUpdatedSubscriptionCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1JobsUpdatedSubscriptionCreate>>
>

export type V1JobsUpdatedSubscriptionCreateMutationError = ErrorType<unknown>

export const useV1JobsUpdatedSubscriptionCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1JobsUpdatedSubscriptionCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1JobsUpdatedSubscriptionCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1OrgsList = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Org[]>({ url: `/v1/orgs/`, method: 'get', signal }, options)
}

export const getV1OrgsListQueryKey = () => [`/v1/orgs/`] as const

export const getV1OrgsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1OrgsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1OrgsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1OrgsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1OrgsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1OrgsList>>> = ({ signal }) =>
    v1OrgsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1OrgsListQueryResult = NonNullable<Awaited<ReturnType<typeof v1OrgsList>>>
export type V1OrgsListQueryError = ErrorType<unknown>

export const useV1OrgsList = <
  TData = Awaited<ReturnType<typeof v1OrgsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1OrgsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1OrgsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1OrgsRetrieve = (
  orgId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Org>({ url: `/v1/orgs/${orgId}/`, method: 'get', signal }, options)
}

export const getV1OrgsRetrieveQueryKey = (orgId: string) => [`/v1/orgs/${orgId}/`] as const

export const getV1OrgsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1OrgsRetrieve>>,
  TError = ErrorType<unknown>
>(
  orgId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1OrgsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1OrgsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1OrgsRetrieveQueryKey(orgId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1OrgsRetrieve>>> = ({ signal }) =>
    v1OrgsRetrieve(orgId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!orgId, ...queryOptions }
}

export type V1OrgsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof v1OrgsRetrieve>>>
export type V1OrgsRetrieveQueryError = ErrorType<unknown>

export const useV1OrgsRetrieve = <
  TData = Awaited<ReturnType<typeof v1OrgsRetrieve>>,
  TError = ErrorType<unknown>
>(
  orgId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1OrgsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1OrgsRetrieveQueryOptions(orgId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * View method that serializes and renders layer JSON.
Requires two request params: "s3_key" and "layer".
 */
export const v1PredictionsRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>({ url: `/v1/predictions/`, method: 'get', signal }, options)
}

export const getV1PredictionsRetrieveQueryKey = () => [`/v1/predictions/`] as const

export const getV1PredictionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1PredictionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1PredictionsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1PredictionsRetrieve>>> = ({ signal }) =>
    v1PredictionsRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1PredictionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1PredictionsRetrieve>>
>
export type V1PredictionsRetrieveQueryError = ErrorType<unknown>

export const useV1PredictionsRetrieve = <
  TData = Awaited<ReturnType<typeof v1PredictionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1PredictionsRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns center point of the geo data of all layers. Collects center data from rx_0 layer.
 */
export const v1PredictionsCenterRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/predictions/center/`, method: 'get', signal },
    options
  )
}

export const getV1PredictionsCenterRetrieveQueryKey = () => [`/v1/predictions/center/`] as const

export const getV1PredictionsCenterRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1PredictionsCenterRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>> = ({
    signal,
  }) => v1PredictionsCenterRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1PredictionsCenterRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>
>
export type V1PredictionsCenterRetrieveQueryError = ErrorType<unknown>

export const useV1PredictionsCenterRetrieve = <
  TData = Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsCenterRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1PredictionsCenterRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * View method that serializes and renders layer JSON.
Requires two request params: "s3_key" and "layer".
 */
export const v1PredictionsExportRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/predictions/export/`, method: 'get', signal },
    options
  )
}

export const getV1PredictionsExportRetrieveQueryKey = () => [`/v1/predictions/export/`] as const

export const getV1PredictionsExportRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1PredictionsExportRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>> = ({
    signal,
  }) => v1PredictionsExportRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1PredictionsExportRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>
>
export type V1PredictionsExportRetrieveQueryError = ErrorType<unknown>

export const useV1PredictionsExportRetrieve = <
  TData = Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsExportRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1PredictionsExportRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns the list of all layers present in the source data
 */
export const v1PredictionsLayersRetrieve = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/predictions/layers/`, method: 'get', signal },
    options
  )
}

export const getV1PredictionsLayersRetrieveQueryKey = () => [`/v1/predictions/layers/`] as const

export const getV1PredictionsLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1PredictionsLayersRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>> = ({
    signal,
  }) => v1PredictionsLayersRetrieve(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1PredictionsLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>
>
export type V1PredictionsLayersRetrieveQueryError = ErrorType<unknown>

export const useV1PredictionsLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1PredictionsLayersRetrieve>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1PredictionsLayersRetrieveQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to list all Soil Collections
 */
export const v1SoilSamplingCollectionsList = (
  params?: V1SoilSamplingCollectionsListParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilCollectionReader[]>(
    { url: `/v1/soil_sampling/collections/`, method: 'get', params, signal },
    options
  )
}

export const getV1SoilSamplingCollectionsListQueryKey = (
  params?: V1SoilSamplingCollectionsListParams
) => [`/v1/soil_sampling/collections/`, ...(params ? [params] : [])] as const

export const getV1SoilSamplingCollectionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>,
  TError = ErrorType<unknown>
>(
  params?: V1SoilSamplingCollectionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingCollectionsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>> = ({
    signal,
  }) => v1SoilSamplingCollectionsList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1SoilSamplingCollectionsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>
>
export type V1SoilSamplingCollectionsListQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>,
  TError = ErrorType<unknown>
>(
  params?: V1SoilSamplingCollectionsListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * POST used to create a collection.
 */
export const v1SoilSamplingCollectionsCreate = (
  soilCollectionCreator: NonReadonly<SoilCollectionCreator>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<SoilCollectionCreatorResponse>(
    {
      url: `/v1/soil_sampling/collections/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: soilCollectionCreator,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreate>>,
    TError,
    { data: NonReadonly<SoilCollectionCreator> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreate>>,
  TError,
  { data: NonReadonly<SoilCollectionCreator> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreate>>,
    { data: NonReadonly<SoilCollectionCreator> }
  > = (props) => {
    const { data } = props ?? {}

    return v1SoilSamplingCollectionsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreate>>
>
export type V1SoilSamplingCollectionsCreateMutationBody = NonReadonly<SoilCollectionCreator>
export type V1SoilSamplingCollectionsCreateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreate>>,
    TError,
    { data: NonReadonly<SoilCollectionCreator> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingCollectionsAnnotationsList = (
  collectionId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Annotation[]>(
    { url: `/v1/soil_sampling/collections/${collectionId}/annotations/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingCollectionsAnnotationsListQueryKey = (collectionId: string) =>
  [`/v1/soil_sampling/collections/${collectionId}/annotations/`] as const

export const getV1SoilSamplingCollectionsAnnotationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>,
  TError = ErrorType<unknown>
>(
  collectionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getV1SoilSamplingCollectionsAnnotationsListQueryKey(collectionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>
  > = ({ signal }) => v1SoilSamplingCollectionsAnnotationsList(collectionId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!collectionId, ...queryOptions }
}

export type V1SoilSamplingCollectionsAnnotationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>
>
export type V1SoilSamplingCollectionsAnnotationsListQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsAnnotationsList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>,
  TError = ErrorType<unknown>
>(
  collectionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsAnnotationsListQueryOptions(
    collectionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1SoilSamplingCollectionsAnnotationsCreate = (
  collectionId: string,
  annotationCreate: AnnotationCreate,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<AnnotationCreate>(
    {
      url: `/v1/soil_sampling/collections/${collectionId}/annotations/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: annotationCreate,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsAnnotationsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsCreate>>,
    TError,
    { collectionId: string; data: AnnotationCreate },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsCreate>>,
  TError,
  { collectionId: string; data: AnnotationCreate },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsCreate>>,
    { collectionId: string; data: AnnotationCreate }
  > = (props) => {
    const { collectionId, data } = props ?? {}

    return v1SoilSamplingCollectionsAnnotationsCreate(collectionId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsAnnotationsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsCreate>>
>
export type V1SoilSamplingCollectionsAnnotationsCreateMutationBody = AnnotationCreate
export type V1SoilSamplingCollectionsAnnotationsCreateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsAnnotationsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsCreate>>,
    TError,
    { collectionId: string; data: AnnotationCreate },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsAnnotationsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingCollectionsAnnotationsDestroy = (
  collectionId: string,
  annotationId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    {
      url: `/v1/soil_sampling/collections/${collectionId}/annotations/${annotationId}/`,
      method: 'delete',
    },
    options
  )
}

export const getV1SoilSamplingCollectionsAnnotationsDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsDestroy>>,
    TError,
    { collectionId: string; annotationId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsDestroy>>,
  TError,
  { collectionId: string; annotationId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsDestroy>>,
    { collectionId: string; annotationId: string }
  > = (props) => {
    const { collectionId, annotationId } = props ?? {}

    return v1SoilSamplingCollectionsAnnotationsDestroy(collectionId, annotationId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsAnnotationsDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsDestroy>>
>

export type V1SoilSamplingCollectionsAnnotationsDestroyMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsAnnotationsDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsAnnotationsDestroy>>,
    TError,
    { collectionId: string; annotationId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsAnnotationsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingCollectionsFilesDestroy = (
  collectionId: string,
  fileId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/soil_sampling/collections/${collectionId}/files/${fileId}/`, method: 'delete' },
    options
  )
}

export const getV1SoilSamplingCollectionsFilesDestroyMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesDestroy>>,
    TError,
    { collectionId: string; fileId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesDestroy>>,
  TError,
  { collectionId: string; fileId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesDestroy>>,
    { collectionId: string; fileId: string }
  > = (props) => {
    const { collectionId, fileId } = props ?? {}

    return v1SoilSamplingCollectionsFilesDestroy(collectionId, fileId, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsFilesDestroyMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesDestroy>>
>

export type V1SoilSamplingCollectionsFilesDestroyMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsFilesDestroy = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesDestroy>>,
    TError,
    { collectionId: string; fileId: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsFilesDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * 🚨EXPERIMENTAL ENDPOINT: May have breaking changes.🚨 Creates a Batch for this collection to trigger layer creation.
 */
export const v1SoilSamplingCollectionsCreateLayersCreate = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<Batch>(
    { url: `/v1/soil_sampling/collections/${id}/create_layers/`, method: 'post' },
    options
  )
}

export const getV1SoilSamplingCollectionsCreateLayersCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreateLayersCreate>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreateLayersCreate>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreateLayersCreate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return v1SoilSamplingCollectionsCreateLayersCreate(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsCreateLayersCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreateLayersCreate>>
>

export type V1SoilSamplingCollectionsCreateLayersCreateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsCreateLayersCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsCreateLayersCreate>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsCreateLayersCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingCollectionsFilesList = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<CollectionFiles[]>(
    { url: `/v1/soil_sampling/collections/${id}/files/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingCollectionsFilesListQueryKey = (id: string) =>
  [`/v1/soil_sampling/collections/${id}/files/`] as const

export const getV1SoilSamplingCollectionsFilesListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingCollectionsFilesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>> = ({
    signal,
  }) => v1SoilSamplingCollectionsFilesList(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsFilesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>
>
export type V1SoilSamplingCollectionsFilesListQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsFilesList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsFilesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Upload: The Upload endpoint expects a multipart payload with one or more files and an
optional metadata json field.

This endpoint accepts a similar request type as the Batches endpoint, but does NOT create a
batch. Instead, it adds files to the Collection directly.

When it is time to process a layer, a new batch & related jobs will be created. Maybe even
only a job? We'll see how it goes. :shrug: TODO: Add a /collections/{id}/process endpoint
which will (at least for the time being) create a batch, tie it to the colln, and that will
kick off the existing ingest/process flow
 */
export const v1SoilSamplingCollectionsFilesCreate = (
  id: string,
  collectionFiles: CollectionFiles,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  const formData = new FormData()
  formData.append('id', collectionFiles.id)
  formData.append('collection_id', collectionFiles.collection_id)
  formData.append('created_at', collectionFiles.created_at)
  formData.append('created_by', JSON.stringify(collectionFiles.created_by))
  if (collectionFiles.file_type !== undefined) {
    formData.append('file_type', collectionFiles.file_type)
  }
  formData.append('file_size', collectionFiles.file_size)
  formData.append('file_name', collectionFiles.file_name)
  formData.append('file', collectionFiles.file)
  formData.append('updated_at', collectionFiles.updated_at)

  return djangoAxiosInstance<CollectionFiles>(
    {
      url: `/v1/soil_sampling/collections/${id}/files/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsFilesCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesCreate>>,
    TError,
    { id: string; data: CollectionFiles },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesCreate>>,
  TError,
  { id: string; data: CollectionFiles },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesCreate>>,
    { id: string; data: CollectionFiles }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1SoilSamplingCollectionsFilesCreate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsFilesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesCreate>>
>
export type V1SoilSamplingCollectionsFilesCreateMutationBody = CollectionFiles
export type V1SoilSamplingCollectionsFilesCreateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsFilesCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsFilesCreate>>,
    TError,
    { id: string; data: CollectionFiles },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsFilesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Readonly endpoint to retrieve a Soil Collection
 */
export const v1SoilSamplingCollectionsRetrieve = (
  id: unknown,
  params?: V1SoilSamplingCollectionsRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilCollectionDetail>(
    { url: `/v1/soil_sampling/collections/${id}/`, method: 'get', params, signal },
    options
  )
}

export const getV1SoilSamplingCollectionsRetrieveQueryKey = (
  id: unknown,
  params?: V1SoilSamplingCollectionsRetrieveParams
) => [`/v1/soil_sampling/collections/${id}/`, ...(params ? [params] : [])] as const

export const getV1SoilSamplingCollectionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  params?: V1SoilSamplingCollectionsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getV1SoilSamplingCollectionsRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>> = ({
    signal,
  }) => v1SoilSamplingCollectionsRetrieve(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>
>
export type V1SoilSamplingCollectionsRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  params?: V1SoilSamplingCollectionsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsRetrieveQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Patch endpoint to update a Soil Collection
 */
export const v1SoilSamplingCollectionsPartialUpdate = (
  id: unknown,
  patchedSoilCollectionWriter: NonReadonly<PatchedSoilCollectionWriter>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<SoilCollectionWriter>(
    {
      url: `/v1/soil_sampling/collections/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSoilCollectionWriter,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedSoilCollectionWriter> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsPartialUpdate>>,
  TError,
  { id: unknown; data: NonReadonly<PatchedSoilCollectionWriter> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsPartialUpdate>>,
    { id: unknown; data: NonReadonly<PatchedSoilCollectionWriter> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1SoilSamplingCollectionsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsPartialUpdate>>
>
export type V1SoilSamplingCollectionsPartialUpdateMutationBody =
  NonReadonly<PatchedSoilCollectionWriter>
export type V1SoilSamplingCollectionsPartialUpdateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedSoilCollectionWriter> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Gets a list of S3 links pointing to all files in the folder inside of solutions-team-ec2-bucket-earthoptics named by the passed in collection id.
 */
export const v1SoilSamplingCollectionsSolutionsDataRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrlList>(
    { url: `/v1/soil_sampling/collections/${id}/solutions_data/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingCollectionsSolutionsDataRetrieveQueryKey = (id: string) =>
  [`/v1/soil_sampling/collections/${id}/solutions_data/`] as const

export const getV1SoilSamplingCollectionsSolutionsDataRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getV1SoilSamplingCollectionsSolutionsDataRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>
  > = ({ signal }) => v1SoilSamplingCollectionsSolutionsDataRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsSolutionsDataRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>
>
export type V1SoilSamplingCollectionsSolutionsDataRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsSolutionsDataRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsSolutionsDataRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsSolutionsDataRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint used to read all Bulk Export requests
 */
export const v1SoilSamplingCollectionsLayersBulkExportList = (
  params?: V1SoilSamplingCollectionsLayersBulkExportListParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<BulkExportResponse[]>(
    { url: `/v1/soil_sampling/collections/layers/bulk_export/`, method: 'get', params, signal },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersBulkExportListQueryKey = (
  params?: V1SoilSamplingCollectionsLayersBulkExportListParams
) => [`/v1/soil_sampling/collections/layers/bulk_export/`, ...(params ? [params] : [])] as const

export const getV1SoilSamplingCollectionsLayersBulkExportListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>,
  TError = ErrorType<unknown>
>(
  params?: V1SoilSamplingCollectionsLayersBulkExportListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getV1SoilSamplingCollectionsLayersBulkExportListQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>
  > = ({ signal }) => v1SoilSamplingCollectionsLayersBulkExportList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersBulkExportListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>
>
export type V1SoilSamplingCollectionsLayersBulkExportListQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersBulkExportList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>,
  TError = ErrorType<unknown>
>(
  params?: V1SoilSamplingCollectionsLayersBulkExportListParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportList>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersBulkExportListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * POST used to create a Bulk Export request
 */
export const v1SoilSamplingCollectionsLayersBulkExportCreate = (
  bulkExportCreator: BulkExportCreator,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<BulkExportCreator>(
    {
      url: `/v1/soil_sampling/collections/layers/bulk_export/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: bulkExportCreator,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersBulkExportCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportCreate>>,
    TError,
    { data: BulkExportCreator },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportCreate>>,
  TError,
  { data: BulkExportCreator },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportCreate>>,
    { data: BulkExportCreator }
  > = (props) => {
    const { data } = props ?? {}

    return v1SoilSamplingCollectionsLayersBulkExportCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsLayersBulkExportCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportCreate>>
>
export type V1SoilSamplingCollectionsLayersBulkExportCreateMutationBody = BulkExportCreator
export type V1SoilSamplingCollectionsLayersBulkExportCreateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersBulkExportCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportCreate>>,
    TError,
    { data: BulkExportCreator },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCollectionsLayersBulkExportCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Readonly endpoint to retrieve a Bulk Export request
 */
export const v1SoilSamplingCollectionsLayersBulkExportRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<BulkExportResponse>(
    { url: `/v1/soil_sampling/collections/layers/bulk_export/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersBulkExportRetrieveQueryKey = (id: string) =>
  [`/v1/soil_sampling/collections/layers/bulk_export/${id}/`] as const

export const getV1SoilSamplingCollectionsLayersBulkExportRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getV1SoilSamplingCollectionsLayersBulkExportRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>
  > = ({ signal }) => v1SoilSamplingCollectionsLayersBulkExportRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersBulkExportRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>
>
export type V1SoilSamplingCollectionsLayersBulkExportRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersBulkExportRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersBulkExportRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * PATCH endpoint used to update a Bulk Export name
 */
export const v1SoilSamplingCollectionsLayersBulkExportPartialUpdate = (
  id: string,
  patchedBulkExportWriter: PatchedBulkExportWriter,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<BulkExportWriter>(
    {
      url: `/v1/soil_sampling/collections/layers/bulk_export/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedBulkExportWriter,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersBulkExportPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportPartialUpdate>>,
    TError,
    { id: string; data: PatchedBulkExportWriter },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportPartialUpdate>>,
  TError,
  { id: string; data: PatchedBulkExportWriter },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportPartialUpdate>>,
    { id: string; data: PatchedBulkExportWriter }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1SoilSamplingCollectionsLayersBulkExportPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCollectionsLayersBulkExportPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportPartialUpdate>>
>
export type V1SoilSamplingCollectionsLayersBulkExportPartialUpdateMutationBody =
  PatchedBulkExportWriter
export type V1SoilSamplingCollectionsLayersBulkExportPartialUpdateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersBulkExportPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportPartialUpdate>>,
    TError,
    { id: string; data: PatchedBulkExportWriter },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions =
    getV1SoilSamplingCollectionsLayersBulkExportPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Download a Bulk Export request
 */
export const v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/bulk_export/${id}/download/`,
      method: 'get',
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryKey = (id: string) =>
  [`/v1/soil_sampling/collections/layers/bulk_export/${id}/download/`] as const

export const getV1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>
>
export type V1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersBulkExportDownloadRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersBulkExportDownloadRetrieveQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve a Carbon Layer for a specific CHIPS request.
 */
export const v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/carbon/${id}/carbon_layers/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryKey = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/carbon/${id}/carbon_layers/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>
>
export type V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryError = ErrorType<string>

export const useV1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersCarbonCarbonLayersRetrieveQueryOptions(
    id,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve Total Carbon Points for a specific CHIPS request.
 */
export const v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/carbon/${id}/total_carbon_points/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryKey = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/carbon/${id}/total_carbon_points/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve(
      id,
      params,
      requestOptions,
      signal
    )

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>>
>
export type V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryError =
  ErrorType<string>

export const useV1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getV1SoilSamplingCollectionsLayersCarbonTotalCarbonPointsRetrieveQueryOptions(
      id,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve a Conductivity Layer for a specific CHIPS request.
 */
export const v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/conductivity/${id}/conductivity_layers/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryKey = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/conductivity/${id}/conductivity_layers/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
    >,
    TError = ErrorType<string>
  >(
    id: string,
    params?: V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
        >,
        TError,
        TData
      >
      request?: SecondParameter<typeof djangoAxiosInstance>
    }
  ): UseQueryOptions<
    Awaited<
      ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
    >,
    TError,
    TData
  > & { queryKey: QueryKey } => {
    const { query: queryOptions, request: requestOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getV1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryKey(id, params)

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
      >
    > = ({ signal }) =>
      v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve(
        id,
        params,
        requestOptions,
        signal
      )

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
  }

export type V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
    >
  >
export type V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryError =
  ErrorType<string>

export const useV1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof v1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieve>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getV1SoilSamplingCollectionsLayersConductivityConductivityLayersRetrieveQueryOptions(
      id,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve a Moisture Layer for a given CHIPS request ID
 */
export const v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/moisture/${id}/moisture_layers/`,
      method: 'get',
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryKey = (
  id: string
) => [`/v1/soil_sampling/collections/layers/moisture/${id}/moisture_layers/`] as const

export const getV1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>
>
export type V1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryError =
  ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersMoistureMoistureLayersRetrieveQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve the Volumentric Water Content (VWC) Points for a given CHIPS request ID
 */
export const v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/moisture/${id}/vwc_points/`,
      method: 'get',
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryKey = (id: string) =>
  [`/v1/soil_sampling/collections/layers/moisture/${id}/vwc_points/`] as const

export const getV1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>
>
export type V1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersMoistureVwcPointsRetrieveQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve a Nutrients Layer for a specific CHIPS request.
 */
export const v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/nutrients/${id}/nutrients_layers/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryKey = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/nutrients/${id}/nutrients_layers/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve(
      id,
      params,
      requestOptions,
      signal
    )

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>>
  >
export type V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryError =
  ErrorType<string>

export const useV1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getV1SoilSamplingCollectionsLayersNutrientsNutrientsLayersRetrieveQueryOptions(
      id,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve Nutrients Points for a specific CHIPS nutrients layer.
 */
export const v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/nutrients/${id}/total_nutrients_points/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryKey = (
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/nutrients/${id}/total_nutrients_points/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve(
      id,
      params,
      requestOptions,
      signal
    )

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>>
  >
export type V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryError =
  ErrorType<string>

export const useV1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>
  >,
  TError = ErrorType<string>
>(
  id: string,
  params?: V1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof v1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieve>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getV1SoilSamplingCollectionsLayersNutrientsTotalNutrientsPointsRetrieveQueryOptions(
      id,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve a Compaction Layer for a given CHIPS request ID
 */
export const v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve = (
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/tillage/${id}/compaction_layers/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryKey = (
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/tillage/${id}/compaction_layers/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryOptions = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>
  >,
  TError = ErrorType<unknown>
>(
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve(
      id,
      params,
      requestOptions,
      signal
    )

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>>
>
export type V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryError =
  ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve = <
  TData = Awaited<
    ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>
  >,
  TError = ErrorType<unknown>
>(
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getV1SoilSamplingCollectionsLayersTillageCompactionLayersRetrieveQueryOptions(
      id,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve the DCP Measurements for a given CHIPS request ID
 */
export const v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/tillage/${id}/dcp_measurements/`,
      method: 'get',
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryKey = (
  id: string
) => [`/v1/soil_sampling/collections/layers/tillage/${id}/dcp_measurements/`] as const

export const getV1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>
>
export type V1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryError =
  ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersTillageDcpMeasurementsRetrieveQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve a Tillage Layer for a given CHIPS request ID
 */
export const v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve = (
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<S3PresignedUrl>(
    {
      url: `/v1/soil_sampling/collections/layers/tillage/${id}/tillage_layers/`,
      method: 'get',
      params,
      signal,
    },
    options
  )
}

export const getV1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryKey = (
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveParams
) =>
  [
    `/v1/soil_sampling/collections/layers/tillage/${id}/tillage_layers/`,
    ...(params ? [params] : []),
  ] as const

export const getV1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getV1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>
  > = ({ signal }) =>
    v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve(id, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>
>
export type V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryError =
  ErrorType<unknown>

export const useV1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  params: V1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingCollectionsLayersTillageTillageLayersRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingCollectionsLayersTillageTillageLayersRetrieveQueryOptions(
    id,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Patch endpoint to update a Soil Core
 */
export const v1SoilSamplingCoresPartialUpdate = (
  id: string,
  patchedSoilCore: NonReadonly<PatchedSoilCore>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<SoilCore>(
    {
      url: `/v1/soil_sampling/cores/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSoilCore,
    },
    options
  )
}

export const getV1SoilSamplingCoresPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCoresPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedSoilCore> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingCoresPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedSoilCore> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingCoresPartialUpdate>>,
    { id: string; data: NonReadonly<PatchedSoilCore> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1SoilSamplingCoresPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingCoresPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingCoresPartialUpdate>>
>
export type V1SoilSamplingCoresPartialUpdateMutationBody = NonReadonly<PatchedSoilCore>
export type V1SoilSamplingCoresPartialUpdateMutationError = ErrorType<unknown>

export const useV1SoilSamplingCoresPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingCoresPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedSoilCore> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingCoresPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingLabsList = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilLab[]>(
    { url: `/v1/soil_sampling/labs/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingLabsListQueryKey = () => [`/v1/soil_sampling/labs/`] as const

export const getV1SoilSamplingLabsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingLabsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingLabsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingLabsList>>> = ({ signal }) =>
    v1SoilSamplingLabsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1SoilSamplingLabsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingLabsList>>
>
export type V1SoilSamplingLabsListQueryError = ErrorType<unknown>

export const useV1SoilSamplingLabsList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingLabsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingLabsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1SoilSamplingLabsRetrieve = (
  id: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilLab>(
    { url: `/v1/soil_sampling/labs/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingLabsRetrieveQueryKey = (id: string) =>
  [`/v1/soil_sampling/labs/${id}/`] as const

export const getV1SoilSamplingLabsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingLabsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>> = ({
    signal,
  }) => v1SoilSamplingLabsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingLabsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>
>
export type V1SoilSamplingLabsRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingLabsRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingLabsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingLabsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1SoilSamplingSamplesList = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilSample[]>(
    { url: `/v1/soil_sampling/samples/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingSamplesListQueryKey = () => [`/v1/soil_sampling/samples/`] as const

export const getV1SoilSamplingSamplesListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingSamplesListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>> = ({
    signal,
  }) => v1SoilSamplingSamplesList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1SoilSamplingSamplesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>
>
export type V1SoilSamplingSamplesListQueryError = ErrorType<unknown>

export const useV1SoilSamplingSamplesList = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingSamplesList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingSamplesListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve a Soil Sample
 */
export const v1SoilSamplingSamplesRetrieve = (
  id: unknown,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<SoilSample>(
    { url: `/v1/soil_sampling/samples/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1SoilSamplingSamplesRetrieveQueryKey = (id: unknown) =>
  [`/v1/soil_sampling/samples/${id}/`] as const

export const getV1SoilSamplingSamplesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1SoilSamplingSamplesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>> = ({
    signal,
  }) => v1SoilSamplingSamplesRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1SoilSamplingSamplesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>
>
export type V1SoilSamplingSamplesRetrieveQueryError = ErrorType<unknown>

export const useV1SoilSamplingSamplesRetrieve = <
  TData = Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1SoilSamplingSamplesRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1SoilSamplingSamplesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Patch endpoint to update a Soil Sample
 */
export const v1SoilSamplingSamplesPartialUpdate = (
  id: unknown,
  patchedSoilSample: NonReadonly<PatchedSoilSample>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<SoilSample>(
    {
      url: `/v1/soil_sampling/samples/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedSoilSample,
    },
    options
  )
}

export const getV1SoilSamplingSamplesPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedSoilSample> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesPartialUpdate>>,
  TError,
  { id: unknown; data: NonReadonly<PatchedSoilSample> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesPartialUpdate>>,
    { id: unknown; data: NonReadonly<PatchedSoilSample> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1SoilSamplingSamplesPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingSamplesPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesPartialUpdate>>
>
export type V1SoilSamplingSamplesPartialUpdateMutationBody = NonReadonly<PatchedSoilSample>
export type V1SoilSamplingSamplesPartialUpdateMutationError = ErrorType<unknown>

export const useV1SoilSamplingSamplesPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedSoilSample> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingSamplesPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const v1SoilSamplingSamplesBulkUpdatePartialUpdate = (
  soilSample: NonReadonly<SoilSample[]>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<SoilSample[]>(
    {
      url: `/v1/soil_sampling/samples/bulk_update/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: soilSample,
    },
    options
  )
}

export const getV1SoilSamplingSamplesBulkUpdatePartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesBulkUpdatePartialUpdate>>,
    TError,
    { data: NonReadonly<SoilSample[]> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesBulkUpdatePartialUpdate>>,
  TError,
  { data: NonReadonly<SoilSample[]> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesBulkUpdatePartialUpdate>>,
    { data: NonReadonly<SoilSample[]> }
  > = (props) => {
    const { data } = props ?? {}

    return v1SoilSamplingSamplesBulkUpdatePartialUpdate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1SoilSamplingSamplesBulkUpdatePartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1SoilSamplingSamplesBulkUpdatePartialUpdate>>
>
export type V1SoilSamplingSamplesBulkUpdatePartialUpdateMutationBody = NonReadonly<SoilSample[]>
export type V1SoilSamplingSamplesBulkUpdatePartialUpdateMutationError = ErrorType<unknown>

export const useV1SoilSamplingSamplesBulkUpdatePartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1SoilSamplingSamplesBulkUpdatePartialUpdate>>,
    TError,
    { data: NonReadonly<SoilSample[]> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1SoilSamplingSamplesBulkUpdatePartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Readonly List endpoint to show all active users that the requesting user is allowed to see, filtered by groups supplied in the request.
 */
export const v1StaffList = (
  params?: V1StaffListParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Staff[]>({ url: `/v1/staff/`, method: 'get', params, signal }, options)
}

export const getV1StaffListQueryKey = (params?: V1StaffListParams) =>
  [`/v1/staff/`, ...(params ? [params] : [])] as const

export const getV1StaffListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1StaffList>>,
  TError = ErrorType<unknown>
>(
  params?: V1StaffListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1StaffList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1StaffList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1StaffListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1StaffList>>> = ({ signal }) =>
    v1StaffList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1StaffListQueryResult = NonNullable<Awaited<ReturnType<typeof v1StaffList>>>
export type V1StaffListQueryError = ErrorType<unknown>

export const useV1StaffList = <
  TData = Awaited<ReturnType<typeof v1StaffList>>,
  TError = ErrorType<unknown>
>(
  params?: V1StaffListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1StaffList>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1StaffListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Readonly endpoint to retrieve an active staff User
 */
export const v1StaffRetrieve = (
  userId: string,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<Staff>({ url: `/v1/staff/${userId}/`, method: 'get', signal }, options)
}

export const getV1StaffRetrieveQueryKey = (userId: string) => [`/v1/staff/${userId}/`] as const

export const getV1StaffRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1StaffRetrieve>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1StaffRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1StaffRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1StaffRetrieveQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1StaffRetrieve>>> = ({ signal }) =>
    v1StaffRetrieve(userId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions }
}

export type V1StaffRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof v1StaffRetrieve>>>
export type V1StaffRetrieveQueryError = ErrorType<unknown>

export const useV1StaffRetrieve = <
  TData = Awaited<ReturnType<typeof v1StaffRetrieve>>,
  TError = ErrorType<unknown>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1StaffRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1StaffRetrieveQueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1UiTableViewsTableviewsList = (
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<UITableView[]>(
    { url: `/v1/ui_table_views/tableviews/`, method: 'get', signal },
    options
  )
}

export const getV1UiTableViewsTableviewsListQueryKey = () =>
  [`/v1/ui_table_views/tableviews/`] as const

export const getV1UiTableViewsTableviewsListQueryOptions = <
  TData = Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1UiTableViewsTableviewsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>> = ({
    signal,
  }) => v1UiTableViewsTableviewsList(requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1UiTableViewsTableviewsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>
>
export type V1UiTableViewsTableviewsListQueryError = ErrorType<unknown>

export const useV1UiTableViewsTableviewsList = <
  TData = Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof v1UiTableViewsTableviewsList>>, TError, TData>
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1UiTableViewsTableviewsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const v1UiTableViewsTableviewsCreate = (
  uITableView: NonReadonly<UITableView>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<UITableView>(
    {
      url: `/v1/ui_table_views/tableviews/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uITableView,
    },
    options
  )
}

export const getV1UiTableViewsTableviewsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsCreate>>,
    TError,
    { data: NonReadonly<UITableView> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsCreate>>,
  TError,
  { data: NonReadonly<UITableView> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsCreate>>,
    { data: NonReadonly<UITableView> }
  > = (props) => {
    const { data } = props ?? {}

    return v1UiTableViewsTableviewsCreate(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1UiTableViewsTableviewsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsCreate>>
>
export type V1UiTableViewsTableviewsCreateMutationBody = NonReadonly<UITableView>
export type V1UiTableViewsTableviewsCreateMutationError = ErrorType<unknown>

export const useV1UiTableViewsTableviewsCreate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsCreate>>,
    TError,
    { data: NonReadonly<UITableView> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1UiTableViewsTableviewsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Readonly endpoint to retrieve a UITableView
 */
export const v1UiTableViewsTableviewsRetrieve = (
  id: unknown,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<UITableView>(
    { url: `/v1/ui_table_views/tableviews/${id}/`, method: 'get', signal },
    options
  )
}

export const getV1UiTableViewsTableviewsRetrieveQueryKey = (id: unknown) =>
  [`/v1/ui_table_views/tableviews/${id}/`] as const

export const getV1UiTableViewsTableviewsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1UiTableViewsTableviewsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>> = ({
    signal,
  }) => v1UiTableViewsTableviewsRetrieve(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type V1UiTableViewsTableviewsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>
>
export type V1UiTableViewsTableviewsRetrieveQueryError = ErrorType<unknown>

export const useV1UiTableViewsTableviewsRetrieve = <
  TData = Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: unknown,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof v1UiTableViewsTableviewsRetrieve>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1UiTableViewsTableviewsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Patch endpoint to update a UITableView
 */
export const v1UiTableViewsTableviewsPartialUpdate = (
  id: unknown,
  patchedUITableView: NonReadonly<PatchedUITableView>,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  return djangoAxiosInstance<UITableView>(
    {
      url: `/v1/ui_table_views/tableviews/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedUITableView,
    },
    options
  )
}

export const getV1UiTableViewsTableviewsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedUITableView> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsPartialUpdate>>,
  TError,
  { id: unknown; data: NonReadonly<PatchedUITableView> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsPartialUpdate>>,
    { id: unknown; data: NonReadonly<PatchedUITableView> }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1UiTableViewsTableviewsPartialUpdate(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1UiTableViewsTableviewsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof v1UiTableViewsTableviewsPartialUpdate>>
>
export type V1UiTableViewsTableviewsPartialUpdateMutationBody = NonReadonly<PatchedUITableView>
export type V1UiTableViewsTableviewsPartialUpdateMutationError = ErrorType<unknown>

export const useV1UiTableViewsTableviewsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UiTableViewsTableviewsPartialUpdate>>,
    TError,
    { id: unknown; data: NonReadonly<PatchedUITableView> },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1UiTableViewsTableviewsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Upload: The Upload endpoint expects a multipart payload with one or more files and an
optional metadata json field. A Batch object is created for this upload a Batch Item
object is created for each file in this upload. These Batch Items are associated with
the created Batch object. As part of this upload / Batch a Manifest File (manifest.json)
is also created with an associated Batch Item.
 */
export const v1UploadCreate = (options?: SecondParameter<typeof djangoAxiosInstance>) => {
  return djangoAxiosInstance<void>({ url: `/v1/upload/`, method: 'post' }, options)
}

export const getV1UploadCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UploadCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1UploadCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1UploadCreate>>,
    TVariables
  > = () => {
    return v1UploadCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1UploadCreateMutationResult = NonNullable<Awaited<ReturnType<typeof v1UploadCreate>>>

export type V1UploadCreateMutationError = ErrorType<unknown>

export const useV1UploadCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UploadCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1UploadCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Upload raw Ward or Waters lab nutrient result files containing: carbon, bulk density, N, P, PH, and/or CEC.
 */
export const uploadLabs = (
  uploadLabsBody: UploadLabsBody,
  options?: SecondParameter<typeof djangoAxiosInstance>
) => {
  const formData = new FormData()
  if (uploadLabsBody.upload_file !== undefined) {
    formData.append('upload_file', uploadLabsBody.upload_file)
  }
  if (uploadLabsBody.lab_types !== undefined) {
    uploadLabsBody.lab_types.forEach((value) => formData.append('lab_types', value))
  }

  return djangoAxiosInstance<Batch>(
    {
      url: `/v1/upload_labs/`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getUploadLabsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadLabs>>,
    TError,
    { data: UploadLabsBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadLabs>>,
  TError,
  { data: UploadLabsBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadLabs>>,
    { data: UploadLabsBody }
  > = (props) => {
    const { data } = props ?? {}

    return uploadLabs(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadLabsMutationResult = NonNullable<Awaited<ReturnType<typeof uploadLabs>>>
export type UploadLabsMutationBody = UploadLabsBody
export type UploadLabsMutationError = ErrorType<unknown>

export const useUploadLabs = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadLabs>>,
    TError,
    { data: UploadLabsBody },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getUploadLabsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Upload: The Upload endpoint expects a multipart payload with one or more files and an
optional metadata json field. A Batch object is created for this upload a Batch Item
object is created for each file in this upload. These Batch Items are associated with
the created Batch object. As part of this upload / Batch a Manifest File (manifest.json)
is also created with an associated Batch Item.
 */
export const v1UploadsCreate = (options?: SecondParameter<typeof djangoAxiosInstance>) => {
  return djangoAxiosInstance<void>({ url: `/v1/uploads/`, method: 'post' }, options)
}

export const getV1UploadsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UploadsCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof v1UploadsCreate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1UploadsCreate>>,
    TVariables
  > = () => {
    return v1UploadsCreate(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type V1UploadsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof v1UploadsCreate>>>

export type V1UploadsCreateMutationError = ErrorType<unknown>

export const useV1UploadsCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1UploadsCreate>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}) => {
  const mutationOptions = getV1UploadsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Returns vector tiles for the fields owned by the user
 */
export const v1VectorTilesFieldsRetrieve = (
  params: V1VectorTilesFieldsRetrieveParams,
  options?: SecondParameter<typeof djangoAxiosInstance>,
  signal?: AbortSignal
) => {
  return djangoAxiosInstance<void>(
    { url: `/v1/vector_tiles/fields/`, method: 'get', params, signal },
    options
  )
}

export const getV1VectorTilesFieldsRetrieveQueryKey = (params: V1VectorTilesFieldsRetrieveParams) =>
  [`/v1/vector_tiles/fields/`, ...(params ? [params] : [])] as const

export const getV1VectorTilesFieldsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: V1VectorTilesFieldsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>, TError, TData> & {
  queryKey: QueryKey
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getV1VectorTilesFieldsRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>> = ({
    signal,
  }) => v1VectorTilesFieldsRetrieve(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions }
}

export type V1VectorTilesFieldsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>
>
export type V1VectorTilesFieldsRetrieveQueryError = ErrorType<unknown>

export const useV1VectorTilesFieldsRetrieve = <
  TData = Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: V1VectorTilesFieldsRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof v1VectorTilesFieldsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof djangoAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getV1VectorTilesFieldsRetrieveQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

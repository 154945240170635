import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { CollectionFilesFileTypeEnum } from 'lib/api/django/model'

import { DEFAULT_FILE_TYPE, FORM_INPUTS } from './config.sampling-plans'
import { MultiThingOrgAutocomplete } from './MultiThingOrgAutocomplete'

export function MultiComboFormInputs() {
  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mb={2}>
      <Box flex={1} maxWidth={300}>
        <MultiThingOrgAutocomplete />
      </Box>
      <TextField
        required
        select
        defaultValue={DEFAULT_FILE_TYPE}
        label="File type"
        name={FORM_INPUTS.file_type}
        size="small"
      >
        {Object.keys(CollectionFilesFileTypeEnum).map((t) => (
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        ))}
      </TextField>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox defaultChecked name={FORM_INPUTS.create_fields} />}
          label="Create fields"
        />
        <FormControlLabel
          control={<Checkbox defaultChecked name={FORM_INPUTS.create_collections} />}
          label="Create collections"
        />
      </FormGroup>
    </Box>
  )
}

import type { FallbackProps } from 'react-error-boundary'
import { ErrorBoundary } from 'react-error-boundary'
import Box from '@mui/material/Box'

import { ConfMessage } from 'components/reusable/alerts-and-messages'

type Props = {
  children: React.ReactNode
}

type RenderFallbackProps = FallbackProps

function Fallback(props: RenderFallbackProps) {
  const { error } = props
  const msg = error instanceof Error ? error.message : ''

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <ConfMessage
        severity="error"
        text={msg || 'Something went wrong.'}
        title="Could not load table"
        variant="outlined"
      />
    </Box>
  )
}

export function ErrorBoundaryWrap(props: Props) {
  const { children } = props

  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
}

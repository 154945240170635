/**
 * @file the reducer-y Munge-glue between dispatch and rendering
 */
import * as turf from '@turf/turf'
import { squareMetersToAcres } from 'lib/utils'

import type {
  BulkEditImportedAttribsPayload,
  ImportedFeature,
  ImportFeatPayload,
} from 'components/fields/types.fields-mgmt'

/**
 * Prep an individual Field feature if it's one of the selected features in the table, using the
 * provided writeable Field attributes.
 *
 * @param feat imported Field feature
 * @param payload what to send to the context dispatch
 * @returns the original feature if its top-level `id` is missing or not in the list, otherwise
 * the feature with the payload's properties applied
 */
export function prepBulkEditImportedFeatAttribs(
  feat: ImportedFeature,
  payload: BulkEditImportedAttribsPayload
): ImportedFeature {
  const { featIds, attributes } = payload

  if (!featIds.includes(feat.id as string)) {
    return feat
  }

  return { ...feat, properties: { ...feat.properties, ...attributes } }
}

/**
 * Munge a raw GeoJSON feature into the schema we want, using the provided column mappings.
 *
 * NOTE: there is no validation such as, in the case of CSVs, checking for things like missing
 * lat/lon. It doesn't actually crap out because we set the coords to `[0, 0]` prior to import
 * (since the lat/lon mappings are not yet known), but obviously that's not ok. The only reason I
 * noticed is because I exported Nicole's Airtable for Spring 2022, which was missing some
 * coordinates. There will be other edge cases, we should define how/what to handle and look for.
 * However, the long-term goal is for backend to replace FE for this:
 * https://earthoptics.atlassian.net/browse/DV-2190
 *
 * @param f any GeoJSON feature
 * @param payload what to send to the context dispatch
 * @returns prepped imported Field feature based on the provided column mappings
 */
export function prepImportedFeature(
  f: GeoJSON.Feature,
  payload: ImportFeatPayload
): ImportedFeature {
  const feature = f as GeoJSON.Feature
  const { properties: attr, geometry: geom } = feature
  const { colMappings, org, latLonColumns: latLonCols, secGroups } = payload
  const { description, external_id, requested_acres } = colMappings
  const { farm, name, producer_field_name: pn } = colMappings
  // Point on surface mimics what backend does. Note that this may lead to confusing results for
  // MultiPolygons, but... so would `turf.center` since it may occur in empty space.
  //
  // The auto-zoom of the map to the MultiPoly's extent should be a visual cue to the user, however,
  // that the point label applies to all the child geometries of that shape.
  //
  // The main drawback to point-on-surface, though, is that if it were to be used outside the app,
  // e.g. a user exports to CSV, the lat/lon for MultiPoly's might not be so intuitive. This isn't
  // just an FE thing, though- backend uses same tactic to generate lat/lon.
  const [lon, lat] = turf.pointOnFeature(turf.feature(geom)).geometry.coordinates
  const extras: ImportedFeature['properties']['extras'] = {}

  payload.extraColumns.forEach((c) => {
    if (attr) {
      extras[c] = attr[c]
    }
  })

  // Keeping same schema as the GET, even though we don't need all the stuff in Imports table,
  // really simplifies table component reuse and avoids TS fight.
  let mapped: ImportedFeature['properties'] & {
    id: string
    short_id: string
  } = {
    id: f.id as string, // uuid from the raw features import step
    short_id: '', // giving up on TS mess here
    org,
    owned_by: org.id,
    name: attr && name ? attr[name] : '',
    description: '',
    external_id: '',
    farm: '',
    producer_field_name: '',
    address: '',
    location: null,
    onsite_contact: null,
    extras,
    status: 'READY_FOR_USE',
    lat,
    lon,
    acres: squareMetersToAcres(turf.area(geom)),
    requested_acres: 0,
    geometry_source: 'FILE',
    security_groups: secGroups,
    collections: [],
    geometry_type: geom.type,
    geohash: '',
  }

  if (attr) {
    mapped = {
      ...mapped,
      description: description ? (attr[description] as string) : '',
      requested_acres: requested_acres ? (attr[requested_acres] as number) : null,
      external_id: external_id ? (attr[external_id] as string) : '',
      farm: farm ? (attr[farm] as string) : '',
      producer_field_name: pn ? (attr[pn] as string) : '',
      // Convert to string here to prevent the scenario where the name column is numeric, which
      // causes MUI table to treat it as a number even though the column `type` defaults to string.
      // The end problem is when the name is `0`, which becomes falsy and makes it look like the
      // Field status (currently based on Org and name existence) is "Not Ready". Long story for an
      // edge cases, but it happened in testing...
      name: name ? attr[name].toString() : '',
    }
  }

  // Not sure how to get by the attr check again
  if (attr && latLonCols.lat && latLonCols.lon) {
    return {
      id: f.id,
      type: 'Feature',
      properties: mapped,
      geometry: {
        type: 'Point',
        // Somehow strings work fine in MB, but we need numbers in the POST, so convert them here.
        // If it somehow ends up coming in as a number during import, JS is fine with that as
        // `parseFloat` still works on a number.
        coordinates: [
          parseFloat(attr[latLonCols.lon]),
          parseFloat(attr[latLonCols.lat]),
        ],
      },
    } as ImportedFeature
  }

  return {
    id: f.id,
    ...feature,
    properties: mapped,
  }
}

import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { topLevelAbsoluteAppRoutes } from 'lib/config/routes'

import { segments } from 'components/reusable/routes'

type Props = {
  accessDenied?: boolean
  /**
   * If this component is nested, e.g. inside a collection detail view, it doesn't make sense to
   * show the home/login buttons, or even the Back button since the user already has other escape
   * hatches, so this prop will hide them.
   */
  omitNavBtns?: boolean
}

export function PageNotFound(props: Props) {
  const { accessDenied, omitNavBtns } = props
  const reason = accessDenied ? 'Access Denied' : 'Page not found'
  const navigate = useNavigate()

  function handleGoBack() {
    navigate(-1)
  }

  const Reason = (
    <Typography component="h1" mb={2} variant="h2">
      {reason}
    </Typography>
  )

  if (omitNavBtns) {
    return Reason
  }

  return (
    <>
      {Reason}
      <Button
        replace // prevent browser's Back button from going back to current page
        component={RouterLink}
        data-cy="link-to-home"
        size="large"
        to={topLevelAbsoluteAppRoutes.home}
      >
        home
      </Button>
      <Button size="large" onClick={handleGoBack}>
        go back
      </Button>
      <Button
        replace // prevent browser's Back button from going back to current page
        component={RouterLink}
        data-cy="link-to-home"
        size="large"
        to={`/${segments.login}`}
      >
        login
      </Button>
    </>
  )
}

import { Link as RouterLink } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useCanAccessBasedOnPerms } from 'lib/queries.user'

import { FmtCollnDetailAccordionFooter } from 'components/fields/fields-mgmt/collections/FmtCollnDetailAccordionFooter'
import { OpenUploadSamplePlanDropzoneBtn } from 'components/fields/fields-mgmt/collections/sampling-plans/OpenUploadSamplePlanDropzoneBtn'
import { fieldMgmtIcons } from 'components/fields/fields-mgmt/config.fields-mgmt-icons'
import { segments } from 'components/fields/routes'

type Props = {
  collnId: string
  dropzone?: React.ReactNode
}

// TODO: this lives in SC but is only used in FMT 🤔
// Needed the wrapper for a little more flexibility when using it in the routes config, so it was
// easiest to just pass the dropzone as a prop since a lot of its props are defined by the parent.
export function FilesDropzoneWrap(props: Props) {
  const { dropzone, collnId } = props
  const canAccessBasedOnPerms = useCanAccessBasedOnPerms(['add_collectionfile'])

  return (
    <>
      {canAccessBasedOnPerms && dropzone}
      <FmtCollnDetailAccordionFooter>
        <Button
          component={RouterLink}
          size="small"
          startIcon={fieldMgmtIcons.collnFiles}
          to={segments.collnFilesRelative}
          variant="contained"
        >
          View all files
        </Button>
        {canAccessBasedOnPerms && <OpenUploadSamplePlanDropzoneBtn collnId={collnId} />}
      </FmtCollnDetailAccordionFooter>
    </>
  )
}

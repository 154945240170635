import { useCallback, useEffect, useMemo, useState } from 'react'
import type { PathMatch } from 'react-router-dom'
import { useMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid-premium'
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as turf from '@turf/turf'
import type { AxiosError } from 'axios'
import type {
  PatchedSoilSample,
  SoilCollectionDetail,
  SoilCollectionReader,
  V1SoilSamplingCollectionsListParams,
} from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey,
  useV1SoilSamplingCollectionsList,
} from 'lib/api/django/v1/v1'
import { soilCollectorStore } from 'lib/config'
import { useCanAccessBasedOnGroups, useCurrentUser } from 'lib/queries.user'
import { getGoogMapsBaseUrl, roundNumber } from 'lib/utils'

import { GPS_ACCURACY_CLASSES } from 'components/reusable/maps/map-ctrls/geolocation/config'

import { getSampleHasRxLatLon } from './collection-detail/sample-view/utils'
import {
  useAllSCglobalFilters,
  useSCglobalFiltersStore,
} from './ssa-landing/filters/store.sc-global-filters'
import { prepCollnsQueryArgs } from './ssa-landing/filters/utils.sc-global-filters'
import {
  AFTER_FIELDWORK_STATUSES,
  defaultRemoteCollnQueryParams as defaultRemoteParams,
  localQueryKeys,
  RX_POINT_DISTANCE_THRESHOLD,
} from './config.ssa'
import { soilCollectorToasts as toasts } from './config.toasts.soilcollector'
import { useLocalSampleMutation } from './mutations.ssa-local'
import { useLocalCollection, useLocalCollections } from './queries.ssa-local'
import { useRemoteCollection } from './queries.ssa-remote'
import { routes } from './routes'
import type {
  RouteParams,
  SaneSoilCollectorRouteInfo,
  SaneSoilCollectorRouteName,
  SoilCollection,
  SoilSample,
} from './types.ssa'
import { isAssignedToCollection } from './utils.soil-sampling'

type UseHandleQrResult = (
  /**
   * Callback to run after the mutation is done. This is useful for things like closing a modal or
   * clearing state.
   */
  onSettled?: () => void
) => (
  /**
   * The `coords` object from the `GeolocationPosition` for the user's current position.
   */
  position: GeolocationCoordinates,
  /**
   * The scanned sample that is being updated.
   */
  sampleToUpdate: SoilSample,
  /**
   * Any additional payload to include in the PATCH. This is useful for things like `external_id`,
   * which is not included in the QR-only scan.
   */
  additionalPayload?: Omit<
    PatchedSoilSample,
    'collected_at' | 'gps_accuracy' | 'sample_lat' | 'sample_lon'
  >
) => void

/**
 * This has historically been a sketchy bugger. Hard to say if it's a local-dev-specific race
 * condition, or a legit problem because it has usually been impossible to reproduce and was largely
 * only seen on `localhost`. However, after a large refactor in May 2023, it was finally
 * reproducible: if you start from SoilCollector, exit to the Dashboard, then return to SC, **it
 * happened**: you'd see a blue popup indicating that there were collections to download, even
 * though the collections on device were all set.
 *
 * After another refactor shortly after, THAT problem went away, only to be replaced by the same
 * problem in this order: 1) click blue popup to download collections, 2) go to any other route,
 * e.g. collection detail, 3) see blue popup again 🤬
 *
 * The possible culprit: `useMyUndownloadedCollnsKeys` was attempting to make the necessary checks
 * before this hook was done doing its thing, perhaps because the `useCallback` dependency array
 * include an array and therefore memoization should have been applied.
 *
 * Regardless of the cause, what seems to be consistently working is to wait until it's done
 * loading, e.g. via `useQuery`, and then invalidate all relevant local queries once all the
 * collections have been downloaded.
 *
 * ⚠️ **Don't touch it unless you know what you're doing!** ⚠️
 *
 * @returns useQuery instance that points to the `localForage` collections keys
 */
export function useLocalCollnsKeys(): UseQueryResult<string[], unknown> {
  return useQuery({
    queryKey: localQueryKeys.collectionsKeys(),
    queryFn: () => soilCollectorStore.keys(),
    networkMode: 'always', // ignore connectivity, always run it
  })
}

// TODO: fix `short_id`s containing exponent notation being read as numbers:
// https://earthoptics.atlassian.net/browse/DV-2724
export const useIsCollnOnDevice = (collnShortId?: string): boolean | undefined => {
  const keys = useLocalCollnsKeys()

  return !!collnShortId && keys.data?.includes(collnShortId)
}

/**
 * One of the more important hooks in SoilCollector, this function is the sole centralized means of
 * getting all of the local and remote collections in one convenient hook. It also provides a
 * `defaultQuery` based on the existence of at least one collection on the device, in addition to
 * the `local` and `remote` queries in case they are also needed, and an `atLeastOneOnDevice`
 * boolean which is true if at least one collection is on the device (duh).
 *
 * @param localQueryOpts `UseQueryOptions` for **local** collections `useQuery`
 * @param remoteQueryOpts `UseQueryOptions` for **remote** collections `useQuery`. If you need to
 * force the remote query to be enabled regardless of whether the user is in a "show local
 * collections only" state, be sure to pass `{ enabled: true }` and any other query options for the
 * remote. By default it _is_ enabled, but only if there are no collections on the device and the
 * user does not have the "show local collections only" checked. A good use case for forcing the
 * `enabled` option is to fetch all the collections assigned to the current user regardless of
 * whether they are showing only local collections.
 * @param requestParams the object included as the Axios `params` which, at time of writing,
 * includes `hide` and `filter` query params. This is also used as the second part of the `queryKey`
 * so it is important to keep it consistent when a new fetch is not desired.
 * @default
 * @returns `{ defaultQuery, atLeastOneOnDevice, local, remote }`. See above for more details.
 */
// Not even gonna attempt the return type here, it's 🍌s. Or at least the dynamic `defaultQuery` is.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCollections<TSelect = SoilCollectionReader[]>(
  localQueryOpts?: UseQueryOptions<SoilCollectionDetail[], Error, TSelect>,
  remoteQueryOpts?: UseQueryOptions<SoilCollectionReader[], AxiosError, TSelect>,
  requestParams?: V1SoilSamplingCollectionsListParams
) {
  const local = useLocalCollections<TSelect>(localQueryOpts)
  const filters = useAllSCglobalFilters()
  const paramsViaFilters = prepCollnsQueryArgs(filters)
  const localOnly = useSCglobalFiltersStore((state) => state.localOnly)
  const params = requestParams || paramsViaFilters || defaultRemoteParams

  const remoteEnabled =
    remoteQueryOpts?.enabled || (!localOnly && remoteQueryOpts?.enabled !== false)

  const remote = useV1SoilSamplingCollectionsList(params, {
    query: { ...remoteQueryOpts, enabled: remoteEnabled },
  })

  const atLeastOneOnDevice = localOnly && Array.isArray(local.data) && !!local.data.length
  const defaultQuery = atLeastOneOnDevice ? local : remote

  return {
    defaultQuery,
    atLeastOneOnDevice,
    local,
    remote,
  }
}

// Not even gonna attempt the return type here, it's 🍌s. Or at least the dynamic `defaultQuery` is.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCollection<TSelect = SoilCollection>(
  localOptions?: Omit<UseQueryOptions<SoilCollection, Error, TSelect>, 'enabled'>,
  remoteOptions?: UseQueryOptions<SoilCollection, AxiosError, TSelect>,
  collnShortIdOverride?: string
) {
  const match = useMatch(`${routes.collectionDetailAbsMatch}/*`)
  const params = match?.params as Partial<RouteParams['collection']> | undefined
  const collnId = collnShortIdOverride || params?.collnId
  const isOnDevice = !!useIsCollnOnDevice(collnId)

  const local = useLocalCollection<TSelect>(collnId, {
    ...localOptions,
    enabled: isOnDevice,
  })

  const remote = useRemoteCollection<TSelect>(collnId, remoteOptions)
  const defaultQuery = isOnDevice ? local : remote

  return {
    local,
    remote,
    isOnDevice,
    defaultQuery,
  }
}

export const useHandleGeolocResult: UseHandleQrResult = (onSettled) => {
  const { mutate } = useLocalSampleMutation(false, toasts.qrSampleStoredOnDevice)

  // TODO: what's a good way to break this out into utils?
  // QUESTION: should it even be a callback? It's not like we're using it in 500 places at once.
  const handleQrResult: ReturnType<UseHandleQrResult> = useCallback(
    (geolocResult, sampleToUpdate, additionalPayload) => {
      // Show a warning if GPS accuracy is poor
      if (geolocResult.accuracy > GPS_ACCURACY_CLASSES.warning) {
        toast.warn(toasts.poorGpsAccuracy(roundNumber(geolocResult.accuracy, 1)), {
          // Needs to stay independent of others since the PATCH still happens BUT we also want the
          // user to know their GPS accuracy is poor.
          toastId: `${sampleToUpdate.short_id}-poor-gps-accuracy`,
          autoClose: false,
          position: 'bottom-left', // shove all warnings to the left
        })
      }

      // Determine if sample has prescribed coordinates
      if (getSampleHasRxLatLon(sampleToUpdate)) {
        // Check if current location is within 5m of the Rx lat/lon
        const distance = turf.distance(
          // Coercion is safe here because we already checked for existence above
          [sampleToUpdate.prescribed_lon, sampleToUpdate.prescribed_lat] as [number, number],
          [geolocResult.longitude, geolocResult.latitude]
        )

        // Allow submission over 5m but show a warning. 0.001 = 1m because default units for turf
        // distance are `km`.
        if (distance > RX_POINT_DISTANCE_THRESHOLD) {
          const roundedDistance = roundNumber(distance * 1000, 1)
          const toastContent = toasts.tooFarFromRxPoint(roundedDistance)

          toast.warn(toastContent, {
            autoClose: false,
            toastId: 'over-5m',
            position: 'bottom-left', // shove all warnings to the left
          })
        }
      }

      const payload: Partial<SoilSample> = {
        ...additionalPayload,
        status: 'UPLOADED', // hoping this fixes the auto-gen-via-backend issue 🤞
        gps_accuracy: geolocResult.accuracy,
        sample_lat: geolocResult.latitude,
        sample_lon: geolocResult.longitude,
        collected_at: new Date().toISOString(),
      }

      mutate(
        {
          collnShortId: sampleToUpdate.collection_id.split('-')[0],
          sampleShortId: sampleToUpdate.short_id,
          data: payload,
        },
        { onSettled }
      )
    },
    // Let 2024 be the "Year of Not Using Function Deps"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return handleQrResult
}

// Convert a Collection's samples to a FeatureCollection with bbox. Bbox includes the Field point,
// which is especially useful if there are no samples with coordinates yet.
// TODO: move this into a `select` function with referential integrity instead of memoizing the
// result, if possible.
export function useSamplesFeatColln(
  notReady: boolean,
  data?: SoilCollection
): GeoJSON.FeatureCollection<GeoJSON.Point, SoilSample> {
  return useMemo(() => {
    if (notReady || !data) return turf.featureCollection([])

    // Not actually displaying a "Field" point, just need it in the bbox in case there are no
    // samples yet.
    const fieldPoint = turf.point([data.field.lon, data.field.lat])
    // Not actually an array, just easier to spread later 🧈
    const fieldBoundary = []

    // Prevent sketchy/old Fields from seeing the light of day. Redundant to push point feature into
    // here, but also doesn't really matter since it's just for the bbox, not actually added to map
    // here.
    if (data.field.status === 'READY_FOR_USE') {
      // Not sure why coercion is needed. Occurred in upgrade to `turf@7.0.0-alpha.1`.
      fieldBoundary.push(turf.feature(data.field.geometry as GeoJSON.Geometry))
    }

    const samplesWithCoords = data.samples.filter(
      (sample) => sample.sample_lat && sample.sample_lon
    )

    const positions = samplesWithCoords.map((sample) => {
      const { sample_lon, sample_lat, short_id } = sample

      return turf.point([sample_lon, sample_lat] as [number, number], sample, {
        id: short_id,
      })
    })

    const featColln = turf.featureCollection(positions)
    const bbox = turf.bbox(turf.featureCollection([...positions, fieldPoint, ...fieldBoundary]))

    return { ...featColln, bbox }
  }, [notReady, data])
}

// Invalidate query when modal is opened as a final check to make sure nothing changed in the
// backend.
export function useInvalidateLocalCollnOnModalOpen(isOpen: boolean, collnShortId: string): void {
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isOpen || !collnShortId) {
      const queryKey = getV1SoilSamplingCollectionsRetrieveQueryKey(collnShortId)

      queryClient.invalidateQueries({ queryKey })
    }
  }, [collnShortId, isOpen, queryClient])
}

/**
 * Hook to get route path prefix with everything up to `:sampleId`.
 *
 * @returns string with every route piece except the sample ID
 */
export function useRoutePrefixOfClickedSample(): string {
  const collnIdMatch = useMatch(`${routes.collectionDetailAbsMatch}/*`)
  const collnRouteParams = collnIdMatch?.params as Partial<RouteParams['collection']> | undefined
  const { collnId = '' } = collnRouteParams || {}

  if (!collnId) {
    return ''
  }

  return [routes.collectionsAbsolute, collnId, routes.samples].join('/')
}

/**
 * Hook to determine if user can edit a collection based on whether they are assigned to it as a
 * team contact or team member.
 *
 * @param collection SoilCollection
 * @param checkDispatcher whether or not to check if user is a dispatcher for this collection
 * @returns true if user is a team contact/member for this collection
 */
export function useIsAssignedToCollection(
  collection?: SoilCollection,
  checkDispatcher?: boolean
): boolean {
  const { data, isLoading } = useCurrentUser()
  const queryIsReady = !isLoading && data

  return (
    !!queryIsReady && !!collection && isAssignedToCollection(collection, data.id, checkDispatcher)
  )
}

/**
 * Collection managers and assignees (inc. dispatchers) can do things like view shipping info and
 * edit tracking numbers, but only CMs can edit collection details such as the name and assignees.
 * This check takes into account the assignee and the user's designation as a CM, as well as
 * collection `status`, if desired.
 *
 * For anything using this check, CMs can always do whatever, whenever, but non-CMs who are only
 * assignees cannot perform actions on anything checked by this hook after the time of submission
 * (if `checkStatus` is true). Example use case: annotations should not be creatable or deletable
 * after submission by anyone except CMs, but tracking numbers can still be _viewed_ by assignees.
 *
 * It is named weirdly because `useCanEditColln` would imply that users can edit details.
 *
 * @param collection SoilCollection
 * @param checkStatus boolean
 * @returns true if user is a collection manager, OR an assignee AND the collection is not submitted
 */
export function useCanSomewhatEditColln(
  collection?: SoilCollection,
  checkStatus?: boolean
): boolean {
  const isAssignedToColln = useIsAssignedToCollection(collection, true)
  const hasGroupBasedAccess = useCanAccessBasedOnGroups(['collection_manager'])
  const hasBeenSubmitted = collection && AFTER_FIELDWORK_STATUSES.includes(collection.status)

  if (checkStatus) {
    return hasGroupBasedAccess || (isAssignedToColln && !hasBeenSubmitted)
  }

  return hasGroupBasedAccess || isAssignedToColln
}

export function useSaneSoilCollectorRouteInfo(): SaneSoilCollectorRouteInfo | null {
  const collectionsList = useMatch(routes.collectionsAbsolute)
  const collectionDetail = useMatch(routes.collectionDetailAbsMatch)
  const sampleDetail = useMatch(routes.sampleDetailMatch)
  const annosList = useMatch(routes.annosListMatch)
  const annoDetail = useMatch(routes.annoDetailMatch)

  let name: SaneSoilCollectorRouteName | undefined
  let match: PathMatch<''> | undefined

  if (collectionsList) {
    name = 'collectionsList'
    match = collectionsList
  } else if (collectionDetail) {
    name = 'collectionDetail'
    match = collectionDetail
  } else if (annosList) {
    name = 'annosList'
    match = annosList
  } else if (annoDetail) {
    name = 'annoDetail'
    match = annoDetail
  } else if (sampleDetail) {
    name = 'sampleDetail'
    match = sampleDetail
  }

  if (!(name && match)) {
    return null
  }

  return {
    name,
    params: match.params,
  } as SaneSoilCollectorRouteInfo
}

/**
 * Munge selected rows from Data Grid API context into an array of `[lat, lon]`, then pass it to the
 * utility function to ultimately prep the Google Maps directions url, or `false` if no rows are
 * selected.
 *
 * @returns Google Maps directions url of the selected stops, or `false` if no rows are selected
 */
export function useGoogleMapsUrl(): string | false {
  const apiRef = useGridApiContext()
  const [stops, setStops] = useState<[number, number][]>([])

  function handleSelectionChange(): void {
    const selected = apiRef.current?.getSelectedRows()
    const rows = Array.from(selected || [])

    setStops(rows.map(([, colln]) => [colln.field.lat, colln.field.lon]))
  }

  useGridApiEventHandler(apiRef, 'rowSelectionChange', handleSelectionChange)

  return stops.length ? getGoogMapsBaseUrl(stops) : false
}

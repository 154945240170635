import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'

// Need this exported so that it can be dismissed when bulk edit is finished
export const SEL_FEAT_LIST_TOAST_ID = 'selected-features'

type SelectedProps = {
  count: number
  uniqueNames: string[]
  pluralNoun: string
}

/**
 * List of selected feature names to show in the toast.
 *
 * @param props
 * @param props.count
 * @param props.uniqueNames
 * @param props.pluralNoun Human-friendly name of the thing that is being selected
 * @example 'collections'
 */
function SelectedFeatNamesToastContent(props: SelectedProps) {
  const { count, uniqueNames, pluralNoun } = props

  return (
    <ToastContentWithTitle
      title={`${count} ${pluralNoun} selected`}
      content={
        <>
          <p style={{ marginTop: 0 }}>Click anywhere in this box to clear the selection.</p>
          <ul style={{ margin: 0, paddingLeft: 16 }}>
            {uniqueNames.map((name) => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </>
      }
    />
  )
}

export const config = {
  selected: (props: SelectedProps) => <SelectedFeatNamesToastContent {...props} />,
  tooManyError: (count: number, pluralNoun: string) => (
    <ToastContentWithTitle
      content={`The limit is 100, but you selected ${count}.`}
      title={`Too many ${pluralNoun} selected`}
    />
  ),
}

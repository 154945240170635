import mapboxgl from 'mapbox-gl'

const BOX_ELEM_ID = 'boxdraw'

// Return the xy coordinates of the mouse position
export function getMousePosition(evt: MouseEvent, canvas: HTMLElement): mapboxgl.Point {
  const rect = canvas.getBoundingClientRect()

  return new mapboxgl.Point(
    evt.clientX - rect.left - canvas.clientLeft,
    evt.clientY - rect.top - canvas.clientTop
  )
}

/**
 * Draw a box on the map to indicate the current box selection.
 *
 * @param startPoint initial corner of the box
 * @param current current corner of the box diagonal to the start point
 * @param canvas map canvas element
 */
export function setBoxOutlineInMap(
  startPoint: mapboxgl.Point,
  current: mapboxgl.Point,
  canvas: HTMLElement
): void {
  let box = document.getElementById(BOX_ELEM_ID)

  // Append the box element if it doesn't exist
  if (!box) {
    box = document.createElement('div')
    box.id = BOX_ELEM_ID
    box.classList.add('box-select-outline') // no way to enforce this in CSS?

    canvas.appendChild(box)
  }

  const minX = Math.min(startPoint.x, current.x)
  const maxX = Math.max(startPoint.x, current.x)
  const minY = Math.min(startPoint.y, current.y)
  const maxY = Math.max(startPoint.y, current.y)

  // Adjust width and x/y position of the box element based on mouse movement.
  box.style.transform = `translate(${minX}px, ${minY}px)`
  box.style.width = `${maxX - minX}px`
  box.style.height = `${maxY - minY}px`
}

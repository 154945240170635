import { useIsStaffUser } from 'lib/queries.user'

type Props = {
  children: React.ReactNode
}

export function AccessCheckViaStaff(props: Props) {
  const { children } = props
  const isStaff = useIsStaffUser()

  return isStaff ? <>{children}</> : null
}

import type { SxProps } from '@mui/material'

export const fmtRootSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}

export const mapAndPanelWrapSx: SxProps = {
  flexGrow: {
    md: 0,
  },
  height: {
    xs: '60%',
    md: '55%',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
}

export const panelOutletWrapSx: SxProps = {
  borderRightColor: 'divider',
  borderRightStyle: 'solid',
  borderRightWidth: 1,
  display: 'flex',
  flexDirection: 'column',
  flex: {
    xs: 1,
    md: 'unset',
  },
  height: {
    xs: '50%',
    md: '100%',
  },
  width: {
    xs: '100%',
    md: 600,
  },
}

export const panelContentSx: SxProps = {
  overflowY: 'auto',
  height: {
    md: '100%',
  },
  p: {
    xs: 1,
    sm: 2,
    md: 3,
  },
}

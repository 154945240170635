import localforage from 'localforage'

const LOCAL_FORAGE_APP_NAME = 'earthoptics'

export const LOCAL_STORAGE_CURRENT_USER_JSON_STR = 'currentUser'

export const soilCollectorStore = localforage.createInstance({
  name: LOCAL_FORAGE_APP_NAME,
  storeName: 'soil_collector',
  description: 'Soil collection application',
})

import type { UseQueryResult } from '@tanstack/react-query'
import type {
  ScanningRequirement,
  ScanningRequirementStatusEnum as SensorReqStatus,
} from 'lib/api/django/model'
import { getPrettyDate, sortArrOfObjects } from 'lib/utils'

import { GROUP_INTERP_KEY } from './collection-detail/sample-view/config.rx-samples'
import type { SoilCollection, SoilSample } from './types.ssa'

export function getSampleById(
  samples: SoilSample[],
  sampleId: string,
  useShortId?: boolean
): SoilSample | undefined {
  return samples.find(({ id, short_id }) => {
    return useShortId ? sampleId === short_id : id === sampleId
  })
}

// TODO: EPIC: uggggh why is backend now saying that dispatcher and team_contact are no longer
// nullable all of a sudden?
export function isAssignedToCollection(
  collection: SoilCollection,
  userId: string,
  checkDispatcher?: boolean
): boolean {
  const { team_contact, team_members, dispatcher } = collection

  return (
    userId === team_contact?.id ||
    (checkDispatcher && userId === dispatcher?.id) ||
    team_members.map((u) => u.id).includes(userId)
  )
}

export function getUniqueBagLabelInfoViaReqs(reqs: SoilCollection['requirements']): {
  marker_color: string
  bag_prefix: string
  groupInterpIndex: number
}[] {
  return reqs.map(({ sample_type }, i) => ({
    marker_color: sample_type.marker_color || '',
    bag_prefix: sample_type.bag_label_prefix || '',
    [GROUP_INTERP_KEY]: i + 1,
  }))
}

// TODO: EPIC: why not just use `getUniqueBagLabelInfoViaReqs`?
export function getBagLabelPrefixes(data: SoilSample[]): string[] {
  const uniqueSampleTypes = data.reduce((acc, curr) => {
    const bagLabelPrefix = curr.bag_prefix

    if (!acc.includes(bagLabelPrefix)) acc.push(bagLabelPrefix)

    return acc
  }, [] as string[])

  return uniqueSampleTypes
}

export function getSamplesGroupedByType(
  uniqueBagLabels: string[],
  data: SoilSample[]
): { [key: string]: SoilSample[] } {
  const samplesByType = uniqueBagLabels.reduce((acc, curr) => {
    acc[curr] = data.filter((sample) => sample.bag_prefix === curr)

    return acc
  }, {} as Record<string, SoilSample[]>)

  return samplesByType
}

export function getUseQueryStatusAndMsg(query: UseQueryResult): {
  message: string
  status: 'error' | 'success'
} {
  const { dataUpdatedAt, errorUpdatedAt, error } = query || {}

  let message = 'No refetches yet'
  let status: 'error' | 'success' = 'success'

  if (errorUpdatedAt) {
    const defaultErrMsg = 'Could not fetch updates from server'
    const errorText = error instanceof Error ? error.message : defaultErrMsg
    const prettyTime = getPrettyDate(new Date(errorUpdatedAt).toISOString(), undefined, false, true)

    message = `${prettyTime}: ${errorText}`
    status = 'error'
  } else if (dataUpdatedAt) {
    const prettyTime = getPrettyDate(new Date(dataUpdatedAt).toISOString(), undefined, false, true)

    message = `Last checked: ${prettyTime}`
  }

  return { message, status }
}

/**
 * Get the name of a lab from a list of labs based on its lab ID.
 *
 * @param lab_id Lab ID from the sample itself (newer version of API)
 * @param labs array of Lab objects (newer version of API)
 * @returns name of corresponding lab based on the given ID
 */
export function getLabNameFromLabId(
  lab_id?: string | null,
  labs: SoilCollection['labs'] = []
): string {
  const match = labs?.find(({ short_id }) => lab_id?.startsWith(short_id))

  return match?.name || 'N/A'
}

/**
 * Not sure the terminology here, just using "group" but if `bag_label` is `MD2.L3`, then it will
 * return all samples starting with `MD*.`. Ideally backend will provide some kind of "group"
 * concept for things like Meter Cores, which are piled up in one spot.
 *
 * @param bag_label `bag_label` from the sample, will be split at the period if it exists
 * @returns the bag label split on the period, e.g. `MD2` from `MD2.L3`
 */
export function getSampleGroupFromBagLabel(bag_label: string): string {
  return bag_label.split('.')[0]
}

// TODO: EPIC: de-dupe this if overlap with any messes in utils.rx-samples.ts
// TODO: EPIC: adapt if/when backend provides legit sample groups
/**
 * Again, not sure the terminology here. See `getSampleGroupFromBagLabel`
 */

/**
 * Get all the samples in the same psuedo-group as the one in question, unless the current one is
 * not part of a group itself based on the existence of a period in its bag label.
 *
 * @param currentSample the current ("active") sample in question
 * @param samples array of samples
 * @returns null or array of samples
 */
export function getOtherSamplesInSameGroupish(
  currentSample: SoilSample,
  samples: SoilSample[] = []
): SoilSample[] | null {
  // Very sketchy check, but the ones which have a period in them have typically been meter cores or
  // any cores with multiple layers. Note that this convention has fluctuated, however, as we
  // originally started with hyphens. There is technically no formal "group-ness" for meter cores,
  // and the user can scan a QR for whatever they want providing there is no prescribed lat or lon
  // present in the sample (which should hopefully be checked for in the consuming component or
  // utility using this function). BUT, in the UI we still want to show all the samples in the same
  // core, aka psuedo-group. The API response has not always been `0` for `sample_group`, however,
  // so the period is an alternative check to psuedo-grouping based on `core_num`.
  const hasLayersAndIsGroupLike = currentSample.bag_label.includes('.')

  if (!hasLayersAndIsGroupLike) {
    return null
  }

  const sampleGroup = getSampleGroupFromBagLabel(currentSample.bag_label)

  return samples.filter((s) => {
    const thisOne = getSampleGroupFromBagLabel(s.bag_label)
    const areFriends = thisOne === sampleGroup

    return areFriends && s.id !== currentSample.id
  })
}

/**
 * Get all the samples in the same `sample_group` as the one in question, unless it is not part of a
 * group itself.
 *
 * @param currentSample the current ("active") sample in question
 * @param samples array of samples
 * @returns null or array of samples
 */
export function getOtherSamplesInSameRealGroup(
  currentSample: SoilSample,
  samples: SoilSample[] = []
): SoilSample[] | null {
  // Backend sets the group to 0 unless it's an Rx sample (???)
  if (currentSample.sample_group === 0) {
    return null
  }

  return samples.filter((s) => {
    const areFriends = s.sample_group === currentSample.sample_group

    return areFriends && s.id !== currentSample.id
  })
}

/**
 * Get all the samples in the same `sample_group` or group-ish as the one in question, unless it is
 * not part of a group itself.
 *
 * @param currentSample the current ("active") sample in question
 * @param samples array of samples
 * @returns undefined or array of samples
 */
export function getOtherSamplesInSameRealGroupOrGroupish(
  currentSample: SoilSample,
  samples: SoilSample[] = []
): SoilSample[] | undefined {
  const otherSamplesInLegitGroup = getOtherSamplesInSameRealGroup(currentSample, samples)
  const samplesInSameGroupish = getOtherSamplesInSameGroupish(currentSample, samples)

  return otherSamplesInLegitGroup || samplesInSameGroupish?.sort(sortArrOfObjects('bag_label'))
}

/**
 * There is a growing list of other non-sensor requirements. This function filters out only the ones
 * we care about.
 *
 * @param scanningReqs - The scanning requirements to filter.
 * @returns An array of scanning requirements for actual sensors.
 */
export function getSensorReqsForActualSensors(
  scanningReqs: SoilCollection['scanning_requirements']
): ScanningRequirement[] {
  return scanningReqs.filter(({ file_type }) => {
    return file_type === 'DCP_FILE' || file_type === 'EMI_FILE' || file_type === 'GPR_FILE'
  })
}

/**
 * Get the cumulative status of all the sensor requirements. Returns null if no requirements are
 * passed or none of the other conditions is met, `REQUIRED` if at least one of the statuses is
 * `REQUIRED`, or `DONE` if any of the remaining `status`es are `DONE` or `CANCELED`.
 *
 * @param reqs array of collection sensor requirements
 * @returns "REQUIRED" | "DONE" | null
 */
export function getAllSensorReqsStatus(
  reqs?: ScanningRequirement[]
): Extract<SensorReqStatus, 'DONE' | 'REQUIRED'> | null {
  if (!reqs?.length) {
    return null
  }

  const actualSensorReqs = getSensorReqsForActualSensors(reqs)

  // At least one is in progress
  if (actualSensorReqs.some((r) => r.status === 'REQUIRED')) {
    return 'REQUIRED'
  }

  // At least one is done. Don't need to check all of them because we are basing the overall status
  // the same way we're checking for in-progress samples above. And we don't need `Optional` in the
  // mix, it's not relevant here.
  if (actualSensorReqs.some((r) => r.status === 'DONE')) {
    return 'DONE'
  }

  return null
}

export function getSampleShortIdFromQrCsvString(text: string): string {
  const [, , sampleId] = text.split(',')

  return sampleId
}

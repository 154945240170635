import { useEffect, useMemo } from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import type { CurrentUser, CurrentUserSecurityGroupsItem } from 'lib/api/django/model'
import { useCurrentUser } from 'lib/queries.user'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

export type NonHookFormProps = {
  value: CurrentUserSecurityGroupsItem[]
  onChange: (values: CurrentUserSecurityGroupsItem[]) => void
}

type Props = {
  defaultValue?: CurrentUserSecurityGroupsItem[]
  disabled?: boolean
  nonHookFormProps?: NonHookFormProps
  hookFormProps?: {
    control: Control<FieldsMgmtFormSchema>
    setValue: UseFormSetValue<FieldsMgmtFormSchema>
  }
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: ({ name }: CurrentUserSecurityGroupsItem) => name,
})

type DropdownProps = {
  isLoading: boolean
  value: CurrentUserSecurityGroupsItem[]
  onChange: (values: CurrentUserSecurityGroupsItem[]) => void
  disabled?: boolean
  currentUser?: CurrentUser
}

function Dropdown(props: DropdownProps) {
  const { value, onChange, currentUser, isLoading, disabled } = props

  // TODO: EPIC: check for access mgmt perms
  if (!currentUser) {
    return <div /> // preserve spacing, prevent layout jank, just in case
  }

  return (
    <Autocomplete
      disableClearable
      disableCloseOnSelect
      filterSelectedOptions
      multiple
      disabled={disabled || isLoading}
      filterOptions={filterOptions}
      getOptionLabel={({ name }) => name}
      id="field-access-autocomplete"
      isOptionEqualToValue={({ id }, val) => id === val.id}
      loading={isLoading} // TODO: EPIC: include field.isLoading?
      options={currentUser.security_groups}
      size="small"
      sx={{ gridColumn: '1/3' }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText="Manage access to this field"
          label="Select groups"
          onKeyDown={(evt) => {
            // TODO: EPIC: is it possible to prevent deletion of JUST the default one w/o jacking
            // up the accessibility?
            //
            // Prevent clearing the Default Chip, but also all its friends. So much for
            // accessibility in this one. But user can still click the X button on the Chip at
            // least.
            if (evt.key === 'Backspace' || evt.key === 'Delete') {
              evt.stopPropagation()
            }
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map(({ id, name }, index) => (
          <Chip
            {...getTagProps({ index })}
            key={id} // not sure why needed, getTagProps has `key`
            disabled={disabled || name === currentUser?.org.name || name === 'Default'}
            label={name}
            size="small"
          />
        ))
      }}
      onChange={(event, values) => onChange(values)}
    />
  )
}

/**
 * Security Groups dropdown
 *
 * @param props
 */
export function FieldAccessAutocomplete(props: Props) {
  const { data: currentUser, isLoading } = useCurrentUser()
  const currentUserOrgName = currentUser?.org.name
  const currentUserSecGroups = currentUser?.security_groups

  // TODO: EPIC: de-shakify
  // Memoizing prevents infinite re-renders in the useEffect below when the consumer is the
  // bulk-import non-hook-form.
  const defaultSecGroup = useMemo(() => {
    return (
      currentUserSecGroups?.filter((g) => g.name === currentUserOrgName || g.name === 'Default') ||
      []
    )
  }, [currentUserOrgName, currentUserSecGroups])

  const { hookFormProps, disabled, defaultValue = defaultSecGroup } = props
  const { nonHookFormProps } = props
  const { onChange: nonHookFormOnChange } = nonHookFormProps || {}
  const { setValue, control } = hookFormProps || {}

  useEffect(() => {
    if (setValue) {
      setValue('security_groups', defaultValue, { shouldDirty: true })
    }

    if (nonHookFormOnChange) {
      nonHookFormOnChange(defaultValue)
    }

    // KEEP AN EYE ON THIS. Really inconsistent results. Sometimes it overwrites the dropdown to the
    // initial state (which is just the "Default" for that Org in Draw mode, at the time of
    // writing).
  }, [defaultValue, nonHookFormOnChange, setValue])

  const commonProps = {
    currentUser,
    disabled,
    isLoading,
  }

  if (!hookFormProps && nonHookFormProps) {
    return (
      <Dropdown
        {...commonProps}
        value={nonHookFormProps.value}
        onChange={nonHookFormProps.onChange}
      />
    )
  }

  return (
    // TODO: EPIC: consider removing the 🤬 boundary from the `FormProvider` and refactor. It's long
    // past due! Also consider giving the "edit existing boundary" its own step, identical to
    // editing ACB. Does NOT need to be part of the main form, but we just need to make sure some
    // type of geometry exists when creating a NEW field.
    //
    // Decoupling the boundary from the form may also alleviate the bug where, after editing the
    // boundary, the user must make an arbitrary change in the form (e.g. Description) in order to
    // "dirty" the form and thus enable the submit button.
    <Controller
      control={control}
      name="security_groups"
      render={({ field: { onChange, value } }) => (
        <Dropdown {...commonProps} value={value} onChange={onChange} />
      )}
    />
  )
}

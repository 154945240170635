import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { COLLN_NAME_FORM_INPUT_NAME } from '.'

/**
 * Guts of the "create collection" form. Portable for use in modals or routes.
 */
export function CreateCollnFormContents() {
  return (
    <>
      <TextField
        fullWidth
        helperText='Optional. Default is "Field Name (Season YYYY)", e.g. "South 40 (Summer 2022)"'
        label="Collection name"
        name={COLLN_NAME_FORM_INPUT_NAME}
      />
      <Typography sx={{ mb: 2.5 }} variant="body2">
        NOTE: the collection's boundary will default to that of the field, when available. The
        boundary can be edited after creation, prior to collection submission.
      </Typography>
    </>
  )
}

import type { PatchedSoilSample } from 'lib/api/django/model'
import { soilCollectorStore } from 'lib/config'

import type { LocalCollnMutationVars, SoilCollection, SoilSample } from './types.ssa'

export async function fetchLocalCollns(): Promise<SoilCollection[]> {
  let collections: SoilCollection[] = []

  await soilCollectorStore.iterate<SoilCollection, void>((value) => {
    collections = [...collections, value]
  })

  return Promise.resolve(collections)
}

export async function fetchLocalCollnById(id: string): Promise<SoilCollection> {
  const matchedColln = await soilCollectorStore.getItem<SoilCollection>(id)

  if (!matchedColln) {
    return Promise.reject(new Error(`No local collection found with id ${id}`))
  }

  return Promise.resolve(matchedColln)
}

export async function patchLocalSample(
  collnShortId: string,
  sampleShortId: string,
  data: PatchedSoilSample
): Promise<SoilSample> {
  const matchedColln = await soilCollectorStore.getItem<SoilCollection>(collnShortId)

  if (!matchedColln) {
    return Promise.reject(new Error(`No collection found with id ${collnShortId} on device`))
  }

  // CRED: https://herewecode.io/blog/replace-item-array-javascript/
  const matchedIndex = matchedColln.samples.findIndex((sample) => sample.short_id === sampleShortId)

  if (matchedIndex === -1) {
    return Promise.reject(
      new Error(
        `No local sample found with id ${sampleShortId} in collection ${collnShortId} on this device. Are you on the correct field?`
      )
    )
  }

  const copy = matchedColln
  const matchedSample = matchedColln.samples[matchedIndex]

  copy.samples[matchedIndex] = { ...matchedSample, ...data }

  const payload: SoilCollection = { ...matchedColln, samples: copy.samples }

  try {
    await soilCollectorStore.setItem<SoilCollection>(collnShortId, payload)

    const resolved = await Promise.resolve(copy.samples[matchedIndex])

    return resolved
  } catch (e) {
    return Promise.reject(new Error('Could not update collection on device'))
  }
}

/**
 * Patch the localForage collection based on its id
 *
 * @param collnShortId Collection `short_id`
 * @param data Payload that will overwrite corresponding attributes
 * @param disable Couldn't find another way to prevent in unwanted scenarios, e.g. when the
 * collection doesn't exist on device.
 * @returns localForage `setItem` Promise
 */
export async function patchLocalColln(
  collnShortId: string,
  data: LocalCollnMutationVars['data'],
  disable?: boolean
): Promise<SoilCollection | null> {
  if (disable) {
    const resolved = await Promise.resolve(null)

    return resolved
  }

  const matchedColln = await soilCollectorStore.getItem<SoilCollection>(collnShortId)

  if (!matchedColln) {
    return Promise.reject(new Error(`No collection found with id ${collnShortId} on device`))
  }

  const payload = { ...matchedColln, ...data }

  return soilCollectorStore.setItem<SoilCollection>(collnShortId, payload as SoilCollection)
}

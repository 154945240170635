import Box from '@mui/material/Box'

type Props = {
  children: React.ReactNode
}

export function FormHeaderBtns(props: Props) {
  const { children } = props

  return (
    <Box alignItems="center" display="flex" gap={1.5} mb={3}>
      {children}
    </Box>
  )
}

import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useCanAccessBasedOnPerms } from 'lib/queries.user'

import { AFTER_FIELDWORK_STATUSES } from 'components/soil-sampling/config.ssa'
import type { SoilCollection } from 'components/soil-sampling/types.ssa'

import { ClearAcbBtn } from './ClearAcbBtn'
import { InitAcbDrawBtn } from './InitAcbDrawBtn'
import { SetAcbFromExistingBtn } from './SetAcbFromExistingBtn'

type Props = {
  collection: SoilCollection
  disabled?: boolean
}

export function AcbMgmtBtnGroup(props: Props) {
  const { collection, disabled } = props
  const { id: collnFullId, field, status, boundary } = collection
  const hasPerms = useCanAccessBasedOnPerms(['change_soilcollection'])
  const fieldGeomType = field.geometry.type
  const is2d = fieldGeomType === 'Polygon' || fieldGeomType === 'MultiPolygon'
  const tooLate = AFTER_FIELDWORK_STATUSES.includes(status)
  const fieldBoundary = is2d ? field.geometry : null
  const shouldDisable = tooLate || !!disabled || !hasPerms

  return (
    <Box display="flex" justifyContent="center">
      <ButtonGroup disabled={shouldDisable} size="small" variant="contained">
        <SetAcbFromExistingBtn
          collnFullOrShortId={collnFullId}
          fieldBoundary={fieldBoundary as GeoJSON.MultiPolygon | GeoJSON.Polygon | null}
        />
        <InitAcbDrawBtn />
        <ClearAcbBtn disabled={!boundary || shouldDisable} />
      </ButtonGroup>
    </Box>
  )
}

import type { SxProps } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { GridToolbarDensitySelector } from '@mui/x-data-grid-premium'

const buttonSx: SxProps = {
  fontSize: 0,
  minWidth: 0,
  span: {
    margin: 0,
  },
  svg: {
    height: 24,
    width: 24,
  },
}

export function TableRowDensityMenu() {
  return (
    <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title="Row height">
      <GridToolbarDensitySelector size="medium" slot="densitySelector" sx={buttonSx} />
    </Tooltip>
  )
}

import { useState } from 'react'
import type { Control, ControllerRenderProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

import type { FieldWriterSerializerGeoJSONStatusEnum } from './types.fields-mgmt-api'
import { FieldWriterSerializerGeoJSONStatuses } from './types.fields-mgmt-api'

type Props = {
  control?: Control<FieldsMgmtFormSchema>
  disabled?: boolean
}

type DropdownProps = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // so erratic, can't tell why, but it must be complicated!
  controlField?: ControllerRenderProps<FieldsMgmtFormSchema, 'status'>
  disabled?: boolean
  helperText?: string
  onChange?: (e: FieldWriterSerializerGeoJSONStatusEnum) => void
  required?: boolean
}

const { NOT_READY, READY_FOR_USE, ARCHIVED, UNKNOWN } = FieldWriterSerializerGeoJSONStatuses

const menuItemsConfig: {
  value: FieldWriterSerializerGeoJSONStatusEnum
  label: string
}[] = [
  { value: NOT_READY, label: 'Not ready' },
  { value: READY_FOR_USE, label: 'Ready for use' },
  { value: ARCHIVED, label: 'Archived' },
  { value: UNKNOWN, label: 'Unknown' },
]

export function FieldMgmtStatusDropdown(props: DropdownProps) {
  const { required, controlField = {}, onChange, disabled } = props
  const { helperText = 'Is the field ready for collections' } = props
  const [status, setStatus] = useState<FieldWriterSerializerGeoJSONStatusEnum>('NOT_READY')

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value as FieldWriterSerializerGeoJSONStatusEnum

    setStatus(value)

    if (onChange) onChange(value)
  }

  return (
    <TextField
      value={status}
      onChange={handleChange}
      {...controlField} // want control to override value and onChange, so put it after the spread
      select
      disabled={disabled}
      helperText={helperText}
      id="field-status-select"
      label="Status"
      required={required}
      size="small"
    >
      {menuItemsConfig.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

// react-hook-form Controller wrapper around the dropdown component
export function FieldStatusDropdownController(props: Props) {
  const { control, disabled } = props

  return (
    <Controller
      control={control}
      name="status"
      render={({ field }) => (
        <FieldMgmtStatusDropdown required controlField={field} disabled={disabled} />
      )}
    />
  )
}

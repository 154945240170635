import Box from '@mui/material/Box'
import type { SoilCollectionDetail } from 'lib/api/django/model'

import { FmtCollnDetailAccordionFooter } from 'components/fields/fields-mgmt/collections/FmtCollnDetailAccordionFooter'
import { LayersAvatarLinksInline } from 'components/fields/fields-mgmt/collections/LayersAvatarLinksInline'

import { SubmitCollnCreateLayersBtn } from './SubmitCollnCreateLayersBtn'

type Props = {
  collection: SoilCollectionDetail
}

export function CollnDetailLayersPanelContent(props: Props) {
  const { collection } = props
  const { id, status } = collection

  return (
    <>
      <Box mt={1}>
        <LayersAvatarLinksInline collection={collection} />
      </Box>
      <FmtCollnDetailAccordionFooter>
        <SubmitCollnCreateLayersBtn collnId={id} hasBeenSubmitted={status === 'SUBMITTED'} />
      </FmtCollnDetailAccordionFooter>
    </>
  )
}

export function getLocalhostness(): boolean {
  return (
    typeof window !== 'undefined' && ['localhost', '127.0.0.1'].includes(window.location.hostname)
  )
}

// Use for limiting logic to disable breadcrumb button for nested route, not '/'
export function pathCantGetMuchHigher(pathname: string, maxDepth = 0): boolean {
  if (pathname === '/') return true

  // CRED: https://stackoverflow.com/a/4009768/1048518
  const numberOfSlashes = (pathname.match(/\//g) || []).length

  return numberOfSlashes === 1 + maxDepth
}

// One path higher than the current
export function getUpOneLevelPath(pathname: string): string {
  const pathAsArray = pathname.split('/')

  pathAsArray.pop()

  return pathAsArray.join('/')
}

/**
 * Get a URI-encoded string of the current URL's `pathname` and `search`.
 *
 * @returns the current URL's `pathname` and `search`, encoded
 */
export function getEncodedCurrentPathnameAndSearch(): string {
  const currentUrl = window.location.pathname
  const currentSearch = window.location.search // in case there are query params

  return encodeURIComponent(currentUrl + currentSearch)
}

import type { GridDensity } from '@mui/x-data-grid-premium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

// For some reason, row density is not part of the MUI's initial state, but we want to include it
type GridStateWithDensity = GridInitialStatePremium & {
  density: GridDensity
}

/**
 * Grid state of MUI Data Grid tables. State includes things like filters, sorting, column etc.
 */
type State = {
  /** If they wanna bail, let 'em bail. */
  userWantsPersistence: boolean
  /** States (plus row density) of individual tables, using `tableSlug` as the identifier. */
  tableStates: {
    [key: string]: GridStateWithDensity | null
  }
}

type Action = {
  actions: {
    setPersistence: (userWantsPersistence: boolean) => void
    setState: (slug: keyof State['tableStates'], newState: GridStateWithDensity) => void
  }
}

export const useTableStatesStore = create<State & Action>()(
  persist(
    (set) => ({
      userWantsPersistence: true,
      tableStates: {},
      actions: {
        setPersistence: (userWantsPersistence) => set(() => ({ userWantsPersistence })),
        setState: (slug, newState) =>
          set((store) => ({
            tableStates: {
              ...store.tableStates,
              [slug]: newState,
            },
          })),
      },
    }),
    {
      name: 'table-states',
      // Only preserve the state, not the action
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => key !== 'actions')),
    }
  )
)

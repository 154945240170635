import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

type PropKey = 'lat' | 'lon'

type Props = {
  availableCols: string[]
  disabled: boolean
  propKey: PropKey
  onChange: (propKey: PropKey, value: string) => void
}

const config = {
  lat: {
    name: 'Latitude column',
    label: 'latitude',
    id: 'lat',
  },
  lon: {
    name: 'Longitude column',
    label: 'longitude',
    id: 'lon',
  },
}

export function CrosswalkLatLonDropdown(props: Props) {
  const { availableCols, disabled, propKey } = props
  const { name, label, id } = config[propKey]
  const { onChange } = props
  const [localState, setLocalState] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalState(e.target.value)
    onChange(propKey, e.target.value)
  }

  return (
    <TextField
      fullWidth
      select
      aria-label={label}
      disabled={disabled || !availableCols.length}
      id={id}
      label={name}
      required={!disabled}
      size="small"
      sx={{ mb: 2 }}
      value={localState}
      onChange={handleChange}
    >
      {availableCols.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  )
}

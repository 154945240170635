import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import {
  FILENAME_COLUMN,
  PRETTY_FILENAME_COL,
} from 'components/fields/fields-mgmt/column-mapping/config.col-mapping'
import type { ColumnConfig, MappableColumn } from 'components/fields/types.fields-mgmt'

export type Props = ColumnConfig & {
  availableCols: string[]
  colMappings: MappableColumn
  onChange: (key: keyof MappableColumn, value: string) => void
}

export function CrosswalkColumnDropdown(props: Props) {
  const { name, helperText, id, availableCols, required } = props
  const { colMappings, onChange } = props
  const value = colMappings[id]
  const initialValue = Array.isArray(value) || !value ? '' : value
  const [localState, setLocalState] = useState<string>(initialValue)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalState(e.target.value)
    onChange(id, e.target.value)
  }

  return (
    <TextField
      select
      disabled={!availableCols.length}
      helperText={helperText}
      id={id}
      label={name}
      required={required}
      size="small"
      value={localState}
      onChange={handleChange}
    >
      {availableCols.map((option) => (
        <MenuItem key={option} value={option}>
          {option === FILENAME_COLUMN ? PRETTY_FILENAME_COL : option}
        </MenuItem>
      ))}
    </TextField>
  )
}

import type { RouteConfigWithHandle } from 'lib/types'

import { PrivateRouteViaHandle } from 'components/reusable/access-gates/PrivateRouteViaHandle'

import { segments, wildcards } from './routes'

export const routesConfig: RouteConfigWithHandle[] = [
  {
    path: `/${segments.base}`,
    element: <PrivateRouteViaHandle />,
    handle: {
      allowedGroups: ['staff_user'],
    },
    children: [
      {
        path: `/${segments.base}`,
        lazy: () => import('./landing/UploadsTable'),
        children: [
          {
            path: `${segments.detail}/${wildcards.batchId}`,
            lazy: () => import('./detail/UploadDetail'),
          },
        ],
      },
    ],
  },
]

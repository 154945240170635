/**
 * User-related API queries
 */
import type { UseQueryResult } from '@tanstack/react-query'
import type { Permission } from 'lib/api/types.api'
import { djangoGroups } from 'lib/api/types.api'
import { clearUserAndAuthStuff } from 'lib/config/api'
import { LOCAL_STORAGE_CURRENT_USER_JSON_STR } from 'lib/config/config.localForage'

import { useAuth } from 'components/AuthProvider'

import type { CurrentUser, CurrentUserSecurityGroupsItem } from './api/django/model'
import { useV1AuthCurrentUserRetrieve } from './api/django/v1/v1'
import type { Handle } from './types'
import { appendPseudoGroupsToUser, checkUserAccessByGroup, checkUserAccessByPerms } from './utils'

function setLocalCurrentUserQuery(data: CurrentUser): void {
  window.localStorage.setItem(LOCAL_STORAGE_CURRENT_USER_JSON_STR, JSON.stringify(data))
}

// Important thing! Need the CurrentUser query from local storage otherwise the check will spin
// forever if user is offline. Obviously still need to be online at SOME point, but this covers
// everything after that. On 08/10/2022 gave up on localforage in favor of plain old localStorage
// with parsed JSON. The async of localforage was annoying and overkill for this use case.
export function getLocalCurrentUserQuery(): ReturnType<JSON['parse']> | null {
  const raw = window.localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_JSON_STR)

  if (raw) return JSON.parse(raw)

  return null
}

// TODO: switch to Orval
export const useCurrentUser = (): UseQueryResult<CurrentUser, unknown> => {
  return useV1AuthCurrentUserRetrieve({
    query: {
      // Failures seem to always be due to not being logged in. Decided to stop retrying because of
      // this. Very annoying UX to sit and wait for it- we KNOW it's going to fail if they aren't
      // logged in. If the failure is due to being offline, the user won't be able to do much
      // anyway. But because we cache the data in localForage on successful login, the "current user
      // info" query should be immune to that if the user has logged into the same browser within
      // the expiration period for refresh and access tokens (at time of writing, 30 and 14 days,
      // respectively). Again, they won't be able to do much else, but retrying this query has not
      // been useful to date.
      retry: false,
      initialData: getLocalCurrentUserQuery() || undefined,
      onSuccess: (data: CurrentUser) => setLocalCurrentUserQuery(data),
      refetchOnWindowFocus: false,
      select: appendPseudoGroupsToUser,
    },
  })
}

export const useIsStaffUser = (): boolean => {
  return useCurrentUser().data?.is_staff === true
}

export const useIsSuperUser = (): boolean => {
  return useCurrentUser().data?.is_superuser === true
}

/**
 * Clear the auth context and the auth-related stuff in local storage.
 */
export const useClearUserAndAuthStuff = (): void => {
  const auth = useAuth()

  auth.logout()

  clearUserAndAuthStuff()
}

export function useCurrentUserPrettyGroupNames(): string[] | null {
  const { data, isLoading, isError } = useCurrentUser()

  if (isLoading || isError || !data) return null

  const groupNames = data.groups
    .filter(({ name }) => djangoGroups[name])
    .map(({ name }) => djangoGroups[name]?.name)

  return groupNames
}

export function useCanAccessBasedOnGroups(allowedGroups: Handle['allowedGroups']): boolean {
  const { data } = useCurrentUser()

  return checkUserAccessByGroup(allowedGroups, data?.groups)
}

// TODO: EPIC: de-shakify
/**
 * Prevent user from locking themselves out of their own Field due to a lack of `security_groups`.
 *
 * @returns array of `security_groups` and `isLoading` status.
 */
export function useDefaultSecGroup(): {
  groups: CurrentUserSecurityGroupsItem[]
  isLoading: boolean
} {
  const { data, isLoading } = useCurrentUser()

  const groups =
    data?.security_groups.filter(({ name }) => {
      return name === data.org.name || name === 'Default'
    }) || []

  return { groups, isLoading }
}

export function useCanAccessBasedOnPerms(
  permsToCheck: Permission[],
  mustHaveAll?: boolean
): boolean {
  const { data } = useCurrentUser()

  return checkUserAccessByPerms(permsToCheck, data?.permissions, mustHaveAll)
}

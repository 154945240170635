import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { soilCollectorStore } from 'lib/config'

import * as localAPI from './api.soil-sampling'
import { localQueryKeys, MUTATION_KEYS } from './config.ssa'
import { soilCollectorToasts as toasts } from './config.toasts.soilcollector'
import * as remoteQueries from './queries.ssa-remote'
import type {
  CreateLocalCollnMutationResult,
  LocalCollnMutationResult,
  LocalSampleMutationResult,
  SoilCollection,
} from './types.ssa'

/**
 * This is the primary hook for mutating a collection on the device. The options make sense for the
 * main use cases, and overrides are provided for the other scenarios, namely the "mini-sync".
 *
 * @param skipRemoteMutate `true` prevents sending the PATCH to the backend. The main use case is to
 * avoid that default behavior when the change came FROM the backend in the first place,
 * specifically in the "mini-sync" overwrite of safe collection properties.
 * @param collnIsNotOnDevice `true` prevents attempting to PATCH the local collection when it
 * doesn't exist.
 * @param patchRemoteStatus `true` forces the `status` to be in remote payload. The use case is we
 * want to deliberately set it to `SUBMITTED`, while backend automates many of the others, e.g.
 * `STARTED`, so no need to include that in the PATCH.
 * @returns `useMutation` instance with callback methods that apply to all scenarios, except for
 * those that need to be overridden by the arguments provided.
 */
export function useLocalCollnMutation(
  skipRemoteMutate?: boolean,
  collnIsNotOnDevice?: boolean,
  patchRemoteStatus?: boolean
): LocalCollnMutationResult {
  const rqc = useQueryClient()
  const remoteMutation = remoteQueries.useRemoteCollnMutation()

  return useMutation({
    mutationKey: MUTATION_KEYS.localColln,
    mutationFn: ({ id: collectionShortId, data }) => {
      return localAPI.patchLocalColln(collectionShortId, data, collnIsNotOnDevice)
    },
    networkMode: 'always', // ignore connectivity, always run it
    onSuccess: (collection, vars) => {
      if (skipRemoteMutate) {
        return rqc.invalidateQueries(localQueryKeys.collnDetail(vars.id))
      }

      const { data } = vars
      const { tracking_numbers, team_contact, field_notes, status } = data
      const { dispatcher, boundary } = data

      let teamMembers = {}

      // Remote only needs IDs
      if (data.team_members) {
        teamMembers = data.team_members.map(({ id }) => id)
      }

      remoteMutation.mutate({
        id: vars.id,
        data: {
          field_notes, // always include to make it nullable
          ...(boundary ? { boundary } : {}),
          ...(tracking_numbers ? { tracking_numbers } : {}),
          ...(status && patchRemoteStatus ? { status } : {}), // critical!
          ...(team_contact ? { team_contact: team_contact.id } : {}),
          ...(dispatcher ? { dispatcher: dispatcher.id } : {}),
          ...teamMembers,
        },
      })

      return rqc.invalidateQueries(localQueryKeys.collnDetail(vars.id))
    },
  })
}

export function useCreateLocalCollnMutation(): CreateLocalCollnMutationResult {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, data }) => soilCollectorStore.setItem<SoilCollection>(id, data),
    networkMode: 'always', // ignore connectivity, always run it
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries(localQueryKeys.collnDetail(vars.id))
    },
  })
}

export function useLocalSampleMutation(
  doNotMutateRemote?: boolean,
  successToastContent?: React.ReactNode
): LocalSampleMutationResult {
  const remoteMutation = remoteQueries.useRemoteSampleMutation()
  const rqc = useQueryClient()

  return useMutation({
    mutationKey: MUTATION_KEYS.localSample,
    mutationFn: ({ collnShortId, sampleShortId, data }) => {
      return localAPI.patchLocalSample(collnShortId, sampleShortId, data)
    },
    networkMode: 'always', // ignore connectivity, always run it
    // Will only reach `onSuccess` if the sample actually exists in the collection. It is not
    // unreasonable to expect that, when dealing with a handful of labels in the truck, someone
    // could grab the wrong bag or label for the current Field. In that case we would NOT want to do
    // anything to the sample or collection.
    onSuccess: (sample, { sampleShortId, data: samplePayload }) => {
      const collnShortId = sample.collection_id.split('-')[0]

      if (successToastContent) {
        toast.success(successToastContent, { toastId: sampleShortId })
      }

      rqc.invalidateQueries(localQueryKeys.collnDetail(collnShortId))

      if (!doNotMutateRemote && !remoteMutation.isLoading) {
        remoteMutation.mutate({ id: sampleShortId, data: samplePayload })
      }
    },
    // Known error cause: bag was scanned for the wrong collection
    onError: (error: Error, { sampleShortId }) => {
      toast.error(toasts.localSampleMutationErr(error.message), {
        toastId: sampleShortId,
        autoClose: false,
        position: 'bottom-left',
      })
    },
  })
}

import { useState } from 'react'
import type { SxProps } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { FILENAME_COLUMN, PRETTY_FILENAME_COL } from './config.col-mapping'

type Props = {
  availableCols: string[]
  onSelect: (colNames: string[]) => void
}

const rootSx: SxProps = {
  height: '100%',
  overflowY: 'auto',
}

export function FieldsMgmtColMapMetadata(props: Props) {
  const { availableCols, onSelect } = props
  const [keepers, setKeepers] = useState<string[]>([FILENAME_COLUMN])

  const handleToggle = (attrib: string) => {
    const shouldKeep = !keepers.includes(attrib)

    let colsToKeep: string[] = []

    if (shouldKeep) colsToKeep = [...keepers, attrib]
    else colsToKeep = keepers.filter((c) => c !== attrib)

    setKeepers(colsToKeep)
    onSelect(colsToKeep)
  }

  return (
    <List dense sx={rootSx}>
      {availableCols.map((col) => {
        const isFilenameCol = col === FILENAME_COLUMN
        const text = isFilenameCol ? PRETTY_FILENAME_COL : col
        const labelId = `checkbox-list-label-${col.replace(/ /g, '-').toLowerCase()}`

        return (
          <ListItem key={col} disablePadding>
            <ListItemButton role={undefined} onClick={() => handleToggle(col)}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <Checkbox
                  // Always keep filename column checked and disabled
                  disableRipple
                  checked={keepers.includes(col) || isFilenameCol}
                  disabled={isFilenameCol}
                  edge="start"
                  inputProps={{ 'aria-labelledby': labelId }}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={text} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

/**
 * This file is the connection between Orval and our Axios Django instance. It will be used in all
 * the queries generated by Orval.
 */
import type { AxiosError, AxiosRequestConfig } from 'axios'
import { axiosInstance } from 'lib/config'

const FIVE_MINUTES = 300000

// TODO: what's the point of `config` AND `options` if they're both spread? A second `options`
// argument adds more options to each generated query.
export function djangoAxiosInstance<T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> {
  const instance = axiosInstance({
    timeout: FIVE_MINUTES, // yes this is insane, but we don't have paging set up yet 🤷
    ...config,
    ...options,
  }).then(({ data }) => data)

  return instance
}

// In some cases with react-query and swr you want to be able to override the return error type so
// you can also do it here like this:
export type ErrorType<Err> = AxiosError<Err>
// Don't use `Error` 👆 as generic

// NOTE: this ☝️ is not super useful since it doesn't contain the second argument of the generic,
// which allows the shape of data to be set in the request config. Seems logical to just use
// AxiosError instead...

import { toast } from 'react-toastify'
import type { ErrorType } from 'lib/api/django-axios-instance'

/**
 * Default handler for collection mutations (POST and PATCH)
 *
 * @param error Axios error object
 * @param isEditing Is the form in `edit` mode, as opposed to `create`
 * @param contactName Name of Contact that could not be updated
 */
export function defaultMutationErrHandler(
  error: ErrorType<unknown>,
  isEditing: boolean,
  contactName: string
): void {
  const verb = isEditing ? 'update' : 'create'
  const errMsg = `Could not ${verb} ${contactName}: `

  toast.error(`${errMsg}${error.message}`)
}

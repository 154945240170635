import type { Expression, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl'

// Field boundary and point color
export const EXISTING_SYMB_COLOR = '#29c326'

// Selected Field boundary or point color
export const SELECTED_FEATURE_COLOR = 'cyan'

// Imported field boundary or point color in FMT
export const IMPORTED_SYMB_COLOR = '#f122cb'

// The ACB outline color in FMT, SC, etc.
export const COLLN_BNDRY_COLOR = 'gold'

export const selectedFieldOutlinePaint: LinePaint = {
  'line-color': EXISTING_SYMB_COLOR,
  'line-width': 3.5,
}

// CRED: multi-geom filters: https://github.com/mapbox/mapbox-gl-js/issues/6508
export const POINT_FILTER: Expression = [
  'match',
  ['geometry-type'],
  ['Point', 'MultiPoint'],
  true,
  false,
]

export const LINE_FILTER: Expression = [
  'match',
  ['geometry-type'],
  ['LineString', 'MultiLineString'],
  true,
  false,
]

// Note that Multi-anything does NOT seem to work for filters in data-driven expressions such as
// `line-color`. Fought it for awhile but couldn't get it to work, found some Github issues that
// seemed related. See docs:
// https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#geometry-type
export const POLY_OR_MULTIPOLY_FILTER: Expression = [
  'match',
  ['geometry-type'],
  ['Polygon', 'MultiPolygon'],
  true,
  false,
]

/**
 * Can be used for any geometry. Font size will scale as the map zooms in/out.
 */
export const labelsLayoutCommonToAllGeoms: SymbolLayout = {
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-justify': 'auto',
  'text-size': [
    'interpolate',
    ['exponential', 0.5], // set the exponential rate of change to 0.5
    ['zoom'], // the thing to base the change upon
    7, // when zoom is this value
    11, // this is the text-size
    9, // and it will continue to change until this zoom level
    13, // at which point it has this value
    14, // and finally, when zoom is this value or higher
    15, // ...value is this
  ],
}

/**
 * Includes a few Layout properties that look better for point labels
 */
export const pointsLabelsLayout: SymbolLayout = {
  ...labelsLayoutCommonToAllGeoms,
  'text-anchor': 'bottom-right',
  'text-offset': [0.25, -0.15],
  'text-radial-offset': 0.85,
  'text-variable-anchor': [
    'bottom-left',
    'top-left',
    'bottom-right',
    'top-right',
  ],
}

/**
 * Halos are a cheap way to make the text stand out against any basemap.
 */
export const pointsLabelsPaint: SymbolPaint = {
  'text-color': '#fff',
  'text-halo-color': '#111',
  'text-halo-width': 1,
}

import { createContext, useContext, useRef } from 'react'
import { getLocalhostness } from 'lib/utils'
import type { Visibility } from 'mapbox-gl'
import { createStore, useStore } from 'zustand'
import { persist } from 'zustand/middleware'

import { Baselayer } from 'components/reusable/maps/config'

import type {
  MapLayersContextStateAndActions,
  MapLayersProviderProps,
} from './types.map-layers-context'

type MapLayersStore = ReturnType<typeof createMapLayersStore>

const IS_LOCAL_HOST = getLocalhostness() // save a little bandwidth (and distractions). See below.

const createMapLayersStore = (settings: MapLayersProviderProps) => {
  const { uniqueMapKey, initialIdsOfHiddenOverlays = [] } = settings

  // We need to use `createStore` here instead of `create` because we're passing in defaults. See
  // the docs for the example this is based on:
  // https://docs.pmnd.rs/zustand/guides/initialize-state-with-props
  return createStore<MapLayersContextStateAndActions>()(
    persist(
      (set) => ({
        baselayer: IS_LOCAL_HOST ? Baselayer.Dark : Baselayer.Satellite, // embrace the darkness 🌚
        showTerrain: false,
        idsOfHiddenOverlays: {
          [uniqueMapKey]: initialIdsOfHiddenOverlays,
        },
        actions: {
          setShowTerrain: (showTerrain) => set(() => ({ showTerrain })),
          setBaselayer: (baselayer) => set(() => ({ baselayer })),
          hideOverlayLayers: (layerIds) => {
            return set(({ idsOfHiddenOverlays }) => ({
              idsOfHiddenOverlays: {
                ...idsOfHiddenOverlays,
                [uniqueMapKey]: [...(idsOfHiddenOverlays[uniqueMapKey] || []), ...layerIds],
              },
            }))
          },
          showOverlayLayers: (layerIds) => {
            return set(({ idsOfHiddenOverlays }) => ({
              idsOfHiddenOverlays: {
                ...idsOfHiddenOverlays,
                [uniqueMapKey]: idsOfHiddenOverlays[uniqueMapKey]?.filter(
                  (id) => !layerIds.includes(id)
                ),
              },
            }))
          },
        },
      }),
      {
        name: 'maps',
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => key !== 'actions')),
      }
    )
  )
}

const StoreContext = createContext<MapLayersStore | null>(null)

export function MapLayersProvider({ children, ...props }: MapLayersProviderProps) {
  const storeRef = useRef<MapLayersStore>()

  if (!storeRef.current) {
    storeRef.current = createMapLayersStore(props)
  }

  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>
}

export function useMapLayersContext<T>(selector: (state: MapLayersContextStateAndActions) => T): T {
  const store = useContext(StoreContext)

  if (!store) {
    throw new Error('Missing StoreContext.Provider in the tree')
  }

  return useStore(store, selector)
}

/**
 * Get visibility of an individual layer by its ID from the store based on the unique map key.
 *
 * @param uniqueMapKey unique map key
 * @param layerId ID of layer to check
 * @returns `visible` if layer is visible, `none` if not
 */
export function useLayerVisibilityFromStore(uniqueMapKey: string, layerId: string): Visibility {
  const idsOfHiddenOverlays = useMapLayersContext(
    (store) => store.idsOfHiddenOverlays[uniqueMapKey]
  )

  return !idsOfHiddenOverlays?.includes(layerId) ? 'visible' : 'none'
}

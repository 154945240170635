import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import {
  getV1SoilSamplingCollectionsFilesListQueryKey as getCollnFilesQueryKey,
  useV1SoilSamplingCollectionsFilesDestroy,
} from 'lib/api/django/v1/v1'
import { isPromiseFulfilled, isPromiseRejected } from 'lib/utils'

import { ConfMessage, ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { ButtonAndModal } from 'components/reusable/buttons/ButtonAndModal'

import { useEditCollnFilesStore } from './store.colln-files'

type Props = {
  disabled: boolean
  collnFullId: string
  fileIDs: string[]
  showBtnText?: boolean
}

const Disclaimer = (
  <ConfMessage
    severity="warning"
    text="You cannot undo this operation. Are you sure you wish to proceed?"
    title="You are about to delete files from this collection"
    variant="standard"
  />
)

export function DeleteCollnFileBtnModal(props: Props) {
  const { fileIDs, disabled, showBtnText, collnFullId } = props
  const count = fileIDs.length
  const { mutateAsync } = useV1SoilSamplingCollectionsFilesDestroy()
  const rqc = useQueryClient()
  const isEditing = useEditCollnFilesStore((state) => state.isEditingFiles)
  const shouldDisableBtn = !fileIDs.length || (disabled && !isEditing)

  function handleSubmit() {
    const promises: Promise<void>[] = []

    async function deleteFiles() {
      fileIDs.forEach((id) => {
        const mutation = mutateAsync({
          collectionId: collnFullId,
          fileId: id,
        })

        promises.push(mutation)
      })

      await Promise.allSettled(promises).then((settledResult) => {
        const successes = settledResult.filter(isPromiseFulfilled)
        const failures = settledResult.filter(isPromiseRejected)

        if (successes.length) {
          toast.success(
            <ToastContentWithTitle
              content="Files were removed from the collection"
              title={`${successes.length} file(s) deleted`}
            />
          )
        }

        if (failures.length) {
          toast.success(
            <ToastContentWithTitle
              content="File(s) could not be removed from the collection"
              title={`Problems deleting ${failures.length} file(s)`}
            />
          )
        }

        if (collnFullId) {
          rqc.invalidateQueries(getCollnFilesQueryKey(collnFullId))
        }
      })
    }

    deleteFiles()
  }

  return (
    <ButtonAndModal
      buttonProps={
        (showBtnText && {
          size: 'small',
          children: 'delete',
          startIcon: <FaTrash />,
          disabled: shouldDisableBtn,
          variant: 'contained',
        }) ||
        undefined
      }
      iconButtonProps={
        (!showBtnText && {
          'aria-label': 'delete',
          size: 'small',
          children: <FaTrash />,
          disabled: shouldDisableBtn,
        }) ||
        undefined
      }
      modalProps={{
        elemId: 'delete-file',
        onSubmit: handleSubmit,
        title: `Deleting ${count} file${count > 1 ? '(s)' : ''}`,
        submitBtnText: 'delete file(s)',
        content: Disclaimer,
      }}
    />
  )
}

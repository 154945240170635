import Typography from '@mui/material/Typography'

type Props = {
  /** Likely `<li>` elements */
  children: React.ReactNode
}

export function FmtCollnDetailPanelInstrs(props: Props) {
  const { children } = props

  return (
    <Typography component="ul" mb={2} mt={-1} pl="20px" variant="caption">
      {children}
    </Typography>
  )
}

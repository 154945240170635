// TEMPERATURE \\
export function kelvinToFahrenheit(valNum: number): number {
  return Math.round((valNum - 273.15) * 1.8 + 32)
}

// DISTANCE \\
export function kilometersToMiles(km: number): number {
  return 0.621371 * km
}

export function milesToFeet(miles: number): number {
  return miles * 5280
}

export function metersToFeet(meters: number): number {
  return meters * 3.28084
}

// AREA \\
export function squareMetersToAcres(squareMeters: number, doNotRoundToInt?: boolean): number {
  const acres = squareMeters / 4046.85642

  return doNotRoundToInt ? acres : Math.round(acres)
}

export function squareMetersToSquareMiles(squareMeters: number): number {
  return squareMeters * 0.00000038610215855
}

export function squareMeterToSquareFeet(squareMeters: number): number {
  return squareMeters * 10.7639
}

export function squareMetersToSquareKilometers(squareMeters: number): number {
  return squareMeters / 1000000
}

import type { Resolver, ValidationMode } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import type { CurrentUserSecurityGroupsItem } from 'lib/api/django/model'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'
import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'

import { FIELD_MGMT_FORM_DEFAULTS } from './config.fields-mgmt-form'
import type { AllFieldAttribs } from './types.fields-mgmt-api'

export function useFieldMgmtHookForm(
  defaultValues: FieldsMgmtFormSchema,
  resolver?: Resolver<FieldsMgmtFormSchema>
) {
  return useForm<FieldsMgmtFormSchema>({
    mode: 'onTouched' as keyof ValidationMode,
    resolver,
    defaultValues,
  })
}

export function showSuccessToast(
  pastTenseVerb: string,
  name: string,
  extraText?: string,
  onClick?: () => void
) {
  toast.success(<ToastContentWithTitle content={extraText} title={`${name} ${pastTenseVerb}`} />, {
    onClick,
  })
}

/**
 * Simple helper to get form-friendly defaults for existing or imported Fields.
 *
 * @param geometry Field's Geometry
 * @param properties Field's `GeoJSON.properties`
 * @param defaultSecGroups
 * @returns Default values adhering to form's schema
 */
export function getFormDefaults(
  geometry?: GeoJSON.Geometry,
  properties?: AllFieldAttribs,
  defaultSecGroups?: CurrentUserSecurityGroupsItem[]
): FieldsMgmtFormSchema {
  if (!properties) {
    return {
      ...FIELD_MGMT_FORM_DEFAULTS,
      // TODO: EPIC: if backend doesn't provide the solution, add unit tests to
      // cover the weak check for "default" group.
      security_groups: defaultSecGroups || FIELD_MGMT_FORM_DEFAULTS.security_groups,
      org: { name: '', id: '' },
    }
  }

  return {
    ...properties,
    // Prevent the form from freaking out about `null` inputs by assigning
    // values to anything with a potential for `null`.
    requested_acres: properties.acres || 0,
    producer_field_name: properties.producer_field_name || '',
    description: properties.description || '',
    external_id: properties.external_id || '',
    address: properties.address || '',
    farm: properties.farm || '',
    security_groups: properties.security_groups || [],
  }
}

import Box from '@mui/material/Box'

type Props = {
  children: React.ReactNode
}

export function FmtCollnDetailAccordionFooter(props: Props) {
  const { children } = props

  return (
    <Box display="flex" gap={1} justifyContent="center">
      {children}
    </Box>
  )
}

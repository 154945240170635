import Typography from '@mui/material/Typography'

import { routes } from 'components/soil-sampling/routes'

type SuccessToastContentProps = {
  collnId: string
  filename: string
  closeToast?: () => void
}

export function SamplePlanSuccessToastContent(props: SuccessToastContentProps) {
  const { collnId, closeToast, filename } = props
  const shortId = collnId.split('-')[0]

  return (
    <Typography
      color="#fff"
      component="a"
      display="block"
      href={`${routes.collectionsAbsolute}/${shortId}`}
      sx={{ textDecoration: 'none' }}
      target="_blank"
      onClick={closeToast}
    >
      <Typography component="span" display="block" variant="h6">
        Sampling plan uploaded
      </Typography>
      <Typography component="span">
        <code>{filename}</code> was used to create prescribed samples. Click to view in
        SoilCollector.
      </Typography>
    </Typography>
  )
}

import { useParams, useRoutes } from 'react-router-dom'
import { useV1ContactsRetrieve, useV1SoilSamplingCollectionsRetrieve } from 'lib/api/django/v1/v1'

import { EditCollnNameBtnAndModal } from 'components/fields/fields-mgmt/collections/EditCollnNameBtnAndModal'
import { fieldMgmtIcons } from 'components/fields/fields-mgmt/config.fields-mgmt-icons'
import { useV1FieldsRetrieve } from 'components/fields/fields-mgmt/queries.fields-mgmt.gets'
import { useImportedFieldFromRoute } from 'components/fields/hooks.fields-mgmt-map'
import type { FmtRouteParams } from 'components/fields/routes'
import { segments, wildcards } from 'components/fields/routes'

import { FieldsMgmtHeading } from './FieldsMgmtHeading'

type Props = {
  prefix?: string
  icon?: React.ReactNode
}

function FieldDetailHeading(props: Props) {
  const { prefix, icon = fieldMgmtIcons.allFields } = props
  const { fieldId = '' } = useParams<FmtRouteParams>()
  const { data } = useV1FieldsRetrieve(fieldId)
  const text = (prefix || '') + (data?.properties.name || '')

  return <FieldsMgmtHeading icon={icon} text={text} />
}

function ImportedFieldDetailHeading() {
  const matched = useImportedFieldFromRoute()
  const text = `Imported Field - ${matched?.properties.name || ''}`

  return <FieldsMgmtHeading icon={fieldMgmtIcons.import} text={text} />
}

function CollnDetailHeading(props: Props) {
  const { icon = fieldMgmtIcons.collections, prefix = '' } = props
  const { collnId = '' } = useParams<FmtRouteParams>()
  const { data, isLoading } = useV1SoilSamplingCollectionsRetrieve(collnId)
  const text = `${prefix}${data?.name}` || ''

  return (
    <FieldsMgmtHeading
      icon={icon}
      iconBtn={isLoading ? null : <EditCollnNameBtnAndModal collection={data} />}
      text={text}
    />
  )
}

function ContactDetailHeading() {
  const { contactId = '' } = useParams<FmtRouteParams>()
  const { data } = useV1ContactsRetrieve(contactId)
  const text = data?.name ?? ''

  return <FieldsMgmtHeading icon={fieldMgmtIcons.allContacts} text={text} />
}

export function FieldsMgmtHeadings() {
  const routes = useRoutes([
    {
      path: segments.fields,
      element: <FieldsMgmtHeading icon={fieldMgmtIcons.allFields} text="Fields" />,
    },
    {
      path: `${segments.fields}/${wildcards.fieldId}`,
      element: <FieldDetailHeading />,
    },
    {
      path: [segments.fields, wildcards.fieldId, segments.collnsRelative].join('/'),
      element: <FieldDetailHeading icon={fieldMgmtIcons.collections} prefix="Collections - " />,
    },
    {
      path: [
        segments.fields,
        wildcards.fieldId,
        segments.collnsRelative,
        wildcards.collnId,
      ].join('/'),
      element: <CollnDetailHeading />,
    },
    {
      path: [
        segments.fields,
        wildcards.fieldId,
        segments.collnsRelative,
        wildcards.collnId,
        segments.collnFilesRelative,
      ].join('/'),
      element: <CollnDetailHeading icon={fieldMgmtIcons.collnFiles} prefix="Files - " />,
    },
    {
      path: [
        segments.fields,
        wildcards.fieldId,
        segments.createCollnRelative,
      ].join('/'),
      element: <FieldDetailHeading prefix="Create Collection - " />,
    },
    {
      path: `${segments.contactsEditExisting}/${wildcards.contactId}`,
      element: <ContactDetailHeading />,
    },
    {
      path: segments.fieldsDrawNew,
      element: <FieldDetailHeading icon={fieldMgmtIcons.draw} prefix="Create New Field" />,
    },
    {
      path: segments.fieldsImport,
      element: <FieldDetailHeading icon={fieldMgmtIcons.import} prefix="Import Fields" />,
    },
    {
      path: `${segments.fieldsImport}/${wildcards.fieldId}`,
      element: <ImportedFieldDetailHeading />,
    },
    {
      path: segments.contactsAddNewSingle,
      element: <FieldDetailHeading icon={fieldMgmtIcons.newContact} prefix="Create New Contact" />,
    },
    {
      path: segments.contacts,
      element: <FieldsMgmtHeading icon={fieldMgmtIcons.allContacts} text="All Contacts" />,
    },
  ])

  return routes
}

import { createContext, useContext, useReducer } from 'react'

import type { Action, State } from '.'

type Dispatch = React.Dispatch<Action>

const initialState: State = {
  filteredRowIds: null,
  selectedRowIds: null,
  boxSelectMode: false,
  mapBbox: null,
}

const StateContext = createContext<State | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_MAP_BBOX':
      return { ...state, mapBbox: action.payload }
    case 'SET_FILTERED_TABLE_ROW_IDS':
      return { ...state, filteredRowIds: action.payload }
    case 'TOGGLE_BOX_SELECT_MODE':
      return { ...state, boxSelectMode: action.payload }
    case 'SET_SELECTED_TABLE_ROW_IDS':
      return {
        ...state,
        selectedRowIds: action.payload,
        boxSelectMode: false,
      }
    default: {
      return state
    }
  }
}

export function MapAndTableProviders(props: { children: React.ReactNode }) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useStateContext(): State {
  const context = useContext(StateContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

function useDispatchContext(): Dispatch {
  const context = useContext(DispatchContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

export { useStateContext as useMapTableStateContext, useDispatchContext as useMapTableDispatch }

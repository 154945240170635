/**
 * This file is for anything API-related that's not already generated by Orval + OpenAPI. Whenever
 * possible, avoid hand-writing API types and try to use Orval instead!
 */

import type { GroupEnum, ShortUser, Staff } from './django/model'

/**
 * No guarantees this is correct across the board in token-land, but it is accurate for both the
 * access and refresh tokens we get from Django when checked on 10/16/2023.
 */
export type DecodedJwtAccessToken = {
  exp: number
  iat: number
  jti: string
  token_type: 'access' | 'refresh'
  user_id: string
}

export const djangoGroups: {
  [group in GroupEnum]: { name: string; description?: string }
} = {
  collection_manager: { name: 'Collection Manager' },
  dispatcher: { name: 'Dispatcher' },
  field_manager: { name: 'Field Manager' },
  field_tech: { name: 'Field Technician' },
  field_tech_contact: { name: 'Field Contact' },
  org_admin: { name: 'Org Administrator' },
  qa_specialist: { name: 'QA Specialist' },
  staff_user: { name: 'Staff user' }, // manually created
  superuser: { name: 'Superuser' }, // manually created
}

// This is a little squirrelly because the `dispatcher` from the Collection is a `ShortUser`, but
// the dispatchers in from the endpoint are `Staff`
export type UserLike = ShortUser | Staff

/**
 * All possible values of the `permissions` object in `CurrentUser`.
 *
 * @example * soil_sampling | soil sampling requirement | Can view soil sampling requirement
 * // TODO: get ALL of them currently available. Move to new file.
 */
export const permissionNames = [
  'add_batch',
  'add_collectionfile',
  'add_field',
  'add_soilcollection',
  'add_soillab',
  'add_soilsample',
  'add_soilsampletype',
  'add_soilsamplingrequirement',
  'add_soiltest',
  'change_collectionfile',
  'change_field',
  'change_soilcollection',
  'change_soillab',
  'change_soilsample',
  'change_soilsampletype',
  'change_soilsamplingrequirement',
  'change_soiltest',
  'delete_collectionfile',
  'delete_field',
  'delete_soilcollection',
  'delete_soillab',
  'delete_soilsample',
  'delete_soilsampletype',
  'delete_soilsamplingrequirement',
  'delete_soiltest',
  'view_batch',
  'view_collectionfile',
  'view_field',
  'view_org',
  'view_soilcollection',
  'view_soillab',
  'view_soilsample',
  'view_soilsampletype',
  'view_soilsamplingrequirement',
  'view_soiltest',
  'view_user',
] as const

// CRED: https://steveholgado.com/typescript-types-from-arrays/
export type Permission = (typeof permissionNames)[number]

// NOTE: there is a mix of stuff for permissions sources: this file, and the
// `CurrentUserPermissions` type, which comes from `overrides.django.ts`, which is based on this
// file (not a circular dep, just don't know how to word it). I could have TS'ed the
// `CurrentUserPermissions` here as well, but since the generated one is based off the
// `permissionNames` in this file, seems safe to lean on the generated one for that object.

export const TILLMAP_PAGE_TITLE = 'SoilMapper' // RIP TillMapper 💀

// TODO: use dynamic values one they're available via API
const PSI_MARKS = [
  { value: 0, label: '0' },
  { value: 50 },
  { value: 100 },
  { value: 150 },
  { value: 200 },
  { value: 250, label: '250' },
  { value: 300 },
  { value: 350 },
  { value: 400 },
  { value: 450 },
  { value: 500, label: '500' },
  { value: 550 },
  { value: 600 },
  { value: 650 },
  { value: 700 },
  { value: 750, label: '750' },
  { value: 800 },
  { value: 850 },
  { value: 900 },
  { value: 950 },
  { value: 1000, label: '1000' },
]

const DEPTH_MARKS = [
  { value: 0, label: 'Surface' },
  { value: -1 },
  { value: -2 },
  { value: -3, label: '-3"' },
  { value: -4 },
  { value: -5 },
  { value: -6, label: '-6"' },
  { value: -7 },
  { value: -8 },
  { value: -9, label: '-9"' },
  { value: -10 },
  { value: -11 },
  { value: -12, label: '-12"' },
  { value: -13 },
  { value: -14 },
  { value: -15 },
  { value: -16, label: '-16"' },
  { value: -17 },
  { value: -18, label: '-18"' },
]

const PERCENTAGE_MARKS = [
  { value: 0, label: '0%' },
  { value: 10, label: '10%' },
  { value: 20, label: '20%' },
  { value: 30, label: '30%' },
  { value: 40, label: '40%' },
  { value: 50, label: '50%' },
  { value: 60, label: '60%' },
  { value: 70, label: '70%' },
  { value: 80, label: '80%' },
  { value: 90, label: '90%' },
  { value: 100, label: '100' },
]

export const INCHES_LEGEND_MARKS = [
  18,
  12,
  9,
  6,
  0,
]

export const INCHES_COLOR_STOPS = [
  'rgb(56, 153, 60)',
  'rgb(255, 255, 0)',
  'rgb(255, 0, 0)',
]

export const CONDUCTIVITY_COLOR_STOPS = [
  'rgb(0, 0, 131)',
  'rgb(0, 60, 170)',
  'rgb(5, 255, 255)',
  'rgb(255, 255, 0)',
  'rgb(250, 0, 0)',
  'rgb(128, 0, 0)',
]

export const PLASMA_COLOR_STOPS = [
  'rgb(13,8,135)',
  'rgb(75,3,161)',
  'rgb(125,3,168)',
  'rgb(168,34,150)',
  'rgb(186,52,136)',
  'rgb(203,70,121)',
  'rgb(229,107,93)',
  'rgb(248,148,65)',
  'rgb(253,195,40)',
  'rgb(240,249,33)',
]

export const MOISTURE_COLOR_STOPS = ['rgb(255,255,255)', 'rgb(0, 60, 170)']

// Rare need for margin-top, but easier than setting mb on headings
const horizSliderStyle = { marginTop: 'var(--sp-4)' }

export const maxPsiSliderProps = {
  'aria-label': 'Max psi',
  marks: PSI_MARKS,
  max: 1000,
  min: 0,
  step: 50,
  style: horizSliderStyle,
  valueLabelDisplay: 'on' as 'auto' | 'off' | 'on',
}

export const depthSliderProps = {
  getAriaLabel: (): string => 'Depth (in.)',
  marks: DEPTH_MARKS,
  max: 0,
  min: -18,
  step: 1,
  valueLabelDisplay: 'on' as 'auto' | 'off' | 'on',
}

export const percentSliderProps = {
  marks: PERCENTAGE_MARKS,
  max: 100,
  min: 0,
  step: 10,
  valueLabelDisplay: 'on' as 'auto' | 'off' | 'on',
  style: horizSliderStyle,
}

export const vertSliderProps = {
  orientation: 'vertical' as 'horizontal' | 'vertical',
  sx: {
    '& .MuiSlider-valueLabelOpen': {
      right: 30,
      transform: 'translateY(6px) scale(1)',
    },
    '& .MuiSlider-valueLabelOpen:before': {
      right: -8,
      left: 'unset',
      bottom: '50%',
    },
  },
}

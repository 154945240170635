import CopyToClipboard from 'react-copy-to-clipboard'
import { FiLink } from 'react-icons/fi'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import type { UITableView } from 'lib/api/django/model'

import { DarkTooltip } from 'components/reusable/info-surfaces'

import { DeleteTableViewBtn } from './DeleteTableViewBtn'

type Props = {
  baseRoute: string
  view: UITableView
  /**
   * The part after the `baseRoute` but before the `view.short_id` in the "copy to clipboard" text
   *
   * @default "saved-views"
   */
  savedViewsRouteSegment?: string
}

export function SavedViewsTableActionsCell(props: Props) {
  const { view, baseRoute, savedViewsRouteSegment = 'saved-views' } = props
  const CURRENT_ORIGIN = window.location.origin
  const textToCopy = `${CURRENT_ORIGIN}${baseRoute}/${savedViewsRouteSegment}/${view.short_id}`

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <CopyToClipboard
        text={textToCopy}
        onCopy={() => toast.success('Copied saved view URL to clipboard')}
      >
        <DarkTooltip
          arrow
          enterDelay={400}
          enterNextDelay={400}
          placement="left"
          title="Copy shareable view link"
        >
          <IconButton aria-label="share" size="small">
            <FiLink />
          </IconButton>
        </DarkTooltip>
      </CopyToClipboard>
      <DeleteTableViewBtn view={view} />
    </div>
  )
}

import Typography from '@mui/material/Typography'

type Props = {
  icon: React.ReactNode
  text: string
}

export function FmtCollnDetailAccordionHeading(props: Props) {
  const { text, icon } = props

  return (
    <Typography
      alignItems="center"
      component="h2"
      display="flex"
      fontWeight="bold"
      gap={1}
      variant="subtitle1"
      sx={{
        svg: { color: 'text.secondary' },
      }}
    >
      {icon}
      {text}
    </Typography>
  )
}

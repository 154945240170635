import { useState } from 'react'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import type { CurrentUser } from 'lib/api/django/model'

import { DebugLocalStgModal } from 'components/reusable/DebugLocalStgModal'

import styles from './UserMenu.module.css'

type Props = {
  icon: React.ReactElement
  data?: CurrentUser
}

export function UserMenuUserInfo(props: Props) {
  const { data, icon } = props
  const { first_name: firstName, last_name: lastName, org } = data || {}
  const isStaff = data?.is_staff
  const [debugModalIsOpen, setDebugModalIsOpen] = useState(false)

  let StatusChip = null
  let classNameUghhh = styles.currentUserInfo // fight a good menu-height fight

  if (isStaff) classNameUghhh += ` ${styles.isStaff}`

  if (isStaff) {
    const label = data.is_superuser ? 'Superuser' : 'Staff user'

    StatusChip = (
      <Chip
        icon={icon}
        label={label}
        size="small"
        classes={{
          root: styles.staffStatusChip, // whaaaat, why does this work? 🤯
          label: styles.staffStatusChipLabel,
        }}
      />
    )
  }

  return (
    <header className={classNameUghhh}>
      <Typography
        className={styles.currentUserName}
        component="div"
        variant={isStaff ? 'h4' : 'h5'}
      >
        {firstName} {lastName} {StatusChip}
        {isStaff && (
          <DebugLocalStgModal
            filenameSansSuffix={`app-debug.${firstName}-${lastName}`.toLowerCase()}
            isOpen={debugModalIsOpen}
            setIsOpen={setDebugModalIsOpen}
          />
        )}
      </Typography>
      <Typography component="div" variant="overline">
        {org?.name}
      </Typography>
    </header>
  )
}

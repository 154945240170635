// NOTE: this is NOT an official source of documentation for sensor file extensions! We have changed
// hardware over the years and may continue to do so, so take this with a grain of salt. It is more
// of a historical record of what we've used in the past and what we have recently used. ADCP and
// EMI v3, for example, are not documented whatsoever. At the time of writing (07/24/2023), we are
// discussing file manifests rather than relying on file extensions.

// *_gps.csv (not always present) is the spatial partner of *_gem.csv EMI files but not always. The
// GSSI profiler uses same *.gps as GPR unfortunately.
// https://docs.novatel.com/OEM7/Content/Logs/GPGGA.htm
//
// EMI, e.g. *_gps.csv - https://docs.novatel.com/OEM7/Content/Logs/GPGGA.htm. The first *_gps.csv
// col is a timestamp used to join to the EMI data (_gem.csv) but the rest of them are the normal
// GGA headers. Same goes for EMI CSV's from the laptop, aka ones ending in '.survey.GPS.csv'.
//
// Going forward will only use GEM2 profiler. GSSI will eventually be deprecated.DZG is old spatial
// GPR format from GSSI profiler and it needs checksum, just like '_gps.csv' EMI files, but there's
// no first col like EMI. $GPGGA is first instead.
const emiFileExtensions = [
  '.emi',
  '.dzb',
  '.GPS',
  '.csv',
  '.gbf',
  '.gem',
]

const gprFileExtensions = ['.DZG', '.DZT', '.DZX']
const dcpFileExtensions = ['.pen']

// Will also be moving away from DZG (GSSI GPR profiler) but not as soon as EMI GSSI. IDS is the
// newer manufacturer (GSSI was older).
const idsGprFileExtensions = [
  '.CScan',
  '.dat',
  '.DT',
  '.gec',
  '.gps', // exact same as .dzg but filenames will start with AA###### (???)
  '.Info',
  '.log',
  '.Log',
  '.nmea', // same as .dzg and .gps. Raw data. Won't be a thing for a bit.
  '.pos',
  '.prcs',
  '.scan',
  '.svy',
  '.txt',
  '.xml',
]

// "Source of truth" for all of our extensions: https://tinyurl.com/yhf6ltl5
export const extensions = {
  dcp: dcpFileExtensions,
  emi: emiFileExtensions,
  gpr: [...gprFileExtensions, ...idsGprFileExtensions],
}

// Case sensitivity shmase sensitivity
export const spatialExtensions = [
  '.gps',
  '_gps.csv',
  '.pen',
  '.dzg',
]

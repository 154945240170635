import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import type { UseQueryResult } from '@tanstack/react-query'
import type { CurrentUser } from 'lib/api/django/model'

// Not really anything to do with the layout except that at the time of writing, all the private
// Routes live in there.
// TODO: mv into hooks.init.ts
export function useSetSentryUserInfo(userInfoQuery: UseQueryResult<CurrentUser>): void {
  const { isLoading, data } = userInfoQuery

  useEffect(() => {
    // CRED: https://stackoverflow.com/a/67658901/1048518 (for `undefined`)
    if (import.meta.env.VITE_DISABLE_SENTRY || isLoading || !data) {
      return undefined
    }

    Sentry.setTag('pwa', window.matchMedia('(display-mode: standalone)').matches)

    Sentry.setUser({
      email: data.email,
      id: data.id,
      username: data.email,
      isStaff: data.is_staff,
      name: `${data.first_name} ${data.last_name}`,
      orgName: data.org.name,
    })

    return function clearUser() {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [isLoading, data])
}

import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useV1ContactsRetrieve } from 'lib/api/django/v1/v1'
import type { ErrorType } from 'lib/api/django-axios-instance'

import { useClearDrawnBoundaryAndStopEditing } from 'components/fields/hooks.fields-mgmt'
import { useDispatchBboxFromFeat } from 'components/fields/hooks.fields-mgmt-map'
import type { FmtRouteParams } from 'components/fields/routes'
import { segments } from 'components/fields/routes'
import type { FieldsMgmtFormSchema as FormSchema } from 'components/fields/types.fields-mgmt'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'

import { CollnNavBtns } from './CollnNavBtns'
import { FieldMgmtForm } from './FieldMgmtForm'
import {
  getV1FieldsListQueryKey,
  getV1FieldsRetrieveQueryKey,
  useV1FieldsRetrieve,
} from './queries.fields-mgmt.gets'
import { useV1FieldsPartialUpdate } from './queries.fields-mgmt.patch'
import type { FieldPatchPayload, FieldReaderRetrieve } from './types.fields-mgmt-api'
import { getFormDefaults, showSuccessToast } from './utils.fields-mgmt-form'

// Grab the id from the url and send it to the API
export function EditExistingFieldPanel() {
  const { fieldId = '' } = useParams<FmtRouteParams>()
  const fieldQuery = useV1FieldsRetrieve(fieldId)
  const field = fieldQuery?.data
  const { properties, geometry } = field || {}
  const { onsite_contact, collections } = properties || {}
  const mutation = useV1FieldsPartialUpdate()
  const { drawnFeatureGeom } = useDrawToolsStateContext()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const onReset = useClearDrawnBoundaryAndStopEditing()

  useDispatchBboxFromFeat(geometry)

  const contactQuery = useV1ContactsRetrieve(onsite_contact?.id, {
    query: { enabled: !!onsite_contact, onSuccess: () => {} },
  })

  const isInitialLoading = contactQuery.isInitialLoading || fieldQuery.isInitialLoading
  const isError = contactQuery.isError || fieldQuery.isError

  if (isInitialLoading || isError) {
    return <ReactQueryStates loadingText="Loading field info" {...fieldQuery} />
  }

  const defaultValues = getFormDefaults(field?.geometry, properties)

  function handleSubmit(allFormData: FormSchema, changedValues?: Partial<FormSchema>) {
    // fieldId should always be there if routing is ✅, but just in case...
    if (!fieldId || !changedValues) return

    const { onsite_contact: contact, org, security_groups } = changedValues || {}

    function onError(err: ErrorType<unknown>) {
      toast.error(`Could not update field: ${err.message}`)
    }

    function onSuccess(res: FieldReaderRetrieve) {
      navigate(`${segments.base}/${segments.fields}`)

      const id = res.id || res.properties.id
      const allFieldsQueryKey = getV1FieldsListQueryKey()
      const thisFieldQueryKey = getV1FieldsRetrieveQueryKey(id as string)

      queryClient.invalidateQueries(allFieldsQueryKey)
      queryClient.invalidateQueries(thisFieldQueryKey)

      showSuccessToast('updated', res.properties.name, 'Click to view again.', () =>
        navigate(`${segments.base}/${segments.fields}/${id}`)
      )
    }

    const payload = {
      ...changedValues,
      ...(contact ? { onsite_contact: contact.id } : {}),
      ...(org ? { owned_by: org.id } : {}),
      ...(drawnFeatureGeom ? { geometry: drawnFeatureGeom } : {}),
      ...(security_groups ? { security_groups: security_groups.map((g) => g.id) } : {}),
    } as FieldPatchPayload

    // Prevents this error: { geometry: ['This field may not be null.'] }
    if (!payload.geometry) delete payload.geometry

    mutation.mutate({ id: fieldId, data: payload }, { onError, onSuccess })
  }

  return (
    <FieldMgmtForm
      defaultValues={defaultValues}
      headerChildren={<CollnNavBtns collectionsCount={collections?.length} />}
      initialGeom={geometry || null}
      mode="edit-existing"
      onReset={onReset}
      onSubmit={handleSubmit}
    />
  )
}

import { LicenseInfo } from '@mui/x-data-grid-premium'
import { MUI_DATA_GRID_KEY } from 'lib/config/api'
import { useCurrentUser } from 'lib/queries.user'

import { useGoogleAnalyticsSetup, useLaunchDarklySetup } from 'components/hooks.init'
import { useSetSentryUserInfo } from 'components/layout/hooks.layout'

LicenseInfo.setLicenseKey(MUI_DATA_GRID_KEY) // MUI Data Grid Premium

export function AppSetup() {
  const currentUserQuery = useCurrentUser()

  // Not including `isError` here because `UserMenu` already throws a toast on any sort of error.
  // And we don't want to block users from accessing the page based on error alone- ran into a
  // situation where there WAS an error but user had everything they needed in `localStorage`, so
  // they were being blocked for no good reason. `UserMenu` is in every page in the app (except
  // login, "not found", etc.) so it makes sense to throw toast there if the user query craps out.
  // eslint-disable-next-line padding-line-between-statements
  const { isLoading, data } = currentUserQuery

  useGoogleAnalyticsSetup(isLoading, data)
  useLaunchDarklySetup(isLoading, data)
  useSetSentryUserInfo(currentUserQuery)

  return null
}

import { useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { useDefaultSecGroup } from 'lib/queries.user'

import { useDispatchContext } from 'components/fields/create-fields-steps/NewFieldContext'
import { useClearDrawnBoundaryAndStopEditing } from 'components/fields/hooks.fields-mgmt'
import {
  useDispatchBboxFromFeat,
  useImportedFieldFromRoute,
} from 'components/fields/hooks.fields-mgmt-map'
import { segments } from 'components/fields/routes'
import type { FieldsMgmtFormSchema as FormSchema } from 'components/fields/types.fields-mgmt'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'

import { FieldMgmtForm } from './FieldMgmtForm'
import { getFormDefaults, showSuccessToast } from './utils.fields-mgmt-form'

type Props = {
  onSubmit?: (allFormData: FormSchema, changedValues?: Partial<FormSchema>) => void
}

export function FieldsMgmtImportFieldsPanel(props: Props) {
  const { onSubmit } = props
  const { drawnFeatureGeom } = useDrawToolsStateContext()
  const dispatch = useDispatchContext()
  const matched = useImportedFieldFromRoute()
  const importedFieldId = matched?.id
  const navigate = useNavigate()
  const onReset = useClearDrawnBoundaryAndStopEditing()
  const { isLoading, groups } = useDefaultSecGroup()

  useDispatchBboxFromFeat(matched?.geometry)

  if (isLoading) {
    return null
  }

  if (!matched) {
    return (
      <Typography component="h2" variant="h5">
        Not found
      </Typography>
    )
  }

  const { properties, geometry } = matched || {}

  const defaultValues: FormSchema = {
    ...getFormDefaults(geometry, properties, groups),
    geometry_source: 'FILE',
  }

  function handleSubmit(allFormData: FormSchema, changedValues?: Partial<FormSchema>) {
    // If the form is doing its job then it shouldn't be possible to submit
    // because the btn will be disabled, but we still need the logic here to
    // satisfy the reducer.
    if (!importedFieldId || !changedValues) return

    showSuccessToast('updated', allFormData.name, 'Click Submit to finish the process.')

    dispatch({
      type: 'BULK_EDIT_IMPORTED_FEAT_ATTRIBS',
      payload: { featIds: [importedFieldId], attributes: changedValues },
    })

    if (drawnFeatureGeom) {
      dispatch({
        type: 'EDIT_IMPORTED_FEAT_GEOM',
        payload: { id: importedFieldId, geometry: drawnFeatureGeom },
      })
    }

    navigate(`${segments.base}/${segments.fieldsImport}`)
  }

  return (
    <FieldMgmtForm
      defaultValues={defaultValues}
      extras={properties.extras}
      initialGeom={geometry}
      mode="edit-import"
      onReset={onReset}
      onSubmit={onSubmit || handleSubmit}
    />
  )
}

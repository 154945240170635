import { Link as RouterLink, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useV1FieldsRetrieve } from 'components/fields/fields-mgmt/queries.fields-mgmt.gets'
import type { FmtRouteParams } from 'components/fields/routes'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'

import { CreateCollnFormContents, useHandleSubmitNewColln } from '.'

export function CreateCollnForm() {
  const { fieldId = '' } = useParams<FmtRouteParams>()
  const query = useV1FieldsRetrieve(fieldId)
  const { data, isInitialLoading, isError } = query
  const { mutationIsLoading, handleSubmit } = useHandleSubmitNewColln(data)

  let Content = null

  if (isInitialLoading || isError) {
    Content = <ReactQueryStates {...query} />
  } else {
    Content = <CreateCollnFormContents />
  }

  return (
    <form onSubmit={handleSubmit}>
      {Content}
      <Box display="flex" gap={2} justifyContent="center" mt={2}>
        <Button
          component={RouterLink}
          disabled={mutationIsLoading}
          size="large"
          to="./.."
          variant="outlined"
        >
          Cancel
        </Button>
        <Button disabled={mutationIsLoading} size="large" type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </form>
  )
}

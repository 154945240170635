import Typography from '@mui/material/Typography'
import type { GridColDef, GridSingleSelectColDef } from '@mui/x-data-grid-premium'
import type { Annotation } from 'lib/api/django/model'

import { DarkTooltip } from 'components/reusable/info-surfaces'
import { annoSymbolConfig } from 'components/reusable/maps/draw-and-annotate/config.anno-symbols'
import type { AnnoFeature } from 'components/reusable/maps/draw-and-annotate/types'
import { RouterLink } from 'components/reusable/routing'
import { SsaDeleteAnnoBtn } from 'components/soil-sampling/collection-detail/annotations/SsaDeleteAnnoBtn'

type CustomColumns = 'actions' | 'geometry'

type TypedColName = {
  field: CustomColumns | keyof Annotation
}

type ColumnDef = Omit<GridColDef<AnnoFeature>, 'field'> & TypedColName

type SingleSelectColumnDef = Omit<GridSingleSelectColDef<AnnoFeature>, 'field'> & TypedColName

export const annoColumnsConfig: (ColumnDef | SingleSelectColumnDef)[] = [
  {
    field: 'actions',
    headerName: 'Actions',
    description: 'Annotation actions, e.g. delete',
    width: 80,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <SsaDeleteAnnoBtn
        annotationId={row.id ? (row.id as string) : row.properties.id}
        collectionId={row.properties.collection_id}
        navigateOnSuccessTo={false} // just hang out in the collection table
      />
    ),
  },
  {
    field: 'name',
    headerName: 'Name/title',
    description: 'Name or title of the annotation',
    width: 160,
    valueGetter: ({ row }) => row.properties.name,
    renderCell: ({ row }) => (
      <RouterLink
        content={row.properties.name}
        to={typeof row.id === 'string' ? row.id : row.properties.id}
      />
    ),
  },
  {
    field: 'type',
    headerName: 'Type/category',
    description: 'Type or category of the annotation',
    width: 125,
    valueOptions: Object.keys(annoSymbolConfig).map((k) => k),
    type: 'singleSelect',
    valueGetter: ({ row }) => row.properties.type,
  },
  {
    field: 'geometry',
    headerName: 'Geometry',
    description: 'Geometry type of the annotation',
    width: 125,
    valueGetter: ({ row }) => row.geometry.type,
  },
  {
    field: 'description',
    headerName: 'Description/summary',
    description: 'Description or summary of the annotation',
    width: 250,
    flex: 1,
    valueGetter: ({ row }) => row.properties.description,
    renderCell: ({ row }) => (
      <DarkTooltip
        enterDelay={400}
        enterNextDelay={400}
        placement="top"
        title={row.properties.description}
      >
        <Typography
          fontSize="body2.fontSize"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {row.properties.description}
        </Typography>
      </DarkTooltip>
    ),
  },
]

import type { RouteConfigWithHandle } from 'lib/types'

import { PublicLayout } from 'components/layout/PublicLayout'
import { LoginForm } from 'components/login/LoginPage'
import { LogoutPage } from 'components/login/LogoutPage'
import { PageNotFound } from 'components/reusable/alerts-and-messages'

import { segments } from './routes'

/**
 * User does not need to be authenticated to access any of these routes.
 */
export const routesConfig: RouteConfigWithHandle[] = [
  {
    element: <PublicLayout />,
    children: [
      { element: <LoginForm />, path: segments.login },
      { element: <LogoutPage />, path: segments.logout },
      { element: <PageNotFound accessDenied />, path: segments.unauthorized },
      { element: <PageNotFound />, path: '*' },
    ],
  },
]

import { useState } from 'react'
import { TbMapPins } from 'react-icons/tb'
import { toast } from 'react-toastify'
import Typography from '@mui/material/Typography'
import type { CollectionFilesFileTypeEnum } from 'lib/api/django/model'
import { usePostCollections } from 'lib/api/django/v2/v2'
import type { ErrorType } from 'lib/api/django-axios-instance'

import { AccessCheckViaPerms } from 'components/reusable/access-gates/AccessCheckViaPerms'
import { AccessCheckViaStaff } from 'components/reusable/access-gates/AccessCheckViaStaff'
import { FILES_FORM_INPUT_NAME } from 'components/reusable/dropzones/config.dropzone'
import { DropzoneModal } from 'components/reusable/dropzones/DropzoneModal'

import { FORM_INPUTS } from './config.sampling-plans'
import { MultiComboFormInputs } from './MultiComboFormInputs'
import { MultiComboSummary } from './MultiComboSummary'
import { ErrorToastContent, InProgressToastContent, SuccessToastContent } from './MultiComboToasts'
import type { MultiThingSuccessResponse } from './types.sampling-plans'

const IN_PROGRESS_TOAST_ID = 'in-progress'

function IntroSection() {
  return (
    <>
      <Typography component="div" mb={3} variant="caption">
        When you upload sampling plan files in a supported format, the tool:
        <ul>
          <li>Auto-creates fields if they don't already exist</li>
          <li>Creates collections from those fields</li>
          <li>Creates prescribed samples in those collections</li>
        </ul>
      </Typography>
      <MultiComboFormInputs />
    </>
  )
}

// TODO: NEVER rename file and button
export function MultiComboMadness() {
  // NOTE: the `string[]` is only the known type for 400 exactly, not others.
  const mutation = usePostCollections<ErrorType<string[]>>()
  const [summaryModalOpen, setSummaryModalOpen] = useState(false)
  const [successResponses, setSuccessResponses] = useState<MultiThingSuccessResponse[]>([])

  function handleSubmit(e?: React.FormEvent) {
    if (!e) {
      return
    }

    const formData = new FormData(e.target as HTMLFormElement)
    const files = formData.get(FILES_FORM_INPUT_NAME)
    const org = formData.get(FORM_INPUTS.org)
    const file_type = formData.get(FORM_INPUTS.file_type)

    if (!files || !org || !file_type) {
      toast.warn('Files, Org, or file type were not supplied.')

      return
    }

    toast.info(<InProgressToastContent />, {
      toastId: IN_PROGRESS_TOAST_ID,
      hideProgressBar: false,
    })

    mutation.mutate(
      {
        data: {
          org_id: org as string,
          file_type: file_type as CollectionFilesFileTypeEnum,
          sampling_plan_file: files as File,
          create_fields: !!formData.get(FORM_INPUTS.create_fields),
          create_collections: !!formData.get(FORM_INPUTS.create_collections),
        },
      },
      {
        onSettled: () => {
          toast.dismiss(IN_PROGRESS_TOAST_ID)
        },
        onSuccess: (res) => {
          const actualResponse = res as unknown as MultiThingSuccessResponse[]

          setSuccessResponses(actualResponse)

          toast.success(<SuccessToastContent items={actualResponse} />, {
            onClick: () => setSummaryModalOpen(true),
            autoClose: false,
          })
        },
        onError: (err) => {
          toast.error(<ErrorToastContent error={err} />)
        },
      }
    )
  }

  return (
    <AccessCheckViaStaff>
      <AccessCheckViaPerms mustHaveAll perms={['add_field', 'add_soilcollection']}>
        <DropzoneModal
          btnTextAndModalTitle="Import sampling plan"
          buttonProps={{ startIcon: <TbMapPins /> }}
          formModalProps={{ onSubmit: handleSubmit }}
          introSection={<IntroSection />}
          dropzoneOptions={{
            accept: ['.json', '.geojson'],
          }}
        />
        <MultiComboSummary
          isOpen={summaryModalOpen}
          setIsOpen={setSummaryModalOpen}
          successResponses={successResponses}
        />
      </AccessCheckViaPerms>
    </AccessCheckViaStaff>
  )
}

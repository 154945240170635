import { useState } from 'react'
import { MdViewColumn } from 'react-icons/md'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { GridColumnsPanel } from '@mui/x-data-grid-premium'
import { useIsAtLeastPhablet } from 'lib/hooks'

import { FormModal } from 'components/reusable/handy-templates/FormModal'

export function ModalTableColumnsSelect() {
  const isAtLeastPhablet = useIsAtLeastPhablet()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <FormModal
        isNotForm
        slideUp
        content={<GridColumnsPanel autoFocusSearchField={false} />}
        dialogProps={{ fullScreen: !isAtLeastPhablet }}
        elemId="column-switcher"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        title="Table columns"
      />
      <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title="Table columns">
        <IconButton aria-label="columns" color="primary" onClick={() => setModalIsOpen(true)}>
          <MdViewColumn />
        </IconButton>
      </Tooltip>
    </>
  )
}

import isEqual from 'react-fast-compare'
import type { PatchedSoilSample, SoilCollectionReader } from 'lib/api/django/model'

import { getSampleHasAllGpsInfo } from 'components/soil-sampling/collection-detail/sample-view'
import { STATUSES_IN_LIFECYCLE_ORDER } from 'components/soil-sampling/config.ssa'
import type { SoilCollection, SoilSample } from 'components/soil-sampling/types.ssa'
import { getSampleById } from 'components/soil-sampling/utils.soil-sampling'

import type {
  LocalVsRemoteConflict,
  QueuedUpwardSample,
  SampleDifferences,
} from './types.colln-sync'

/**
 * Determine if the local and remote samples have the same GPS content, excluding `collected_at`.
 *
 * @param localSample local sample
 * @param remoteSample remote sample
 * @returns `true` if lat/lon and accuracy are identical, otherwise `false`
 */
export function getLocalVsRemoteSampleGPSparity(
  localSample: SoilSample,
  remoteSample: SoilSample
): boolean {
  const localGps = {
    gps_accuracy: localSample.gps_accuracy,
    sample_lat: localSample.sample_lat,
    sample_lon: localSample.sample_lon,
  }

  const remoteGps = {
    gps_accuracy: remoteSample.gps_accuracy,
    sample_lat: remoteSample.sample_lat,
    sample_lon: remoteSample.sample_lon,
  }

  return isEqual(localGps, remoteGps)
}

/**
 * Determine if a local sample thing (e.g. was collected at) happened after the remote sample.
 *
 * @param localTime local sample thing, e.g. `collected_at`
 * @param remoteTime remote sample thing, e.g. `collected_at`
 * @returns true if local sample thing happened after remote sample thing
 */
export function getIsLocalPropertyNewer(localTime: string, remoteTime: string): boolean {
  const localThingDate = new Date(localTime)
  const remoteThingDate = new Date(remoteTime)

  return localThingDate > remoteThingDate
}

export function getCollnFieldNotesParity(localNotes: string, remoteNotes: string): boolean {
  const localCollnsNotesNoNewlines = localNotes.replace(/\n/g, '')
  const remoteCollnsNotesNoNewlines = remoteNotes.replace(/\n/g, '')

  return localCollnsNotesNoNewlines === remoteCollnsNotesNoNewlines
}

export function getGPScontentFromSample(sample: SoilSample): {
  sample_lat: number | null | undefined
  sample_lon: number | null | undefined
  collected_at: string | null | undefined
  gps_accuracy: number | null | undefined
} {
  return {
    sample_lat: sample.sample_lat,
    sample_lon: sample.sample_lon,
    collected_at: sample.collected_at,
    gps_accuracy: sample.gps_accuracy,
  }
}

export function getDepthToSyncValue(
  remoteSample: SoilSample,
  localSample: SoilSample
): number | undefined {
  const requestedDepthTo = remoteSample.requested_depth_to
  const remoteDepthTo = remoteSample.depth_to
  const localDepthTo = localSample.depth_to
  const toDepthsAreEqual = localDepthTo === remoteDepthTo

  if (toDepthsAreEqual) {
    return remoteDepthTo // could take local, doesn't matter
  }

  if (localDepthTo === requestedDepthTo && remoteDepthTo !== requestedDepthTo) {
    return remoteDepthTo
  }

  return localDepthTo // assume local is right. Will be considered a "conflict".
}

/**
 * For the given local and remote samples, determine if there are any conflicts that must be
 * resolved by the user such as field notes or "depth to". Also determine if there are any
 * differences between the two that can be sent from the device to the server, such as GPS data or
 * field notes, and return it in `upwardPayload`.
 *
 * @param local local sample
 * @param remote remote sample
 * @returns An object with an array of manually-resolvable `conflicts` and potentially an
 * `upwardPayload`. See description above.
 */
export function getSampleConflictsAndUpwardPayload(
  local: SoilSample,
  remote: SoilSample
): {
  conflicts: LocalVsRemoteConflict[]
  upwardPayload: QueuedUpwardSample | null
} {
  const conflicts: LocalVsRemoteConflict[] = []
  const localNotes = local.field_notes || ''
  const remoteNotes = remote.field_notes || ''
  const fieldNotesAreEqual = getCollnFieldNotesParity(localNotes, remoteNotes)
  const toDepthsAreEqual = local.depth_to === remote.depth_to
  const hasGpsParity = getLocalVsRemoteSampleGPSparity(local, remote)

  const commonConflictProps = {
    sampleShortId: local.short_id,
    bagLabel: local.bag_label,
  }

  let payload: PatchedSoilSample | null = null

  // Local sample has GPS but it is
  if (!hasGpsParity && getSampleHasAllGpsInfo(local)) {
    const isLocalSampleIsNewer =
      !remote.collected_at ||
      getIsLocalPropertyNewer(
        local.collected_at as string, // ok to coerce, checked existence in `getSampleHasAllGpsInfo`
        remote.collected_at
      )

    // Don't include GPS stuff in the payload unless local `collected_at` is newer
    payload = isLocalSampleIsNewer ? getGPScontentFromSample(local) : payload
  }

  if (!fieldNotesAreEqual) {
    if (localNotes && !remoteNotes) {
      payload = {
        ...(payload || {}),
        field_notes: localNotes,
      }
    } else if (localNotes && remoteNotes) {
      conflicts.push({
        local: localNotes,
        remote: remoteNotes,
        property: 'field_notes',
        label: 'Field notes',
        ...commonConflictProps,
      })
    }
  }

  // The sync will overwrite the local `depth_to` if it's the same as that of the requested but the
  // remote is not, so no need to treat it as a conflict.
  if (!toDepthsAreEqual && local.depth_to !== remote.requested_depth_to) {
    conflicts.push({
      local: local.depth_to as number, // required in <form>
      remote: remote.depth_to as number, // required in <form>
      property: 'depth_to',
      label: 'Depth to (actual)',
      ...commonConflictProps,
    })
  }

  return {
    conflicts,
    // Not sure why `short_id` instead of `id`. Either one should work, but not sure if there are
    // any other bits of code that rely on `short_id` being used here.
    upwardPayload: payload ? { sampleId: commonConflictProps.sampleShortId, payload } : null,
  }
}

// Not unit-testing this as it's just a wrapper around other tested utils
export function getCollnDifferences(
  localColln?: SoilCollection,
  remoteColln?: SoilCollection
): {
  fieldNotesAreEqual: boolean
  sampleDifferences: SampleDifferences
} {
  const sampleDifferences: SampleDifferences = {
    conflicts: [],
    upwardPayloads: [],
  }

  const fieldNotesAreEqual = getCollnFieldNotesParity(
    localColln?.field_notes || '',
    remoteColln?.field_notes || ''
  )

  remoteColln?.samples.forEach((remoteSample) => {
    const localSample = getSampleById(localColln?.samples || [], remoteSample.id)

    if (!localSample) {
      return
    }

    const { conflicts, upwardPayload } = getSampleConflictsAndUpwardPayload(
      localSample,
      remoteSample
    )

    sampleDifferences.conflicts = [...sampleDifferences.conflicts, ...conflicts]

    sampleDifferences.upwardPayloads = [
      ...sampleDifferences.upwardPayloads,
      ...(upwardPayload ? [upwardPayload] : []),
    ]
  })

  return { fieldNotesAreEqual, sampleDifferences }
}

/**
 * Get the full array of samples to be be safely overwritten on the device by comparing their
 * potential sources of conflict, e.g. GPS info, field notes, and "depth to". The `samples` property
 * of the collection on the device can then be overwritten wholesale with the return value of this
 * function. See individual utility functions for more details.
 *
 * @param remoteSamples array of samples from the server
 * @param localSamples array of samples on the device
 * @returns array of samples that can be safely overwritten on the device
 */
export function getSafeToOverwriteSamples(
  remoteSamples: SoilSample[],
  localSamples: SoilSample[]
): SoilSample[] {
  if (!localSamples.length) {
    return remoteSamples // no samples on the device yet, so just take the remote's verbatim
  }

  return remoteSamples.map((remoteSample) => {
    const localSample = getSampleById(localSamples, remoteSample.id)

    if (!localSample) {
      return remoteSample // append a new one
    }

    let gpsPayload: Partial<SoilSample> = {}

    // At time of writing, 11/08/2023, `DID_NOT_COLLECT` is the only status where the remote wins.
    // Everything else is derived (in the table, e.g. "Done" if there are sample coordinates), or
    // used directly, from the local sample.
    const shouldUseRemoteStatus = remoteSample.status === 'DID_NOT_COLLECT'
    const localSampleHasGPS = getSampleHasAllGpsInfo(localSample)
    const remoteSampleHasGPS = getSampleHasAllGpsInfo(remoteSample)
    const remoteGps = getGPScontentFromSample(remoteSample)
    const localGps = getGPScontentFromSample(localSample)

    // Take remote as-is
    if (!localSampleHasGPS) {
      gpsPayload = remoteGps
    }
    // Use local if remote has no GPS
    else if (!remoteSampleHasGPS) {
      gpsPayload = localGps
    }
    // Otherwise compare times
    else {
      const isLocalSampleIsNewer =
        !remoteSample.collected_at ||
        // ok to coerce string, checked existence in `getSampleHasAllGpsInfo`
        getIsLocalPropertyNewer(localSample.collected_at as string, remoteSample.collected_at)

      gpsPayload = isLocalSampleIsNewer ? localGps : remoteGps
    }

    // Soil cores: use the newer one, or the remote one if local has none, or clear it in the edge
    // case where it's gone on the remote and present on local (just set default to `null`).
    let soilCorePayload: SoilSample['soil_core'] = null

    const remoteSoilCore = remoteSample.soil_core
    const localSoilCore = localSample.soil_core

    if (remoteSoilCore && !localSoilCore) {
      soilCorePayload = remoteSoilCore
    } else if (localSoilCore && remoteSoilCore) {
      // Compare timestamps and take newer one
      const isLocalCoreNewer = getIsLocalPropertyNewer(
        localSoilCore.updated_at,
        remoteSoilCore.updated_at
      )

      soilCorePayload = isLocalCoreNewer ? localSoilCore : remoteSoilCore
    }

    const sample: SoilSample = {
      ...remoteSample, // use remote for nearly everything
      ...gpsPayload,
      soil_core: soilCorePayload,
      depth_to: getDepthToSyncValue(remoteSample, localSample),
      status: shouldUseRemoteStatus ? remoteSample.status : localSample.status,
      // Prefer local notes and external ID but fall back to remote notes
      field_notes: localSample.field_notes || remoteSample.field_notes,
      external_id: localSample.external_id || remoteSample.external_id,
    }

    return sample
  })
}

export function getIsRemoteCollnStatusMoreMature(
  remoteStatus: SoilCollection['status'],
  localStatus: SoilCollection['status']
): boolean {
  const remoteStage = STATUSES_IN_LIFECYCLE_ORDER.indexOf(remoteStatus)
  const localStage = STATUSES_IN_LIFECYCLE_ORDER.indexOf(localStatus)

  return remoteStage > localStage
}

// Whitelisted safe-to-overwrite on local device
export function getSafeToOverwriteLocalCollnProps(
  remoteColln: SoilCollection,
  localColln: SoilCollection
): SoilCollection {
  const remoteCopy = { ...remoteColln }
  const BLACKLISTED: (keyof SoilCollection)[] = ['status', 'field_notes']

  BLACKLISTED.forEach((k) => delete remoteCopy[k])

  const remoteStatusIsMoreMature = getIsRemoteCollnStatusMoreMature(
    remoteColln.status,
    localColln.status
  )

  // "WILL_NOT_COLLECT" doesn't really fit in the lifecycle order, so it's a special case:
  const shouldUseRemote = remoteStatusIsMoreMature || remoteColln.status === 'WILL_NOT_COLLECT'

  return {
    ...remoteCopy,
    samples: getSafeToOverwriteSamples(remoteColln.samples, localColln.samples),
    status: shouldUseRemote ? remoteColln.status : localColln.status,
    // Prefer local but fall back to remote notes
    field_notes: localColln.field_notes || remoteColln.field_notes,
  }
}

/**
 * Get `short_id`s of collections that need to be downloaded based on the
 * current user's assignments and what's on their device.
 *
 * @param data array of collections from server
 * @param localKeys array of collection `short_id`s already on the device
 * @param currentUserId current user's `id`
 * @returns array of string `short_id`s of collections to be downloaded
 */
export function getCollnKeysToDownload(
  // Technically it's `SoilCollectionReader`, but had problems fighting it. We really don't care
  // about anything except the basic properties here, not the detail-only ones like `boundary` that
  // are not common to both types.
  data: (SoilCollection | SoilCollectionReader)[],
  localKeys?: string[],
  currentUserId?: string
): string[] {
  return data
    .filter((remoteColln) => {
      // Already on device, or we're still waiting on the Promise that
      // returns the keys to resolve.
      if (localKeys?.includes(remoteColln.short_id)) {
        return false
      }

      // Assigned to current user
      return (
        remoteColln.team_contact?.id === currentUserId ||
        remoteColln.team_members.some((tm) => tm.id === currentUserId)
      )
    })
    .map(({ short_id }) => short_id)
}

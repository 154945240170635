import { useCallback, useState } from 'react'
import type { DropEvent, FileRejection } from 'react-dropzone'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import type { Props as FormModalProps } from 'components/reusable/handy-templates/FormModal'
import { FormModal } from 'components/reusable/handy-templates/FormModal'

import { DropzoneFilesTable } from './DropzoneFilesTable'
import type { Props as FileDropzoneProps } from './FileDropzone'
import { FileDropzone } from './FileDropzone'
import { RejectionsList } from './RejectionsList'

type Props = {
  /**
   * Text to use for the button and modal title. Note that this will _not_ be
   * overridden by `formModalProps.dialogProps.title`, if provided. This is true
   * for the `<FormModal>` component itself as well.
   *
   * @default 'Upload files'
   */
  btnTextAndModalTitle?: string
  formModalProps?: Partial<FormModalProps>
  introSection?: React.ReactNode
  dropzoneOptions?: FileDropzoneProps['dropzoneOptions']
  buttonProps?: ButtonProps
}

const contentSx = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  py: 2,
}

export function DropzoneModal(props: Props) {
  const { formModalProps, introSection, dropzoneOptions } = props
  const { btnTextAndModalTitle = 'Upload files', buttonProps } = props
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const filesArePresent = !!acceptedFiles.length || !!fileRejections.length

  const onDrop = useCallback(
    (goodKids: File[], badKids: FileRejection[], evt: DropEvent) => {
      setFileRejections(badKids)
      setAcceptedFiles(goodKids)

      if (dropzoneOptions?.onDrop) {
        dropzoneOptions.onDrop(goodKids, badKids, evt)
      }
    },
    [dropzoneOptions]
  )

  function closeAndClear() {
    setModalIsOpen(false)
    setAcceptedFiles([])
    setFileRejections([])
  }

  function handleSubmit(e?: React.FormEvent) {
    if (formModalProps?.onSubmit) {
      formModalProps.onSubmit(e)
    }

    closeAndClear()
  }

  const Content = (
    <Container maxWidth="lg" sx={contentSx}>
      {!!introSection && (
        <Typography component="div" mb={2}>
          {introSection}
        </Typography>
      )}
      <RejectionsList rejections={fileRejections} />
      <FileDropzone
        required
        dropzoneOptions={{ ...dropzoneOptions, onDrop }}
        minimize={filesArePresent}
      />
      {filesArePresent && <DropzoneFilesTable acceptedFiles={acceptedFiles} />}
    </Container>
  )

  const Modal = (
    <FormModal
      title={btnTextAndModalTitle}
      // Order matters from here down since we are mixing overrides, defaults,
      // and in-component handlers.
      dialogProps={{
        fullScreen: true,
        ...formModalProps?.dialogProps,
      }}
      {...formModalProps}
      content={Content}
      elemId="file-dropzone"
      isOpen={modalIsOpen}
      onSubmit={handleSubmit}
      onClose={() => {
        if (formModalProps?.onClose) {
          formModalProps.onClose()
        }

        closeAndClear()
      }}
    />
  )

  return (
    <>
      <Button
        variant="contained"
        {...buttonProps}
        onClick={(e) => {
          if (buttonProps?.onClick) {
            buttonProps.onClick(e)
          }

          setModalIsOpen(true)
        }}
      >
        {btnTextAndModalTitle}
      </Button>
      {Modal}
    </>
  )
}

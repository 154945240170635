import { useState } from 'react'
import { FaSearchLocation } from 'react-icons/fa'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import type { GridValidRowModel } from '@mui/x-data-grid-premium'
import { useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid-premium'
import * as turf from '@turf/turf'
import type { Bbox2d } from 'lib/types'

import { useMapTableDispatch } from 'components/reusable/tables/context'

interface Props<R extends GridValidRowModel = GridValidRowModel> {
  /**
   * Prep the features for the bbox calculation. There are too many variations across apps, e.g. FMT
   * uses `lat` and `lon` properties directly, while SC needs either `boundary.geometry` or
   * `field.lat` and `field.lon`, etc. Refer to consumers of this component for examples.
   *
   * @param row a row from the data grid. Not sure how to TS this thing. Ideally would not need to
   * do `row: GridValidRowModel` in the consumer every time, but not sure how to do that. 🤷
   * @returns a GeoJSON feature that will be used in the bbox calculation.
   */
  selRowsBboxPrepFn: (row: R) => GeoJSON.Feature
}

const TITLE = 'Zoom to selected features'

export function ZoomToSelectedFeatsBtn(props: Props) {
  const { selRowsBboxPrepFn } = props
  const mapTableDispatch = useMapTableDispatch()
  const [features, setFeatures] = useState<GeoJSON.Feature[]>([])
  const apiRef = useGridApiContext()

  function handleClick() {
    const bbox = turf.bbox({ type: 'FeatureCollection', features }) as Bbox2d

    mapTableDispatch({
      type: 'SET_MAP_BBOX',
      payload: {
        bbox,
        instantZoom: false,
        // Prevent over-zooming:
        isPointFeature: features.length === 1 && features[0].geometry.type === 'Point',
      },
    })
  }

  function handleSelectionChange() {
    const selRows = Array.from(apiRef.current?.getSelectedRows().values() || [])

    // For some reason, this crapped out in the SoilMapper v2 (if we'd released it) collections
    // list, but works fine in SoilCollector. Not sure why, but this fix works for both maps.
    if (selRows[0]) {
      const preppedFeatures: GeoJSON.Feature[] = selRows.map(selRowsBboxPrepFn)

      setFeatures(preppedFeatures)
    }
  }

  useGridApiEventHandler(apiRef, 'rowSelectionChange', handleSelectionChange)

  return (
    <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title={TITLE}>
      <span>
        <IconButton
          aria-label={TITLE}
          color="primary"
          disabled={!features.length}
          onClick={handleClick}
        >
          <FaSearchLocation />
        </IconButton>
      </span>
    </Tooltip>
  )
}

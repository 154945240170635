export const SOILMAPPER_BASEPATH = 'soilmapper'

export const LAYER_COMPARE_BASEPATH = 'layer-compare'

export const wildcards = {
  batch: ':batchId',
  field: ':fieldId',
  product: ':productName',
}

// "old" as in "old SoilMapper", aka "current SoilMapper" 🙃
export const OLD_PRODUCT_MATCH_PATH = [
  '/',
  SOILMAPPER_BASEPATH,
  wildcards.field,
  wildcards.batch,
  wildcards.product,
].join('/')

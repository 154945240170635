import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { UseMutationResult } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import type {
  PatchedSoilCollectionWriter,
  SoilCollectionCreator,
  SoilCollectionCreatorResponse,
  SoilCollectionWriter,
} from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey,
  useV1SoilSamplingCollectionsCreate,
  useV1SoilSamplingCollectionsPartialUpdate,
} from 'lib/api/django/v1/v1'
import type { ErrorType } from 'lib/api/django-axios-instance'
import { topLevelAbsoluteAppRoutes } from 'lib/config'
import { useCanAccessBasedOnPerms, useIsStaffUser } from 'lib/queries.user'

import { getV1FieldsListQueryKey } from 'components/fields/fields-mgmt/queries.fields-mgmt.gets'
import type { FieldReaderRetrieve } from 'components/fields/fields-mgmt/types.fields-mgmt-api'
import { segments as fmtPaths } from 'components/fields/routes'

import { COLLN_NAME_FORM_INPUT_NAME, fmtToastsConfig } from '.'

export const ACB_MUTATION_KEY = ['ACB_MUTATION_KEY']

function handleAcbPatchError(): void {
  toast.error('Could not update boundary')
}

function useHandleCollnPatchSuccess(collnId: string, toastSuccessMsg: React.ReactNode): () => void {
  const rqc = useQueryClient()

  function handleSuccess(): void {
    // TODO: ugh, now it's mixed between FMT using long and SC using short. Should we go all-in with
    // short? Kinda painted ourselves into that corner for SC because short is how it's on device.
    // Invalidate both for now? If the query isn't being used, no harm no foul, but otherwise
    // invalidate.
    const shortId = collnId.split('-')[0]
    const queryKey = getV1SoilSamplingCollectionsRetrieveQueryKey(collnId)
    const queryKeyShort = getV1SoilSamplingCollectionsRetrieveQueryKey(shortId)

    rqc.invalidateQueries(queryKey)
    rqc.invalidateQueries(queryKeyShort)

    toast.success(toastSuccessMsg)
  }

  return handleSuccess
}

export function useCanViewSoilCollector(): boolean {
  const isStaff = useIsStaffUser()
  const hasSCpermAccess = useCanAccessBasedOnPerms(['view_soilcollection'])

  return hasSCpermAccess || isStaff
}

export function useCollnPatchMutation(
  collnId: string,
  toastSuccessMsg?: React.ReactNode
): UseMutationResult<
  SoilCollectionWriter,
  ErrorType<unknown>,
  {
    id: unknown
    data: PatchedSoilCollectionWriter
  },
  unknown
> {
  const onSuccess = useHandleCollnPatchSuccess(collnId, toastSuccessMsg)

  return useV1SoilSamplingCollectionsPartialUpdate({
    mutation: {
      mutationKey: ACB_MUTATION_KEY,
      onSuccess,
      onError: handleAcbPatchError,
    },
  })
}

function useCreateCollnMutation(): UseMutationResult<
  SoilCollectionCreatorResponse,
  ErrorType<unknown>,
  { data: SoilCollectionCreator },
  unknown
> {
  const navigate = useNavigate()
  const canViewSoilCollector = useCanViewSoilCollector()
  const rqc = useQueryClient()

  const mutation = useV1SoilSamplingCollectionsCreate({
    mutation: {
      onError: () => {
        toast.error('Could not create collection')
      },
      onSuccess: (res: SoilCollectionCreatorResponse) => {
        const successRedirectPath = [
          fmtPaths.base,
          fmtPaths.fields,
          res.field,
          fmtPaths.collnsRelative,
          res.id,
        ].join('/')

        navigate(successRedirectPath)

        if (canViewSoilCollector) {
          const href = [
            topLevelAbsoluteAppRoutes.soilCollector,
            res.id.split('-')[0], // collection short id
          ].join('/')

          toast.success(fmtToastsConfig.collnCreateSuccess, {
            onClick: () => window.open(href, '_blank'),
            autoClose: 10000, // 10 seconds to let user read the toast
          })
        } else {
          toast.success('Collection created successfully')
        }

        rqc.invalidateQueries(getV1FieldsListQueryKey()) // refresh fields table and map
      },
    },
  })

  return mutation
}

export function useHandleSubmitNewColln(field?: FieldReaderRetrieve): {
  handleSubmit: (e: React.FormEvent) => void
  mutationIsLoading: boolean
} {
  const mutation = useCreateCollnMutation()

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault()

    const { geometry } = field || {}
    const formData = new FormData(e.target as HTMLFormElement)
    const collnName = formData.get(COLLN_NAME_FORM_INPUT_NAME) as string
    const shouldUseFieldBoundary = geometry?.type === 'Polygon' || geometry?.type === 'MultiPolygon'

    let boundary = {}

    if (shouldUseFieldBoundary) {
      boundary = { boundary: geometry }
    }

    const payload = {
      field: (field?.id as string) || '',
      name: collnName,
      ...boundary,
    }

    mutation.mutate({ data: payload })
  }

  return { handleSubmit, mutationIsLoading: mutation.isLoading }
}

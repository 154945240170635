import { useState } from 'react'
import type { Control, ControllerRenderProps } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'

import { GeometrySourceEnum } from './types.fields-mgmt-api'

type Props = {
  control?: Control<FieldsMgmtFormSchema>
  disabled?: boolean
}

type GeometrySource = keyof typeof GeometrySourceEnum

type DropdownProps = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // so erratic, can't tell why, but it must be complicated!
  controlField?: ControllerRenderProps<FieldsMgmtFormSchema, 'geometry_source'>
  disabled?: boolean
  onChange?: (e: GeometrySource) => void
}

function FieldMgmtSourceDropdown(props: DropdownProps) {
  const { controlField = {}, onChange, disabled } = props
  const [source, setSource] = useState<GeometrySource>('UNKNOWN')

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value as GeometrySource

    setSource(value)

    if (onChange) onChange(value)
  }

  return (
    <TextField
      value={source}
      onChange={handleChange}
      {...controlField} // want control to override value and onChange, so put it after the spread
      required
      select
      disabled={disabled}
      helperText="Source of field's geometry"
      id="field-source-select"
      label="Geometry source"
      size="small"
    >
      {Object.keys(GeometrySourceEnum).map((src) => (
        <MenuItem key={src} value={src}>
          {src}
        </MenuItem>
      ))}
    </TextField>
  )
}

// react-hook-form Controller wrapper around the dropdown component
export function FieldSourceDropdownController(props: Props) {
  const { control, disabled } = props

  return (
    <Controller
      control={control}
      name="geometry_source"
      render={({ field }) => <FieldMgmtSourceDropdown controlField={field} disabled={disabled} />}
    />
  )
}

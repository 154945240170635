import { MdCopyAll } from 'react-icons/md'
import Button from '@mui/material/Button'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'
import { useIsCollnOnDevice } from 'components/soil-sampling/hooks.ssa'
import { useLocalCollnMutation } from 'components/soil-sampling/mutations.ssa-local'

import { useCollnPatchMutation } from '.'

type Props = {
  collnFullOrShortId: string
  fieldBoundary: GeoJSON.MultiPolygon | GeoJSON.Polygon | null
}

const SuccessToast = (
  <ToastContentWithTitle
    content="This is NOT dynamic. If the field changes, it does not change the collection automatically."
    title="Boundary was set to field"
  />
)

export function SetAcbFromExistingBtn(props: Props) {
  const { fieldBoundary, collnFullOrShortId } = props
  const remoteMutation = useCollnPatchMutation(collnFullOrShortId, SuccessToast)
  const { toolbarVisible } = useDrawToolsStateContext()
  const collnShortId = collnFullOrShortId.split('-')[0]
  const collnIsOnDevice = useIsCollnOnDevice(collnShortId)
  const localMutation = useLocalCollnMutation()

  function handleClick() {
    if (!fieldBoundary) {
      return
    }

    const payload = {
      id: collnShortId,
      data: { boundary: fieldBoundary },
    }

    if (collnIsOnDevice) {
      localMutation.mutate(payload)
    } else {
      remoteMutation.mutate(payload)
    }
  }

  return (
    <Button
      disabled={remoteMutation.isLoading || toolbarVisible || !fieldBoundary}
      startIcon={<MdCopyAll />}
      onClick={handleClick}
    >
      Use field
    </Button>
  )
}

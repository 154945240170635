import { Navigate, Outlet } from 'react-router-dom'
import { topLevelSearchParams } from 'lib/config/routes'
import { getEncodedCurrentPathnameAndSearch } from 'lib/utils/paths'

import { useAuth } from 'components/AuthProvider'
import { AppSetup } from 'components/reusable/AppSetup'
import { segments } from 'components/reusable/routes'

import { Header } from './Header'

const LOGIN_PATH = `/${segments.login}`

const rootStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  bottom: 'env(safe-area-inset-bottom)',
  top: 'env(safe-area-inset-top)',
  position: 'absolute',
}

/**
 * Layout wrapper around private routes, and an app setup component
 *
 * @returns full-page layout around authenticated routes
 */
export function Layout() {
  const auth = useAuth()
  const encodedIntendedRoute = getEncodedCurrentPathnameAndSearch()
  const to = `${LOGIN_PATH}?${topLevelSearchParams.from}=${encodedIntendedRoute}`

  if (!auth.isLoggedIn) {
    return <Navigate replace to={to} />
  }

  return (
    <>
      <AppSetup />
      <div id="layout" style={rootStyle}>
        <Header />
        <Outlet />
      </div>
    </>
  )
}

import { useState } from 'react'
import { RiPencilFill } from 'react-icons/ri'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { FormModal } from 'components/reusable/handy-templates/FormModal'
import type { SoilCollection } from 'components/soil-sampling/types.ssa'

import { useCollnPatchMutation } from './hooks.fmt-collns'

type Props = {
  collection?: SoilCollection
}

const SuccessToast = (
  <ToastContentWithTitle
    content="You can edit it again until the collection is submitted."
    title="Collection name set"
  />
)

export function EditCollnNameBtnAndModal({ collection }: Props) {
  const { id, name, status } = collection || {}
  const [modalOpen, setModalOpen] = useState(false)
  const [newCollnName, setNewCollnName] = useState(name || '')
  const hasBeenSubmitted = status === 'SUBMITTED'
  const mutation = useCollnPatchMutation(id || '', SuccessToast)

  function handleClick() {
    setModalOpen(true)
  }

  function handleSubmit() {
    mutation.mutate({
      id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: { name: newCollnName }, // TODO: EPIC: fix when backend ready
      // TODO: EPIC: tell Nik/Jamie that name needs to be PATCH-able
    })
  }

  let helperText = 'Enter a new name for this collection'

  if (hasBeenSubmitted) {
    helperText = 'Cannot edit collection name after submission'
  }

  const Btn = (
    <IconButton aria-label="edit collection name" edge="start" onClick={handleClick}>
      <RiPencilFill />
    </IconButton>
  )

  const Content = (
    <TextField
      fullWidth
      disabled={hasBeenSubmitted}
      helperText={helperText}
      label="Collection name"
      name="name"
      value={newCollnName}
      onChange={(e) => setNewCollnName(e.target.value)}
    />
  )

  return (
    <>
      {Btn}
      <FormModal
        content={Content}
        disableSubmit={hasBeenSubmitted || name === newCollnName}
        elemId="edit-collection-name"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        submitBtnText="Set collection name"
        title="Edit collection name"
        onSubmit={handleSubmit}
      />
    </>
  )
}

/**
 * Mapping utilities
 */
import { renderToStaticMarkup } from 'react-dom/server'
import type { MapRef } from 'react-map-gl'
import type { GridRowId } from '@mui/x-data-grid-premium'
import * as turf from '@turf/turf'
import { localStgAuthKeys } from 'lib/config/api'
import { VECTOR_TILES_PATH } from 'lib/config/map'
import { getLocalCurrentUserQuery } from 'lib/queries.user'
import type { Bbox2d } from 'lib/types'

import type { OverlayConfig } from 'components/reusable/maps/types'
import { soilMapperLayerIds } from 'components/tillmapper/map/config'
import type { Dimensions } from 'components/upload/map/types'

type MinMax = [] | [number, number]

/** Approximate cushion on the x-axis where auto-zoom can occur without hitting map controls */
const SAFE_HORIZONTAL_PADDING = 45
const GOOG_MAPS_BASE_URL = 'https://www.google.com/maps/dir/'

/**
 * Get the ideal-ish padding to put around the outer edge of the map. There is no hard-and-fast
 * rule- sometimes there will be some overlap with the map controls/attribution and the
 * layer/feature in question, e.g. with a very rectangular TillMapper instance with a very
 * rectangular map viewport. But in general the 30/40/50/60 look decent at their respective map
 * widths of 0-500, 500-800, 800-1200, and 1200+. Could probably get fancy and do some kind of
 * proportion-based math instead of hardcoded assumptions, and factor in the size and position of
 * the map controls/attribution while we're at it, but seems a liiiitle overkill.
 *
 * Note that the dimension ranges are for the map itself, not the browser viewport, and are not
 * necessarily the same as the MUI breakpoints.
 *
 * The reason for the other check regarding padding vs. dimension ratio is to prevent a crash for
 * smaller maps where the total padding on one axis is too big for the map itself.
 *
 * {@link https://docs.mapbox.com/mapbox-gl-js/example/offset-vanishing-point-with-padding/ Cool MB example}
 *
 * @param mapDimensions object with map width and height
 * @returns a single numeric value to be used as x and y padding in the map
 */
export const getSafeMapPadding = (mapDimensions: Dimensions): mapboxgl.PaddingOptions | number => {
  const { height, width } = mapDimensions

  let yAxisPadding = 30

  if (width >= 1200) {
    yAxisPadding = 60
  } else if (width >= 800) {
    yAxisPadding = 50
  } else if (width >= 500) {
    yAxisPadding = 40
  }

  // Prevent all kinds of crashes, e.g. when the viewport is really small
  if (yAxisPadding * 2 >= height || SAFE_HORIZONTAL_PADDING * 2 >= width) {
    return 0
  }

  return {
    top: yAxisPadding,
    bottom: yAxisPadding,
    left: SAFE_HORIZONTAL_PADDING,
    right: SAFE_HORIZONTAL_PADDING,
  }
}

// NOTE: this will omit all falsy values, including 0
export function getUniqueFeatValues<Schema>(
  features: GeoJSON.Feature<GeoJSON.Geometry, Schema>[],
  key: keyof Schema
): unknown[] {
  const noEmpties = features.filter((feature) => feature.properties[key])
  const mapped = noEmpties.map(({ properties }) => properties[key])

  return Array.from(new Set(mapped))
}

export function getMapDimensions(map: MapRef): Dimensions {
  const mapContainer = map.getContainer()
  const { offsetHeight: height, offsetWidth: width } = mapContainer

  return { width, height }
}

// TODO: unit test
export function getMinMaxFromFeatProps<Schema>(pointSymbOption: keyof Schema) {
  return (all: MinMax, feature: GeoJSON.Feature<GeoJSON.Geometry, Schema>): MinMax => {
    const { properties } = feature
    const value = properties[pointSymbOption]
    const [min, max] = all

    if (typeof value !== 'number') return all
    if (!min || !max) return [value, value]

    return [Math.min(min, value), Math.max(max, value)]
  }
}

export function getLayerValueAtUserLoc<ReturnType>(
  mapObj: MapRef,
  pos: GeolocationCoordinates | undefined,
  layerId = soilMapperLayerIds.rx,
  propertyKey = 'Tillage_Certainty'
): ReturnType | undefined {
  if (!pos) return undefined

  const point = mapObj.project({ lon: pos.longitude, lat: pos.latitude })
  const feats = mapObj.queryRenderedFeatures(point, { layers: [layerId] })
  const firstFeat = feats[0]

  if (
    firstFeat &&
    firstFeat.properties &&
    firstFeat.properties[propertyKey] !== undefined // TODO: reconsider logic
  ) {
    return firstFeat.properties[propertyKey] as ReturnType
  }

  return undefined
}

// CRED: github.com/mapbox/mapbox-gl-js/issues/5529#issuecomment-503799896
const svgPathToImage = (path: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image(36, 36)

    image.addEventListener('load', () => resolve(image))
    image.src = path
  })
}

export const symbolAsInlineImage = (icon: React.ReactElement): Promise<HTMLImageElement> => {
  const svg = renderToStaticMarkup(icon)
  const encoded = window.btoa(svg)

  return svgPathToImage(`data:image/svg+xml;charset=utf-8;base64,${encoded}`)
}

/**
 * `transformRequest` is the basically Mapbox's equivalent of `Axios.interceptors.request`, except
 * it's baked nicely into the `ReactMapGL` component. This function is responsible for intercepting
 * any outgoing map requests, and tacking on an access token for anything hitting our vector tiles
 * endpoint. At time of writing, 08/14/2023, we can conveniently obtain both the token and the user
 * object from local storage (user object is stored locally so that SoilCollector users can work
 * offline).
 *
 * See {@link https://docs.mapbox.com/mapbox-gl-js/api/properties/#requestparameters Mapbox docs}
 * for more info.
 *
 * **NOTE:** if we ever cleave off SoilCollector from the rest of the app, there is a chance we
 * wouldn't store the user object in local storage, in which case we'd need to use the
 * `useCurrentUser` hook here instead (and rename and move this util to hook).
 *
 * @param url request url of the data source to be transformed
 * @returns Mapbox `TransformRequestFunction` with the original url if it's not hitting our vector
 * tiles endpoint, or the original url with the user id appended and the user's access token
 * obtained from local storage.
 */
export const getVectorTilesTransformRequest: mapboxgl.TransformRequestFunction = (url: string) => {
  if (!url.startsWith(VECTOR_TILES_PATH) || !getLocalCurrentUserQuery()) {
    return { url }
  }

  const userId = getLocalCurrentUserQuery().id
  const accessToken = localStorage.getItem(localStgAuthKeys.access)

  return {
    url: `${url}&user_id=${userId}`,
    headers: { Authorization: `Bearer ${accessToken}` },
  }
}

/**
 * Get the two-dimensional bounding box for a set of features.
 *
 * @param filteredTableRowIds array of row IDs to filter by in each feature's `properties`
 * @param features array of GeoJSON features to filter
 * @param propertyKey key to filter by in each feature's `properties`
 * @returns bbox of filtered features if row IDs are provided, otherwise bbox of all features
 */
export function getFilteredFeaturesBbox<T extends GeoJSON.Feature>(
  filteredTableRowIds: GridRowId[],
  features: T[],
  // CRED: Copilot
  propertyKey: keyof T['properties'] extends string ? keyof T['properties'] : never
): Bbox2d | undefined {
  let bbox: Bbox2d | undefined

  if (filteredTableRowIds.length) {
    const filteredFeats = features.filter(
      (c) => c.properties && filteredTableRowIds?.includes(c.properties[propertyKey] as string)
    )

    bbox = turf.bbox(turf.featureCollection(filteredFeats)) as Bbox2d
  } else {
    bbox = turf.bbox(turf.featureCollection(features)) as Bbox2d
  }

  return bbox
}

// TODO: consider using Mapbox's optimized-trips API, or their super-nice directions plugin:
// - https://api.mapbox.com/optimized-trips/v1/mapbox/driving/
// - https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-directions/
export function getGoogMapsBaseUrl(stops: [number, number][]): string {
  let suffix = ''

  if (stops.length === 1)
    suffix = `?api=1&destination=${stops[0][0]},${stops[0][1]}&travelmode=driving`
  else if (stops.length > 1) {
    suffix = `${stops.map((stop) => stop.join(',')).join('/')}?travelmode=driving`
  }

  return GOOG_MAPS_BASE_URL + suffix
}

/**
 * Get the IDs of all map overlays that should be hidden by default.
 *
 * @param overlaysConfig optional map overlays config array
 * @returns array of layer IDs
 */
export function getIdsOfHiddenOverlays(overlaysConfig: OverlayConfig[] = []): string[] {
  let idsOfHiddenOverlays: string[] = []

  const items = overlaysConfig?.filter((config) => config.hiddenByDefault)

  items.forEach((item) => {
    idsOfHiddenOverlays = [...idsOfHiddenOverlays, ...item.layerIds]
  })

  return idsOfHiddenOverlays
}

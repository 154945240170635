import { createContext, useContext, useReducer } from 'react'

import { gridLineColorLocalStg } from './map-ctrls/grid/utils'

type MarkerFromCoords = {
  lat: number
  lon: number
}

export type GridType = 'grid' | 'lines' | 'none'

export type GridLayerSettings = {
  /**
   * @default 60 // maximum swath width (today is 01/18/2024)
   * @example 10 acres: 660, 5 acres: 467, 2.5 acres: 330, 1 acre: 208.71
   * {@link https://sciencing.com/calculate-acreage-triangle-8082030.html How to Calculate the Acreage of a Triangle}
   */
  cellSideInFeet: number
  /**
   * Longitude, latitude
   */
  center: [number, number] | null
  /**
   * @default 0
   */
  rotationAngle: number
  /**
   * At time of writing (01/18/2024), the most common use case for the "grid" tool is for scanning,
   * so we technically default to `none` but it quickly gets set to `lines` when the Grid modal is
   * first opened.
   *
   * @default "none"
   */
  gridType: GridType
  /**
   * Show/hide the small panel at the bottom of the screen
   *
   * @default false
   */
  miniToolbarOpen: boolean
}

type InteractiveMapState = {
  markerFromCoords: MarkerFromCoords | null
  squareGridProps: GridLayerSettings
  /**
   * Grid line color. Keeping this separate from the `GridLayerSettings` object since it's easier to
   * preserve it in local storage independently, and because the grid settings object is optional.
   *
   * @default cyan
   */
  gridLineColor: string
}

type Props = {
  children: React.ReactNode
}

type Action =
  | { type: 'CLEAR_MARKER_FROM_COORDS' }
  | { type: 'SET_GRID_COLOR'; payload: string }
  | { type: 'SET_MARKER_FROM_COORDS'; payload: MarkerFromCoords }
  | { type: 'SET_SQUARE_GRID_PROPS'; payload: Partial<GridLayerSettings> | null }

type Dispatch = React.Dispatch<Action>

const DEFAULT_GRID_CELL_SIZE_IN_FEET = 60

const initialState: InteractiveMapState = {
  markerFromCoords: null,
  gridLineColor: gridLineColorLocalStg.get(),
  squareGridProps: {
    cellSideInFeet: DEFAULT_GRID_CELL_SIZE_IN_FEET,
    center: null,
    rotationAngle: 0,
    gridType: 'none',
    miniToolbarOpen: false,
  },
}

const StateContext = createContext<InteractiveMapState | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: InteractiveMapState, action: Action) {
  switch (action.type) {
    case 'SET_MARKER_FROM_COORDS':
      return { ...state, markerFromCoords: action.payload }
    case 'SET_GRID_COLOR':
      return { ...state, gridLineColor: action.payload }
    case 'CLEAR_MARKER_FROM_COORDS':
      return { ...state, markerFromCoords: null }
    case 'SET_SQUARE_GRID_PROPS':
      return { ...state, squareGridProps: { ...state.squareGridProps, ...action.payload } }
    default: {
      return state
    }
  }
}

export function InteractiveMapContext(props: Props) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useInteractiveMapState(): InteractiveMapState {
  const context = useContext(StateContext)

  if (context !== undefined) return context

  throw new Error('useMapContext must be used within a Provider')
}

function useInteractiveMapDispatch(): Dispatch {
  const context = useContext(DispatchContext)

  if (context !== undefined) return context

  throw new Error('useMapDispatch must be used within a Provider')
}

export { useInteractiveMapDispatch, useInteractiveMapState }

import { useCallback } from 'react'
import type { GridInitialState } from '@mui/x-data-grid-premium'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { useV1UiTableViewsTableviewsList } from 'lib/api/django/v1/v1'
import { useCurrentUser } from 'lib/queries.user'
import { sortArrOfObjects } from 'lib/utils'

import {
  createMenuItemsFromViews,
  showActiveViewSuccessToast,
  showResetViewSuccessToast,
} from './utils'

/**
 * Hook to get the click handler for the table view reset button.
 *
 * @param initialState initial table state, if provided
 * @returns click handler for the table view reset button
 */
export function useHandleResetBtnClick(initialState?: GridInitialState | undefined): () => void {
  const apiRef = useGridApiContext()

  // NOTE: to reset column order, the `orderedFields` property must be part of the default state.
  // See `config.ssa-landing-cols.tsx` for example.
  /**
   * Click handler for the table view reset button.
   */
  function handleClick(): void {
    if (apiRef.current && initialState) {
      apiRef.current.restoreState(initialState)
      showResetViewSuccessToast()
    }
  }

  return handleClick
}

/**
 * Hook to get the click handler for activating a saved table view.
 *
 * @param parentTableApiRef Grid API ref of the parent table, if provided. The use case here is to
 * activate a saved view from within another table, in this case the "Saved views" management table.
 * The parent ref must be passed since the "Saved views" table is technically nested within it but
 * has its own context.
 * @returns click handler for activating a saved table view
 */
export function useActivateSavedView(
  parentTableApiRef?: React.MutableRefObject<GridApiPremium>
): (savedViewName: string, state?: GridInitialStatePremium) => void {
  const apiRef = useGridApiContext()

  return useCallback(
    (savedViewName: string, state?: GridInitialStatePremium) => {
      if (!state) {
        return
      }

      if (parentTableApiRef) {
        parentTableApiRef.current.restoreState(state)
      } else if (apiRef.current) {
        apiRef.current.restoreState(state)
      }

      showActiveViewSuccessToast(savedViewName)
    },
    [apiRef, parentTableApiRef]
  )
}

/**
 * Hook to get the 5 most recent saved view menu items for the current user.
 *
 * @param tableSlug see `ReusableTableProps` for guidance
 * @returns array of the 5 most recent saved view menu items for the current user
 */
export function useCurrentUserMostRecentViews(
  tableSlug: string | false
): ReturnType<typeof createMenuItemsFromViews> {
  const user = useCurrentUser()
  const views = useV1UiTableViewsTableviewsList()
  const handleClick = useActivateSavedView()

  if (!tableSlug || user.isLoading || views.isLoading) {
    return []
  }

  if (user.isError || views.isError) {
    return [
      {
        uniqueKey: 'table-views-error',
        text: 'Error loading saved views',
      },
    ]
  }

  // Shouldn't be possible to get here if the consumer of this hook is doing its job, but just in
  // case, show the user something.
  if (!views.data) {
    return [
      {
        uniqueKey: 'table-views-no-data',
        text: 'No saved views found',
      },
    ]
  }

  const filteredAndSorted = views.data
    .filter((view) => tableSlug === view.table_slug && view.created_by.id === user.data?.id)
    .sort(sortArrOfObjects('updated_at'))

  filteredAndSorted.reverse()

  return createMenuItemsFromViews(filteredAndSorted.slice(0, 5), handleClick)
}

import { FaDrawPolygon } from 'react-icons/fa'
import Button from '@mui/material/Button'

import {
  useDrawToolDispatchContext,
  useDrawToolsStateContext as useDrawToolsState,
} from 'components/reusable/maps/draw-and-annotate'

export function EditFeatureGeomBtn() {
  const drawToolDispatch = useDrawToolDispatchContext()
  const { toolbarVisible } = useDrawToolsState()

  function handleClick() {
    drawToolDispatch({
      type: 'SET_ALLOWED_MODES',
      payload: ['draw_polygon', 'draw_point'],
    })

    drawToolDispatch({
      type: 'SET_GEOMETRY_MODE',
      payload: 'draw_polygon',
    })

    drawToolDispatch({
      type: 'TOGGLE_TOOLBAR_VISIBILITY',
      payload: true,
    })

    drawToolDispatch({
      type: 'SET_ACTIVE_TOOLBAR_ID',
      payload: 'field',
    })
  }

  return (
    <Button
      disabled={toolbarVisible}
      size="small"
      startIcon={<FaDrawPolygon />}
      variant="contained"
      onClick={handleClick}
    >
      edit boundary
    </Button>
  )
}

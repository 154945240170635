import type { Expression } from 'mapbox-gl'

import { EXISTING_SYMB_COLOR, SELECTED_FEATURE_COLOR } from 'components/reusable/maps/symbology'
import {
  existing2Dfill,
  existingPoints,
} from 'components/reusable/maps/symbology/config.fields-mgmt-map'

import type { FieldReaderRetrieve } from './fields-mgmt/types.fields-mgmt-api'

// TODO: rm when backend omits these by default or provides filter
export function getShouldHideField(status: FieldReaderRetrieve['properties']['status']): boolean {
  return status === 'ARCHIVED' || status === 'UNKNOWN'
}

/**
 * Get the Mapbox `Expression` filters for 2D features, points, and labels.
 *
 * @param fieldIds list of Field IDs to filter on
 * @returns object with Mapbox `Expression` filters for 2D features, points, and labels
 */
export function getFmtFieldFeatsFilters(fieldIds: string[] | null): {
  points: Expression
  twoDimensional: Expression
  labels: Expression
} {
  const tableFiltersPresent = fieldIds !== null
  const fieldIdsFilter: Expression = ['in', ['get', 'id'], ['literal', fieldIds]]
  const nonLabelsFilter = tableFiltersPresent ? [fieldIdsFilter] : []

  const twoDimensional: Expression = [
    'all',
    existing2Dfill.filter,
    ...nonLabelsFilter,
  ]

  const points: Expression = [
    'all',
    existingPoints.filter,
    ...nonLabelsFilter,
  ]

  return {
    points,
    twoDimensional,
    labels: fieldIdsFilter,
  }
}

/**
 * Get color for selected points and 2D features.
 *
 * @param ids list of selected IDs, or null
 * @returns if anything is selected, a Mapbox `Expression` to use `SELECTED_FEATURE_COLOR` if
 * selected, otherwise default to `EXISTING_SYMB_COLOR`.
 */
export function getFmtSelectedFeatsColor(ids: string[] | null): Expression | string {
  const atLeastOneFeatSelected = ids !== null
  const idsFilter: Expression = ['in', ['get', 'id'], ['literal', ids]]

  const selectedColor: Expression = [
    'case',
    idsFilter,
    SELECTED_FEATURE_COLOR,
    EXISTING_SYMB_COLOR,
  ]

  return atLeastOneFeatSelected ? selectedColor : EXISTING_SYMB_COLOR
}

/**
 * Fields API queries
 */
import { useParams } from 'react-router-dom'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import * as turf from '@turf/turf'
import { apiEndpoints, axiosInstance } from 'lib/config'

import type { Batch, Field } from 'components/upload/common/types'

import type { FieldIdFromRoute } from './types'
import type { CommonAttributes } from './types.maps'

type FieldFeature = GeoJSON.Feature<GeoJSON.Geometry, CommonAttributes>

async function fetchField(fieldId: string): Promise<Field> {
  const res = await axiosInstance.get(`${apiEndpoints.fields}${fieldId}/`)

  return res.data
}

async function fetchFieldUploads(fieldId: string): Promise<Batch[]> {
  const res = await axiosInstance.get(`${apiEndpoints.fields}${fieldId}/uploads/`)

  return res.data
}

// TODO: switch to useBatch() for SoilMapper
// TODO: bbox not needed if B.E. provides
// TODO: see if BE can flatten properties into `extras__grower`, for example
export function useFieldFeature(
  customFieldId?: string,
  disable?: boolean
): UseQueryResult<FieldFeature> {
  const { fieldId: fieldIdFromCurrentRoute } = useParams<FieldIdFromRoute>()
  const fieldId = customFieldId || fieldIdFromCurrentRoute || ''

  return useQuery(['field-feature', fieldId], () => fetchField(fieldId), {
    enabled: !!fieldId && !disable,
    // 1. Flatten data because MB can't hang with nested GeoJSON.
    // 2. Add bbox until BE provides.
    select: (field) => {
      const { geometry } = field

      return {
        type: 'Feature',
        bbox: turf.bbox(geometry),
        // Important that extras is FIRST so that any attribs which may have been "promoted" from
        // extras into top level of Field object are overwritten by them. e.g. `Field.extras.farm`
        // became `Field.farm`.
        properties: {
          ...field.extras,
          ...field,
          geometry: null,
        },
        geometry,
      }
    },
  })
}

export function useUploadsFromField(fieldId: string, enabled = true): UseQueryResult<Batch[]> {
  return useQuery(['field-uploads', fieldId], () => fetchFieldUploads(fieldId), {
    enabled: !!fieldId && enabled,
  })
}

import { AiOutlineReload } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

type Props = {
  icon: React.ReactNode
  text: string
  /** For nested lists */
  shouldIndent?: boolean
  children?: React.ReactNode
  route?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

// Btns take up too much real estate if minWidth is not overriden
const sxCommon = { minWidth: 24 }

export const ReloadPageListItemBtn = (
  <ListItemButton divider onClick={() => window.location.reload()}>
    <ListItemIcon sx={sxCommon}>
      <AiOutlineReload />
    </ListItemIcon>
    <ListItemText primary="Refresh page" />
  </ListItemButton>
)

export function DrawerListItemLink(props: Props) {
  const { icon, route, text, children, onClick, shouldIndent } = props

  const Content = (
    <>
      <ListItemIcon sx={sxCommon}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      {children}
    </>
  )

  if (route) {
    return (
      <ListItemButton
        divider
        component={RouterLink}
        sx={{ pl: shouldIndent ? 4 : undefined }}
        to={route}
      >
        {Content}
      </ListItemButton>
    )
  }

  if (onClick) {
    return (
      <ListItemButton divider sx={{ pl: shouldIndent ? 3 : undefined }} onClick={onClick}>
        {Content}
      </ListItemButton>
    )
  }

  return <ListItem divider>{Content}</ListItem>
}

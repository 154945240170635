import type { Control, FieldValues, FormState } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import type { Contact } from 'lib/api/django/model'

import { OrgAutocomplete } from 'components/reusable/OrgAutocomplete'

import { contactsFormControls } from './config.contacts'

type Props = {
  control: Control<Contact>
  formState: FormState<Contact>
  isLoading: boolean
}

export function ContactFormControls(props: Props) {
  const { control, formState, isLoading } = props

  return (
    <Box
      columnGap={2}
      display="grid"
      gridTemplateColumns="1fr 1fr"
      justifyContent="center"
      mb={3}
      rowGap={3}
    >
      <OrgAutocomplete
        required
        control={control as unknown as Control<FieldValues>} // 🙄
        disabled={isLoading}
      />
      {contactsFormControls.map((ctrlConfig) => (
        <Controller
          key={ctrlConfig.name}
          control={control}
          name={ctrlConfig.name}
          render={({ field }) => (
            <TextField
              size="small"
              {...field}
              {...ctrlConfig.props}
              disabled={isLoading}
              error={!!formState.errors[ctrlConfig.name]}
              helperText={formState.errors[ctrlConfig.name]?.message || ctrlConfig.props.helperText}
            >
              {ctrlConfig.children?.map((child) => child)}
            </TextField>
          )}
        />
      ))}
    </Box>
  )
}

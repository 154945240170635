import type { GridColDef, GridColumnsInitialState } from '@mui/x-data-grid-premium'
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-premium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

/**
 * In order for dimensions to be restored on reset, we need to start with an initial dimensions
 * state. This function will do that based on the `field` in each config item and its `width` if
 * available.
 *
 * @param columnsConfig array of column definitions
 * @returns `dimensions` object based on `field` in each `colsConfig` item
 */
export function getDefaultColumnDimensions(
  columnsConfig: GridColDef[]
): GridColumnsInitialState['dimensions'] {
  return columnsConfig.reduce((all, { width, field }) => {
    if (!width) {
      return {
        ...all,
        [field]: {
          maxWidth: -1,
          minWidth: 50,
          width: 75,
        },
      }
    }

    return {
      ...all,
      [field]: { width },
    }
  }, {})
}

/**
 * Get sensible defaults for MUI DataGrid tables. This is primarily used for two things:
 *
 * 1. setting the initial state of the table when there is none available either through
 *    `localStorage` or the "UI table view" id.
 * 2. resetting the state of the table on "reset view" button click.
 *
 * @param columnsConfig array of column definitions
 * @param overrides for the most part, any of the initial state to override. See how it's spread in
 * the implementation though, e.g. `orderedFields` can be overridden wholesale, but you can't spread
 * more fields into it.
 * @example pinnedColumns: { left: ['a'] }
 * @param excludeCheckboxColumn if `true`, the checkbox column will not be included in the
 * `orderedFields` array.
 * @returns initial state object for the table
 */
export function getDefaultInitialTableState(
  columnsConfig: GridColDef[],
  overrides?: GridInitialStatePremium,
  excludeCheckboxColumn?: boolean
): GridInitialStatePremium {
  const dimensions = getDefaultColumnDimensions(columnsConfig)

  return {
    filter: { filterModel: { items: [] } },
    sorting: { sortModel: [] },
    pinnedColumns: {},
    ...overrides,
    columns: {
      columnVisibilityModel: {},
      dimensions,
      orderedFields: [
        ...(excludeCheckboxColumn ? [] : [GRID_CHECKBOX_SELECTION_FIELD]),
        ...columnsConfig.map((c) => c.field),
      ],
      ...overrides?.columns,
    },
  }
}

import type { CollectionStatusEnum } from 'lib/api/django/model'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { Action, Filters, State } from './types.sc-global-filters'

export const useSCglobalFiltersStore = create<State & Action>()(
  persist(
    (set) => ({
      localOnly: typeof window !== 'undefined' && !!window.localStorage.getItem('localOnly'),
      filters: {
        assignee: [],
        dispatcher: [],
        org: [],
        status: [],
        team_contact: [],
        team_members: [],
      },
      actions: {
        clearAllFilters: () => ({}),
        setLocalOnly: (localOnly: boolean) => set({ localOnly }),
        setFilter: (filterColumnName, filters) =>
          set((store) => ({
            filters: {
              ...store.filters,
              [filterColumnName]: filters,
            },
          })),
      },
    }),
    {
      name: 'sc-global-filters',
      // Only preserve the state, not the action
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => key !== 'actions')),
    }
  )
)

/**
 * Store actions, aka setters
 *
 * @returns just the actions from the store - (see {@link Action type docs})
 */
export function useSCglobalFiltersActions(): {
  clearAllFilters: () => void
  setLocalOnly: (localOnly: boolean) => void
  setFilter: (column: keyof Filters, newState: string[]) => void
} {
  return useSCglobalFiltersStore((store) => store.actions)
}

/**
 * Get the whole Filters object from the store
 *
 * @returns Filters object from the store - (see {@link Filters type docs})
 */
export function useAllSCglobalFilters(): Filters {
  return useSCglobalFiltersStore((store) => store.filters)
}

/**
 * Get individual filters list from store, e.g. `status`
 *
 * @param filterColumnName key of {@link Filters Filters}
 * @returns array of filter items from store for the provided key - (see {@link Filters type docs})
 */
export function useSCglobalFilter(
  filterColumnName: keyof State['filters']
): CollectionStatusEnum[] | string[] {
  return useSCglobalFiltersStore((store) => store.filters[filterColumnName])
}

import type { V1SoilSamplingCollectionsListParams } from 'lib/api/django/model'

import { COLLN_LIST_COLS_TO_HIDE } from 'components/soil-sampling/config.ssa'

import type { State } from './types.sc-global-filters'

export function prepCollnsQueryArgs(
  filters: State['filters']
): Partial<V1SoilSamplingCollectionsListParams> {
  const entries = Object.entries(filters)

  const params: Partial<V1SoilSamplingCollectionsListParams> = {
    hide: COLLN_LIST_COLS_TO_HIDE.join(','),
  }

  entries.forEach(([k, v]) => {
    if (v.length) {
      params[k as keyof V1SoilSamplingCollectionsListParams] = v.sort().join(',')
    }
  })

  return params
}

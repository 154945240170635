import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import * as turf from '@turf/turf'
import type { Bbox2d } from 'lib/types'
import type { MapLayerMouseEvent } from 'mapbox-gl'

import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'
import { useTableBoxSelectEventHandlers } from 'components/reusable/maps/map-ctrls/box-select'
import {
  EXISTING_FIELDS_SRC_ID,
  existing2Dfill,
  existingLabelsLayerProps,
  existingPoints,
  IMPORTED_FIELDS_SRC_ID,
  imported2Dfill,
  importedLabelsLayerProps,
  importedPoints,
  interactiveNewFieldLayerIds,
} from 'components/reusable/maps/symbology'
import { useMapTableDispatch } from 'components/reusable/tables/context'

import { useStateContext } from './create-fields-steps/NewFieldContext'
import type { AllFieldAttribs } from './fields-mgmt/types.fields-mgmt-api'
import { segments, wildcards } from './routes'
import type { ImportedFeature } from './types.fields-mgmt'

const BOX_SELECT_UNIQUE_ID_PROP: keyof AllFieldAttribs = 'id'
const BOX_SELECT_UNIQUE_NAME_PROP: keyof AllFieldAttribs = 'name'

const EXISTING_FIELD_PATH = [
  segments.base,
  segments.fields,
  wildcards.fieldId,
  '*',
].join('/')

function useClickedImportedFieldFeat(): (e: MapLayerMouseEvent) => void {
  const navigate = useNavigate()
  const { toolbarVisible } = useDrawToolsStateContext()

  // Navigate to clicked feature's detail/edit view if applicable.
  return useCallback(
    (e: MapLayerMouseEvent) => {
      if (toolbarVisible) {
        return // avoid conflicts with Draw clicks
      }

      const newFieldClickQuery = e.target.queryRenderedFeatures(e.point, {
        layers: interactiveNewFieldLayerIds,
      })

      const topmostFeature = newFieldClickQuery[0]

      // Need to check existence because of Draw clicks
      if (!topmostFeature || !topmostFeature.properties?.id) {
        return
      }

      let mid = ''

      if (topmostFeature.source === IMPORTED_FIELDS_SRC_ID) {
        mid = segments.fieldsImport
      } else if (topmostFeature.source === EXISTING_FIELDS_SRC_ID) {
        mid = segments.fields
      }

      if (mid) {
        navigate(`${segments.base}/${mid}/${topmostFeature.properties?.id}`)
      }
    },
    [toolbarVisible, navigate]
  )
}

export function useDispatchBboxFromFeat(geom?: GeoJSON.Geometry): void {
  const mapTableDispatch = useMapTableDispatch()
  const geometry = useMemo(() => geom, [geom])
  const { pathname } = useLocation()

  useEffect(() => {
    if (!geometry) {
      return
    }

    mapTableDispatch({
      type: 'SET_MAP_BBOX',
      payload: {
        bbox: turf.bbox(geometry) as Bbox2d,
        isPointFeature: geometry.type === 'Point',
        instantZoom: false,
      },
    })
  }, [geometry, mapTableDispatch, pathname])
}

export function useExistingFieldIdFromRoute(): string | undefined {
  const match = useMatch(EXISTING_FIELD_PATH)

  return match?.params.fieldId
}

export function useImportedFieldFromRoute(): ImportedFeature | undefined {
  const PATH = `${segments.base}/${segments.fieldsImport}/:id`
  const match = useMatch(PATH)
  const { importedFeatures } = useStateContext()

  return importedFeatures.find((f) => f.id === match?.params.id)
}

/**
 * Hook to return a function to run when an imported Field is clicked.
 *
 * @returns function to run when an imported Field is clicked
 */
export function useOnClickCallback(): (e: MapLayerMouseEvent) => void {
  const cb = useClickedImportedFieldFeat()

  function callback(e: MapLayerMouseEvent): void {
    cb(e)
  }

  return callback
}

/**
 * Hook to return the box select event handlers for the FMT map.
 *
 * @returns event handlers for mouse movement events, geolocation, and map clicks
 */
export function useFmtBoxSelectEventHandlers(): ReturnType<typeof useTableBoxSelectEventHandlers> {
  return useTableBoxSelectEventHandlers(
    [
      existing2Dfill.id,
      imported2Dfill.id,
      existingPoints.id,
      importedPoints.id,
      existingLabelsLayerProps.id,
      importedLabelsLayerProps.id,
    ],
    BOX_SELECT_UNIQUE_ID_PROP,
    BOX_SELECT_UNIQUE_NAME_PROP,
    'fields'
  )
}

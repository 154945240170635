import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { UITableView } from 'lib/api/django/model'
import { useV1UiTableViewsTableviewsRetrieve } from 'lib/api/django/v1/v1'

import { SAVED_VIEW_ROUTE_LOC_STATE_KEY, type wildcards } from './config'

type Props = {
  redirectTo: string
}

function useTableViewByIdRouteParam(): ReturnType<
  typeof useV1UiTableViewsTableviewsRetrieve<UITableView>
> {
  const { savedViewId = '' } = useParams<typeof wildcards>()

  return useV1UiTableViewsTableviewsRetrieve(savedViewId, {
    query: {
      enabled: !!savedViewId,
      onError: () => {
        toast.warn(
          `Could not find table view with id "${savedViewId}". Falling back to last known view, if available.`,
          { toastId: savedViewId }
        )
      },
    },
  })
}

/**
 * This is used to redirect to the parent route of a saved view. It is not meant to be accessed or
 * seen directly in the UI, but rather only indirectly via a copy/pasted shared URL. The user will
 * be taken to the `redirectTo` route (after the saved view is loaded) with the saved view
 * piggybacked onto the `location.state`. The temporary view will only flash briefly as the via is
 * loading, and the user will be taken to the correct view, and the interim view will be removed
 * from the history. Errors and "not found" states will also redirect so that the user is not stuck
 * at the interim page.
 *
 * @param props see Props definition
 */
export function SavedViewRedirectToParent(props: Props) {
  const { redirectTo } = props
  const navigate = useNavigate()
  const { data: savedView, isInitialLoading, isError } = useTableViewByIdRouteParam()

  useEffect(() => {
    if (isInitialLoading) {
      return
    }

    if (isError || !savedView?.state) {
      navigate(redirectTo, { replace: true })

      return
    }

    navigate(redirectTo, { state: { [SAVED_VIEW_ROUTE_LOC_STATE_KEY]: savedView } })
  }, [
    isError,
    isInitialLoading,
    navigate,
    redirectTo,
    savedView,
  ])

  return null
}

import { AiOutlineCloudDownload, AiOutlineCloudUpload, AiOutlineSync } from 'react-icons/ai'
import { BsAsterisk } from 'react-icons/bs'
import { FiServer } from 'react-icons/fi'
import { MdTabletMac } from 'react-icons/md'
import { RiSignalWifiOffLine } from 'react-icons/ri'
import type {
  CollectionStatusEnum,
  GroupEnum,
  ScanningRequirementStatusEnum,
  SoilCollectionReader,
  V1SoilSamplingCollectionsListParams,
} from 'lib/api/django/model'

// Using a separate key from the main collections list to avoid refetching/invalidation in
// unnecessary cases, e.g. after bulk editing.
export const UNDOWNLOADED_QUERY_KEY = ['undownloaded-keys']

/** Distance, in km, above which we start showing warnings */
export const RX_POINT_DISTANCE_THRESHOLD = 0.005

// At the time of writing, we really only need like 5-6 columns out of the full collection object:
// id (maybe?), short_id, status, team_contact, team_members.
const COLLN_DWNLD_COLS_TO_HIDE: (keyof SoilCollectionReader)[] = [
  'acres',
  'boundary',
  'completed_on',
  'completed_sample_count',
  'created_at',
  'dispatcher',
  'field_notes',
  'field',
  'lab',
  'labs',
  'name',
  'org',
  'required_sample_count',
  'requirements',
  'samples',
  'scanning_requirements',
  'scheduled_on',
  'special_instructions',
  'started_on',
  'submitter',
  'tracking_numbers',
  'updated_at',
]

// Columns to hide for the majority of the collection list GETs.
export const COLLN_LIST_COLS_TO_HIDE: (keyof SoilCollectionReader)[] = [
  'boundary',
  'lab',
  'requirements',
  'samples',
]

export const defaultRemoteCollnQueryParams: V1SoilSamplingCollectionsListParams = {
  hide: COLLN_LIST_COLS_TO_HIDE.join(','),
}

// TODO: why are these nested?? Just do `thing: <Icon />`.
export const ssaEnvIcons = {
  all: { icon: <BsAsterisk /> },
  downstream: { icon: <AiOutlineCloudDownload /> },
  upstream: { icon: <AiOutlineCloudUpload /> },
  remote: { icon: <FiServer /> },
  local: { icon: <MdTabletMac /> },
  offline: { icon: <RiSignalWifiOffLine /> },
  sync: { icon: <AiOutlineSync /> },
}

/**
 * User-friendlier version of the `status` of each `ScanningRequirement`.
 */
export const SENSOR_REQS_STATUS_MAP: { [key in ScanningRequirementStatusEnum]: string } = {
  REQUIRED: 'Required',
  DONE: 'Done',
  OPTIONAL: 'Optional',
  CANCELED: 'Canceled',
  DELETE: 'Not required',
}

// As of react-query v4 these now must be arrays, just like query keys.
export const MUTATION_KEYS = {
  localColln: ['localColln'],
  localSample: ['localSample'],
  remoteColln: ['remoteColln'],
  remoteSample: ['remoteSample'],
}

/**
 * Pattern used to keep query keys flexible and consistent across the app. Note that Orval follows
 * this same approach, however, thereby eliminating the need to write our own here- the ones below
 * are only used by `localForage`.
 *
 * {@link https://tkdodo.eu/blog/effective-react-query-keys Effective React Query Keys}
 */
export const localQueryKeys = {
  root: ['sc-local'] as const,
  collections: () => [...localQueryKeys.root, 'collections'] as const,
  collnDetail: (collnShortId: string) => [...localQueryKeys.collections(), collnShortId] as const,
  collectionsKeys: () => [...localQueryKeys.root, 'collection-keys'] as const,
}

// NOTE: all status and access-related UI decisions should be documented in Google Sheets:
// https://docs.google.com/spreadsheets/d/1aRaLvFAQHU5AM5gjhN_pe9Vt4qQYP5ZKeHweWbMRtG4

// Never returned from backend, no need to show
export const IMPOSSIBLE_STATUS_OPTIONS: CollectionStatusEnum[] = ['ABORTED', 'UNKNOWN']

// TODO: mv all status stuff into new file
export const PRE_COLLECTION_STATUSES: CollectionStatusEnum[] = [
  'NOT_YET_AVAILABLE',
  'ASSIGNED',
  'AVAILABLE',
]

export const AFTER_FIELDWORK_STATUSES: CollectionStatusEnum[] = [
  'SUBMITTED',
  'LAB_COMPLETE',
  'LAB_CONFIRMED',
]

// Exclude `NOT_YET_AVAILABLE` and anything after `SUBMITTED`. Basically anything that a field user
// might want if they are switching devices, or switching from laptop to iPad.
const DOWNLOADABLE_COLLN_STATUSES: CollectionStatusEnum[] = [
  'AVAILABLE',
  'ASSIGNED',
  'DOWNLOADED',
  'STARTED',
  'FIELDWORK_COMPLETE',
]

// Used to just have `STARTED` but that falls apart if the status isn't automatically set to
// `DOWNLOADED`.
export const STARTABLE_COLLN_STATUSES: CollectionStatusEnum[] = [
  'AVAILABLE',
  'ASSIGNED',
  'DOWNLOADED',
]

export const GROUPS_WHO_CAN_ASSIGN_COLLNS: GroupEnum[] = [
  'collection_manager',
  'dispatcher',
]

// IMPORTANT: keep these in order! It is used to determine if the remote status should overwrite
// that of the local. Does not include the FT-irrelvant ones: UNKNOWN, ABORTED, NOT_YET_AVAILABLE,
// WILL_NOT_COLLECT.
export const STATUSES_IN_LIFECYCLE_ORDER: CollectionStatusEnum[] = [
  'AVAILABLE',
  'ASSIGNED',
  'DOWNLOADED',
  'STARTED',
  'FIELDWORK_COMPLETE',
  'SUBMITTED',
  'LAB_COMPLETE',
  'LAB_CONFIRMED',
]

// The query for the "you have new collections to download" is pretty simple and only needs a
// handful of columns and a subset of collections based on status.
export const collnDwnldQueryParams: V1SoilSamplingCollectionsListParams = {
  hide: COLLN_DWNLD_COLS_TO_HIDE.join(','),
  status: DOWNLOADABLE_COLLN_STATUSES.join(','),
}

import type { GridColDef } from '@mui/x-data-grid-premium'
import { formatFileSize } from 'lib/utils'

type DataSchema = File

type ColumnDef = Omit<GridColDef<DataSchema>, 'field'> & {
  field: keyof DataSchema
}

export const config: ColumnDef[] = [
  {
    field: 'name',
    width: 300,
    headerName: 'Name',
  },
  {
    field: 'size',
    width: 100,
    headerName: 'Size',
    type: 'number',
    renderCell: ({ row }) => formatFileSize(row.size),
  },
]

import { useEffect, useState } from 'react'
import { FiFilter } from 'react-icons/fi'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import type { GridFilterModel } from '@mui/x-data-grid-premium'
import {
  GridFilterPanel,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid-premium'
import { useIsAtLeastPhablet, useIsTouchEnabled } from 'lib/hooks'

import { FormModal } from 'components/reusable/handy-templates/FormModal'

type Props = {
  /**
   * Content to show above the built-in filters
   */
  children?: React.ReactNode
}

const VALUE_INPUT_ELEM_ID = 'table-filters-value-input'

/**
 * So gross. Could NOT figure out how to blur the value input in the filter modal, which was
 * absolutely maddening on mobile since the keyboard would pop up and cover most of the screen. Very
 * disorienting.
 *
 * @param modalIsOpen is the modal open
 */
function useBlurValueInput(modalIsOpen: boolean): void {
  const touchIsEnabled = useIsTouchEnabled()

  useEffect(() => {
    if (touchIsEnabled && modalIsOpen) {
      setTimeout(() => {
        const valueInputElement = document.getElementById(VALUE_INPUT_ELEM_ID)

        valueInputElement?.blur()
      }, 1)
    }
  }, [modalIsOpen, touchIsEnabled])
}

export function ModalTableFilter(props: Props) {
  const { children } = props
  const isAtLeastPhablet = useIsAtLeastPhablet()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [filtersCount, setFiltersCount] = useState(0)
  const apiRef = useGridApiContext()

  function handleFiltersChange(evt: GridFilterModel) {
    const count = evt.items.length

    setFiltersCount(count)
  }

  useGridApiEventHandler(apiRef, 'filterModelChange', handleFiltersChange)
  useBlurValueInput(modalIsOpen)

  return (
    <>
      <FormModal
        isNotForm
        slideUp
        elemId="table-filters"
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        title="Filters"
        content={
          <>
            {children}
            <GridFilterPanel
              filterFormProps={{
                valueInputProps: {
                  InputComponentProps: {
                    id: VALUE_INPUT_ELEM_ID,
                  },
                },
              }}
              sx={{
                mx: !isAtLeastPhablet ? -3 : 'unset',
                '.MuiDataGrid-filterForm': { p: 0 },
              }}
            />
          </>
        }
      />
      <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title="Filters">
        <IconButton aria-label="filters" color="primary" onClick={() => setModalIsOpen(true)}>
          <Badge badgeContent={filtersCount} color="info">
            <FiFilter />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  )
}

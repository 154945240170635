import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import type { GridColDef } from '@mui/x-data-grid-premium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import type { UITableView } from 'lib/api/django/model'

import { DateTooltip } from 'components/reusable/info-surfaces/DateTooltip'
import { TwoCharColoredAvatar } from 'components/reusable/TwoCharColoredAvatar'

// Tests fail if anything in the utils file is imported via `from '.'` 🤷
import { ActivateTableViewLink } from './ActivateTableViewLink'
import { SavedViewsTableActionsCell } from './SavedViewsTableActionsCell'
import type { TableViewsTableProps } from './types'

type UITableViewKey = keyof UITableView

interface TableColumn {
  row: UITableView
}

export const SAVED_VIEW_ROUTE_LOC_STATE_KEY = 'savedView'

export const segments = {
  savedViews: 'saved-views',
}

export const wildcards = {
  savedViewId: ':savedViewId',
}

export function getColumns(settings: TableViewsTableProps): GridColDef[] {
  return [
    {
      field: 'name' as UITableViewKey,
      headerName: 'Name',
      width: 225,
      renderCell: function renderCell({ row }: TableColumn) {
        return (
          <ActivateTableViewLink
            parentTableApiRef={settings.parentTableApiRef}
            view={row}
            onClick={settings.onSavedViewActivated}
          />
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 100,
      renderCell: function renderCell({ row }: TableColumn) {
        return <SavedViewsTableActionsCell baseRoute={settings.parentTableBaseRoute} view={row} />
      },
    },
    {
      field: 'created_at' as UITableViewKey,
      headerName: 'Created',
      type: 'date',
      width: 120,
      valueGetter: ({ row }: TableColumn) => new Date(row.updated_at),
      renderCell: function renderCell({ row }: TableColumn) {
        return <DateTooltip date={row.updated_at} />
      },
    },
    {
      field: 'creator' as UITableViewKey,
      headerName: 'Creator',
      valueGetter: ({ row }: TableColumn) =>
        `${row.created_by.first_name} ${row.created_by.last_name}`,
      renderCell: function renderCell({ row }: TableColumn) {
        const firstName = row.created_by.first_name
        const lastName = row.created_by.last_name
        const fullName = `${firstName} ${lastName}`

        return (
          <Tooltip arrow placement="right" title={fullName}>
            <Box alignItems="center" display="flex" gap={1}>
              <TwoCharColoredAvatar text={fullName} />
              {firstName}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      field: 'is_private' as UITableViewKey,
      headerName: 'Private',
      width: 100,
      type: 'boolean',
    },
    {
      field: 'description' as UITableViewKey,
      headerName: 'Description',
      width: 500,
    },
  ]
}

export const defaultStateOverrides: GridInitialStatePremium = {
  sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
}

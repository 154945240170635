import { useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { FaSeedling } from 'react-icons/fa'
import { FiMenu } from 'react-icons/fi'
import { GiField } from 'react-icons/gi'
import { VscBeaker } from 'react-icons/vsc'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import * as Sentry from '@sentry/react'
import { topLevelAbsoluteAppRoutes } from 'lib/config'
import { useDynamicElemItemWidth } from 'lib/hooks'
import { useCanAccessBasedOnPerms, useCurrentUser } from 'lib/queries.user'

import { AppVersionInfo } from 'components/reusable/AppVersionInfo'
import { LogoutBtn } from 'components/reusable/buttons'
import { TILLMAP_PAGE_TITLE } from 'components/tillmapper/config'

import { DrawerListItemLink, ReloadPageListItemBtn } from './DrawerListItem'

export function OffCanvasNav() {
  const [open, setOpen] = useState(false)
  const { data: user } = useCurrentUser()
  const isStaff = user?.is_staff
  // Visibility
  const hasSCpermAccess = useCanAccessBasedOnPerms(['view_soilcollection'])
  const hasUploadsPermAccess = useCanAccessBasedOnPerms(['view_batch'])
  const canViewUploads = hasUploadsPermAccess || isStaff
  const canViewSoilCollector = hasSCpermAccess || isStaff
  const handleClose = () => setOpen(false)
  const righthandItemWidth = useDynamicElemItemWidth()

  const handleOpen = () => {
    Sentry.addBreadcrumb({
      category: 'ui.click',
      message: 'Sidebar nav menu opened',
      level: 'info',
    })

    setOpen(true)
  }

  return (
    <Box display="inline-flex" width={righthandItemWidth}>
      <IconButton data-cy="burger" data-testid="burger" edge="start" onClick={handleOpen}>
        <FiMenu />
      </IconButton>
      <Drawer anchor="left" open={open} style={{ cursor: 'pointer' }} onClose={handleClose}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          role="presentation"
          width={300}
          onClick={handleClose}
          onKeyDown={handleClose}
        >
          <List disablePadding component="nav">
            <DrawerListItemLink
              icon={<FaSeedling />}
              route={topLevelAbsoluteAppRoutes.home}
              text={TILLMAP_PAGE_TITLE}
            />
            {canViewUploads && (
              <DrawerListItemLink
                icon={<AiOutlineCloudUpload />}
                route={topLevelAbsoluteAppRoutes.uploads}
                text="Uploads"
              />
            )}
            {/* TODO: gate by perms */}
            {canViewSoilCollector && (
              <DrawerListItemLink
                icon={<VscBeaker />}
                route={topLevelAbsoluteAppRoutes.soilCollector}
                text="SoilCollector"
              />
            )}
            <DrawerListItemLink
              icon={<GiField />}
              route={topLevelAbsoluteAppRoutes.fieldsMgmt}
              text="Fields management"
            />
          </List>
          <div style={{ marginTop: 'auto' }}>
            <Divider />
            <List disablePadding>{ReloadPageListItemBtn}</List>
          </div>
          <AppVersionInfo expanded={isStaff} />
          <Divider />
          <List disablePadding>
            <LogoutBtn />
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}

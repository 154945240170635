import { FaUserCircle, FaUserShield } from 'react-icons/fa'
import { RiShieldUserFill } from 'react-icons/ri'
import type { CurrentUser } from 'lib/api/django/model'

type Props = {
  data?: CurrentUser
}

export function UserIcon({ data }: Props) {
  if (data?.is_superuser) {
    return <RiShieldUserFill />
  }

  if (data?.is_staff) {
    return <FaUserShield />
  }

  return <FaUserCircle />
}

import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import type { ErrorType } from 'lib/api/django-axios-instance'
import { djangoAxiosInstance } from 'lib/api/django-axios-instance'

import type {
  FieldPatchPayload,
  FieldReaderRetrieve,
  SecondParameter,
} from './types.fields-mgmt-api'

/**
 * PATCH endpoint to update an existing Field
 *
 * @param id Field ID
 * @param payload the PATCH payload
 * @param options axios request options
 * @returns individual Field fetcher from axios Django instance
 */
const v1FieldsPartialUpdate = (
  id: unknown, // it really really is a string though
  payload: FieldPatchPayload,
  options?: SecondParameter<typeof djangoAxiosInstance>
): Promise<FieldReaderRetrieve> => {
  return djangoAxiosInstance<FieldReaderRetrieve>(
    {
      url: `/v1/fields/${id}/`,
      method: 'patch',
      // TODO: API-PENDING: stop using owned_by once `org` is response:
      // https://earthoptics.atlassian.net/browse/DV-2152
      data: payload,
    },
    options
  )
}

export const useV1FieldsPartialUpdate = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    TError,
    { id: unknown; data: FieldPatchPayload },
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationResult<
  FieldReaderRetrieve,
  TError,
  {
    id: unknown
    data: FieldPatchPayload
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    { id: unknown; data: FieldPatchPayload }
  > = (props) => {
    const { id, data } = props ?? {}

    return v1FieldsPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof v1FieldsPartialUpdate>>,
    TError,
    { id: unknown; data: FieldPatchPayload },
    TContext
  >(mutationFn, mutationOptions)
}

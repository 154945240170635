import { useState } from 'react'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import type { IconButtonProps } from '@mui/material/IconButton'
import IconButton from '@mui/material/IconButton'
import type { TooltipProps } from '@mui/material/Tooltip'
import Tooltip from '@mui/material/Tooltip'

import type { Props as ModalProps } from 'components/reusable/handy-templates/FormModal'
import { FormModal } from 'components/reusable/handy-templates/FormModal'
import { DarkTooltip } from 'components/reusable/info-surfaces'

import type { Props as ResponsiveIconBtnProps } from './ResponsiveIconBtn'
import { ResponsiveIconBtn } from './ResponsiveIconBtn'

interface CustomTooltipProps extends TooltipProps {
  /**
   * If `true`, the tooltip will use `<DarkTooltip>`.
   */
  dark?: boolean
}

/**
 * // TODO: DRY out, make less gross
 *
 * Wasn't sure how to TS this, but either `customBtnProps` OR `buttonProps` OR `iconButtonProps`
 * must be passed, but not both, in order for it to return something with a button.
 */
type Props = {
  /**
   * Note that things may get weird or not work as expected if `onClose` is passed since the
   * FormModal's `handleClose` will only use one or the other. Other than that, all props can be
   * passed except the state getter/setter.
   */
  modalProps: Omit<ModalProps, 'isOpen' | 'setIsOpen'>
  /**
   * Props passed to the custom `<ResponsiveIconBtn>` component. `onClick` is omitted since we
   * already have a handler to open the modal. Mutually exclusive with `buttonProps`.
   */
  customBtnProps?: Omit<ResponsiveIconBtnProps, 'onClick'>
  /**
   * Props passed to the actual `<Button>`, which will be used instead of the `<ResponsiveIconBtn>`
   * component. `onClick` is omitted since we already have a handler to open the modal. Mutually
   * exclusive with `customBtnProps`.
   */
  buttonProps?: Omit<ButtonProps, 'onClick'>
  /**
   * Props passed to the actual `<IconButton>`, which will be used instead of the
   * `<ResponsiveIconBtn>` component. `onClick` is omitted since we already have a handler to open
   * the modal. Mutually exclusive with `customBtnProps`.
   */
  iconButtonProps?: Omit<IconButtonProps, 'onClick'>
  /**
   * In case another action is needed in addition to opening the modal, e.g. close a toast or set a
   * state. Will be applied regardless of button type (regular, icon, or custom/responsive).
   */
  handleBtnClick?: () => void
  /**
   * MUI `<Tooltip>` props. The defaults are `arrow`, `enterDelay={400}`, `enterNextDelay={400}`,
   * but the tooltip will only be shown if this prop is passed at all.
   */
  tooltipProps?: Partial<CustomTooltipProps>
}

const defaultTooltipProps: Partial<TooltipProps> = {
  arrow: true,
  enterDelay: 400,
  enterNextDelay: 400,
}

export function ButtonAndModal(props: Props) {
  const { customBtnProps, modalProps, buttonProps, iconButtonProps, tooltipProps } = props
  const { handleBtnClick } = props
  const [isOpen, setIsOpen] = useState(false)

  function handleClick() {
    if (handleBtnClick) {
      handleBtnClick()
    }

    setIsOpen(true)
  }

  let TheButton = null
  let Content = null

  if (buttonProps) {
    TheButton = <Button {...buttonProps} onClick={handleClick} />
  } else if (iconButtonProps) {
    TheButton = <IconButton {...iconButtonProps} onClick={handleClick} />
  } else if (customBtnProps) {
    TheButton = <ResponsiveIconBtn {...customBtnProps} onClick={handleClick} />
  }

  if (tooltipProps && TheButton) {
    const { dark, ...rest } = tooltipProps

    if (dark) {
      Content = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // not today. I KNOW there are children, TypeScript. Thaaaaanks.
        <DarkTooltip {...defaultTooltipProps} {...rest}>
          <span>{TheButton}</span>
        </DarkTooltip>
      )
    } else {
      Content = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // not today. I KNOW there are children, TypeScript. Thaaaaanks.
        <Tooltip {...defaultTooltipProps} {...rest}>
          <span>{TheButton}</span>
        </Tooltip>
      )
    }
  } else {
    Content = TheButton
  }

  return (
    <>
      {Content}
      <FormModal slideUp {...modalProps} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}

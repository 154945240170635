import type { MultiThingSuccessResponse } from './types.sampling-plans'

/**
 * Tally up the counts of created or pre-existing fields and collections.
 *
 * @param items success responses from the multi-thing
 * @returns object with counts for each of the scenarios (samples not included).
 */
export function getMultiThingSummaryCounts(items: MultiThingSuccessResponse[]): {
  collectionsCreated: number
  collectionsAlreadyExisting: number
  fieldsCreated: number
  fieldsAlreadyExisting: number
} {
  const collectionsCreated = items.filter((i) => i.status === 201 && i.collection_id).length
  const collectionsAlreadyExisting = items.filter((i) => i.status === 200 && i.collection_id).length
  const fieldsCreated = items.filter((i) => i.status === 201 && i.field_id).length
  const fieldsAlreadyExisting = items.filter((i) => i.status === 200 && i.field_id).length

  return {
    collectionsCreated,
    collectionsAlreadyExisting,
    fieldsCreated,
    fieldsAlreadyExisting,
  }
}

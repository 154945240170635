import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import { usePageTitle } from 'components/layout/hooks'
import { IconSpacer } from 'components/reusable'
import { IconBtnBreadcrumb } from 'components/reusable/routing'

type Props = {
  /**
   * The main text to show in the heading and also the `<title>` of the page
   */
  text: string
  /**
   * Icon shown to the left of `text`
   */
  icon?: React.ReactNode
  /**
   * IconButton to show immediately to the right of the panel title. Use case at time of writing is
   * a pencil to edit a collection name.
   *
   * **TIP**: if you don't want the button to be miles away from the text, give it an `edge="start"`
   * prop to snug it up.
   */
  iconBtn?: React.ReactNode
  /**
   * Component to show at the far right side of the heading toolbar. Current use case is the "access
   * control" button. Note that it is not currently used (it was for the button, since removed).
   * Leaving as-is out of sheer handiness.
   *
   * @default IconSpacer
   */
  righthandComponent?: React.ReactNode
}

const rootSx = {
  backgroundColor: 'primary.dark',
  display: 'flex',
  gap: 1,
  justifyContent: 'space-between',
  px: 1,
}

export function FieldsMgmtHeading(props: Props) {
  const { text, icon, righthandComponent = IconSpacer, iconBtn } = props

  usePageTitle(text)

  return (
    <Toolbar disableGutters sx={rootSx} variant="dense">
      <IconBtnBreadcrumb id="fmt-up-one-level" maxDepth={1} />
      <Typography
        alignItems="center"
        component="h1"
        data-cy="fmt-heading"
        display="flex"
        gap={1}
        sx={{ overflow: 'hidden', svg: { color: 'text.secondary' } }}
        textOverflow="ellipsis"
        variant="h6"
        whiteSpace="nowrap"
      >
        {icon}
        {text}
        {iconBtn}
      </Typography>
      {righthandComponent}
    </Toolbar>
  )
}

/**
 * Configuration for frontend routes. These can be used several ways by
 * react-router:
 *
 *    useRouteMatch($route)
 *    <Route path="$route">
 *    <Link to="$route">
 */
import { FMT_BASEPATH, FMT_RELATIVE_FIELDS_PATH } from 'components/fields/routes'
import {
  SOILCOLLECTOR_BASEPATH,
  SOILCOLLECTOR_COLLNS_SEGMENT,
} from 'components/soil-sampling/routes'
import { LAYER_COMPARE_BASEPATH } from 'components/tillmapper/routes'
import { UPLOADS_BASEPATH } from 'components/upload/routes'

export const topLevelSearchParams = {
  from: 'from',
}

/**
 * Any routes accessed in a cross-app fashion, e.g. FMT <--> SC, should be defined and exported
 * here to avoid circular dependencies. The navigation menu uses these as well.
 */
export const topLevelAbsoluteAppRoutes = {
  home: '/',
  fieldsMgmt: `/${FMT_BASEPATH}/${FMT_RELATIVE_FIELDS_PATH}`,
  layerCompare: `/${LAYER_COMPARE_BASEPATH}`,
  soilCollector: `/${SOILCOLLECTOR_BASEPATH}/${SOILCOLLECTOR_COLLNS_SEGMENT}`,
  uploads: `/${UPLOADS_BASEPATH}`,
} as const

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useIsAtLeastPhablet } from 'lib/hooks'

import { OffCanvasNav } from 'components/nav/OffCanvasNav'
import { UserMenu } from 'components/user/UserMenu'

import styles from './Header.module.css'

export function Header() {
  const isAtLeastPhablet = useIsAtLeastPhablet()

  return (
    <AppBar className={styles.root} position="static">
      <Toolbar className={styles.toolbar} disableGutters={!isAtLeastPhablet} variant="dense">
        <OffCanvasNav />
        <img alt="company logo" src="/eo-logo-white.svg" style={{ height: 32 }} />
        {/* Notifcations btn here? */}
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}

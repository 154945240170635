import { memo } from 'react'

export type MapPinProps = {
  bgPinFillColor: string
  /** @default 32 */
  height?: number
  icon?: React.ReactNode
  includeShadow?: boolean
  /**
   * Override if needed, e.g. for legend/toggles
   *
   * @default undefined
   */
  lineHeight?: number
  strokeColor?: string
  strokeWidth?: number
  text?: React.ReactNode
  /** @default 24 */
  width?: number
}

export const DEFAULT_PIN_HEIGHT = 32

export const DEFAULT_PIN_WIDTH = 24

const textStyle: React.CSSProperties = {
  fontSize: '0.85rem',
  fontWeight: 'bold',
  position: 'absolute',
  textShadow: 'hsl(0deg 0% 0% / 75%) 2px 2px 4px',
  top: 0,
  width: 'max-content',
  zIndex: 1,
}

const path = `M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z`

const SHADOW_FILTER = {
  filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))',
}

function Pin(props: MapPinProps) {
  const { height = DEFAULT_PIN_HEIGHT, bgPinFillColor, icon, text } = props
  const { includeShadow, strokeColor = 'none', strokeWidth } = props
  const { width = DEFAULT_PIN_WIDTH, lineHeight } = props

  // CRED: for clipPath: https://stackoverflow.com/a/70013225/1048518
  const BgMarker = (
    <svg
      fill={bgPinFillColor}
      height={height}
      style={{ ...(includeShadow ? SHADOW_FILTER : {}) }}
      viewBox="0 0 384 512"
      width={width}
    >
      <defs>
        <clipPath id="my-clip-path">
          <path d={path} id="my-clip-path-rect" />
        </clipPath>
      </defs>
      <path
        clipPath="url(#my-clip-path)"
        d={path}
        paintOrder="fill"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  )

  return (
    <div style={{ position: 'relative', lineHeight }}>
      {BgMarker}
      <div
        // 🙄 🙄 🙄
        style={{
          position: 'absolute',
          left: -4,
          top: -3,
          fontSize: '0.75em',
        }}
      >
        {icon}
      </div>
      {(typeof text === 'string' && (
        <div style={{ ...textStyle, left: DEFAULT_PIN_WIDTH }}>{text}</div>
      )) ||
        text}
    </div>
  )
}

export const MapPin = memo(Pin)

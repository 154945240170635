import { toast } from 'react-toastify'
import Button from '@mui/material/Button'
import { LOCAL_STORAGE_CURRENT_USER_JSON_STR } from 'lib/config'
import { downloadJson } from 'lib/utils'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { ReactQueryStates } from 'components/reusable/alerts-and-messages/ReactQueryStates'
import { useCollections } from 'components/soil-sampling/hooks.ssa'

import { FormModal } from './handy-templates/FormModal'

type Props = {
  filenameSansSuffix: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  omitButton?: boolean
}

const EmailInstrs = <p>Email the file to your EarthOptics contact.</p>

const Message = (
  <>
    <p>This will export a JSON file with the following:</p>
    <ul>
      <li>
        <code>localStorage.currentUser</code>
      </li>
      <li>
        <code>soil_collector</code> localforage DB
      </li>
      <li>
        <code>failed_outbound_requests</code> localforage DB
      </li>
    </ul>
    <p>
      Please share this with your EarthOptics contact for troubleshooting purposes, if requested.
    </p>
  </>
)

export function DebugLocalStgModal(props: Props) {
  const { filenameSansSuffix, omitButton, isOpen, setIsOpen } = props

  const { local: localCollnsQuery } = useCollections(undefined, {
    enabled: false,
  })

  const { data, isLoading, isError } = localCollnsQuery

  let Content = Message

  async function handleSubmit() {
    const currentUserObj = localStorage.getItem(LOCAL_STORAGE_CURRENT_USER_JSON_STR)

    const json = {
      time: new Date().toLocaleString(),
      currentUser: currentUserObj ? JSON.parse(currentUserObj) : null,
      collections: data,
    }

    downloadJson(json, filenameSansSuffix, () => {
      toast.info(
        <ToastContentWithTitle
          content={EmailInstrs}
          title={`Samples downloaded to ${filenameSansSuffix}.json`}
        />,
        {
          autoClose: false,
        }
      )
    })

    setIsOpen(false)
  }

  const handleBtnClick = () => setIsOpen(true)

  if (isLoading || isError) {
    Content = (
      <>
        {Message}
        <ReactQueryStates {...localCollnsQuery} />
      </>
    )
  }

  const Modal = (
    <FormModal
      slideUp
      content={Content}
      disableSubmit={isLoading || isError}
      elemId="debug-local-json"
      isOpen={isOpen}
      submitBtnText="Export debug file"
      title="Debug browser contents on this device"
      onClose={() => setIsOpen(false)}
      onSubmit={handleSubmit}
    />
  )

  return (
    <>
      {!omitButton && (
        <Button size="small" variant="contained" onClick={handleBtnClick}>
          Debug
        </Button>
      )}
      {Modal}
    </>
  )
}

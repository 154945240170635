// TODO: rename all `config.thing-cols.tsx` files to `config.table.thing.tsx`
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import type { GridColDef, GridSingleSelectColDef } from '@mui/x-data-grid-premium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import type { CollectionFiles } from 'lib/api/django/model'
import { CollectionFilesFileTypeEnum } from 'lib/api/django/model'
import { formatFileSize, getPrettyDate } from 'lib/utils'

import { TwoCharColoredAvatar } from 'components/reusable/TwoCharColoredAvatar'

import { DeleteCollnFileBtnModal } from './DeleteCollnFileBtnModal'

type CustomColField = 'actions'

type TypedColName = {
  field: CustomColField | keyof CollectionFiles
}

type ColumnDef = Omit<GridColDef<CollectionFiles>, 'field'> & TypedColName

type SingleSelectColumnDef = Omit<GridSingleSelectColDef<CollectionFiles>, 'field'> & TypedColName

export function getColumns(canDelete: boolean): ColumnDef[] {
  return [
    {
      field: 'actions',
      width: 75,
      headerName: 'Actions',
      align: 'center',
      renderCell: ({ row }) => (
        <DeleteCollnFileBtnModal
          collnFullId={row.collection_id}
          disabled={!canDelete}
          fileIDs={[row.id]}
        />
      ),
    },
    {
      field: 'file_name',
      width: 300,
      headerName: 'Name',
      renderCell: ({ row }) => (
        <Link href={row.file} target="_blank">
          {row.file_name}
        </Link>
      ),
    },
    {
      field: 'file_type',
      width: 145,
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.keys(CollectionFilesFileTypeEnum).map((t) => t),
    } as SingleSelectColumnDef,
    {
      field: 'file_size',
      width: 100,
      headerName: 'Size',
      headerAlign: 'left',
      // Normally numbers are right-aligned, but looks weird with string suffix:
      align: 'left',
      type: 'number',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // tell Nik it's a number
      renderCell: ({ row }) => formatFileSize(row.file_size),
    },
    {
      field: 'created_at',
      width: 200,
      headerName: 'Created',
      type: 'date',
      valueGetter: ({ row }) => new Date(row.created_at),
      valueFormatter: ({ value }) => getPrettyDate(value),
    },
    {
      field: 'updated_at',
      width: 200,
      headerName: 'Updated',
      type: 'date',
      valueGetter: ({ row }) => new Date(row.updated_at),
      valueFormatter: ({ value }) => getPrettyDate(value),
    },
    {
      field: 'created_by',
      width: 200,
      headerName: 'Uploaded by',
      valueGetter: ({ row }) => `${row.created_by.first_name} ${row.created_by.last_name}`,
      renderCell: function renderCell({ row }) {
        const firstName = row.created_by.first_name
        const lastName = row.created_by.last_name
        const fullName = `${firstName} ${lastName}`

        return (
          <Typography
            alignItems="center"
            component="div"
            display="flex"
            fontSize="caption.fontSize"
            gap={1}
          >
            <TwoCharColoredAvatar text={fullName} />
            {fullName}
          </Typography>
        )
      },
    },
  ]
}

export const defaultStateOverrides: GridInitialStatePremium = {
  sorting: {
    sortModel: [
      {
        field: 'file_type' as keyof CollectionFiles,
        sort: 'asc',
      },
      {
        field: 'file_name' as keyof CollectionFiles,
        sort: 'asc',
      },
    ],
  },
}

import type { CollectionFilesFileTypeEnum } from 'lib/api/django/model'

/**
 * Used as the `name` property of the form's `<input>` elements and `formData.get` methods.
 */
export const FORM_INPUTS = {
  create_collections: 'create_collections',
  create_fields: 'create_fields',
  file_type: 'file_type',
  org: 'org',
} as const

export const DEFAULT_FILE_TYPE: CollectionFilesFileTypeEnum = 'SOIL_STACK_FILE'

import { createRoot } from 'react-dom/client'
import { toast } from 'react-toastify'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
// eslint-disable-next-line import/no-unresolved
import { registerSW } from 'virtual:pwa-register' // nothing works, ever

import { InstallNewUpdateToastContent } from 'components/reusable/alerts-and-messages/InstallNewUpdateToastContent'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) when using TS
const LAUNCH_DARKLY_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID

// If you want to start measuring performance in your app, pass a function to log results (for
// example: reportWebVitals(console.log)) or send to an analytics endpoint. Learn more:
// https://bit.ly/CRA-vitals
reportWebVitals()

// IMPORTANT: show popup to user when there is a service worker update. We cannot safely "force
// install" anything. Having the app open in multiple tabs is also problematic. The "force install"
// has high potential to break things since the code is bundled and hashed into chunks. We don't
// want users to end up with new code on one page that is trying to reference old chunks, so we
// require that they "install" the updates themselves.
if ('serviceWorker' in navigator) {
  // && !/localhost/.test(window.location) && !/lvh.me/.test(window.location)) {
  const updateSW = registerSW({
    onNeedRefresh() {
      toast.info(
        ({ closeToast }) => (
          <InstallNewUpdateToastContent closeToast={closeToast} onClick={() => updateSW(true)} />
        ),
        {
          autoClose: false,
          position: 'bottom-right',
        }
      )
    },
  })
}

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_ID,
  })

  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  )
}

renderApp()

import { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import Link from '@mui/material/Link'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

import { PopoverizeAnything } from 'components/reusable/info-surfaces'

type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
}

const DISCLAIMER =
  'WARNING: This setting is intended for map viewing purposes only. If you need to draw or select features, please disable this setting.'

const InfoPopoverContent = (
  <>
    <Typography gutterBottom variant="body2">
      When enabled, the map will display a 3D terrain layer. If you are experiencing performance
      issues or need to conserve battery, disable this setting. Your selection will be preserved for
      all maps in the application.
    </Typography>
    <Typography variant="body2">
      To <b>tilt and rotate</b> the map, follow{' '}
      <Link href="https://docs.mapbox.com/mapbox-gl-js/api/handlers/" target="_blank">
        these instructions
      </Link>{' '}
      for the type of device you are using.
    </Typography>
  </>
)

export function MapTerrainToggle(props: Props) {
  const { checked: initialChecked, onChange } = props
  const [checked, setChecked] = useState(initialChecked)

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) {
    setChecked(isChecked)
    onChange(isChecked)
  }

  return (
    <FormGroup sx={{ mb: 2 }}>
      <div style={{ display: 'flex' }}>
        <FormControlLabel
          label="Show 3D terrain"
          control={
            <Switch
              checked={checked}
              inputProps={{ 'aria-label': 'toggle terrain' }}
              onChange={handleChange}
            />
          }
        />
        <PopoverizeAnything
          ariaLabel="instructions"
          infoPopoverProps={{
            title: '3D terrain',
            nonHtmlContent: <div style={{ whiteSpace: 'pre-wrap' }}>{InfoPopoverContent}</div>,
          }}
        />
      </div>
      <Collapse in={checked}>
        <FormHelperText>{DISCLAIMER}</FormHelperText>
      </Collapse>
    </FormGroup>
  )
}

import { CenteredLoadingSpinner } from 'components/reusable/loaders-and-transitions'

import { ConfMessage, QueryErrorMsg } from '.'

type Props = {
  error: unknown
  isLoading: boolean
  data: unknown
  spinnerSize?: number | string
  loadingText?: string
}

const NoDataFoundMsg = (
  <ConfMessage
    fullWidth
    severity="warning"
    text="No errors were encountered, but no data was found"
    title="No data found"
  />
)

export function ReactQueryStates(props: Props) {
  const { error, isLoading, spinnerSize, loadingText, data } = props

  if (isLoading) {
    return <CenteredLoadingSpinner size={spinnerSize} text={loadingText} />
  }

  if (error) {
    return <QueryErrorMsg error={error} />
  }

  if (!data) {
    return NoDataFoundMsg
  }

  return null
}

import { useState } from 'react'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import type { CurrentUserSecurityGroupsItem, Org } from 'lib/api/django/model'
import { sortCaseInsensitive } from 'lib/utils'

import { CrosswalkLatLonDropdown } from 'components/fields/create-fields-steps/CrosswalkLatLonDropdown'
import { CrosswalkSection } from 'components/fields/create-fields-steps/CrosswalkSection'
import { NewFieldsSrcFeatsTable } from 'components/fields/create-fields-steps/NewFieldsSrcFeatsTable'
import type { ImportsState, MappableColumn } from 'components/fields/types.fields-mgmt'
import { FormModal } from 'components/reusable/handy-templates/FormModal'
import { OrgAutocomplete } from 'components/reusable/OrgAutocomplete'

import { ColMapSectionHeading } from './column-mapping/ColMapSectionHeading'
import { FILENAME_COLUMN, PRETTY_FILENAME_COL } from './column-mapping/config.col-mapping'
import { FieldsMgmtColMapMetadata } from './column-mapping/FieldsMgmtColMapMetadata'

export type Props = {
  isOpen: boolean
  onClose: () => void
  rawFeatures: GeoJSON.Feature[]
  onSubmit: (
    org: Org,
    colMappings: MappableColumn,
    latLonColumns: ImportsState['latLonColumns'],
    extraColumns: string[],
    secGroups: CurrentUserSecurityGroupsItem[]
  ) => void
  requiresLatLonCols?: boolean
}

const contentSx = {
  display: 'flex',
  gap: 3,
  height: '100%',
}

const defaultImportedColMappings: MappableColumn = {
  description: '',
  external_id: '',
  farm: '',
  name: '',
  producer_field_name: '',
}

const defaultLatLonColMappings = { lat: '', lon: '' }

export function FieldsMgmtColumnMapping(props: Props) {
  const { onSubmit, rawFeatures, onClose, isOpen, requiresLatLonCols } = props
  const firstFeature = rawFeatures[0]
  const attribs = firstFeature ? firstFeature.properties : {}
  const [org, setOrg] = useState<Org | null>(null)
  const [secGroups, setSecGroups] = useState<CurrentUserSecurityGroupsItem[]>([])
  const [columnMappings, setColumnMappings] = useState<MappableColumn>(defaultImportedColMappings)
  const [extraColumns, setExtraColumns] = useState<string[]>([FILENAME_COLUMN])

  const [latLonColumns, setLatLonColumns] =
    useState<ImportsState['latLonColumns']>(defaultLatLonColMappings)

  const availableCols = Object.keys(attribs as object)
    .map((col) => col)
    .sort(sortCaseInsensitive)

  const disableSubmit = !org || (requiresLatLonCols && (!latLonColumns.lat || !latLonColumns.lon))

  function handleClose() {
    onClose()
    setColumnMappings(defaultImportedColMappings)
    setLatLonColumns(defaultLatLonColMappings)
    setOrg(null)
    setSecGroups([])
  }

  function handleSubmit() {
    if (!org) {
      return // form will protect us, but TS isn't in the know
    }

    onSubmit(org, columnMappings, latLonColumns, extraColumns, secGroups)

    toast.success('Files imported successfully. Edit in bulk or click the Submit button above.', {
      toastId: 'fields-files',
    })

    handleClose()
  }

  function handleOrgChange(orgObj: Org | null) {
    setOrg(orgObj)
  }

  function handleColMapDropdownChange(key: keyof MappableColumn, value: string) {
    setColumnMappings({ ...columnMappings, [key]: value })
  }

  function handleLatLonChange(key: 'lat' | 'lon', value: string) {
    setLatLonColumns({ ...latLonColumns, [key]: value })
  }

  function handleMetaColCheckboxSelect(colNames: string[]) {
    setExtraColumns(colNames)
  }

  const Content = (
    <Box sx={contentSx}>
      <CrosswalkSection
        availableCols={availableCols}
        colMappings={columnMappings}
        orgMenu={<OrgAutocomplete required onChange={handleOrgChange} />}
        secGroupsDropdownProps={{
          onChange: setSecGroups,
          value: secGroups,
        }}
        onColMapDropdownChange={handleColMapDropdownChange}
      />
      <Box display="flex" flex={1} flexDirection="column" sx={{ overflow: 'hidden' }}>
        <ColMapSectionHeading
          info="This is the raw data from the file(s) that you just brought in."
          text="Attribute table"
        />
        <Box flex={1} height="100%" sx={{ overflowX: 'hidden' }} width="100%">
          <NewFieldsSrcFeatsTable features={rawFeatures} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ overflow: 'hidden' }}>
        <ColMapSectionHeading
          info="CSV files require a column to be specified for latitude and longitude."
          text="CSV Lat/Lon columns"
        />
        <Box mb={1}>
          <CrosswalkLatLonDropdown
            availableCols={availableCols}
            disabled={!requiresLatLonCols}
            propKey="lat"
            onChange={handleLatLonChange}
          />
          <CrosswalkLatLonDropdown
            availableCols={availableCols}
            disabled={!requiresLatLonCols}
            propKey="lon"
            onChange={handleLatLonChange}
          />
        </Box>
        <ColMapSectionHeading
          info={`This is a list of all columns in your raw data. Optionally, check one or more of the boxes to retain that column even if it is not mapped to a "target" column. The values will be stored as unstructured metadata, or "extra" properties, and should not be relied on except for reference.\n\nNote that "${PRETTY_FILENAME_COL}" cannot be removed as it may be used for reference later.`}
          text="Extra columns"
        />
        <FieldsMgmtColMapMetadata
          availableCols={availableCols}
          onSelect={handleMetaColCheckboxSelect}
        />
      </Box>
    </Box>
  )

  return (
    <FormModal
      slideUp
      content={Content}
      dialogProps={{ fullScreen: true }}
      disableSubmit={disableSubmit}
      elemId="crosswalk-columns"
      isOpen={isOpen}
      submitBtnText="Continue"
      title="Prep data for import and choose corresponding columns"
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}

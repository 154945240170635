import { useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Hidden from '@mui/material/Hidden'
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { useIsAtLeastPhablet } from 'lib/hooks'

import { ZoomToSelectedFeatsBtn } from 'components/reusable/maps'

import { ModalTableColumnsSelect } from './ModalTableColumnsSelect'
import { ModalTableFilter } from './ModalTableFilter'
import { SavedViewsMenu, SetTableStateViaSavedViewRedirect } from './saved-views'
import {
  toolbarBtnsWrapStyle,
  toolbarBtnsWrapSx,
  toolbarRootSx,
  toolbarSearchMobileSx,
} from './styles'
import { TableExportMenu } from './TableExportMenu'
import { TableRowDensityMenu } from './TableRowDensityMenu'
import { TableToolbarBtnGroupDivider } from './TableToolbarBtnGroupDivider'
import { ToggleFullscreenTableBtn } from './ToggleFullscreenTableBtn'
import { ToggleSearchBarBtn } from './ToggleSearchBarBtn'
import type { TableToolbarProps } from './types'

export function TableToolbar(props: TableToolbarProps) {
  const { initialState, tableSlug, contentBeforeDefaultBtns, toggleFullscreen } = props
  const { parentTableSavedViewsBaseRoute, hideFullScreenBtn, filtersModalChildren } = props
  const { sxProps, selRowsBboxPrepFn, contentAfterDefaultBtns } = props
  const apiRef = useGridApiContext()
  const isAtLeastPhablet = useIsAtLeastPhablet()
  const [searchBarOpen, setSearchBarOpen] = useState(isAtLeastPhablet)
  const sx = { ...toolbarRootSx, ...sxProps } // TS doesn't like this spread in the `sx` prop 🤷

  return (
    <GridToolbarContainer sx={sx}>
      <Box style={toolbarBtnsWrapStyle} sx={toolbarBtnsWrapSx}>
        {!!contentBeforeDefaultBtns && (
          <>
            <div style={{ display: 'flex' }}>
              {contentBeforeDefaultBtns}
              {!!selRowsBboxPrepFn && (
                <ZoomToSelectedFeatsBtn selRowsBboxPrepFn={selRowsBboxPrepFn} />
              )}
            </div>
            {TableToolbarBtnGroupDivider}
          </>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ModalTableFilter>{filtersModalChildren}</ModalTableFilter>
          <ModalTableColumnsSelect />
          <TableRowDensityMenu />
          <TableExportMenu />
          {!hideFullScreenBtn && toggleFullscreen && (
            <ToggleFullscreenTableBtn onClick={toggleFullscreen} />
          )}
          <Hidden smUp>
            <ToggleSearchBarBtn onClick={() => setSearchBarOpen((prev) => !prev)} />
          </Hidden>
          <Box ml={1.5}>
            <SavedViewsMenu
              initialState={initialState}
              parentTableApiRef={parentTableSavedViewsBaseRoute ? apiRef : undefined}
              parentTableBaseRoute={parentTableSavedViewsBaseRoute}
              tableSlug={tableSlug}
            />
          </Box>
        </div>
        {contentAfterDefaultBtns && (
          <>
            {TableToolbarBtnGroupDivider}
            {contentAfterDefaultBtns}
          </>
        )}
      </Box>
      <Hidden smDown>
        <GridToolbarQuickFilter
          style={{
            minWidth: 200,
            flex: 1,
            maxWidth: 400,
          }}
        />
      </Hidden>
      <Hidden smUp>
        <Collapse in={searchBarOpen} style={{ width: '100%' }}>
          <GridToolbarQuickFilter sx={toolbarSearchMobileSx} />
        </Collapse>
      </Hidden>
      <SetTableStateViaSavedViewRedirect />
    </GridToolbarContainer>
  )
}

import { createContext, useContext, useReducer } from 'react'

import type {
  SoilCollectorContextAction as Action,
  SoilCollectorContextState as State,
} from './types.ssa'

type Dispatch = React.Dispatch<Action>

const initialState: State = {
  collnSpatialFiles: [],
  currentGeolocation: null,
}

const StateContext = createContext<State | undefined>(undefined)
const DispatchContext = createContext<Dispatch | undefined>(undefined)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    // Was in its own context, but so far only SoilCollector uses it
    case 'SET_CURRENT_GEOLOCATION':
      return { ...state, currentGeolocation: action.payload }
    case 'SET_COLLN_SPATIAL_FILES':
      return { ...state, collnSpatialFiles: action.payload }
    default: {
      return state
    }
  }
}

export function SoilCollectorProviders(props: { children: React.ReactNode }) {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

function useStateContext(): State {
  const context = useContext(StateContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

function useDispatchContext(): Dispatch {
  const context = useContext(DispatchContext)

  if (context === undefined) {
    throw new Error('Must use within a Provider!')
  }

  return context
}

export { useStateContext, useDispatchContext }

import type { ToastContentProps } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { ToastContentWithTitle } from '.'

type Props = Pick<ToastContentProps, 'closeToast'> & {
  onClick: () => void
}

export function InstallNewUpdateToastContent(props: Props) {
  const { closeToast, onClick } = props

  const Content = (
    <>
      Click "Install" to refresh the page with the latest software update.
      <Box display="flex" gap={1} justifyContent="center" mt={1}>
        <Button color="inherit" onClick={closeToast}>
          remind me later
        </Button>
        <Button color="inherit" variant="outlined" onClick={onClick}>
          install
        </Button>
      </Box>
    </>
  )

  return <ToastContentWithTitle content={Content} title="New update available" />
}

import { Outlet } from 'react-router-dom'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'

const loginLogoutRootStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  overflow: 'hidden',
  textAlign: 'center',
}

const contentWrapSx: SxProps = {
  px: 2,
  width: {
    xs: '100%',
    sm: 'max(80%, 600px)',
    md: 'min(80%, 720px)',
  },
  img: {
    mb: 4,
    width: {
      xs: 240,
      md: 320,
    },
  },
}

/**
 * Vertically and horizontally centered wrapper around login, logout, "not found", and "not
 * authorized" pages, etc.
 *
 * @returns `JSX.Element`
 */
export function PublicLayout() {
  return (
    <div id="public-layout" style={loginLogoutRootStyle}>
      <Box sx={contentWrapSx}>
        <Outlet />
      </Box>
    </div>
  )
}

import type { Expression } from 'mapbox-gl'

import { CONDUCTIVITY_COLOR_STOPS } from 'components/tillmapper/config'

const GREEN = 'rgb(56,153,60)'
const YELLOW = 'rgb(255,255,0)'
const RED = 'rgb(255,0,0)'

function getInterpolatedLinearColorsViaProp(property: string): Expression {
  return [
    'interpolate',
    ['linear'],
    ['to-number', ['get', property], ['get', property]],
  ]
}

// Interpolation expression used by Mapbox to calculate spectrums/gradients
export const interpTillCert = getInterpolatedLinearColorsViaProp('Tillage_Certainty')

export function getInterpColorsFromRange(
  colorStops: string[],
  minMax?: [number, number],
  interpolationProp = 'Tillage_Certainty'
): Expression | string {
  if (!minMax) {
    return 'transparent'
  }

  const [min, max] = minMax

  if (min === max) {
    return colorStops[0] // jump ship if there's no range to work with
  }

  const numberOfStops = colorStops.length
  const range = max - min
  const step = range / (numberOfStops - 1)
  const interpolation = getInterpolatedLinearColorsViaProp(interpolationProp)

  const magic = colorStops.reduce((accum, thisOne, i) => {
    const isFirstItem = i === 0

    if (isFirstItem) return [min, thisOne] // kick things off

    const isLastItem = i === numberOfStops - 1

    if (isLastItem) return [...accum, max, thisOne] // no math needed

    const previousValue = accum[(i - 1) * 2] // thanks Jaaaaake 👏

    return [...accum, (previousValue as number) + step, thisOne]
  }, [] as (number | string)[])

  return [...interpolation, ...magic]
}

export function getCompactionColors(
  relativeColors?: boolean,
  minMaxPsi?: [number, number]
): Expression {
  if (relativeColors) {
    const [minPsi, maxPsi] = minMaxPsi || [0, 400]
    const halfsies = minPsi + (maxPsi - minPsi) / 2

    return [
      ...interpTillCert,
      minPsi,
      GREEN,
      halfsies,
      YELLOW,
      maxPsi,
      RED,
    ]
  }

  return [
    ...interpTillCert,
    0,
    GREEN,
    200,
    YELLOW,
    400,
    RED,
  ]
}

export function getConductivityColors(
  minMax: [number, number],
  interpolationProp = 'Tillage_Certainty'
): Expression {
  const [min, max] = minMax
  const numberOfStops = CONDUCTIVITY_COLOR_STOPS.length
  const range = max - min
  const step = range / (numberOfStops - 1)
  const interpolation = getInterpolatedLinearColorsViaProp(interpolationProp)

  // To prevent blank map layer, use darkest color when min and max are identical
  if (range === 0) {
    return [...interpolation, min, CONDUCTIVITY_COLOR_STOPS[5]]
  }

  return [
    ...interpolation,
    min,
    CONDUCTIVITY_COLOR_STOPS[0],
    min + step,
    CONDUCTIVITY_COLOR_STOPS[1],
    min + step * 2,
    CONDUCTIVITY_COLOR_STOPS[2],
    min + step * 3,
    CONDUCTIVITY_COLOR_STOPS[3],
    min + step * 4,
    CONDUCTIVITY_COLOR_STOPS[4],
    min + step * 5,
    CONDUCTIVITY_COLOR_STOPS[5],
  ]
}

export const rxCellFillColor: Expression = [
  ...interpTillCert,
  0,
  GREEN,
  9,
  YELLOW,
  18,
  RED,
]

// TODO: into lib/utils
export function stringToColor(string: string): string {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff

    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

import Typography from '@mui/material/Typography'

import styles from 'components/reusable/maps/symbology/FilesLegend.module.css'

type FilesLegendItemProps = {
  label: string
  legendSymbol: React.ReactNode
  sensorIcon?: React.ReactNode
  count?: number
  snug?: boolean
}

const { legendItem, legendItemLabel, legendItemIcon, legendCount } = styles

export function FilesLegendItem(props: FilesLegendItemProps) {
  const { sensorIcon, label, legendSymbol, count, snug } = props
  const snugSymbStyle = snug ? { marginRight: 0 } : {}

  return (
    <div className={legendItem}>
      <div className={styles.legendSymbol} style={snugSymbStyle}>
        {legendSymbol}
      </div>
      <Typography className={legendItemLabel} component="div" variant="body2">
        {sensorIcon && <div className={legendItemIcon}>{sensorIcon}</div>}
        {/* Wrapper to enable ellipsis... */}
        <div className={styles.legendItemLabelText}>{label}</div>
      </Typography>
      {count ? <span className={legendCount}>{count}</span> : null}
    </div>
  )
}

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

import { useActivateSavedView } from './hooks'
import type { SavedViewRouteLocState } from './types'

export function SetTableStateViaSavedViewRedirect() {
  const { state: locationState } = useLocation()
  const { savedView } = (locationState as SavedViewRouteLocState) || {}
  const apiRef = useGridApiContext()
  const onSavedViewStateChange = useActivateSavedView()

  // Need to set this every time the route is changed. If we set it to listen to JUST the query arg,
  // it won't trigger anything because it doesn't necessarily change when going from one route to
  // another.
  useEffect(() => {
    if (savedView) {
      onSavedViewStateChange(savedView.name, savedView.state as GridInitialStatePremium)
    }
  }, [apiRef, savedView, onSavedViewStateChange])

  return null
}

import type { Permission } from 'lib/api/types.api'
import { useCanAccessBasedOnPerms } from 'lib/queries.user'

type Props = {
  children: React.ReactNode
  perms: Permission[]
  mustHaveAll?: boolean
}

export function AccessCheckViaPerms(props: Props) {
  const { perms, children, mustHaveAll } = props
  const currentUserHasAccess = useCanAccessBasedOnPerms(perms, mustHaveAll)

  return currentUserHasAccess ? <>{children}</> : null
}

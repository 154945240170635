import { forwardRef } from 'react'
import type { SlideProps } from '@mui/material/Slide'
import Slide from '@mui/material/Slide'

export const SlideUpTransition = forwardRef(
  (
    props: SlideProps & {
      children: React.ReactElement
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide ref={ref} direction="up" {...props} />
  }
)

import List from '@mui/material/List'

import { CollectionListItem } from './CollectionListItem'

type Props = {
  collectionIds: string[]
}

export function CollectionsList(props: Props) {
  const { collectionIds } = props

  return (
    <List>
      {collectionIds.map((id) => {
        return <CollectionListItem key={id} collnId={id} />
      })}
    </List>
  )
}

import styles from './UserMenu.module.css'

type Props = {
  orgName: string
  logoUrl: string
}

export function OrgLogo({ orgName, logoUrl }: Props) {
  return <img alt={`${orgName} logo`} className={styles.orgLogo} src={logoUrl} />
}

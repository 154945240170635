import type React from 'react'
import { toast } from 'react-toastify'
import type { QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey as collnDetailQueryKey,
  useV1SoilSamplingCollectionsPartialUpdate as useRemoteCollnPatchMutation,
  useV1SoilSamplingCollectionsRetrieve,
  useV1SoilSamplingSamplesPartialUpdate,
} from 'lib/api/django/v1/v1'

import { localQueryKeys, MUTATION_KEYS } from './config.ssa'
import { soilCollectorToasts as toasts } from './config.toasts.soilcollector'
import type { SoilCollection } from './types.ssa'

/**
 * Hook to return a `useQuery` instance pointing to the API's collections retrieve ("detail") GET.
 * The collection `short_id` is automatically determined based off of the route if it's not supplied
 * directly in the usage, e.g. if used in a table or list that's _not_ in a
 * `/soilcollector/collections/:short_id` route (see note below about it always being enabled in the
 * route). Reasoning: it is needed in collection detail view very often, even if local data is the
 * focus. Thanks to react-query, the data is cached very nicely and does not trigger new fetches
 * until stale or invalidated 🎉.
 *
 * @param id most likely the collection's `short_id`, but at this time the API supports full or
 * short. If not supplied, the frontend will grab it from the route if possible.
 * @param someQueryOptions everything in UseQueryOptions except queryFn and queryKey since we want
 * those to be consistent.
 * @returns `useQuery` instance pointing to backend's collection detail GET.
 */
export function useRemoteCollection<TSelect = SoilCollection>(
  id = '',
  someQueryOptions?: Omit<
    UseQueryOptions<SoilCollection, AxiosError, TSelect>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<TSelect, AxiosError> & {
  queryKey: QueryKey
} {
  let isEnabled = false

  if (typeof someQueryOptions?.enabled === 'undefined') {
    isEnabled = !!id
  } else {
    isEnabled = someQueryOptions.enabled
  }

  // TODO: start using `hide` for detail view: https://earthoptics.atlassian.net/browse/DV-6333
  return useV1SoilSamplingCollectionsRetrieve<TSelect, AxiosError>(id, undefined, {
    query: { ...someQueryOptions, enabled: isEnabled },
  })
}

/// /////////////// 🧟  MUTATIONS  🧟 \\\\\\\\\\\\\\\\\\

const patchRetrySettings = {
  retry: 3,
  retryDelay: (attempt: number) => {
    return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
  },
}

// TODO: mv mutations to mutations file
// NOTE: Django API is set to accept `short_id` in addition to full `id` 🥳
/**
 * Hook to get the remote collection PATCH mutation, with some error/success handling that includes
 * query invalidation and toast messages.
 *
 * @param doNotShowToasts skip showing the toasts, e.g. in bulk edit
 * @returns `useMutation` instance that will PATCH a Collection
 */
export function useRemoteCollnMutation(
  doNotShowToasts?: boolean
): ReturnType<typeof useRemoteCollnPatchMutation> {
  const rqc = useQueryClient()

  return useRemoteCollnPatchMutation({
    mutation: {
      ...patchRetrySettings,
      mutationKey: MUTATION_KEYS.remoteColln,
      onError: (error, { id }) => {
        if (!doNotShowToasts) {
          const toastId = id as string

          // TODO: try to show real errors: Object.entries(error.response.data)
          toast.error(toasts.remoteCollnPatchFail, { toastId })
        }
        // TODO: set the local to UNKNOWN ??? invalidate local/remote queries
      },
      onSettled: (partialColln) => {
        if (partialColln) {
          const collnShortId = partialColln.id.split('-')[0]
          const remoteCollnQueryKey = collnDetailQueryKey(collnShortId)

          rqc.invalidateQueries(remoteCollnQueryKey)
          rqc.invalidateQueries(localQueryKeys.collnDetail(collnShortId))
        }
      },
      onSuccess: async (data, { id: collnShortId }) => {
        const collnShortIdStr = collnShortId as string

        if (!doNotShowToasts) {
          toast.success(toasts.remoteCollnPatchSuccess, {
            toastId: collnShortIdStr,
          })
        }
      },
    },
  })
}

export function useRemoteSampleMutation(
  invalidateLocalColln?: boolean,
  successToastContent?: React.ReactNode
): ReturnType<typeof useV1SoilSamplingSamplesPartialUpdate> {
  const rqc = useQueryClient()

  return useV1SoilSamplingSamplesPartialUpdate({
    mutation: {
      ...patchRetrySettings,
      mutationKey: MUTATION_KEYS.remoteSample,
      onError: (error, { id: sampleShortId }) => {
        // TODO: stop casting if backend types it as string instead of `Any`
        const toastId = sampleShortId as string

        // TODO: SOMEDAY: nice error w/bag label
        toast.error(toasts.remoteSamplePatchFail, {
          position: 'bottom-left',
          toastId,
        })

        // TODO: invalidate local AND remote queries after setting local sample to FAILED. But how,
        // if we don't have the collection id here, and also FAILED isn't a status?
      },
      onSuccess: (sample, { id: sampleShortId }) => {
        // TODO: stop casting if backend types it as string instead of `Any`
        const toastId = sampleShortId as string
        const collnShortId = sample.collection_id.split('-')[0]
        const queryKey = collnDetailQueryKey(collnShortId)

        toast.update(toastId, {
          type: 'success',
          render: successToastContent || toasts.remoteSamplePatchSuccess(sample.bag_label),
        })

        return rqc.invalidateQueries(queryKey).then(() => {
          if (invalidateLocalColln) {
            const localQueryKey = localQueryKeys.collnDetail(collnShortId)

            rqc.invalidateQueries(localQueryKey)
          }
        })
      },
    },
  })
}

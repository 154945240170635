import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import type { DialogProps } from '@mui/material/Dialog'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useIsAtLeastPhablet } from 'lib/hooks'

import { MenuCloseBtn } from 'components/reusable/buttons'
import { SlideUpTransition } from 'components/reusable/loaders-and-transitions'

export type Props = {
  elemId: string
  isOpen: boolean
  title?: React.ReactNode
  /**
   * Text for the submit button
   *
   * @default 'Submit'
   */
  submitBtnText?: string
  submitBtnIcon?: React.ReactNode
  content?: React.ReactNode
  dialogProps?: Partial<DialogProps>
  disableSubmit?: boolean
  /**
   * Don't show the footer bar, e.g. for a fullscreen table
   */
  hideDialogActions?: boolean
  /**
   * Some stuff like `DialogActions` won't make sense if it's not a form.
   */
  isNotForm?: boolean
  /**
   * In case it's a react-hook-form thing
   */
  noValidate?: boolean
  /**
   * Cheap way to override the Dispatch assumption, e.g. for router nav.
   */
  onClose?: () => void
  onSubmit?: (e?: React.FormEvent) => void
  /**
   * This is usually what is used, but sometimes might want router nav instead.
   */
  setIsOpen?: React.Dispatch<boolean>
  /**
   * Slide up animation
   */
  slideUp?: boolean
}

// TODO: convert this to be more button-buddy-friendly:
// https://medium.com/@BogdanSoare/how-to-use-reacts-new-context-api-to-easily-manage-modals-2ae45c7def81
export function FormModal(props: Props) {
  const { content, elemId, isOpen, onSubmit, setIsOpen, title, disableSubmit, slideUp } = props
  const { dialogProps, onClose, isNotForm, noValidate, submitBtnText = 'Submit' } = props
  const { hideDialogActions, submitBtnIcon } = props
  const isAtLeastPhablet = useIsAtLeastPhablet()

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else if (setIsOpen) {
      setIsOpen(false)
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (onSubmit) {
      onSubmit(e)
    }

    if (setIsOpen) {
      setIsOpen(false)
    }
  }

  return (
    <Dialog
      fullWidth
      aria-labelledby={`${elemId}-dialog-title`}
      fullScreen={!isAtLeastPhablet}
      id={`${elemId}-dialog`}
      open={isOpen}
      TransitionComponent={slideUp ? SlideUpTransition : undefined}
      onClose={handleClose}
      {...dialogProps}
    >
      <Box
        component={isNotForm ? 'div' : 'form'}
        noValidate={noValidate}
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
        onSubmit={handleSubmit}
      >
        <MenuCloseBtn handleClick={handleClose} />
        {title && <DialogTitle id={`${elemId}-dialog-title`}>{title}</DialogTitle>}
        <DialogContent dividers>{content}</DialogContent>
        {!hideDialogActions && (
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button variant="text" onClick={handleClose}>
              {isNotForm ? 'Exit' : 'Cancel'}
            </Button>
            {!isNotForm && (
              <Button
                disabled={disableSubmit}
                startIcon={submitBtnIcon}
                type="submit"
                variant="contained"
              >
                {submitBtnText}
              </Button>
            )}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  )
}

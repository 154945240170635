import { createContext, useContext, useMemo, useState } from 'react'
import { localStgAuthKeys } from 'lib/config/api'

type State = {
  isLoggedIn: boolean
  login: () => void
  logout: () => void
}

type AuthProviderProps = {
  children: React.ReactNode
}

// Note that the logic in api.ts should control redirects to the login page, which includes a wiping
// of local storage tokens. Unfortunately the redirect does not have access to the router, so we're
// doing it with old-school `window.location.href`, which doesn't really lend itself to a better UX
// with toasts, etc. Considering the refresh token is valid for 30 days, this is not a huge deal.
function getInitialState() {
  if (typeof localStorage === 'undefined') {
    return false
  }

  const refreshToken = localStorage.getItem(localStgAuthKeys.refresh)

  return !!(refreshToken && refreshToken !== 'undefined')
}

const AuthContext = createContext<State>({
  isLoggedIn: getInitialState(),
  login() {},
  logout() {},
})

/**
 * Wrapper for the entire app (except maybe the QueryClient provider) that provides auth state to
 * all children. This is a common pattern, but look at
 * {@link https://ui.dev/react-router-protected-routes-authentication this guide} as a starting
 * point.
 *
 * @param props
 */
export function AuthProvider(props: AuthProviderProps) {
  // Can only check for existence because we need it to work offline. If user is offline and the
  // token is expired, they won't be able to get a new one.
  const refreshToken = localStorage.getItem(localStgAuthKeys.refresh)
  const [isLoggedIn, setIsLoggedIn] = useState(!!refreshToken)
  const { children } = props

  const initialValue = useMemo(() => {
    return {
      isLoggedIn,
      login() {
        setIsLoggedIn(true)
      },
      logout() {
        setIsLoggedIn(false)
      },
    }
  }, [isLoggedIn])

  return <AuthContext.Provider value={initialValue}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { segments } from 'components/reusable/routes'

const DEFAULT_PAGE_TITLE = 'EarthOptics Portal'

/**
 * Set document title. Extremely useful for routing, history, and GA.
 *
 * @param pageTitle text to use for <title>
 */
export const usePageTitle = (pageTitle: string): void => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (!pageTitle) {
      return // easy way to disable, e.g. child doesn't know title
    }

    // Cheap hack to avoid edge case of hitting 'Back' btn and arriving at the
    // sign-in page:
    if (pathname === `/${segments.login}`) {
      document.title = `Sign in - ${DEFAULT_PAGE_TITLE}`

      return
    }

    document.title = `${pageTitle ? `${pageTitle} - ` : ''}${DEFAULT_PAGE_TITLE}`
  }, [pageTitle, pathname])
}

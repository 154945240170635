import { useState } from 'react'
import { GoInfo } from 'react-icons/go'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { PopoverizeAnything } from 'components/reusable/info-surfaces'
import { useTableStatesStore } from 'components/reusable/tables/store'

const EXPLANATION = `When checked, the state of the tables in the app will be preserved the next time you return to this view.

Table state includes such things as filters, sorting, and column properties like order, width, and pinning. By default, the state will remain intact when you navigate within the app, or leave it entirely. Turn off the switch to disable this behavior and provide a clean default table state on each visit.

NOTE: disabling the switch will affect ALL tables in the application, not just the current.`

export function RememberTableStatesToggle() {
  const [persist, setPersist] = useTableStatesStore((state) => [
    state.userWantsPersistence,
    state.actions.setPersistence,
  ])

  const [checked, setChecked] = useState(persist)

  function handleSwitchChange(evt: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) {
    setChecked(isChecked)
    setPersist(isChecked)
  }

  return (
    <FormControl variant="standard">
      <div style={{ display: 'flex' }}>
        <FormControlLabel
          label="Remember table layouts"
          slotProps={{ typography: { variant: 'body2' } }}
          sx={{ mr: 1 }}
          control={
            <Switch
              checked={checked}
              size="small"
              inputProps={{
                'aria-label': 'remember table layouts',
              }}
              onChange={handleSwitchChange}
            />
          }
        />
        <PopoverizeAnything
          ariaLabel="option definition"
          theClickableThing={<GoInfo style={{ fontSize: '1.35em' }} />}
          infoPopoverProps={{
            title: 'Remember table layouts',
            nonHtmlContent: <Box whiteSpace="pre-wrap">{EXPLANATION}</Box>,
          }}
        />
      </div>
    </FormControl>
  )
}

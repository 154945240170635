import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import type { MapLayerMouseEvent } from 'mapbox-gl'
import type mapboxgl from 'mapbox-gl'

import { useMapTableDispatch, useMapTableStateContext } from 'components/reusable/tables/context'

import { config as toastsConfig, SEL_FEAT_LIST_TOAST_ID } from './config'
import { getMousePosition, setBoxOutlineInMap } from './utils'

/**
 * Hook to return handlers related to box select for use in `<InteractiveMap>`.
 *
 * {@link https://docs.mapbox.com/mapbox-gl-js/example/using-box-queryrenderedfeatures/ Non-React Mapbox example}
 *
 * @param isActive is the tool active
 * @param layerIds list of layer ids to include in the box select
 * @param uniqueIdProp key of the unique id property in `properties` object
 * @param uniqueNameProp key of unique name property in `properties` object. Shown in toast list.
 * @param onComplete callback to run when box select is complete
 * @param onFeatNameListToastClick callback to run when the toast is clicked,
 * @param pluralNoun human-friendly name of the thing that is being selected, e.g. `collections`
 * @returns map event handlers needed for box select
 */
function useBoxSelect(
  isActive: boolean,
  layerIds: string[],
  uniqueIdProp: string,
  uniqueNameProp: string,
  onComplete: (ids: string[]) => void,
  onFeatNameListToastClick: () => void,
  pluralNoun: string
): {
  onMouseMove: (evt: mapboxgl.MapLayerMouseEvent) => void
  onMouseDown: (evt: mapboxgl.MapLayerMouseEvent) => void
  onMouseUp: (evt: mapboxgl.MapLayerMouseEvent) => void
} {
  const [boxStartPoint, setBoxStartPoint] = useState<mapboxgl.Point | null>()

  const onMouseMove = useCallback(
    (evt: mapboxgl.MapLayerMouseEvent) => {
      if (boxStartPoint && isActive) {
        const canvas = evt.target.getCanvasContainer()
        const point = getMousePosition(evt.originalEvent, canvas)

        setBoxOutlineInMap(boxStartPoint, point, canvas)
      }
    },
    [boxStartPoint, isActive]
  )

  const onMouseDown = useCallback(
    (evt: mapboxgl.MapLayerMouseEvent) => {
      if (isActive) {
        const canvas = evt.target.getCanvasContainer()
        const point = getMousePosition(evt.originalEvent, canvas)

        setBoxStartPoint(point)
      }
    },
    [isActive]
  )

  const onMouseUp = useCallback(
    (evt: mapboxgl.MapLayerMouseEvent): void => {
      if (!boxStartPoint) {
        return undefined
      }

      const canvas = evt.target.getCanvasContainer()
      const point = getMousePosition(evt.originalEvent, canvas)

      const features = evt.target.queryRenderedFeatures([boxStartPoint, point], {
        layers: layerIds,
      })

      const selFeatIds = features.map(({ properties }) =>
        // Shouldn't be possible, but TS doesn't know that
        properties ? properties[uniqueIdProp] : ''
      )

      // Need `Set`s because there may be dupes due to multiple layers being
      // selectable, e.g. labels and icons
      const uniqueIds = [...new Set(selFeatIds)]

      // Rolling with 100 for now. Seems rare.
      if (uniqueIds.length > 100) {
        toast.error(
          toastsConfig.tooManyError(uniqueIds.length, pluralNoun),
          // Little more time to learn the "error" of their ways...
          { autoClose: 10000 }
        )

        return undefined
      }

      const selFeatNames = features.map(({ properties }) =>
        // Shouldn't be possible, but TS doesn't know that
        properties ? properties[uniqueNameProp] : ''
      )

      // NOTE: this doesn't handle the edge case (ok, edge case in prod at least) of collections
      // with the same name. The `uniqueIds` will still work fine in that case, but the toast will
      // be misleading.
      const uniqueNames = [...new Set(selFeatNames)].sort()

      const ToastContent = toastsConfig.selected({
        count: uniqueIds.length,
        pluralNoun,
        uniqueNames,
      })

      toast.update(SEL_FEAT_LIST_TOAST_ID, {
        render: () => ToastContent,
      })

      // Show toast with list of selected features
      toast.success(ToastContent, {
        autoClose: false,
        position: 'bottom-right',
        toastId: SEL_FEAT_LIST_TOAST_ID,
        onClick: onFeatNameListToastClick,
      })

      onComplete(uniqueIds)
      setBoxStartPoint(null) // clear so it's not there next time user activates

      return undefined
    },
    [
      boxStartPoint,
      layerIds,
      uniqueNameProp,
      onComplete,
      onFeatNameListToastClick,
      pluralNoun,
      uniqueIdProp,
    ]
  )

  return {
    onMouseMove,
    onMouseDown,
    onMouseUp,
  }
}

/**
 * Hook to prepare box-select event handlers. See {@link useBoxSelect} for more details. Depends
 * heavily on the `MapTableContext`.
 *
 * // TODO: change toast to something USEFUL, e.g. "click to see selected features" and "clear
 * selection" buttons.
 * // TODO: how to reuse this? Can't do conditionals inside hooks, but if the consumer is not inside
 * a Map/Table context, it will crash. 😞
 *
 * @param layerIds list of layer ids to include in the box select
 * @param uniqueIdProp key of the unique id property in `properties` object
 * @param uniqueNameProp key of unique name property in `properties` object. Shown in toast list.
 * @param pluralNoun human-friendly name of the thing that is being selected, e.g. `collections`
 * @returns map event handlers needed for box select
 */
export function useTableBoxSelectEventHandlers(
  layerIds: string[],
  uniqueIdProp: string,
  uniqueNameProp: string,
  pluralNoun: string
): {
  onMouseMove: (evt: MapLayerMouseEvent) => void
  onMouseDown: (evt: MapLayerMouseEvent) => void
  onMouseUp: (evt: MapLayerMouseEvent) => void
} {
  const { boxSelectMode } = useMapTableStateContext()
  const dispatch = useMapTableDispatch()

  const onComplete = useCallback(
    (uniqueIds: string[]) => {
      dispatch({ type: 'SET_SELECTED_TABLE_ROW_IDS', payload: uniqueIds })
    },
    [dispatch]
  )

  const onFeatNameListToastClick = useCallback(() => {
    dispatch({ type: 'SET_SELECTED_TABLE_ROW_IDS', payload: [] })
  }, [dispatch])

  const { onMouseMove, onMouseDown, onMouseUp } = useBoxSelect(
    boxSelectMode,
    layerIds,
    uniqueIdProp,
    uniqueNameProp,
    onComplete,
    onFeatNameListToastClick,
    pluralNoun
  )

  return {
    onMouseMove,
    onMouseDown,
    onMouseUp,
  }
}

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

import { DarkTooltip } from 'components/reusable/info-surfaces'
import type { SoilCollection } from 'components/soil-sampling/types.ssa'
import { legendConfig } from 'components/tillmapper/accordion/config.legend'
import {
  getProductNameByLayerName,
  getSoilMapperV1layerPath,
} from 'components/tillmapper/accordion/tabs/utils.products'
import type { AccordionPanelConfig, LayersCapabilities } from 'components/tillmapper/types'

type Props = {
  collection?: SoilCollection
}

const avatarSx = {
  height: 24,
  width: 24,
  color: 'text.primary',
  bgcolor: 'primary.main',
  svg: {
    fontSize: '0.85em',
  },
}

function getSoilMapperLinkFromCollection(
  layerName: keyof LayersCapabilities,
  collection: SoilCollection
): string {
  const { batch_id, layers, field } = collection
  const productName = getProductNameByLayerName(layerName)
  const hasBatchIdAndLayers = !!(batch_id && layers[layerName])

  if (!hasBatchIdAndLayers) {
    return '' // jump ship if there's nothing to work with at all
  }

  return getSoilMapperV1layerPath(field.id, batch_id, productName, layerName)
}

function getSoilMapperStatusConfigViaColln(
  collection?: SoilCollection
): (AccordionPanelConfig & { href: string; isAvailable: boolean })[] {
  if (!collection) {
    return []
  }

  return Object.values(legendConfig)
    .filter((item) => item.sourceId !== 'ssurgo')
    .map((item) => {
      const layerName = item.sourceId as keyof LayersCapabilities
      const href = getSoilMapperLinkFromCollection(layerName, collection)

      return {
        ...item,
        isAvailable: !!href,
        href,
      }
    }, {})
}

export function LayersAvatarLinksInline(props: Props) {
  const { collection } = props
  const { batch_id, layers } = collection || {}

  const atLeastOneChipsRequestSuccess = !!collection?.chips_requests?.some(
    (r) => r.chips_request.status === 'SUCCESS'
  )

  const layerConfigs = getSoilMapperStatusConfigViaColln(collection).filter((l) => l.isAvailable)

  const hasAtLeastOneLayer =
    atLeastOneChipsRequestSuccess || (batch_id && layers && Object.values(layers).some((l) => l))

  if (!hasAtLeastOneLayer || !layerConfigs.length) {
    return <>No layers available</>
  }

  return (
    // `span` prevents silly "<div> cannot appear as a descendent of <p>" error. Not sure how to
    // make the parent a <div>, it has no `component` prop. 🤷‍♂️
    <Box component="span" display="flex" gap={0.5} role="list">
      {layerConfigs.map(({ sourceId, title, icon, href }) => (
        <DarkTooltip key={sourceId} title={title}>
          <Avatar component="a" href={href} role="listitem" sx={avatarSx} target="_blank">
            {icon}
          </Avatar>
        </DarkTooltip>
      ))}
    </Box>
  )
}

import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaCheck } from 'react-icons/fa'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

type Props = {
  expandableContent?: React.ReactNode
  copyableDetails?: string
}

export function ExpandableToastContent(props: Props) {
  const { expandableContent, copyableDetails } = props
  const [showDetails, setShowDetails] = useState(false)
  const [textHasBeenCopied, setTextHasBeenCopied] = useState(false)

  return (
    <>
      <Box display="flex" gap={1} justifyContent="space-around" mt={0.5}>
        <Button color="inherit" size="small" onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? 'hide details' : 'show details'}
        </Button>
        {!!copyableDetails && (
          <CopyToClipboard text={copyableDetails} onCopy={() => setTextHasBeenCopied(true)}>
            <Button
              color="inherit"
              disabled={textHasBeenCopied}
              size="small"
              startIcon={textHasBeenCopied ? <FaCheck /> : null}
            >
              {textHasBeenCopied ? 'copied' : 'copy details'}
            </Button>
          </CopyToClipboard>
        )}
      </Box>
      <Collapse in={showDetails}>
        <Box bgcolor="error.dark" mt={1} px={1.5} style={{ overflowX: 'auto', fontSize: 'small' }}>
          <pre>{expandableContent}</pre>
        </Box>
      </Collapse>
    </>
  )
}

import { MdSearch } from 'react-icons/md'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

type Props = {
  onClick: () => void
}

const TITLE = 'Toggle search bar'

export function ToggleSearchBarBtn(props: Props) {
  const { onClick } = props

  return (
    <Tooltip arrow enterDelay={400} enterNextDelay={400} placement="top" title={TITLE}>
      <IconButton aria-label={TITLE} color="primary" onClick={onClick}>
        <MdSearch />
      </IconButton>
    </Tooltip>
  )
}

import Typography from '@mui/material/Typography'

type Props = {
  html: string
  spacious?: boolean
}

// Tricky formatting when we lose control of it in Markdownland, USA.
export function SaneMarkdownWrapper(props: Props) {
  const { html, spacious } = props

  return (
    <Typography
      component="div"
      dangerouslySetInnerHTML={html ? { __html: html } : undefined}
      mb={3}
      sx={{
        h2: {
          mt: 0,
          mb: 1,
        },
        'h3,h4,h5,h6': {
          mb: 1,
        },
        'p + *': {
          mt: spacious ? 3 : 2,
        },
        '& > p': {
          mt: 0,
          fontSize: 'body2.fontSize',
        },
        a: {
          color: 'primary.main',
        },
        '& > ol, & > ul': {
          fontSize: 'body2.fontSize',
          ml: 0,
          pl: 4,
          mt: spacious ? 1 : 0,
        },
        img: {
          maxWidth: 300,
          display: 'inline-block',
          mx: 'auto',
        },
      }}
    />
  )
}

import { BsCollection } from 'react-icons/bs'
import { FaDrawPolygon } from 'react-icons/fa'
import { FiUserPlus, FiUsers } from 'react-icons/fi'
import { GiField } from 'react-icons/gi'
import { ImFilesEmpty } from 'react-icons/im'
import { IoLayers } from 'react-icons/io5'
import { TbTableImport } from 'react-icons/tb'

export const fieldMgmtIcons = {
  allFields: <GiField />,
  import: <TbTableImport />,
  draw: <FaDrawPolygon />,
  allContacts: <FiUsers />,
  newContact: <FiUserPlus />,
  collections: <BsCollection />,
  collnFiles: <ImFilesEmpty />,
  layers: <IoLayers />,
}

import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

type Props = {
  params: AutocompleteRenderInputParams
  helperText?: string
  placeholder?: string
  required?: boolean
}

/**
 * The child component of the Autocomplete
 *
 * @param props Basic options and the Autocomplete params to spread
 * @returns JSX Element with AutocompleteProps spread into TextField
 */
export function ContactsTextInput(props: Props) {
  const { params, placeholder = 'Select a contact', required } = props
  const { helperText = 'Individual to contact about the field' } = props

  return (
    <TextField
      {...params}
      fullWidth
      aria-label="contact"
      helperText={helperText}
      label="Onsite contact"
      placeholder={placeholder}
      required={required}
    />
  )
}

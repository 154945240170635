import type { ErrorType } from 'lib/api/django-axios-instance'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'

import type { MultiThingSuccessResponse } from './types.sampling-plans'
import { getMultiThingSummaryCounts } from './utils.sampling-plans'

type SuccessToastContentProps = {
  items: MultiThingSuccessResponse[]
}

type ErrorToastContentProps = {
  error: ErrorType<string[]>
}

const DEFAULT_ERR_MSG = 'An error was encountered'

function InProgressToastContent() {
  return (
    <ToastContentWithTitle
      content="You will be notified when it is finished."
      title="Multi-thing in progress"
    />
  )
}

function SuccessToastContent(props: SuccessToastContentProps) {
  const { items } = props
  const summaryCounts = getMultiThingSummaryCounts(items)

  return (
    <ToastContentWithTitle
      title="Success. Click for details."
      content={
        <ul style={{ paddingLeft: 24 }}>
          <li>Fields created: {summaryCounts.fieldsCreated}</li>
          <li>Fields already existing: {summaryCounts.fieldsAlreadyExisting}</li>
          <li>Collections created: {summaryCounts.collectionsCreated}</li>
          <li>Collections already existing: {summaryCounts.collectionsAlreadyExisting}</li>
        </ul>
      }
    />
  )
}

/**
 * Errors are a mixed bag but 400 (exactly) has an array of strings. Need to check for the other
 * scenarios just in case.
 *
 * @param props just an object with an `unknown` type of error
 * @returns JSX component
 */
function ErrorToastContent(props: ErrorToastContentProps) {
  const { error } = props
  const axiosErrors = error.response?.data
  const isArray = axiosErrors && Array.isArray(axiosErrors)

  let Content: React.ReactNode = error.message

  if (isArray) {
    Content = (
      <ul style={{ paddingLeft: 24 }}>
        {axiosErrors.map((e, i) => {
          const msg = typeof e === 'string' ? e : `${i}: ${DEFAULT_ERR_MSG}`

          return <li key={msg}>{msg}</li>
        })}
      </ul>
    )
  }

  return <ToastContentWithTitle content={Content || DEFAULT_ERR_MSG} title="Something went wrong" />
}

export { SuccessToastContent, ErrorToastContent, InProgressToastContent }

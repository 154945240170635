import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

type Props = {
  disableReset: boolean
  disableSubmit: boolean
  handleReset: () => void
  isLoading: boolean
  /**
   * Handy override when "Submit" doesn't make as much sense
   *
   * @default Submit
   * @example "Continue"
   */
  submitBtnText?: string
}

export function FormResetAndSubmitBtns(props: Props) {
  const { disableReset, handleReset, disableSubmit, isLoading } = props
  const { submitBtnText = 'Submit' } = props

  return (
    <Box display="flex" gap={2} justifyContent="center">
      <LoadingButton
        disabled={disableReset || isLoading}
        size="large"
        variant="outlined"
        onClick={handleReset}
      >
        Reset
      </LoadingButton>
      <LoadingButton
        disabled={disableSubmit}
        loading={isLoading}
        size="large"
        type="submit"
        variant="contained"
      >
        {submitBtnText}
      </LoadingButton>
    </Box>
  )
}

/**
 * Miscellaneous API queries
 */
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { apiEndpoints, axiosInstance } from 'lib/config'

import type { LayersCapabilities } from 'components/tillmapper/types'

async function fetchLayerCapabilities(jobId: string): Promise<LayersCapabilities> {
  const res = await axiosInstance.get(`${apiEndpoints.layersCapabilities}?job_id=${jobId}`)

  return res.data
}

// Return available layers based on a given job (obtained from batch, which is obtained from
// `useParams`). Not all users may see all layers, and not all jobs may contain all layers.
export function useLayerCapabilities(
  isStaff: boolean, // TODO: rm if backend handles it
  jobId?: string | null,
  jobIdFromBatch?: string | null
): UseQueryResult<LayersCapabilities> {
  return useQuery(
    ['layer-capabilities', jobId || jobIdFromBatch],
    () => fetchLayerCapabilities(jobId || jobIdFromBatch || ''),
    {
      enabled: !!jobIdFromBatch || !!jobId,
      // TODO: should backend handle non-staff access to this stuff?
      select: (data) => {
        // If staff or no conductivity, use data verbatim, no addl steps needed
        if (isStaff || !data.conductivity) return data

        // Only staff should see Powerline, and freq options
        const { conductivity } = data

        return {
          ...data,
          conductivity: {
            emiDataTypes: conductivity.emiDataTypes.filter(
              (emiType) => emiType === 'Quadrature' // for now...
            ),
            frequencies: conductivity.frequencies.filter((freq) => freq === 63030),
          },
        } as LayersCapabilities
      },
    }
  )
}

import { PiPathBold } from 'react-icons/pi'
import Button from '@mui/material/Button'

import { useFmtStore } from 'components/fields/store'

export function NewFieldBndryViaGpsBtn() {
  const disabled = useFmtStore((state) => state.geolocLinePath.status) !== 'OFF'
  const setGeolocLinePathStatus = useFmtStore((state) => state.actions.setGeolocLinePathStatus)

  function handleClick() {
    setGeolocLinePathStatus('PAUSED')
  }

  return (
    <Button
      disabled={disabled}
      size="small"
      startIcon={<PiPathBold />}
      variant="contained"
      onClick={handleClick}
    >
      set boundary via GPS
    </Button>
  )
}

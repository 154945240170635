const LOCAL_STG_KEY = 'mapGridLineColor'
const DEFAULT_COLOR = '#00ffff' // cyan

export const gridLineColorLocalStg = {
  get: () => {
    if (typeof localStorage === 'undefined') {
      return DEFAULT_COLOR
    }

    return localStorage.getItem(LOCAL_STG_KEY) || DEFAULT_COLOR
  },
  set: (color: string) => {
    localStorage.setItem(LOCAL_STG_KEY, color)
  },
}

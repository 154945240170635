import { toast } from 'react-toastify'
import { AxiosError } from 'axios'

import { ErrorToastContent } from 'components/reusable/alerts-and-messages/ErrorToastContent'
import type { Props as ContentProps } from 'components/reusable/alerts-and-messages/ToastContentWithTitle'

function getErrorContent(error: unknown) {
  let axiosErrorStatusCode: number | undefined
  let errorMsg =
    'This operation could not be completed. For troubleshooting, keep track of any steps that may have led to this error.'
  let axiosErrorUrl = ''

  if (error instanceof AxiosError) {
    // eslint-disable-next-line prefer-destructuring
    errorMsg = error.message
    axiosErrorStatusCode = error.response?.status
    axiosErrorUrl = error.config?.url || ''
  } else if (error instanceof Error) {
    // eslint-disable-next-line prefer-destructuring
    errorMsg = error.message
  } else if (typeof error === 'string') {
    errorMsg = error
  }

  return {
    errorMsg,
    axiosErrorStatusCode,
    axiosErrorUrl,
  }
}

/**
 * Shows an toast with the `<ErrorToastContent>` component. See that component's `Props` type for
 * details on the arguments.
 *
 * @param error unknown but usually an `AxiosError` or regular `Error`
 * @param contentProps subset of optional props to pass to the ToastContentWithTitle component. See
 * the component's implementation for details such as the default title.
 */
export function showErrorToast(
  error: unknown,
  contentProps?: Partial<Pick<ContentProps, 'content' | 'title'>>
): void {
  const { errorMsg, axiosErrorStatusCode, axiosErrorUrl } = getErrorContent(error)

  toast.error(
    <ErrorToastContent
      axiosErrorStatusCode={axiosErrorStatusCode}
      axiosErrorUrl={axiosErrorUrl}
      contentProps={contentProps}
      errorMsg={errorMsg}
    />,
    {
      closeOnClick: false,
      autoClose: false,
      bodyStyle: {
        overflowX: 'auto', // needed for scrolling the `<pre>` info section horizontally
      },
    }
  )
}

import { useState } from 'react'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import type { IconButtonProps } from '@mui/material/IconButton'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'

import { MenuCloseBtn } from 'components/reusable/buttons'

type Props = {
  btnText: string
  children: React.ReactNode
  elemId: string
  type: 'button' | 'icon'
  icon?: React.ReactNode
  btnProps?: ButtonProps | IconButtonProps
  /**
   * @default 320
   */
  contentMaxWidth?: number | 'unset'
}

const TEST_ID = 'soilmapper-links-btn'

export function BasicBtnWithPopover(props: Props) {
  const { type, btnText, elemId, icon, btnProps, children } = props
  const { contentMaxWidth = 320 } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? elemId : undefined

  let Btn = null

  if (type === 'icon') {
    Btn = (
      <IconButton
        {...btnProps}
        aria-describedby={popoverId}
        aria-label={btnText}
        data-cy={TEST_ID}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
    )
  } else {
    Btn = (
      <Button
        {...(btnProps as ButtonProps)}
        aria-describedby={popoverId}
        data-cy={TEST_ID}
        startIcon={icon}
        onClick={handleClick}
      >
        {btnText}
      </Button>
    )
  }

  return (
    <>
      {Btn}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id={popoverId}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Paper
          elevation={16}
          sx={{
            p: 2,
            whiteSpace: 'pre-wrap',
            maxWidth: contentMaxWidth,
          }}
        >
          {children}
        </Paper>
        <MenuCloseBtn tiny handleClick={() => setAnchorEl(null)} />
      </Popover>
    </>
  )
}

import * as React from 'react'
import { MdClose } from 'react-icons/md'
import IconButton from '@mui/material/IconButton'

type Props = {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  tiny?: boolean
}

const rootStyle: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1, // doesn't seem to be clickable on mobile otherwise
}

export function MenuCloseBtn(props: Props) {
  const { handleClick, tiny } = props

  const tinyFontStyle = {
    fontSize: '1rem',
    top: -2,
    right: -2,
  }

  return (
    <IconButton
      aria-label="close"
      style={{ ...rootStyle, ...(tiny ? tinyFontStyle : {}) }}
      onClick={handleClick}
    >
      <MdClose />
    </IconButton>
  )
}

import { useMemo, useState } from 'react'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import type { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid-premium'
import { useV1SoilSamplingCollectionsFilesList } from 'lib/api/django/v1/v1'
import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import type { TableToolbarProps } from 'components/reusable/tables'
import { ReusableTable } from 'components/reusable/tables/ReusableTable'
import { AFTER_FIELDWORK_STATUSES } from 'components/soil-sampling/config.ssa'
import { useCollection, useIsAssignedToCollection } from 'components/soil-sampling/hooks.ssa'

import { defaultStateOverrides, getColumns } from './config.colln-files-cols'
import { DeleteCollnFileBtnModal } from './DeleteCollnFileBtnModal'

type Props = {
  fullCollnId?: string
  wrapperSx?: SxProps
}

type ToolbarProps = {
  canDelete: boolean
  fullCollnId: string
}

const TABLE_SLUG = 'collection_files'

const defaultWrapperSx: SxProps = {
  height: '100%',
  minHeight: 300,
  width: '100%',
  overflow: 'hidden',
}

function useCanDelete(fullCollnId: string): boolean {
  const { defaultQuery } = useCollection(undefined, undefined, fullCollnId)
  const { data: collection } = defaultQuery
  const currentUserIsAssigned = useIsAssignedToCollection(collection, true)
  const hasGroupBasedAccess = useCanAccessBasedOnGroups(['collection_manager'])
  const tooLate = collection && AFTER_FIELDWORK_STATUSES.includes(collection.status)

  return !tooLate && (currentUserIsAssigned || hasGroupBasedAccess)
}

function DeleteFilesBtnWrap(props: ToolbarProps) {
  const { fullCollnId, canDelete } = props
  const apiRef = useGridApiContext()
  const [selRowIds, setSelRowIds] = useState<string[]>([])

  function handleSelectionChange(rowIds: GridRowSelectionModel) {
    setSelRowIds(rowIds as string[])
  }

  useGridApiEventHandler(apiRef, 'rowSelectionChange', handleSelectionChange)

  return (
    <DeleteCollnFileBtnModal
      showBtnText
      collnFullId={fullCollnId}
      disabled={!canDelete}
      fileIDs={selRowIds}
    />
  )
}

export function CollectionFilesTable(props: Props) {
  const { fullCollnId = '', wrapperSx } = props
  const enabled = !!fullCollnId
  const canDelete = useCanDelete(fullCollnId)
  const columns = useMemo(() => getColumns(canDelete), [canDelete])

  const filesQuery = useV1SoilSamplingCollectionsFilesList(fullCollnId || '', {
    query: { enabled },
  })

  const { data, isInitialLoading } = filesQuery

  const toolbarProps: TableToolbarProps = {
    tableSlug: TABLE_SLUG,
    contentBeforeDefaultBtns: (
      <DeleteFilesBtnWrap canDelete={canDelete} fullCollnId={fullCollnId} />
    ),
  }

  return (
    // TS loses its mind without the coercion, even though both the default AND
    // the overrides are typed as `SxProps` 🤷‍♂️
    <Box sx={{ ...defaultWrapperSx, ...wrapperSx } as SxProps}>
      <ReusableTable
        columns={columns}
        data={data || []}
        defaultStateOverrides={defaultStateOverrides}
        tableSlug={TABLE_SLUG}
        tableProps={{
          getRowId: (row) => row.id,
          loading: isInitialLoading,
          slotProps: { toolbar: toolbarProps },
        }}
      />
    </Box>
  )
}

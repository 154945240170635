import { darken } from '@mui/material'
import * as turf from '@turf/turf'

import type { SoilCollection, SoilSample } from 'components/soil-sampling/types.ssa'
import { getUniqueBagLabelInfoViaReqs } from 'components/soil-sampling/utils.soil-sampling'

import type { PreppedRxSampleFeat, RxSamplesSelectOutput } from '.'
import { GROUP_INTERP_KEY, RX_DOT_STROKE_COLOR_KEY } from '.'

export function getSampleHasRxLatLon(sample: SoilSample): boolean {
  return typeof sample.prescribed_lat === 'number' && typeof sample.prescribed_lon === 'number'
}

export function getSampleHasCollectedLatLon(sample: SoilSample): boolean {
  return typeof sample.sample_lat === 'number' && typeof sample.sample_lon === 'number'
}

/**
 * Check if a sample has all GPS info: lat/lon, accuracy, and collection timestamp.
 *
 * @param sample soil sample
 * @returns true if sample has all GPS info, otherwise false
 */
export function getSampleHasAllGpsInfo(sample: SoilSample): boolean {
  const { gps_accuracy, collected_at } = sample

  return getSampleHasCollectedLatLon(sample) && typeof gps_accuracy === 'number' && !!collected_at
}

/**
 * Returns the number of completed soil samples in the given array, based on sample lat and lon. The
 * API already returns this, but it is based on `status` rather than whether or not the sample has
 * collected lat/lon coords. Normally this works fine, but we have not fully resolved the issue that
 * prevents the automatic changing of `status` to `UPLOADED` when a sample is uploaded.
 *
 * @param samples array of soil samples to count
 * @returns the number of completed soil samples in the given array
 */
export function getCompletedSampleCount(samples: Array<SoilSample>): number {
  return samples.filter((s) => getSampleHasCollectedLatLon(s)).length
}

export function getRxCoords(sample: SoilSample): [number, number] | undefined {
  if (getSampleHasRxLatLon(sample)) {
    return [sample.prescribed_lon, sample.prescribed_lat] as [number, number]
  }

  return undefined
}

export function getCollectedSampleCoords(sample: SoilSample): [number, number] | undefined {
  if (getSampleHasCollectedLatLon(sample)) {
    return [sample.sample_lon, sample.sample_lat] as [number, number]
  }

  return undefined
}

/**
 * Mainly used for map auto-zoom, this will return the sample's "collected" (aka from QR scan)
 * coordinates, if available, otherwise the Rx coordinates, or undefined if neither exists.
 *
 * @param sample SoilSample
 * @returns coordinate pair of sample lat an lon, or rx, or nothing
 */
export function getSampleCoords(sample: SoilSample): [number, number] | undefined {
  return getCollectedSampleCoords(sample) || getRxCoords(sample)
}

/**
 * See if a feature has a coordinate pair (rx or regular) that can be mapped.
 *
 * @param sample soil sample
 * @returns true if Rx lat AND lon are present, OR sample lat AND lon are
 */
export function getSampleIsMapWorthy(sample: SoilSample): boolean {
  return getSampleHasCollectedLatLon(sample) || getSampleHasRxLatLon(sample)
}

export function collectionHasAtLeastOneRxSample(samples: SoilSample[]): boolean {
  return samples.some((s) => getSampleHasRxLatLon(s))
}

// TODO: error: coordinates must contain numbers
// Too turfy for unit testing. Will test its non-turf deps instead.
export function rxSamplesQuerySelect(c: SoilCollection): RxSamplesSelectOutput {
  const uniqueBagLabelInfoViaReqs = getUniqueBagLabelInfoViaReqs(c.requirements)
  const samplesWithRx = c.samples.filter((s) => getSampleHasRxLatLon(s))

  let features: PreppedRxSampleFeat[] = []

  samplesWithRx.forEach((s) => {
    const coords = [s.prescribed_lon, s.prescribed_lat] as [number, number]
    const circleStrokeColor = darken(s.marker_color, 0.35)

    const correspondingGroupInterpIndex = uniqueBagLabelInfoViaReqs.findIndex(
      (req) => req.bag_prefix === s.bag_prefix
    )

    const properties = {
      ...s,
      [GROUP_INTERP_KEY]: correspondingGroupInterpIndex + 1,
      [RX_DOT_STROKE_COLOR_KEY]: circleStrokeColor,
    }

    const circle = turf.circle(coords, 5, {
      steps: 30,
      units: 'meters',
      properties,
    })

    features = [
      ...features,
      turf.point(coords, properties),
      turf.polygonToLine(circle.geometry, {
        properties,
      }) as PreppedRxSampleFeat,
    ]
  })

  return {
    features,
    totalSampleTypes: uniqueBagLabelInfoViaReqs.length,
  }
}

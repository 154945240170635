import { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { useV1UiTableViewsTableviewsList } from 'lib/api/django/v1/v1'

import type { ReusableTableProps, TableToolbarProps } from 'components/reusable/tables/types'

import { ReusableTable } from '..'

import type { TableViewsTableProps as Props } from '.'
import { defaultStateOverrides, getColumns } from './config'

const TABLE_SLUG = 'ui-table-views'

const SavedViewInstrs = (
  <Typography component="div" mb={1}>
    <p style={{ marginTop: 0 }}>
      This is a list of saved views- a convenient way to store and share table settings such as
      filters, sorting, and column visibility. Any filters will apply to the map as well.
    </p>
    <p>
      Saved views cannot be updated after they are created. Any changes to the table will not update
      the original saved view. Instead, feel free to create a new view and delete the original.
      {/* TODO: "editing" functionality and instructions */}
    </p>
  </Typography>
)

const toolbarProps: TableToolbarProps = {
  hideFullScreenBtn: true,
  tableSlug: TABLE_SLUG,
}

const tableProps: ReusableTableProps['tableProps'] = {
  getRowId: (row) => row.short_id,
  slotProps: { toolbar: toolbarProps },
  localeText: {
    noRowsLabel: 'No table views are available.',
    noResultsOverlayLabel:
      'No table views found. Try adjusting your filters or click "Views -> Reset to default view" to clear all filters.',
  },
}

/**
 * List of saved table views for a given table. **Important:** to use most of this stuff, need to
 * create a UI Table in Django first, then use its slug.
 *
 * @param props
 */
export function TableViewsTable(props: Props) {
  const { tableSlug } = props
  const query = useV1UiTableViewsTableviewsList()
  const { data = [], isLoading } = query
  const columns = useMemo(() => getColumns(props), [props])

  const TheTable = (
    <ReusableTable
      columns={columns}
      data={data.filter((row) => row.table_slug === tableSlug)} // don't show other tables' views
      defaultStateOverrides={defaultStateOverrides}
      tableProps={{ ...tableProps, loading: isLoading }}
      tableSlug={TABLE_SLUG}
    />
  )

  return (
    <>
      {SavedViewInstrs}
      {TheTable}
    </>
  )
}

export const UPLOADS_BASEPATH = 'uploads'

export const segments = {
  base: UPLOADS_BASEPATH,
  detail: 'detail',
}

export const wildcards = {
  batchId: ':batchId',
}

import { useMemo } from 'react'

import { ReusableTable } from 'components/reusable/tables/ReusableTable'

import { getColumns } from './config.fmt-raw-import-cols'

type Props = {
  features: GeoJSON.Feature[]
}

// TODO: rename this component and file to something better
export function NewFieldsSrcFeatsTable({ features }: Props) {
  const firstFeature = features[0]

  const columns = useMemo(() => {
    const attribs = firstFeature ? firstFeature.properties : {}
    const columnNames = Object.keys(attribs as object)

    return getColumns(columnNames)
  }, [firstFeature])

  return (
    <ReusableTable
      columns={columns}
      data={features}
      tableSlug={false}
      tableProps={{
        localeText: {
          noRowsLabel: 'No files (might still be loading).',
        },
      }}
    />
  )
}

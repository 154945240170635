import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import type { FieldAttributes } from './types.fields-mgmt-api'

type Props = {
  extras?: FieldAttributes['extras']
}

const rootSx = {
  borderTopColor: 'divider',
  borderTopStyle: 'solid',
  borderTopWidth: 1,
  mt: 3,
  pt: 2,
}

const listSx = {
  columnGap: 2,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  rowGap: 1,
}

function Extra(props: { keyName: string; val: unknown }) {
  const { keyName, val } = props

  return (
    <>
      <b style={{ textAlign: 'right' }}>{keyName}:</b> {val}
    </>
  )
}

export function FieldMgmtExtras({ extras }: Props) {
  if (!Object.keys(extras || {}).length) return null

  return (
    <Box mt={3} sx={rootSx}>
      <Typography component="h2" mb={2} variant="h6">
        Additional Metadata
      </Typography>
      <Typography component="div" sx={listSx}>
        {Object.entries(extras || {}).map(([key, val]) => {
          // Prevents crash for Date-like strings, which are somehow interpreted as Dates when
          // passed to <Extra>. Interestingly, for the TS, VS Code is fine without the string
          // coercion, but CLI is not. We know it's coming from JSON though, so `val` will never be
          // a function or anything crazy, and <Extra> is purely for presentation anyway, so no
          // manipulation is done on the source data.
          return <Extra key={key} keyName={key} val={(val as string)?.toString()} />
        })}
      </Typography>
    </Box>
  )
}

import type { ToastContentProps } from 'react-toastify'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages/ToastContentWithTitle'

type Props = Pick<ToastContentProps, 'closeToast'> & {
  onClick: () => void
  count: number
}

export function DownloadCollnsToastContent(props: Props) {
  const { closeToast, onClick, count } = props
  const title = `${count} new collection${count > 1 ? 's' : ''} available`

  const Content = (
    <>
      Click "Download" to save to this device.
      <Box display="flex" gap={1.5} justifyContent="center" mt={1.5}>
        <Button color="inherit" size="small" onClick={closeToast}>
          remind me later
        </Button>
        <Button color="inherit" size="small" variant="outlined" onClick={onClick}>
          download
        </Button>
      </Box>
    </>
  )

  return <ToastContentWithTitle content={Content} title={title} />
}

import { Link as RouterLink } from 'react-router-dom'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import type { IconButtonProps } from '@mui/material/IconButton'
import IconButton from '@mui/material/IconButton'
import { useIsAtLeastPhablet } from 'lib/hooks'

export type Props = {
  icon: React.ReactNode
  /** Used for `aria-label` of icon button */
  text: string
  btnProps?: ButtonProps
  disabled?: boolean
  iconBtnProps?: IconButtonProps
  onClick?: () => void
  to?: string
}

export function ResponsiveIconBtn(props: Props) {
  const { icon, text, onClick, to, disabled, btnProps, iconBtnProps } = props
  const isAtLeastPhablet = useIsAtLeastPhablet()
  const size = isAtLeastPhablet ? 'medium' : 'small'

  // CRED: github.com/mui/material-ui/issues/16846#issuecomment-766747287
  const commonProps = {
    to,
    disabled,
    component: to ? RouterLink : undefined,
    onClick: to ? undefined : onClick,
  }

  if (isAtLeastPhablet) {
    return (
      <Button {...commonProps} size={size} startIcon={icon} {...btnProps}>
        {text}
      </Button>
    )
  }

  return (
    <IconButton aria-label={text} {...commonProps} size={size} {...iconBtnProps}>
      {icon}
    </IconButton>
  )
}

import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import LoadingButton from '@mui/lab/LoadingButton'
import { useQueryClient } from '@tanstack/react-query'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey,
  useV1SoilSamplingCollectionsCreateLayersCreate,
  useV1SoilSamplingCollectionsFilesList,
} from 'lib/api/django/v1/v1'

import { useDispatchContext } from 'components/fields/create-fields-steps/NewFieldContext'
import { soilCollectorToasts } from 'components/soil-sampling/config.toasts.soilcollector'
import { useRemoteCollnMutation } from 'components/soil-sampling/queries.ssa-remote'

type Props = {
  hasBeenSubmitted: boolean
  collnId?: string
}

export function SubmitCollnCreateLayersBtn(props: Props) {
  const { hasBeenSubmitted, collnId = '' } = props
  const createLayersMutation = useV1SoilSamplingCollectionsCreateLayersCreate()
  // Skip toast in favor of a more specific custom one
  const remoteMutation = useRemoteCollnMutation(true)
  const rqc = useQueryClient()
  const dispatch = useDispatchContext()

  // TODO: seems like a waste to call this, but there's no other way to check to see if any files
  // are present. And we want to disable submission if there aren't any. Maybe this is the only way?
  const filesQuery = useV1SoilSamplingCollectionsFilesList(collnId, {
    query: {
      enabled: !!collnId && !hasBeenSubmitted,
    },
  })

  function handleClick() {
    // Don't really need a success toast since the user in this flow mainly cares if "submit" was a
    // success. They might not even know what happens after submission, so this avoids confusion.
    createLayersMutation.mutate(
      { id: collnId },
      {
        onSuccess: () => {
          remoteMutation.mutate(
            {
              id: collnId,
              data: { status: 'SUBMITTED' },
            },
            {
              onSuccess: () => {
                toast.success(soilCollectorToasts.collnSubmitted)
                dispatch({ type: 'CLEAR_COLLN_SPATIAL_FILES' })
                rqc.invalidateQueries(getV1SoilSamplingCollectionsRetrieveQueryKey(collnId))
              },
            }
          )
        },
        onError: (err) => {
          let msg = 'Something went wrong triggering the process.'

          if (err instanceof Error) {
            msg = err.message
          }

          toast.error(soilCollectorToasts.layerProcessingFailed(msg), {
            autoClose: false,
          })
        },
      }
    )
  }

  return (
    <LoadingButton
      disabled={hasBeenSubmitted || !filesQuery.data?.length}
      loading={createLayersMutation.isLoading || remoteMutation.isLoading}
      startIcon={hasBeenSubmitted ? <BsCheckCircleFill /> : <BsCheckCircle />}
      variant="contained"
      onClick={handleClick}
    >
      {hasBeenSubmitted ? 'collection submitted' : 'submit collection'}
    </LoadingButton>
  )
}

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useCanAccessBasedOnGroups, useDefaultSecGroup } from 'lib/queries.user'

import { useClearDrawnBoundaryAndStopEditing } from 'components/fields/hooks.fields-mgmt'
import { segments } from 'components/fields/routes'
import type { FieldsMgmtFormSchema } from 'components/fields/types.fields-mgmt'
import { NoAccessSpan } from 'components/reusable/alerts-and-messages'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'

import { newFieldValidation } from './config.fields-mgmt-form'
import { FieldMgmtForm } from './FieldMgmtForm'
import { NewFieldBndryViaGpsBtn } from './NewFieldBndryViaGpsBtn'
import { getV1FieldsListQueryKey } from './queries.fields-mgmt.gets'
import { useV1FieldsCreate } from './queries.fields-mgmt.post'
import type { FieldPostSuccessResponse } from './types.fields-mgmt-api'
import { getFormDefaults, showSuccessToast } from './utils.fields-mgmt-form'

export function DrawNewFieldPanel() {
  const mutation = useV1FieldsCreate()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const onReset = useClearDrawnBoundaryAndStopEditing()
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])
  const { isLoading, groups } = useDefaultSecGroup()
  const { drawnFeatureGeom } = useDrawToolsStateContext()

  if (isLoading) {
    return null
  }

  if (!userHasWritePerms) {
    return NoAccessSpan
  }

  function handleSubmit(allFormData: FieldsMgmtFormSchema) {
    function onSuccess({ id }: FieldPostSuccessResponse) {
      const listQueryKey = getV1FieldsListQueryKey()

      showSuccessToast('created', allFormData.name)

      queryClient.invalidateQueries(listQueryKey)

      navigate(`${segments.base}/${segments.fields}/${id}`)
    }

    if (!drawnFeatureGeom) {
      return
    }

    mutation.mutate(
      {
        type: 'Feature',
        // Can be certain it's present if the resolver is on top of its game
        geometry: drawnFeatureGeom,
        properties: {
          ...allFormData,
          id: '', // only applicable in the bulk import. Just making TS happy...
          // TODO: API-PENDING: stop using owned_by once `org` is response:
          // https://earthoptics.atlassian.net/browse/DV-2152
          // Will fail w/o a valid id, but form should be covering us
          owned_by: allFormData.org?.id || '',
          onsite_contact: allFormData.onsite_contact?.id || '',
          // Have to munge it here because form uses array of objects but the PATCH and POST only
          // need array of IDs.
          security_groups: allFormData.security_groups.map(({ id }) => id),
        },
      },
      {
        onError: (error) => {
          const firstErr = error.response?.data.non_field_errors[0] || ''

          toast.error(`Field could not be created. ${firstErr}`)
        },
        onSuccess,
      }
    )
  }

  const defaultValues = getFormDefaults(undefined, undefined, groups) // TODO: EPIC: de-shakify

  // DV-4940 - why is submit btn enabled when there's no Org and maybe Field??
  return (
    <FieldMgmtForm
      headerChildren={<NewFieldBndryViaGpsBtn />}
      initialGeom={drawnFeatureGeom}
      mode="draw"
      resolver={yupResolver(newFieldValidation)}
      defaultValues={{
        ...defaultValues,
        geometry_source: 'DRAWN',
      }}
      onReset={onReset}
      onSubmit={handleSubmit}
    />
  )
}

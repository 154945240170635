import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { getPrettyDate, timeDifference } from 'lib/utils'

type Props = {
  date: string
  /**
   * Tell `timeDifference` to abbreviate.
   */
  concise?: boolean
  /**
   * If `true`, don't show relative dates "4 days ago"
   */
  absolute?: boolean
  /**
   * If `true`, don't show the time
   */
  omitTime?: boolean
}

export function DateTooltip(props: Props) {
  const { date, absolute, concise, omitTime } = props
  const prettyDate = getPrettyDate(date, omitTime)
  const relativeDate = timeDifference(new Date(), new Date(date), concise)

  return (
    <Tooltip arrow placement="right" title={absolute ? relativeDate : prettyDate}>
      <Typography fontSize="caption.fontSize">{absolute ? prettyDate : relativeDate}</Typography>
    </Tooltip>
  )
}

import { useEffect } from 'react'
import type { ValidationMode } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import type { Contact } from 'lib/api/django/model'
import { useV1ContactsCreate, useV1ContactsPartialUpdate } from 'lib/api/django/v1/v1'
import type { ErrorType } from 'lib/api/django-axios-instance'

import { FormResetAndSubmitBtns } from 'components/reusable/forms/FormResetAndSubmitBtns'

import { CONTACT_FORM_DEFAULTS } from './config.contacts'
import { ContactFormControls } from './ContactFormControls'
import { useOnSuccessDefault } from './hooks.contacts'
import { schema } from './onsite-contact-form-validation'
import { defaultMutationErrHandler } from './utils.contacts'

type Props = {
  /** aka POST vs. PATCH */
  type: 'create' | 'edit'
  /** Default form values */
  defaultValues?: Contact // QUESTION: handle empty string for pref method?
  /**
   * Contact POST/PATCH error handler
   *
   * @default defaultMutationErrHandler
   */
  onSubmitError?: (err: ErrorType<unknown>, isEditing: boolean, contactName: string) => void
  /**
   * Contact POST/PATCH success handler
   *
   * @default defaultMutationErrHandler
   */
  onSuccess?: (contact: Contact) => void
}

const formConfig = {
  mode: 'onChange' as keyof ValidationMode, // other modes: confusing/misleading
  resolver: yupResolver(schema),
}

export function ContactPersonForm(props: Props) {
  const { defaultValues = CONTACT_FORM_DEFAULTS, type, onSuccess: onSuccessHandler } = props
  const { onSubmitError = defaultMutationErrHandler } = props
  const editMutation = useV1ContactsPartialUpdate()
  const createMutation = useV1ContactsCreate()
  const hookForm = useForm<Contact>({ defaultValues, ...formConfig })
  const { handleSubmit, control, reset, formState } = hookForm
  const isEditing = type === 'edit'
  const mutation = isEditing ? editMutation : createMutation
  const defaultOnSuccess = useOnSuccessDefault(isEditing)
  const onSuccess = onSuccessHandler || defaultOnSuccess

  // Reset, otherwise the form hangs onto previous values if react-query has the data cached from a
  // previous query. This results in slight jank when the changed input/s briefly flash to the
  // previous values, but it's not an issue and maybe only something Jason notices 🧐
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSubmit = (data: Contact) => {
    const onError = (err: ErrorType<unknown>) => {
      onSubmitError(err, isEditing, data.name)
    }

    if (isEditing) {
      // TODO: try to submit dirtyValues only: https://tinyurl.com/2zjjq23n
      editMutation.mutate({ id: data.short_id, data }, { onError, onSuccess })
    } else {
      createMutation.mutate({ data }, { onError, onSuccess })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContactFormControls control={control} formState={formState} isLoading={mutation.isLoading} />
      <FormResetAndSubmitBtns
        disableReset={!formState.isDirty}
        disableSubmit={!formState.isValid || (isEditing && !formState.isDirty)}
        handleReset={() => reset()}
        isLoading={mutation.isLoading}
      />
    </form>
  )
}

import { toast } from 'react-toastify'
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { SoilCollectionDetail } from 'lib/api/django/model'

import { fetchLocalCollnById, fetchLocalCollns } from './api.soil-sampling'
import { localQueryKeys } from './config.ssa'
import { soilCollectorToasts as toasts } from './config.toasts.soilcollector'
import type { SoilCollection } from './types.ssa'

/**
 * Hook to return a `useQuery` instance of `localForage` for all collections on device.
 *
 * @param partialQueryOptions everything in UseQueryOptions except `networkMode` since it must
 * always be `always` in order to run offline.
 * @returns `useQuery` instance with all collections in `localForage` store on device.
 */
export function useLocalCollections<TSelect = SoilCollectionDetail[]>(
  partialQueryOptions?: Omit<UseQueryOptions<SoilCollectionDetail[], Error, TSelect>, 'networkMode'>
): UseQueryResult<TSelect, Error> {
  return useQuery({
    queryKey: localQueryKeys.collections(),
    queryFn: () => fetchLocalCollns(),
    ...partialQueryOptions,
    networkMode: 'always', // ignore connectivity, always run it
  })
}

/**
 * Hook to return a `useQuery` instance of `localForage` collection on device. See `useCollection`
 * for more details as it controls all of the `enabled` decisions. Note that it is ALWAYS enabled if
 * the collection exists on device.
 *
 * @param collnShortId optional. Defaults to the `:collnId` route param but can be overridden, e.g.
 * when used in a list and the route param is not available.
 * @param partialQueryOptions everything in UseQueryOptions except `queryFn`, `queryKey`, and
 * `networkMode` since we want those to be consistent.
 * @returns `useQuery` instance pointing to the collection in `localForage`. Since this hook is only
 * used in ONE place, `useCollection`, which checks for on-device-ness itself, we can already bank
 * on it being on the device.
 */
export function useLocalCollection<TSelect = SoilCollection>(
  collnShortId = '',
  partialQueryOptions?: Omit<
    UseQueryOptions<SoilCollection, Error, TSelect>,
    'networkMode' | 'queryFn' | 'queryKey'
  >
): UseQueryResult<TSelect, Error> {
  return useQuery<SoilCollection, Error, TSelect>({
    onError: () => {
      toast.error(toasts.localCollnFail, {
        toastId: 'localCollnFail', // attempt to keep one toast instead of 🤪
      })
    },
    ...partialQueryOptions,
    queryFn: () => fetchLocalCollnById(collnShortId),
    queryKey: localQueryKeys.collnDetail(collnShortId),
    networkMode: 'always', // ignore connectivity, always run it
  })
}

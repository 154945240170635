import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { djangoAxiosInstance } from 'lib/api/django-axios-instance'

import type {
  FieldPostPayload,
  FieldPostSuccessResponse,
  SecondParameter,
} from './types.fields-mgmt-api'

const v1FieldsCreate = (
  data: FieldPostPayload,
  options?: SecondParameter<typeof djangoAxiosInstance>
): Promise<FieldPostSuccessResponse> => {
  const { geometry, properties } = data

  return djangoAxiosInstance<FieldPostSuccessResponse>(
    {
      url: `/v1/fields/`,
      method: 'post',
      data: { ...properties, geometry },
    },
    options
  )
}

type DjangoErrorData = { non_field_errors: string[] }

export type V1FieldsCreateMutationError = AxiosError<
  DjangoErrorData,
  string // stringified FieldPostPayload
>

export const useV1FieldsCreate = <
  TError = V1FieldsCreateMutationError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    TError,
    FieldPostPayload,
    TContext
  >
  request?: SecondParameter<typeof djangoAxiosInstance>
}): UseMutationResult<FieldPostSuccessResponse, TError, FieldPostPayload, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    FieldPostPayload
  > = (data) => v1FieldsCreate(data, requestOptions)

  return useMutation<
    Awaited<ReturnType<typeof v1FieldsCreate>>,
    TError,
    FieldPostPayload,
    TContext
  >(mutationFn, mutationOptions)
}

import { BiMinus } from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import { ImWarning } from 'react-icons/im'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { FormModal } from 'components/reusable/handy-templates/FormModal'

import type { MultiThingSuccessResponse } from './types.sampling-plans'

type Props = {
  successResponses: MultiThingSuccessResponse[]
  isOpen: boolean
  setIsOpen: React.Dispatch<boolean>
}

type ItemProps = {
  successResponse: MultiThingSuccessResponse
}

function SummaryListItem(props: ItemProps) {
  const { successResponse } = props
  const { status, collection_id, field_id, msg } = successResponse

  let color = 'default'
  let icon = <BiMinus />
  let noun = 'Item'
  let verb = ''

  if (field_id) {
    noun = 'Field'
  } else if (collection_id) {
    noun = 'Collection'
  }

  if (status === 201) {
    color = 'success.main'
    icon = <FaCheck />
    verb = 'created'
  } else if (status === 200) {
    color = 'warning.main'
    icon = <ImWarning />
    verb = 'already exists'
  }

  return (
    <ListItem divider>
      <ListItemIcon>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: color, color: 'text.primary' }}>{icon}</Avatar>
        </ListItemAvatar>
      </ListItemIcon>
      <ListItemText primary={`${noun} ${verb}`} secondary={msg} />
    </ListItem>
  )
}

export function MultiComboSummary(props: Props) {
  const { successResponses, isOpen, setIsOpen } = props

  const Content = (
    <List>
      {successResponses.map((r) => (
        <SummaryListItem key={r.msg} successResponse={r} />
      ))}
    </List>
  )

  return (
    <FormModal
      isNotForm
      content={Content}
      elemId="multi-thing-summary"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Multi-thing Summary"
    />
  )
}

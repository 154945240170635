import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools' // uncomment if needed
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { asyncStoragePersister, queryClient } from 'lib/config/react-query'

import { AppThemeAndStyles } from 'components/AppThemeAndStyles'
import { AuthProvider } from 'components/AuthProvider'
import { LayoutProviders } from 'components/layout/LayoutContext'
import { routesConfig as privateRoutesConfig } from 'components/reusable/private-views'
import { routesConfig as publicRoutesConfig } from 'components/reusable/public-views'

import { useSentrySetup } from './components/hooks.init'

import 'react-toastify/dist/ReactToastify.css'
import 'components/layout/toastify-style-overrides.css'

const router = createBrowserRouter([
  ...publicRoutesConfig,
  ...privateRoutesConfig,
])

function App() {
  useSentrySetup()

  return (
    <PersistQueryClientProvider
      client={queryClient}
      // See https://tanstack.com/query/v4/docs/framework/react/plugins/persistQueryClient#options
      persistOptions={{
        persister: asyncStoragePersister,
        maxAge: 1000 * 60 * 60 * 24 * 2 * 7, // 1 week (24 hours is the default)
      }}
      // Resume mutations after initial restore from local IndexedDB was successful. Seems harmless
      // to invalidate everything since it should only occur on page load. Initial plan was to
      // fine-grain it with a queryKey, but not sure how to get the short_id of the collection that
      // was being edited, and that is a piece of the queryKey so kind of a no-go.
      onSuccess={() => {
        queryClient.resumePausedMutations().then(() => {
          queryClient.invalidateQueries()
        })
      }}
    >
      <AppThemeAndStyles>
        <AuthProvider>
          <LayoutProviders>
            <ToastContainer
              closeOnClick
              position="bottom-center"
              theme="colored"
              transition={Slide}
            />
            <RouterProvider router={router} />
          </LayoutProviders>
          {/* <ReactQueryDevtools /> */}
          {/* ☝️ uncomment this if needed during local dev */}
        </AuthProvider>
      </AppThemeAndStyles>
    </PersistQueryClientProvider>
  )
}

// eslint-disable-next-line import/no-default-export
export default App

import type { Props as ContentProps } from './ToastContentWithTitle'
import { ToastContentWithTitle } from './ToastContentWithTitle'

type Props = {
  /**
   * The error message string. If `contentProps.content` is provided, this will be ignored.
   */
  errorMsg: string
  /**
   * Subset of optional props to pass to the ToastContentWithTitle component. See the component's
   * implementation for details such as the default title.
   */
  contentProps?: Partial<ContentProps>
  /**
   * The status code of the error response, if any. If provided, this will be shown in the
   * "expandable content" section and included in the copyable details. Note that it is not
   * necessarily Axios-specific, but it is the only use case at the time of writing.
   */
  axiosErrorStatusCode?: number
  /**
   * The relative endpoint path of the request that failed, if any. If provided, this will be shown
   * in the "expandable content" section and included in the copyable details. Note that it is not
   * necessarily Axios-specific, but it is the only use case at the time of writing.
   *
   * @example "/api/v1/soil_sampling/collections/layers/tillage/bulk_export/"
   */
  axiosErrorUrl?: string
}

function getCopyFriendlyErrorDetails(errorMsg: string, code?: number, apiUrl = 'N/A') {
  const currentUrl = window.location.href

  return [
    `CURRENT URL: ${currentUrl}`,
    `API URL: ${apiUrl}`,
    `ERROR CODE: ${code || 'N/A'}`,
    `ERROR MESSAGE: ${errorMsg || 'N/A'}`,
  ].join('\n')
}

export function ErrorToastContent(props: Props) {
  const { contentProps, axiosErrorStatusCode, axiosErrorUrl, errorMsg } = props
  const { content = errorMsg } = contentProps || {}

  const expandableContent = getCopyFriendlyErrorDetails(
    errorMsg,
    axiosErrorStatusCode,
    axiosErrorUrl
  )

  return (
    <ToastContentWithTitle
      content={content}
      {...contentProps}
      copyableDetails={expandableContent}
      expandableContent={expandableContent}
      title={contentProps?.title || 'Something went wrong'}
    />
  )
}

import { useMemo, useState } from 'react'
import type { Control } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import type { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import type { Org } from 'lib/api/django/model'
import { useV1OrgsList } from 'lib/api/django/v1/v1'
import { DJANGO_ADMIN_URL } from 'lib/config'

type OrgOrNull = Org | null

type Props = {
  /** Let react-hook-form take, ahem, control 🥁 */
  control?: Control
  disabled?: boolean
  initialValue?: OrgOrNull
  onChange?: (org: OrgOrNull) => void
  placeholder?: string
  required?: boolean
  omitDefaultHelperText?: boolean
}

type TextInputProps = {
  params: AutocompleteRenderInputParams
  placeholder?: string
  required?: boolean
  omitDefaultHelperText?: boolean
}

const DJANGO_NEW_ORG_URL = `${DJANGO_ADMIN_URL}/core/org/add/`

const DEFAULT_HELPER_TEXT = (
  <>
    Didn't find it?{' '}
    <Link href={DJANGO_NEW_ORG_URL} target="_blank">
      Create one
    </Link>
    , then refresh.
  </>
)

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: ({ name }: Org) => name,
})

const init = { name: '', id: '' }

function TextInput(props: TextInputProps) {
  const { params, placeholder = 'Select an Org', required } = props
  const { omitDefaultHelperText } = props

  return (
    <TextField
      {...params}
      fullWidth
      aria-label="organization"
      helperText={omitDefaultHelperText ? '' : DEFAULT_HELPER_TEXT}
      label="Org"
      placeholder={placeholder}
      required={required}
    />
  )
}

export function OrgAutocomplete(props: Props) {
  const { onChange, initialValue = init, control, disabled } = props
  const { placeholder, required, omitDefaultHelperText } = props
  const [localState, setLocalState] = useState<OrgOrNull>(initialValue)
  const { data, isLoading, isError } = useV1OrgsList()

  const handleChange = (newValue: OrgOrNull) => {
    setLocalState(newValue)

    if (onChange) {
      onChange(newValue)
    }
  }

  const commonProps: AutocompleteProps<Org, false, false, false> = useMemo(() => {
    return {
      id: 'org-autocomplete',
      disabled,
      filterOptions,
      getOptionLabel: (org) => org.name,
      isOptionEqualToValue: (org, val) => org.id === val.id,
      loading: isLoading,
      options: data || [],
      size: 'small',
      renderInput: (params) => (
        <TextInput
          omitDefaultHelperText={omitDefaultHelperText}
          params={params}
          placeholder={placeholder}
          required={required}
        />
      ),
    }
  }, [
    disabled,
    placeholder,
    data,
    omitDefaultHelperText,
    isLoading,
    required,
  ])

  if (isError) {
    return <div>Problem fetching Orgs list</div>
  }

  if (control) {
    return (
      <Controller
        control={control}
        name="org"
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...commonProps}
            onChange={(_, val) => {
              field.onChange(val)

              if (onChange) {
                onChange(val)
              }
            }}
          />
        )}
      />
    )
  }

  return (
    <Autocomplete {...commonProps} value={localState} onChange={(_, val) => handleChange(val)} />
  )
}

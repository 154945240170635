type Props = {
  color: string
  borderColor: string
  strokeWidth: number
  radius: number
  icon?: React.ReactNode
}

export function CircleSymbol(props: Props) {
  const { borderColor, color, strokeWidth, radius, icon } = props
  const size = radius * 2

  return (
    <div
      style={{
        borderColor,
        backgroundColor: color,
        height: size,
        width: size,
        borderWidth: strokeWidth,
        borderRadius: '100%',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
    </div>
  )
}

import { MdClear } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'

import { ToastContentWithTitle } from 'components/reusable/alerts-and-messages'
import { useDrawToolsStateContext } from 'components/reusable/maps/draw-and-annotate'
import { useIsCollnOnDevice } from 'components/soil-sampling/hooks.ssa'
import { useLocalCollnMutation } from 'components/soil-sampling/mutations.ssa-local'

import { useCollnPatchMutation } from '.'

type Props = {
  disabled: boolean
}

const SuccessToast = (
  <ToastContentWithTitle
    content='The collection boundary was cleared. To set it again, click "Use field" or "Draw".'
    title="Boundary was cleared"
  />
)

export function ClearAcbBtn(props: Props) {
  const { disabled } = props
  const { collnId: collnShortId = '' } = useParams()
  const remoteMutation = useCollnPatchMutation(collnShortId, SuccessToast)
  const { toolbarVisible } = useDrawToolsStateContext()
  const collnIsOnDevice = useIsCollnOnDevice(collnShortId)
  const localMutation = useLocalCollnMutation()

  // TODO: test this again when backend is wired back up
  function handleClick() {
    const payload = {
      id: collnShortId,
      data: { boundary: null },
    }

    if (collnIsOnDevice) {
      localMutation.mutate(payload)
    } else {
      remoteMutation.mutate(payload)
    }
  }

  return (
    <Button
      disabled={disabled || remoteMutation.isLoading || toolbarVisible}
      startIcon={<MdClear />}
      onClick={handleClick}
    >
      Clear
    </Button>
  )
}

import type { GridColDef } from '@mui/x-data-grid-premium'

import {
  FILENAME_COLUMN,
  PRETTY_FILENAME_COL,
} from 'components/fields/fields-mgmt/column-mapping/config.col-mapping'

export function getColumns(columnNames: string[]): GridColDef[] {
  const customColumns = columnNames
    .filter((name) => name !== FILENAME_COLUMN) // got plans for you later
    .map((name) => {
      return {
        field: name,
        width: 175,
        valueGetter: ({ row }: { row: GeoJSON.Feature }) => {
          return row.properties ? row.properties[name] : null
        },
      }
    })

  return [
    {
      field: FILENAME_COLUMN,
      width: 200,
      headerName: PRETTY_FILENAME_COL,
      valueGetter: ({ row }: { row: GeoJSON.Feature }) => {
        return row.properties ? row.properties[FILENAME_COLUMN] : null
      },
    },
    ...customColumns,
  ]
}

import type { FileError, FileRejection } from 'react-dropzone'

import { ConfMessage } from 'components/reusable/alerts-and-messages'

type Props = {
  rejections: FileRejection[]
}

type PreppedError = FileError & {
  filename: string
}

function prepErrors(rejections: FileRejection[]) {
  const preppedErrors: PreppedError[] = []

  rejections.forEach(({ file, errors }) => {
    errors.forEach((e) => {
      preppedErrors.push({
        ...e,
        filename: file.name,
      })
    })
  })

  return preppedErrors
}

export function RejectionsList(props: Props) {
  const { rejections } = props

  if (!rejections.length) {
    return null
  }

  const preppedErrors = prepErrors(rejections)

  const ErrorsList = (
    <ul>
      {preppedErrors.map((r) => (
        <li key={r.code + r.filename}>
          <b>{r.filename}: </b> <i>{r.code}</i> ({r.message})
        </li>
      ))}
    </ul>
  )

  return <ConfMessage fullWidth severity="error" text={ErrorsList} title="Errors found" />
}

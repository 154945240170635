export const SOILCOLLECTOR_BASEPATH = 'soilcollector'

export const SOILCOLLECTOR_COLLNS_SEGMENT = 'collections'

/** Params used in `useParams`, `useMatch`, `<Link>`, `path`, etc. */
export const wildcards = {
  annoId: ':annoId',
  collnId: ':collnId',
  sampleId: ':sampleId',
}

export const segments = {
  annotations: 'annotations',
  base: SOILCOLLECTOR_BASEPATH,
  collections: SOILCOLLECTOR_COLLNS_SEGMENT,
  files: 'files',
  samples: 'samples',
  shipping: 'shipping',
  trackingNumbers: 'tracking-numbers',
}

const { base } = segments
const root = `/${base}` // TODO: redirect /remote to /collections
const collectionsAbsolute = `${root}/${segments.collections}`

export const routes = {
  annotations: segments.annotations,
  base: segments.base,
  collectionsAbsolute,
  collectionsRelative: segments.collections,
  files: segments.files,
  samples: segments.samples,
  shipping: segments.shipping,
  trackingNumbers: segments.trackingNumbers,
  trackingNumbersRel: `${segments.shipping}/${segments.trackingNumbers}`,
  // "Match" paths from here down
  collectionDetailAbsMatch: `${root}/${segments.collections}/${wildcards.collnId}`,
  collectionDetailRelMatch: `${segments.collections}/${wildcards.collnId}`,
  annoDetailMatch: [
    base,
    segments.collections,
    wildcards.collnId,
    segments.annotations,
    wildcards.annoId,
  ].join('/'),
  annosListMatch: [
    base,
    segments.collections,
    wildcards.collnId,
    segments.annotations,
  ].join('/'),
  sampleDetailMatch: [
    collectionsAbsolute,
    wildcards.collnId,
    segments.samples,
    wildcards.sampleId,
  ].join('/'),
} as const

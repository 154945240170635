import type { RouteConfigWithHandle } from 'lib/types'

import { LAYER_COMPARE_BASEPATH, SOILMAPPER_BASEPATH, wildcards } from './routes'

export const routesConfig: RouteConfigWithHandle[] = [
  {
    path: `${SOILMAPPER_BASEPATH}/${wildcards.field}/${wildcards.batch}/*`,
    lazy: () => import('./TillMapLanding'),
  },
  {
    path: `${SOILMAPPER_BASEPATH}/${LAYER_COMPARE_BASEPATH}`,
    lazy: () => import('./layer-compare/layout/LayerCompareLayout'),
  },
]

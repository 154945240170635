import { useState } from 'react'
import { GoInfo } from 'react-icons/go'
import ButtonBase from '@mui/material/ButtonBase'

import type { Props as InfoPopoverProps } from './InfoPopover'
import { InfoPopover } from './InfoPopover'

export type PopoverizeAnythingProps = {
  /**
   * This should NOT be a button. That's the whole point. The <ButtonBase> is a glorious component
   * that button-izes any other component and preserves accessibility (VERY challenging to do, but
   * MUI nails it). You can take a Chip or a heading or what-ev-er and make a button out of it and
   * show the InfoPopover on click.
   *
   * @default <GoInfo style={{ fontSize: '1.35em' }} />
   */
  theClickableThing?: React.ReactNode
  /** Make it accessible */
  ariaLabel?: string
  infoPopoverProps?: Pick<InfoPopoverProps, 'html' | 'nonHtmlContent' | 'title'>
}

// TODO: add aria-label to all instances of this
export function PopoverizeAnything(props: PopoverizeAnythingProps) {
  const {
    infoPopoverProps,
    theClickableThing = <GoInfo style={{ fontSize: '1.35em' }} />,
    ariaLabel,
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <ButtonBase aria-label={ariaLabel} onClick={handleClick}>
        {theClickableThing}
      </ButtonBase>
      <InfoPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} {...infoPopoverProps} />
    </>
  )
}

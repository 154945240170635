import csv from 'csvtojson'
import { getFileExtension, isValidChecksum } from 'lib/utils'

import { extensions, spatialExtensions } from 'components/upload/common/config'

import type { GroundTruthSensor } from '.'

type GpggaCoordsParams = {
  latHemi: 'N' | 'S'
  lonHemi: 'E' | 'W'
  rawLat: string
  rawLon: string
}

export const CBD_LAB_LON_LAT_COLUMN = 'ACTUAL_SAMPLE_LOCATION'

export const CBD_LAB_FILE_SUFFIX = 'cbd_lab.csv'

// TODO: de-fragilitize the '.GPS' vs. '.gps' (old EMI vs. new GPR) issue
export const isAnOldEmiGpsFile = (extension: string): boolean => extension === '.GPS'

export const isCbdLabFile = (filename: string): boolean =>
  filename.includes(`.${CBD_LAB_FILE_SUFFIX}`)

export const getCbdLabFeatsFromFile = (
  rows: { [key: string]: string }[],
  lonLatColumnKey = CBD_LAB_LON_LAT_COLUMN,
  filename = ''
): GeoJSON.Feature[] => {
  const features: GeoJSON.Feature[] = rows.map((row) => {
    const coordinates: [number, number] = JSON.parse(row[lonLatColumnKey])

    return {
      type: 'Feature',
      properties: { filename, sensorType: 'cbdLab' as GroundTruthSensor },
      geometry: { type: 'Point', coordinates },
    }
  })

  return features
}

export const getLonLatFromGpggaRow = (params: GpggaCoordsParams): [lon: number, lat: number] => {
  const { latHemi, lonHemi, rawLat, rawLon } = params
  const latDegInt = parseInt(rawLat.slice(0, 2), 10)
  const lonDegInt = parseInt(rawLon.slice(0, 3), 10)
  const latMinutes = parseFloat(rawLat.slice(2))
  const lonMinutes = parseFloat(rawLon.slice(3))
  const latAbs = parseFloat((latDegInt + latMinutes / 60).toFixed(8))
  const lonAbs = parseFloat((lonDegInt + lonMinutes / 60).toFixed(8))
  const lon = lonHemi === 'W' ? lonAbs * -1 : lonAbs
  const lat = latHemi === 'S' ? latAbs * -1 : latAbs

  return [lon, lat]
}

/**
 * Get lat/lon, in decimal degrees, of a single row from a DCP file.
 *
 * @param row single row from a DCP file, e.g. `N44 13.401 W095 02.817`
 * @returns `[lon, lat]` in decimal degrees
 */
export function getLatLonFromDcpRow(row: string): [lon: number, lat: number] {
  /* eslint-disable padding-line-between-statements */
  const values = row.split(' ')

  const latHemi = values[0]
  const lonHemi = values[2]

  const isNorthernHemi = latHemi.includes('N')
  const isWesternHemi = lonHemi.includes('W')

  const latDegreesInt = parseInt(latHemi.replace('N', '').replace('S', ''), 10)
  const lonDegreesInt = parseInt(lonHemi.replace('E', '').replace('W', ''), 10)

  const latMinutes = parseFloat(values[1])
  const lonMinutes = parseFloat(values[3])

  const latDegMinSec = latDegreesInt + latMinutes / 60
  const lonDegMinSec = lonDegreesInt + lonMinutes / 60

  const positiveLat = parseFloat(latDegMinSec.toFixed(8))
  const positiveLon = parseFloat(lonDegMinSec.toFixed(8))

  const lat = isNorthernHemi ? positiveLat : positiveLat * -1
  const lon = isWesternHemi ? positiveLon * -1 : positiveLon

  return [lon, lat]
  /* eslint-enable padding-line-between-statements */
}

export const getDcpFeatsFromFile = (text: string, filename: string): GeoJSON.Feature[] => {
  const match = /\b[NS]([0-9. ]{9}) [WE]([0-9. ]{10})/g
  const matched = text.match(match)

  const features =
    matched?.reduce((all: GeoJSON.Feature[], thisOne): GeoJSON.Feature[] => {
      const coordinates = getLatLonFromDcpRow(thisOne)

      return [
        ...all,
        {
          type: 'Feature',
          properties: { filename, sensorType: 'dcp' as GroundTruthSensor },
          geometry: { type: 'Point', coordinates },
        } as GeoJSON.Feature,
      ]
    }, [] as GeoJSON.Feature[]) || []

  return features
}

// CRED: https://sebhastian.com/javascript-csv-to-array/ (adapted)
export const getSpatialFromEmiOrGprCsv = (
  srcText: string,
  filename: string,
  sensorType: GroundTruthSensor
): GeoJSON.Feature[] => {
  // 1. clean up file: rm empty lines, whitespace, and up to $GPGGA
  // 2. split into array at newlines
  // 3. reduce into rows with legit checksums:
  //    https://rietman.wordpress.com/2008/09/25/how-to-calculate-the-nmea-checksum/
  // 4. in each row, calculate and return [lon, lat]
  // 5. but also extend bounds since you're already in there

  const findSpacesRegex = / +?/g
  const beforeGpgga = /^.*(?=(\$GPGGA))/gm
  const linesWeDontCareAbout = /^(?!\$GPGGA).*/gm
  const lineFeedAfterWhitespace = /^\s*[\n]/gm
  const carriageReturn = /\r/gm
  const twoLineFeeds = /\n\n/gm
  const finalLineFeed = /\n$/gm

  // IMPORTANT: don't mess with the order!
  const clean = srcText
    .replace(findSpacesRegex, '') // remove all spaces
    .replace(beforeGpgga, '') // remove stuff before $GPGGA, e.g. EMI timestamp
    .replace(carriageReturn, '\n') // standardize returns with linefeeds
    .replace(linesWeDontCareAbout, '') // rm lines that don't start with $GPGGA
    .replace(lineFeedAfterWhitespace, '') // more cleanup?
    .replace(twoLineFeeds, '\n') // e.g. first line is blank in EMI *_gps.csv
    .replace(finalLineFeed, '') // otherwise it results in an empty array row

  const rows = clean.split('\n')

  const coordinates =
    rows.reduce((all: GeoJSON.Position[], thisOne): GeoJSON.Position[] => {
      const hasValidCheckSum = isValidChecksum(thisOne)

      if (!hasValidCheckSum) return all

      const values = thisOne.split(',')

      const lonLat = getLonLatFromGpggaRow({
        latHemi: values[3] as 'N' | 'S',
        lonHemi: values[5] as 'E' | 'W',
        rawLat: values[2],
        rawLon: values[4],
      })

      return [...all, lonLat]
    }, [] as GeoJSON.Position[]) || []

  // TODO: consider handling edge cases, e.g. only a handful of rows exist and have same or similar
  // coordinates. This causes prepped.bounds to fail hard. This whole function should actually
  // return null if things don't go well. Or maybe wrap something in a try/catch and return the
  // empty bounds and coords?
  return [
    {
      type: 'Feature',
      properties: { sensorType, filename },
      geometry: { type: 'LineString', coordinates },
    } as GeoJSON.Feature,
  ]
}

export const getSensorTypeByFilename = (filename: string): GroundTruthSensor | null => {
  if (isCbdLabFile(filename)) return 'cbdLab'

  const extension = getFileExtension(filename)
  const extLowerCase = getFileExtension(filename).toLowerCase()
  const sameStringCaseInsensitive = (ext: string): boolean => ext.toLowerCase() === extLowerCase

  if (isAnOldEmiGpsFile(extension)) return 'emi'

  // Order matters! The '.gps' extension is not unique
  if (extensions.gpr.some(sameStringCaseInsensitive)) return 'gpr'
  if (extensions.emi.some(sameStringCaseInsensitive)) return 'emi'
  if (extensions.dcp.some(sameStringCaseInsensitive)) return 'dcp'

  return null
}

export const isSupportedSpatialFile = (filename: string): boolean => {
  const extension = getFileExtension(filename)

  if (isAnOldEmiGpsFile(extension)) return false

  const isLabFile = isCbdLabFile(filename)
  const isGemEmiGpsFile = filename.includes('_gps.csv')
  const isEmiV3File = filename.includes('.eo.csv')
  const isLaptopEmiGpsFile = filename.includes('.survey.GPS.csv')
  const isOtherSpatialExtension = spatialExtensions.some((ext) => ext === extension.toLowerCase())

  return (
    isGemEmiGpsFile || isOtherSpatialExtension || isLabFile || isLaptopEmiGpsFile || isEmiV3File
  )
}

// TODO: unit test
// Makes no assumptions that filenames are or are not unique, it just returns the first one it
// finds.
export const getFileIndexByFilename = (filenameToMatch: string, files: File[]): number | null => {
  let matchedIndex: number | null = null

  files.find((file, i) => {
    const match = file.name === filenameToMatch

    if (match) matchedIndex = i

    return match
  })

  return matchedIndex
}

export const sensorFilesToGeoJson = async (
  file: File,
  sensorType: GroundTruthSensor
): Promise<GeoJSON.Feature[]> => {
  return new Promise<GeoJSON.Feature[]>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async (e) => {
      try {
        let features: GeoJSON.Feature[] = []

        const text = e.target?.result as string

        // DZG, GPS, _gps.csv can be parsed the same way (w/tons of RegEx)
        if (sensorType === 'dcp') features = getDcpFeatsFromFile(text, file.name)
        else if (sensorType === 'cbdLab') {
          const asJson: { [CBD_LAB_LON_LAT_COLUMN]: string }[] = await csv().fromString(text)

          features = getCbdLabFeatsFromFile(asJson, undefined, file.name)
        } else features = getSpatialFromEmiOrGprCsv(text, file.name, sensorType)

        resolve(features as GeoJSON.Feature[]) // resolve the promise with the response value
      } catch (err) {
        reject(err)
      }
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsText(file)
  })
}

import { CircleSymbol } from 'components/reusable/maps/symbology'
import type { CompactionScore } from 'components/upload/common/types'
import { FilesLegendItem } from 'components/upload/map/FilesLegendItem'

type Props = {
  score: CompactionScore
}

const colors = {
  1: '#388e3c',
  2: 'yellowgreen',
  3: 'yellow',
  4: '#ff9800',
  5: '#f44336',
}

export function CompactionScoreTableCell({ score }: Props) {
  const color = colors[score]

  return (
    <FilesLegendItem
      snug
      label={score.toString()}
      legendSymbol={<CircleSymbol borderColor={color} color={color} radius={8} strokeWidth={2} />}
    />
  )
}

import { object, string } from 'yup'

import 'yup-phone-lite'

import { PREFERRED_CONTACT_METHODS } from './config.contacts'

const requiredValidation = {
  name: string().required('Contact name is required'),
  org: object({
    id: string().required(),
    name: string().required(),
  }).required(),
}

const optionalValidation = {
  alt_phone: string().nullable().phone('US', 'Invalid phone format').optional(),
  // QUESTION: should email be required if preferred === 'email'?
  email: string().nullable().email('Invalid email format').optional(),
  notes: string().nullable().optional(),
  phone: string().nullable().phone('US', 'Invalid phone format').optional(),
  preferred: string().required().oneOf(PREFERRED_CONTACT_METHODS),
}

const allValidation = { ...optionalValidation, ...requiredValidation }

export const schema = object(allValidation).required()

import { Navigate } from 'react-router-dom'
import type { RouteConfigWithHandle } from 'lib/types'

import { PrivateRouteViaHandle } from 'components/reusable/access-gates/PrivateRouteViaHandle'
import { RouteErrorBoundary } from 'components/reusable/alerts-and-messages/RouteErrorBoundary'
import {
  SavedViewRedirectToParent,
  segments as savedViewsSegments,
  wildcards as savedViewsWildcards,
} from 'components/reusable/tables/saved-views'

import { segments, SOILCOLLECTOR_BASEPATH, wildcards } from './routes'

export const COLLECTIONS_BASEPATH = `/${segments.base}/${segments.collections}`

export const routesConfig: RouteConfigWithHandle[] = [
  {
    path: SOILCOLLECTOR_BASEPATH,
    element: <PrivateRouteViaHandle />,
    handle: {
      allowedGroups: [
        'staff_user',
        'dispatcher',
        'collection_manager',
        'field_tech',
        'field_tech_contact',
      ],
    },
    children: [
      {
        path: '*', // no other top-level routes besides `/soilcollector/collections` at this time
        element: <Navigate replace to={COLLECTIONS_BASEPATH} />,
      },
      {
        path: segments.collections,
        lazy: () => import('./SoilCollectorRoot'),
        children: [
          {
            path: `${savedViewsSegments.savedViews}/${savedViewsWildcards.savedViewId}`,
            element: <SavedViewRedirectToParent redirectTo={COLLECTIONS_BASEPATH} />,
          },
          {
            path: '*', // redirect legacy stuff like `/soilcollector/collections/remote`
            element: <Navigate replace to={COLLECTIONS_BASEPATH} />,
          },
          {
            // Can't use error element here because then the map wouldn't show either. Only want to
            // show the error in the panel area.
            lazy: () => import('./SoilCollectorLayout'),
            children: [
              {
                index: true,
                lazy: () => import('./ssa-landing/SsaLandingTable'),
                errorElement: <RouteErrorBoundary />,
              },
              {
                path: `${wildcards.collnId}/*`,
                lazy: () => import('./collection-detail/SoilSamplingCollnDetail'),
                errorElement: <RouteErrorBoundary />,
              },
            ],
          },
        ],
      },
    ],
  },
]

import Button from '@mui/material/Button'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import type { UITableView } from 'lib/api/django/model'

import { useActivateSavedView } from './hooks'

type Props = {
  /**
   * The saved view from the backend.
   */
  view: UITableView
  /**
   * DataGrid `ref` for the parent table. See docs in `useActivateSavedView` for more info.
   */
  parentTableApiRef: React.MutableRefObject<GridApiPremium>
  /**
   * Click handler for the button. Most likely will close the "manage views" modal and (via a hook)
   * show a success toast.
   */
  onClick: () => void
}

export function ActivateTableViewLink(props: Props) {
  const { view, parentTableApiRef, onClick } = props
  const setState = useActivateSavedView(parentTableApiRef)

  function handleClick() {
    if (view.state) {
      // Strong assumption that `view.state` is of `GridInitialStatePremium` type
      setState(view.name, view.state as GridInitialStatePremium)
    }

    onClick()
  }

  return (
    <Button size="small" onClick={handleClick}>
      {view.name}
    </Button>
  )
}

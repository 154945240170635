import Avatar from '@mui/material/Avatar'

import { stringToColor } from 'components/tillmapper/map/utils.tillmap-symbology'
import { getTwoLetterAbbrev } from 'components/tillmapper/utils'

type Props = {
  text: string
  fontSize?: number | string
  size?: number
  variant?: 'circular' | 'rounded' | 'square'
}

export function TwoCharColoredAvatar(props: Props) {
  const { text, variant = 'circular', size = 24, fontSize = '0.8rem' } = props

  return (
    <Avatar
      variant={variant}
      sx={{
        bgcolor: stringToColor(text),
        color: '#fff',
        fontSize,
        fontWeight: 'bold',
        height: size,
        width: size,
      }}
    >
      {getTwoLetterAbbrev(text)}
    </Avatar>
  )
}

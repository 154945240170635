import type { TillMapPointFeature, TillMapPointsData } from './types'

export function getTwoLetterAbbrev(string: string): string {
  const words = string.split(' ')

  if (words.length === 1) return string[0] + (string[1] || '')

  return words[0][0] + words[1][0]
}

export function getMinMaxFromArray(values: (number | string)[]): [number, number] {
  const uniqueValues = Array.from(new Set(values)).filter(
    (value) => typeof value === 'number'
  ) as number[]

  const min = Math.min(...uniqueValues)
  const max = Math.max(...uniqueValues)

  // TODO: rm if not needed (sorted values seems like strong assumption). Compaction seemed to be
  // fine. It's just such screwy logic. Why wasn't Math.max used to begin with? Some kind of tiny
  // perf gain? const max = zValues.slice(-1)[0] as number

  return [min, max]
}

// Convert the shabby Dash thing into real GeoJSON for DCP/VWC/Carbon points, which are the same
// locations as each other (from penetrometer) but with different values.
export const prepTillMapPointsLayer = (
  data: TillMapPointsData,
  prettyLayerName: string // for popups
): TillMapPointFeature[] => {
  return (
    // Bulk density and possibly other point layers may have null values
    data.customdata
      .filter((values) => !values.some((value) => value === null))
      .map((values, i): TillMapPointFeature => {
        return {
          type: 'Feature',
          properties: {
            id: i,
            value: values[0],
            layerName: prettyLayerName,
          },
          geometry: {
            type: 'Point',
            coordinates: [data.lon[i], data.lat[i]],
          },
        }
      })
  )
}

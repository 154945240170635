import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import type { AnnotationCreate } from 'lib/api/django/model'
import {
  getV1SoilSamplingCollectionsRetrieveQueryKey as collnDetailQueryKey,
  useV1SoilSamplingCollectionsAnnotationsCreate,
} from 'lib/api/django/v1/v1'
import { ANNOTATION_MUTATION_KEY } from 'lib/config/react-query'
import { showErrorToast } from 'lib/utils/toasts'
import { v4 as uuidv4 } from 'uuid'

import type { AnnoFormValues } from 'components/reusable/maps/draw-and-annotate'
import { useDrawToolDispatchContext } from 'components/reusable/maps/draw-and-annotate'
import { localQueryKeys } from 'components/soil-sampling/config.ssa'
import {
  ANNO_SUCCESS_TOAST_ID,
  soilCollectorToasts,
} from 'components/soil-sampling/config.toasts.soilcollector'
import { useCollection } from 'components/soil-sampling/hooks.ssa'
import { useLocalCollnMutation } from 'components/soil-sampling/mutations.ssa-local'
import { useSafeDownwardSync } from 'components/soil-sampling/syncing/hooks.colln-sync'
import { getSafeToOverwriteLocalCollnProps } from 'components/soil-sampling/syncing/utils'
import type { RouteParams, SoilCollection } from 'components/soil-sampling/types.ssa'

// No matter what OpenAPI says, the payload is a GeoJSON Feature
type TheRealActualRemoteAnnoPayload = GeoJSON.Feature<
  GeoJSON.Geometry,
  Omit<AnnotationCreate, 'geometry'>
>

/**
 * Hook to get the annotation success handler for POST or DELETE. Basically just invalidates the
 * remote collection query, syncs it down to the local collection (if there is one), and shows a
 * toast if `omitToast` is not passed.
 *
 * @param collnShortId collection `short_id`
 * @param omitToast don't show a toast, e.g. since the "delete annotation" has its own
 * @returns annotation success handler
 */
export function useRemoteAnnoSuccessHandler(
  collnShortId?: string,
  omitToast?: boolean
): (annotationName?: string) => void {
  const rqc = useQueryClient()
  const { local, isOnDevice } = useCollection()
  const safeDownwardSync = useSafeDownwardSync()
  const queryKey = collnShortId ? collnDetailQueryKey(collnShortId) : ''

  function handler(annotationName = 'Annotation'): void {
    if (!queryKey) {
      return
    }

    let toastContent = soilCollectorToasts.remoteAnnotationSuccess(annotationName)

    if (!omitToast) {
      if (isOnDevice) {
        toastContent = soilCollectorToasts.localAnnotationSuccess(annotationName)
        toast.update(ANNO_SUCCESS_TOAST_ID, { render: toastContent }) // update existing toast
      } else {
        toast.success(toastContent) // if not on device, just show a toast
      }
    }

    // A bit messy, but since anno's are a separate endpoint from collections, we need to invalidate
    // the remote collection query, get its data, and then PATCH the local collection.
    rqc.invalidateQueries(queryKey).then(() => {
      const remoteData = rqc.getQueryData<SoilCollection>(queryKey)

      if (local.data && remoteData) {
        const safe = getSafeToOverwriteLocalCollnProps(remoteData, local.data)

        safeDownwardSync(safe)
      }
    })
  }

  return handler
}

/**
 * Error handler for creating annotations. Shows a toast with the error message. More of a util, not
 * a hook, but it's a response handler and feels ok here.
 *
 * @param err likely an `AxiosError` from Django API
 */
function handleAnnoCreateError(err: unknown): void {
  showErrorToast(err, {
    title: 'Could not create annotation',
  })
}

function getLocalAnnoCreationPayload(
  collectionId: string,
  features: TheRealActualRemoteAnnoPayload[],
  payload: TheRealActualRemoteAnnoPayload
) {
  return {
    id: collectionId,
    data: {
      annotations: {
        type: 'FeatureCollection',
        features: [
          ...features,
          // Append the new annotation to the existing collection annotations
          {
            ...payload,
            properties: {
              ...payload.properties,
              collection_id: collectionId,
            },
          },
        ],
      } as GeoJSON.FeatureCollection,
    },
  }
}

/**
 * Big sloppy convoluted mess of lots of annotation stuff. Can be reused by the GPS line path
 * toolbar and the original annotation toolbar.
 *
 * @param onLocalSuccess function to run after the local mutation has succeeded. At the time of
 * writing (06/21/2024), this is only used by the GPS line path toolbar to close the toolbar and
 * reset the state (including clearing the line).
 * @returns function to create a remote mutation
 */
export function useCreateAnnoMutations(onLocalSuccess?: () => void) {
  const { collnId: collnShortId } = useParams<RouteParams['collection']>()
  const rqc = useQueryClient()
  const localCollnMutation = useLocalCollnMutation(true)
  const handleRemoteAnnoSuccess = useRemoteAnnoSuccessHandler(collnShortId)
  const dispatch = useDrawToolDispatchContext()
  const { local, isOnDevice } = useCollection(undefined, undefined, collnShortId)
  const { data: collection, isLoading, isError } = local

  // Kick off the local mutation. Should only be run if the collection is on device.
  async function mutateLocal(
    collectionShortId: string,
    remotePayload: TheRealActualRemoteAnnoPayload
  ) {
    if (!collection) {
      return
    }

    const localQueryKey = localQueryKeys.collnDetail(collectionShortId)

    await rqc.cancelQueries(localQueryKey) // juuuuust in case

    const localPayload = getLocalAnnoCreationPayload(
      collectionShortId,
      collection.annotations.features as TheRealActualRemoteAnnoPayload[],
      remotePayload
    )

    // If `onLocalSuccess`, stop editing and show a toast.
    function defaultLocalSuccess() {
      dispatch({ type: 'STOP_EDITING' }) // close toolbar, reset state

      toast.success(soilCollectorToasts.localAnnotationSuccess(remotePayload.properties.name), {
        toastId: ANNO_SUCCESS_TOAST_ID,
      })
    }

    localCollnMutation.mutate(localPayload, {
      onSuccess: onLocalSuccess || defaultLocalSuccess,
    })
  }

  // Fires in the `onMutate` of the remote mutation
  async function handleRemoteMutate(variables: {
    collectionId: string
    data: TheRealActualRemoteAnnoPayload
  }) {
    const { collectionId, data } = variables

    if (isLoading || isError || !collection || !collnShortId) {
      return
    }

    // Avoid clashes with our optimistic update when an offline mutation continues
    await rqc.cancelQueries(collnDetailQueryKey(collnShortId))

    // Collection managers still have the power to create annotations even if they are not the
    // assignee, so only mutate locally if the collection is on device.
    if (isOnDevice) {
      mutateLocal(collectionId, data)
    }
  }

  const remoteCreateAnnoMutation = useV1SoilSamplingCollectionsAnnotationsCreate({
    mutation: {
      mutationKey: ANNOTATION_MUTATION_KEY, // for online retries after user has left the app
      // @ts-expect-error OpenAPI is not even close
      onMutate: handleRemoteMutate,
    },
  })

  // Kick off the remote mutation
  function mutateRemote(geometry: GeoJSON.Geometry, formValues: AnnoFormValues) {
    if (!collnShortId) {
      return
    }

    remoteCreateAnnoMutation.mutate(
      {
        collectionId: collnShortId,
        data: {
          // `id` is not in API but needed for optimistic updates and make it available in the UI
          id: uuidv4(),
          // @ts-expect-error OpenAPI is not even close
          type: 'Feature',
          // @ts-expect-error OpenAPI is not even close
          geometry,
          properties: formValues,
        },
      },
      {
        onError: handleAnnoCreateError,
        onSuccess: () => {
          handleRemoteAnnoSuccess(formValues.name || formValues.type)
          dispatch({ type: 'STOP_EDITING' }) // close toolbar, reset state
        },
      }
    )
  }

  return mutateRemote
}

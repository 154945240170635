import { useState } from 'react'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import type { IconButtonProps } from '@mui/material/IconButton'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'

import { BasicMenuItem, SUBHEADER_CLASSNAME } from './BasicMenuItem'
import type { MenuItemConfig } from './types'

type Props = {
  btnText: string
  elemId: string
  items: MenuItemConfig[]
  btnIcon?: React.ReactNode
  btnProps?: ButtonProps | IconButtonProps
  children?: React.ReactNode
  disableBtn?: boolean
  iconOnly?: boolean
  /**
   * Have only needed this to make the Menu work in fullscreen. Must be a unique
   * selector, e.g. `interactive-map`.
   *
   * {@link https://github.com/snakesilk/react-fullscreen/issues/44#issuecomment-706579393 GH issue}
   */
  containerId?: string
}

export function BasicMenu(props: Props) {
  const { btnText, btnIcon, btnProps, iconOnly } = props
  const { elemId, items, disableBtn, children, containerId } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const commonBtnProps: ButtonProps | IconButtonProps = {
    'aria-controls': open ? `${elemId}-menu` : undefined,
    'aria-expanded': open ? 'true' : undefined,
    'aria-haspopup': 'true',
    id: `${elemId}-button`,
    onClick: handleBtnClick,
  }

  let Btn = null
  let container: (() => Element | null) | undefined

  if (containerId) {
    container = () => document.getElementById(containerId)
  }

  if (iconOnly) {
    Btn = (
      <IconButton disabled={disableBtn} {...commonBtnProps} {...btnProps} aria-label={btnText}>
        {btnIcon}
      </IconButton>
    )
  } else {
    Btn = (
      // Not dealing with this. Works fine for IconButton.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Button disabled={disableBtn} startIcon={btnIcon} {...commonBtnProps} {...btnProps}>
        {btnText}
      </Button>
    )
  }

  return (
    <>
      {Btn}
      <Menu
        anchorEl={anchorEl}
        container={container}
        id={`${elemId}-menu`}
        MenuListProps={{ 'aria-labelledby': `${elemId}-button` }}
        open={open}
        sx={{
          [`& .${SUBHEADER_CLASSNAME}:not(:first-of-type)`]: {
            mt: 1,
          },
        }}
        onClose={handleClose}
      >
        {/* Gross-but-cheap way to inject another item */}
        {children}
        {items.map((item) => (
          <BasicMenuItem key={item.uniqueKey} handleClose={handleClose} {...item} />
        ))}
      </Menu>
    </>
  )
}

type Props = {
  color: string
  /** @default 3 */
  lineWidth?: number
  /** @default solid */
  borderTopStyle?: React.CSSProperties['borderTopStyle']
}

export function LineSymbol(props: Props) {
  const { color, lineWidth = 3, borderTopStyle = 'solid' } = props

  return (
    <div
      style={{
        width: 24,
        borderTopColor: color,
        borderTopWidth: lineWidth,
        borderTopStyle,
      }}
    />
  )
}

import { MdChevronRight } from 'react-icons/md'
import { Link as ReactRouterLink } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { RouterLink } from 'components/reusable/routing'
import { useCollection } from 'components/soil-sampling/hooks.ssa'

import { getCollnLayersStatusAndIcon, LinkToSoilCollectorColln, useCanViewSoilCollector } from '.'

type Props = {
  collnId: string
}

export function CollectionListItem(props: Props) {
  const { collnId } = props
  const { remote } = useCollection(undefined, { enabled: true }, collnId)
  const { data, isInitialLoading, isError } = remote
  const canViewSoilCollector = useCanViewSoilCollector()

  const { icon, secondaryElem, color } = getCollnLayersStatusAndIcon(
    isInitialLoading,
    isError,
    data
  )

  let PrimaryText: React.ReactNode = ''

  if (isInitialLoading) {
    PrimaryText = 'Loading collection...'
  } else if (data?.name) {
    PrimaryText = <RouterLink content={data.name} to={collnId} />
  }

  return (
    <ListItem
      divider
      secondaryAction={
        <IconButton
          aria-label="view collection"
          component={ReactRouterLink}
          edge="end"
          to={collnId}
        >
          <MdChevronRight />
        </IconButton>
      }
    >
      <ListItemIcon>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: color, color: 'text.primary' }}>{icon}</Avatar>
        </ListItemAvatar>
      </ListItemIcon>
      <ListItemText
        id={collnId}
        primary={PrimaryText}
        secondary={
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            {secondaryElem}
            {canViewSoilCollector && <LinkToSoilCollectorColln shortId={collnId} />}
          </span>
        }
      />
    </ListItem>
  )
}

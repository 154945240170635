import { styled } from '@mui/material/styles'
import type { TooltipProps } from '@mui/material/Tooltip'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
  },
}))

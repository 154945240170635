import type {
  Contact,
  CurrentUserSecurityGroupsItem,
  GeometrySourceEnum as GeometrySource,
  Location as ReverseGeocodeLocation,
  Org,
} from 'lib/api/django/model'

export const FieldWriterSerializerGeoJSONStatuses = {
  NOT_READY: 'NOT_READY',
  READY_FOR_USE: 'READY_FOR_USE',
  ARCHIVED: 'ARCHIVED',
  UNKNOWN: 'UNKNOWN',
} as const

export const GeometrySourceEnum: { [key in GeometrySource]: GeometrySource } = {
  DRAWN: 'DRAWN',
  FILE: 'FILE',
  UNKNOWN: 'UNKNOWN',
  UPLOADER: 'UPLOADER',
}

export type FieldWriterSerializerGeoJSONStatusEnum =
  (typeof FieldWriterSerializerGeoJSONStatuses)[keyof typeof FieldWriterSerializerGeoJSONStatuses]

type ReadonlyFieldAttributes = {
  /**
   * Important to note: Django's GeoJSON response does NOT include this in the `properties` object
   * by default. Instead it promotoes to the `id` at the top of the GeoJSON object. Sadly Mapbox
   * destroys this and I have no id to work with on click 😞. So, need to include it in the
   * properties object. Could also use the short_id instead of API supports it like we do w/SC.
   */
  readonly id: string
  /** Not totally sure what this is for, but it's not being used. */
  readonly geohash: string
  // TODO: adapt to any of these if needed in Fields API v2
  readonly lat: number
  readonly lon: number
  readonly acres: number | null
  /** Aka `org.id` */
  readonly owned_by: string
  /** Collection `id`s */
  readonly collections: string[]
}

/**
 * This is the `properties` object of an individual GeoJSON feature in the GET. I say "Attributes"
 * because I come from GIS where we have Attribute tables, not properties tables!
 */
export interface FieldAttributes {
  name: string
  /** Org is always the full object (id, name, logo) in the GET */
  org: Org
  // ------------------------------------------------------------------------
  // Everything from here down is optional in the POST, so technically they should have a `?` after
  // each property, but if API is returning `null` for the GET rather than undefined, then null
  // seems fine.
  // ------------------------------------------------------------------------
  /** Address and directions on how to get onto the field */
  address: string | null
  /** Hodgepodge catch-all dumping ground. */
  description: string | null
  /** I think these will start being more common if we use BB importer. */
  external_id: string | null
  farm: string | null
  /**
   * Full object, if it exists. Worth noting that at time of writing, I don't really need most of
   * the stuff inside Contact, mainly just name/id. So if we wanted to reduce response time, could
   * consider sending back a subset of the full contact info.
   */
  onsite_contact: Contact | null
  producer_field_name: string | null
  /**
   * The only thing `extras` is used for now with any regularity is:
   *
   * 1) In Uploader: `grower` when a Field is created as a result of a Batch upload in a pinch when
   *    the desired Field does not exist. But when we sunset Uploader (aka merge it into
   *    SoilCollector), there will no longer be a way to enter the grower into the system besides
   *    manually editing the JSON in `extras`.
   * 2) In FMT: catch-all for unstructured metadata, with `__filename__` at a minimum, plus any
   *    other values the user wishes to preserve that don't "map" into our schema, they'd go here.
   *    Needless to say, any JSON field is a bit shaky and we can't confidently rely on it in TS,
   *    and this includes `extras.grower`.
   */
  extras: { [key: string]: unknown } | null
  /**
   * @default Field.acres
   *
   * This feels more like a Collection thing 🤔 But for now it's used in scenarios like Point
   * geometry or when polygon acreage differs from that of the full Field's acreage, which is the
   * default when it's a Polygon or MultiPolygon.
   */
  requested_acres: number | null
  /**
   * Reverse geocoding results mapped from the Nominatim/OpenStreetMap API. The mappings to note are
   * `district --> county` and `region --> state`. At the time of the backend PR, the mapping is:
   *
   * ```python
   *  nominatim_mapping = {
   *    "address": ["road"],
   *    "country": ["country"],
   *    "district": ["county"],
   *    "locality": ["city_district", "town", "village", "locality"],
   *    "neighborhood": ["neighborhood", "hamlet"],
   *    "place": ["city", "municipality", "region"],
   *    "postcode": ["postcode"],
   *    "region": ["state"],
   *  }
   * ```
   *
   * @see
   * {@link https://github.com/EarthOptics/portal/pull/476/files#diff-8f4d7f70986f30f2fa573ea094aac21837065dd175f787647277c9e8b8429377R15-R24 PR diffs}
   */
  location: ReverseGeocodeLocation | null
  status: FieldWriterSerializerGeoJSONStatusEnum
  /** How the Field was created */
  geometry_source: GeometrySource
  /** Array of objects here, but the POST/PATCH only need an array of IDs */
  security_groups: CurrentUserSecurityGroupsItem[]
  /** Convenience thing when GeoJSON is not being used */
  geometry_type:
    | 'GeometryCollection' // hate you so much
    | 'LineString'
    | 'MultiLineString'
    | 'MultiPoint'
    | 'MultiPolygon'
    | 'Point'
    | 'Polygon'
  /**
   * Because "Scope-creep" Jamie had to speak up 😆 If we include it, it would only be shown if
   * `source` === 'FILE'.
   */
  // source_file_info?: SourceFileInfo
}

// Yes, there are others. No, I do not think we will have LineString fields 🙃 Is this worth it?
// Have to TS a LOT in places that expect any GeoJSON flavor.
// export type GeomWeCareAbout =
//   | GeoJSON.Point
//   | GeoJSON.Polygon
//   | GeoJSON.MultiPolygon

/** Attributes ("properties") for creating (POSTing) new Field. */
type RequiredFieldPostAttribs = {
  /**
   * This would not actually be part of the data posted, it (or short_id) would be the first
   * argument in the Axios function. I just don't know how to TS it here but you can assume it will
   * always be somewhere in the PATCH.
   *
   * At time of writing, only keeping this so that the uuid gets POSTed and then we can associate it
   * on success and ultimately remove it from Context state.
   */
  readonly id: string
  name: string
  owned_by: string // TODO: switch to org when API can handle org as string
}

type OptionalPostAttribs = Partial<
  Pick<
    Omit<FieldAttributes, 'onsite_contact' | 'security_groups'>,
    | 'address'
    | 'description'
    | 'external_id'
    | 'extras'
    | 'farm'
    | 'producer_field_name'
    | 'requested_acres'
    | 'status'
  > & {
    onsite_contact: string | null
    /** `null` or array of UUIDs */
    security_groups: number[]
  }
>

export type PostFieldAttribs = RequiredFieldPostAttribs & OptionalPostAttribs

// Again, ignore my TS `id` thing, it doesn't need to be in the PATCH or POST.
type PatchedFieldAttribs = Partial<PostFieldAttribs> & OptionalPostAttribs

// For both the individual ("retrieve") GET and the list GET, `bbox` is optional. BUT if it were
// supplied (I think Django has a thing) then I could stop using Turf.js bbox if I was confident the
// API included it. And I use it a LOT for auto-zooming.

export type AllFieldAttribs = FieldAttributes & ReadonlyFieldAttributes

// `format=json` in the query
export type RegularJSONfieldAttribs = AllFieldAttribs & {
  geometry: GeoJSON.Geometry
}

// Individual (aka "retrieve" or "detail") GET (see bbox comment above). This is a perfect GeoJSON
// feature, very easy to work with!
export type FieldReaderRetrieve = GeoJSON.Feature<GeoJSON.Geometry, AllFieldAttribs>

// Perfect GeoJSON object to POST
export type FieldPostPayload = GeoJSON.Feature<GeoJSON.Geometry, PostFieldAttribs>

// GeoJSON-ish object, but geometry and properties are both optional. Again, note that `id` (or
// short_id) will just be part of the fetch function as it is readonly.
export type FieldPatchPayload = PatchedFieldAttribs & {
  // properties?: PatchedFieldAttribs // TODO?
  geometry?: GeoJSON.Geometry
}

// Wishlist: in the GET, let me ask for the specific geometry type:
//
// NATIVE   (or "original" or "verbatim"): whatever the original geometry was.
// POINTS:  return all features as points (e.g. centroid of polygon). Mapbox
//          can't label polygons, so I'd need to convert in FE (which would get
//          weird for MultiPolygons).
// BOTH:    I often want the outline AND its centroid, for labeling purposes.
//          Obviously we don't want to double up on points though, so would just
//          be for Poly and MultiPoly's.

// TODO: rm this entire file when new endpoint is 👌
// eslint-disable-next-line
export type SecondParameter<T extends (...args: any) => any> = T extends (
  // eslint-disable-next-line
  config: any,
  args: infer P
  // eslint-disable-next-line
) => any
  ? P
  : never

export type FieldPostSuccessResponse = {
  readonly id: string
  name: string
  owned_by: string
  readonly lon: number
  readonly lat: number
  geometry: GeoJSON.Geometry
  extras: FieldAttributes['extras']
  onsite_contact: string | null // when it works, what is it?
  external_id?: ''
  producer_field_name?: ''
  farm?: ''
  address?: ''
  description?: ''
}

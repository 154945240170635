import type { AlertColor } from '@mui/material/Alert'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

type Props = {
  severity: AlertColor
  text: React.ReactNode
  /** Fade in transition */
  fade?: boolean
  fullWidth?: boolean
  /**
   * Bottom margin, using `sx` theme. Was the only style-ish thing to customize
   * so far.
   *
   * @default 4
   */
  mb?: number
  title?: string
  /** @default 'filled' */
  variant?: 'filled' | 'outlined' | 'standard'
  /**
   * Override the icon displayed before the children.
   * Unless provided, the icon is mapped to the value of the `severity` prop.
   * Set to `false` to remove the `icon`.
   */
  icon?: React.ReactNode
}

export function ConfMessage(props: Props) {
  const { text, severity, title, fullWidth, fade, variant = 'filled', icon, mb = 4 } = props

  const style = {
    maxWidth: fullWidth ? '100%' : '90%',
    width: fullWidth ? '100%' : 'unset',
  }

  const Content = (
    <Box mb={mb} mx="auto" style={style}>
      <Alert icon={icon} severity={severity} variant={variant}>
        <AlertTitle>{title || severity.toUpperCase()}</AlertTitle>
        {text}
      </Alert>
    </Box>
  )

  return fade ? (
    <Fade in timeout={1000}>
      {Content}
    </Fade>
  ) : (
    Content
  )
}

import { useEffect } from 'react'
import type { Control, FieldValues, FormState } from 'react-hook-form'
import { useFormState } from 'react-hook-form'

export type PartialFormState = Pick<FormState<FieldValues>, 'dirtyFields'>

type ReversePartialFormStateProviderProps = {
  formStateRef: React.MutableRefObject<PartialFormState>
  control: Control<FieldValues>
}

/**
 * Note that this is only for simple fields, NOT arrays or nested objects. It is interesting to note
 * that the Field Mgmt Tool form never shows `false`, it just doesn't include the keys that didn't
 * change, whereas Uploader might.
 */
type SimpleDirtyField<T> = {
  [K in keyof T]: boolean
}

/**
 * Get the subset of a react-hook-form's schema that has been changed. Note that this function does
 * NOT support shenanigans like nested objects or `fieldArray`s, which are not simply `boolean`
 * values. If a use case arises for that, see the example linked below. This is how the function was
 * originally set up but abandoned due to TypeScript fights and difficulty in documenting and
 * refactoring.
 *
 * {@link https://codesandbox.io/s/react-hook-form-submit-only-dirty-fields-without-rerender-6esie2 CodeSandbox starting point}
 *
 * @param dirtyFields the `dirtyFields` object from react-hook-form `formState`, or a boolean.
 * @param allValues initially, all values in the form's schema, but after recursion will include
 * only the changed values.
 * @returns an object in the shape of the form's schema, but containing only the properties that
 * have been changed.
 * @example <caption>Only farm has changed:</caption> getDirtyFormValues( { farm: true }, { farm:
 * 'My farm', field: 'My field' } ) // returns `{ farm: 'My farm' }`
 */
export function getDirtyFormValues<T = Record<string, unknown>>(
  dirtyFields: Partial<SimpleDirtyField<T>>,
  allValues: T
): Partial<T> {
  const changedKeys = Object.keys(dirtyFields)

  return Object.fromEntries(
    changedKeys.map((key) => [key, allValues[key as keyof T]])
  ) as Partial<T>
}

export function ReversePartialFormStateProvider({
  control,
  formStateRef,
}: ReversePartialFormStateProviderProps) {
  const { dirtyFields } = useFormState({ control })

  useEffect(() => {
    // This was a gnarly one to begin with, not gonna touch it.
    // eslint-disable-next-line no-param-reassign
    formStateRef.current = { dirtyFields }
  }, [formStateRef, dirtyFields])

  return null
}

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

type SpinnerColor = 'error' | 'info' | 'inherit' | 'primary' | 'secondary' | 'success' | 'warning'

type Props = {
  progress: number
}

type LoadSpinnerProps = {
  color?: SpinnerColor
  size?: number | string
  text?: string
}

const DIAMETER = 120

const rootStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: DIAMETER,
  justifyContent: 'center',
  position: 'relative',
  width: DIAMETER,
}

export function ProgressSpinner(props: Props) {
  const { progress } = props

  return (
    <div style={rootStyle}>
      <Typography style={{ position: 'absolute' }} variant="h4">
        {progress}%
      </Typography>
      <CircularProgress size={DIAMETER} thickness={4} value={progress} variant="determinate" />
    </div>
  )
}

// Fits nicely anywhere
export function CenteredLoadingSpinner(props: LoadSpinnerProps) {
  const { size = 40, text, color = 'primary' } = props

  return (
    <div style={{ ...rootStyle, width: '100%' }}>
      <CircularProgress color={color} size={size} />
      {!!text && (
        <Typography color="text.secondary" mt={1} variant="overline">
          {text}
        </Typography>
      )}
    </div>
  )
}

/**
 * @file boilerplate for MUI table with sensible defaults
 */
import { useState } from 'react'
import Box from '@mui/material/Box'
import type { GridValidRowModel } from '@mui/x-data-grid-premium'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'

import { ErrorBoundaryWrap } from 'components/reusable/alerts-and-messages/ErrorBoundaryWrap'
import { FormModal } from 'components/reusable/handy-templates/FormModal'

import { useOnStateChange } from './hooks'
import { useTableStatesStore } from './store'
import { TableToolbar } from './TableToolbar'
import type { ReusableTableProps, TableToolbarProps } from './types'
import { getDefaultInitialTableState } from './utils'

function Toolbar(props: TableToolbarProps) {
  return <TableToolbar {...props} />
}

function ReusableTableContent<Schema extends GridValidRowModel = GridValidRowModel>(
  props: ReusableTableProps<Schema>
) {
  const { tableProps, data, tableSlug, columns, defaultStateOverrides, containerSx } = props
  const apiRef = useGridApiRef()
  const persist = useTableStatesStore((state) => state.userWantsPersistence)
  const [fullscreen, setFullscreen] = useState(false)

  const tableStateFromStore = useTableStatesStore((state) => {
    return tableSlug ? state.tableStates[tableSlug] : null
  })

  const density = tableStateFromStore?.density || 'compact'
  const onStateChange = useOnStateChange(tableSlug || null, apiRef, tableProps?.loading || false)

  const defaultDefaultState = getDefaultInitialTableState(
    columns,
    defaultStateOverrides,
    tableProps?.checkboxSelection === false
  )

  let initialState = defaultDefaultState

  if (persist && tableStateFromStore) {
    initialState = tableStateFromStore
  }

  const toolbarProps: TableToolbarProps = {
    hideFullScreenBtn: fullscreen || tableProps?.slotProps?.toolbar?.hideFullScreenBtn,
    toggleFullscreen: () => setFullscreen((prev) => !prev),
    ...tableProps?.slotProps?.toolbar,
    initialState: defaultDefaultState, // always the starting point for TableViewResetBtn
    tableSlug,
  }

  // Order matters with some of these props. Weird mix of overrides and non.
  const Table = (
    <DataGridPremium
      // Begin non-overrideable \\
      checkboxSelection
      disableAggregation // TODO: wire this up, so cool 🤩
      disableRowGrouping // TODO: wire this up, so cool 🤩
      disableRowSelectionOnClick
      apiRef={apiRef}
      columns={columns}
      density={density}
      initialState={initialState}
      rows={data}
      // Begin overrideable defaults \\
      {...tableProps} // the big override
      // Begin overrideable defaults with spread \\
      slotProps={{
        ...tableProps?.slotProps,
        toolbar: toolbarProps,
      }}
      slots={{
        ...tableProps?.slots,
        toolbar: Toolbar,
      }}
      sx={{
        border: 'none',
        ...tableProps?.sx,
      }}
      // `onStateChange` is not technically overrideable but a passed-in `onStateChange` will be
      // called along with the custom `onStateChange`.
      onStateChange={(params, evt, details) => {
        onStateChange()

        if (tableProps?.onStateChange) {
          tableProps.onStateChange(params, evt, details)
        }
      }}
    />
  )

  const Wrapper = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        ...containerSx,
      }}
    >
      {Table}
    </Box>
  )

  // Gross but it makes the fullscreen modal use the same state as the main table. If they are BOTH
  // include, their states will be independent. It does trigger a ref warning in the console, but no
  // issues have been observed yet.
  if (!fullscreen) {
    return Wrapper
  }

  return (
    <FormModal
      hideDialogActions
      isNotForm
      slideUp
      content={Wrapper}
      dialogProps={{ fullScreen: true }}
      elemId="fullscreen-table"
      isOpen={fullscreen}
      onClose={() => setFullscreen(false)}
    />
  )
}

export function ReusableTable<Schema extends GridValidRowModel = GridValidRowModel>(
  props: ReusableTableProps<Schema>
) {
  return (
    <ErrorBoundaryWrap>
      <ReusableTableContent<Schema> {...props} />
    </ErrorBoundaryWrap>
  )
}

import { useCanAccessBasedOnGroups } from 'lib/queries.user'

import { NoAccessSpan } from 'components/reusable/alerts-and-messages'

import { ContactPersonForm } from './ContactPersonForm'

export function CreateNewContactPanel() {
  const userHasWritePerms = useCanAccessBasedOnGroups(['field_manager'])

  if (!userHasWritePerms) {
    return NoAccessSpan
  }

  return <ContactPersonForm type="create" />
}

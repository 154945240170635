import { RiTreasureMapLine } from 'react-icons/ri'
import Button from '@mui/material/Button'
import { useIsMutating } from '@tanstack/react-query'

import {
  useDrawToolDispatchContext,
  useDrawToolsStateContext,
} from 'components/reusable/maps/draw-and-annotate'

import { ACB_MUTATION_KEY } from './hooks.fmt-collns'

function useShouldDisableBtn() {
  const { toolbarVisible } = useDrawToolsStateContext()
  const isMutating = useIsMutating({ mutationKey: ACB_MUTATION_KEY })

  return !!isMutating || toolbarVisible
}

export function InitAcbDrawBtn() {
  const drawToolDispatch = useDrawToolDispatchContext()
  const shouldDisableBtn = useShouldDisableBtn()

  function handleClick() {
    drawToolDispatch({
      type: 'SET_ALLOWED_MODES',
      payload: ['draw_polygon'],
    })

    drawToolDispatch({
      type: 'SET_GEOMETRY_MODE',
      payload: 'draw_polygon',
    })

    drawToolDispatch({
      type: 'TOGGLE_TOOLBAR_VISIBILITY',
      payload: true,
    })

    drawToolDispatch({
      type: 'SET_ACTIVE_TOOLBAR_ID',
      payload: 'acb',
    })
  }

  return (
    <Button disabled={shouldDisableBtn} startIcon={<RiTreasureMapLine />} onClick={handleClick}>
      draw
    </Button>
  )
}

import type { UseMutateAsyncFunction } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import type { ErrorType } from 'lib/api/django-axios-instance'
import type { CircleLayer, LineLayer, Visibility } from 'mapbox-gl'

import {
  POINT_FILTER,
  POLY_OR_MULTIPOLY_FILTER,
  selectedImportOutline,
  selectedImportPoint,
} from 'components/reusable/maps/symbology'

import type { FMTbulkEditFormPayload } from './fields-mgmt/bulk-edit/types.bulk-edit'
import type { V1FieldsCreateMutationError } from './fields-mgmt/queries.fields-mgmt.post'
import type {
  FieldPatchPayload,
  FieldPostPayload,
  FieldPostSuccessResponse,
  FieldReaderRetrieve,
} from './fields-mgmt/types.fields-mgmt-api'
import type { ImportedFeature } from './types.fields-mgmt'

export function getSpatialRelevanceOfFile(filename: string): boolean {
  const pieces = filename.split('.')
  const extension = pieces.pop() || ''

  // TODO: 'json', 'zip'
  const RELEVANT_EXTENSIONS = [
    'dbf',
    'shp',
    'geojson',
    'csv',
    'kmz',
    'kml',
  ]

  return RELEVANT_EXTENSIONS.includes(extension)
}

export function isFileOfType(filename: string, extToCheck: string): boolean {
  const ext = filename.split('.').pop()

  return ext === extToCheck
}

// CRED: https://stackoverflow.com/a/4250408/1048518
export function hasSameFilenameSansExt(file1: string, file2: string): boolean {
  const pattern = /\.[^/.]+$/
  const file1name = file1.replace(pattern, '')
  const file2name = file2.replace(pattern, '')

  return file1name === file2name
}

/**
 * Prep an object with a list of problems based on a rejected promise from a bulk EDIT transaction.
 * Not quite the same as POST/create maybe?
 *
 * @param rejectedPromiseResult rejected promise result object
 * @returns object with `name` key and an array of strings
 */
export function prepBulkEditFieldsFailReasons(rejectedPromiseResult: PromiseRejectedResult): {
  name: string
  problems: string[]
} {
  const { reason } = rejectedPromiseResult
  const { config, response } = reason as AxiosError
  const { data } = response || {}
  const payloadErrors = JSON.stringify(data)
  const url = config?.url
  const id = url?.split('/')[3] || 'Field ID not available'

  return { name: id, problems: [payloadErrors] }
}

/**
 * Prep an object with a list of problems based on a rejected promise from a bulk CREATE
 * transaction. Not quite the same as PATCH/edit maybe?
 *
 * @param rejectedResult `PromiseRejectedResult` object
 * @returns object with `name` key and an array of strings
 */
export function prepBulkCreateFieldsFailReasons(rejectedResult: PromiseRejectedResult): {
  name: string
  problems: string[]
} {
  const { reason } = rejectedResult
  const { config, response } = reason
  const { data } = config
  const payload: FieldPostSuccessResponse = JSON.parse(data || '{}')

  return {
    name: payload.name,
    // Kinda dicey since the entire API TS is hand-written, so no guarantees. Just assume it is an
    // array of strings 🎲🤞🤠.
    problems: response?.data.non_field_errors || [],
  }
}

/**
 * Create an array of mutations PATCHing Fields in bulk
 *
 * @param featIds IDs of the Fields to PATCH
 * @param payload subset of Field POST payload, with only bulk-applicable props
 * @param mutateAsync `mutateAsync` method of the react-query mutation
 * @returns array of Promises (one returned for each mutation)
 */
export function getBulkEditFieldsMutations(
  featIds: (number | string)[],
  payload: FMTbulkEditFormPayload,
  mutateAsync: UseMutateAsyncFunction<
    FieldReaderRetrieve,
    ErrorType<unknown>,
    { id: unknown; data: FieldPatchPayload },
    unknown
  >
): Promise<Partial<FieldReaderRetrieve>>[] {
  const max = featIds.length
  const allMutations: Promise<Partial<FieldReaderRetrieve>>[] = []

  for (let index = 0; index < max; index += 1) {
    const patchMutation = mutateAsync({
      id: featIds[index],
      data: {
        ...payload,
        onsite_contact: payload.onsite_contact?.id || null,
      },
    })

    allMutations.push(patchMutation)
  }

  return allMutations
}

/**
 * Create an array of mutations POSTing Fields in bulk
 *
 * @param importedFields imported Field GeoJSON features to POST
 * @param mutateAsync `mutateAsync` method of the react-query mutation
 * @returns array of Promises (one returned for each mutation)
 */
export function getBulkCreateFieldsMutations(
  importedFields: ImportedFeature[],
  mutateAsync: UseMutateAsyncFunction<
    FieldPostSuccessResponse,
    V1FieldsCreateMutationError,
    FieldPostPayload,
    unknown
  >
): Promise<FieldPostSuccessResponse>[] {
  const max = importedFields.length
  const allMutations: Promise<FieldPostSuccessResponse>[] = []

  for (let index = 0; index < max; index += 1) {
    const feature = importedFields[index]
    const { onsite_contact, org, security_groups } = feature.properties

    const postMutation = mutateAsync({
      ...feature,
      properties: {
        ...feature.properties,
        onsite_contact: onsite_contact?.id || '',
        // Will fail w/o a valid id, but form should be covering us
        owned_by: org?.id || '',
        security_groups: security_groups?.map(({ id }) => id),
      },
    })

    allMutations.push(postMutation)
  }

  return allMutations
}

/**
 * Get props to use on <Layer> components of selected feature
 *
 * @param feature GeoJSON feature
 * @returns props to use on <Layer> components of selected feature
 */
export function getSelectedFeatLayerProps(feature?: GeoJSON.Feature): {
  pointProps: CircleLayer
  polyProps: LineLayer
} {
  const filter = ['==', ['get', 'id'], feature?.id || '']
  const { geometry } = feature || {}

  const pointProps: CircleLayer = {
    ...selectedImportPoint,
    filter: ['all', filter, POINT_FILTER],
    layout: {
      ...selectedImportPoint.layout,
      visibility: geometry?.type === 'Point' ? 'visible' : 'none',
    },
  }

  let polyVisible: Visibility = 'none'

  if (['Polygon', 'MultiPolygon'].includes(geometry?.type || '')) {
    polyVisible = 'visible'
  }

  const polyProps = {
    ...selectedImportOutline,
    filter: ['all', filter, POLY_OR_MULTIPOLY_FILTER],
    layout: {
      ...selectedImportOutline.layout,
      visibility: polyVisible,
    },
  }

  return { pointProps, polyProps }
}

const FILTERED_ORG_IDS_LOCAL_STG_KEY = 'fmtOrgFilters'

export const fmtLocalStorage = {
  getFilteredOrgIdsString: () => {
    return localStorage.getItem(FILTERED_ORG_IDS_LOCAL_STG_KEY) || undefined
  },
  setFilteredOrgIdsString: (orgIds?: string) => {
    if (!orgIds) {
      localStorage.removeItem(FILTERED_ORG_IDS_LOCAL_STG_KEY)
    } else {
      localStorage.setItem(FILTERED_ORG_IDS_LOCAL_STG_KEY, orgIds)
    }
  },
}

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useCurrentUserPrettyGroupNames } from 'lib/queries.user'

/**
 * Sanity check to see which groups the current user belongs to.
 */
export function GroupsOfCurrentUser() {
  const prettyGroupNames = useCurrentUserPrettyGroupNames()

  if (!prettyGroupNames) return null

  return (
    <Box mb={2}>
      <Typography component="h3" mb={1} variant="h6">
        My groups
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {prettyGroupNames.map((groupName) => (
          <Chip key={groupName} label={groupName} size="small" />
        ))}
      </Box>
    </Box>
  )
}

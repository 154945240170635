import type { Expression } from 'mapbox-gl'

import { POINT_FILTER } from 'components/reusable/maps/symbology/config.symbology'
import type { SoilSample } from 'components/soil-sampling/types.ssa'

type Key = keyof SoilSample

// CONSTANTS
export const MIN_RING_ZOOM = 17
export const RX_SAMPLES_SRC_ID = 'prescribed-samples'
export const RX_LABELS_LAYER_ID = 'rx-sample-labels'
export const RX_DOTS_LAYER_ID = 'rx-concentric-dots'
export const RX_THRESHOLD_LAYER_ID = '5m-ring'
export const RX_THRESHOLD_CONTRAST_LAYER_ID = '5m-ring-contrast'
export const GROUP_INTERP_KEY = 'groupInterpIndex'
export const RX_DOT_STROKE_COLOR_KEY = 'circleStrokeColor'
export const RX_5M_RING_INNER_COLOR = '#fff'

const latColumnName: Key = 'sample_lat'
const lonColumnName: Key = 'sample_lat'

// EXPRESSIONS
export const GET_MARKER_COLOR: Expression = ['get', 'marker_color']

export const FEAT_STATE_KEYS = {
  active: 'active',
  within: 'within',
}

export const ACTIVE = [
  'boolean',
  ['feature-state', FEAT_STATE_KEYS.active],
  false,
]

export const WITHIN = [
  'boolean',
  ['feature-state', FEAT_STATE_KEYS.within],
  false,
]

/**
 * {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#types-to-boolean Mapbox style spec - types to boolean}
 */
export const HAS_BEEN_SCANNED: Expression = [
  'all',
  [
    'any',
    ['==', ['get', 'sample_lat'], 0], // ya never know
    ['to-boolean', ['get', latColumnName]], // see docs link above
  ],
  [
    'any',
    ['==', ['get', 'sample_lon'], 0], // ya never know
    ['to-boolean', ['get', lonColumnName]], // see docs link above
  ],
]

const HAS_NOT_BEEN_SCANNED: Expression = ['!', HAS_BEEN_SCANNED]

export const UNSCANNED_POINTS = ['all', HAS_NOT_BEEN_SCANNED, POINT_FILTER]

export const MOVE_RX_SAMPLE_TOAST_ID = 'move-rx-sample'

import { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useOnDrop } from './hooks.fields-mgmt'

type Props = {
  onFilesAccepted: () => void
  onFilesProcessed: (feats: GeoJSON.Feature[], requiresLatLonCols?: boolean) => void
}

const focusedStyle: React.CSSProperties = { borderColor: 'text.secondary' }
const acceptStyle: React.CSSProperties = { borderColor: '#00e676' }
const rejectStyle: React.CSSProperties = { borderColor: '#ff1744' }

const rootSx: SxProps = {
  borderColor: 'divider',
  borderRadius: 4,
  borderStyle: 'dashed',
  borderWidth: 2,
  cursor: 'pointer',
  fontSize: 'body2.fontSize',
  mb: 3,
  pb: 1,
  pt: 3,
  px: 4,
  width: '100%',
  transition: 'all .24s ease-in-out',
  ul: {
    mt: 1,
  },
  '&:hover': {
    borderColor: 'text.secondary',
  },
}

export function ProducerFieldsDropzone(props: Props) {
  const { onFilesAccepted, onFilesProcessed } = props
  const onDrop = useOnDrop(onFilesAccepted, onFilesProcessed)
  const dropzone = useDropzone({ onDrop })
  const { getRootProps, getInputProps, isFocused } = dropzone
  const { isDragAccept, isDragReject } = dropzone

  const style: SxProps = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <Typography
      sx={rootSx}
      {...getRootProps({ style: style as React.CSSProperties })}
      component="div"
      variant="body2"
    >
      <input {...getInputProps()} />
      <Typography mb={2} variant="body2">
        Drag files here or click to browse. The following formats are supported and must be in the{' '}
        <code>EPSG:4326</code> spatial reference system:
      </Typography>
      <Box component="ul" display="flex" flexDirection="column" gap={1} pl={3}>
        <li>
          <b>CSV</b> must end in a <code>.csv</code> extension and have two numeric columns, one for
          latitude and one for longitude. The column names do not matter, but you will need to set
          them in the "column mappings" step.
        </li>
        <li>
          <b>GeoJSON</b> must end in a <code>.geojson</code> extension- if it ends in{' '}
          <code>.json</code>, rename it first.
        </li>
        <li>
          <b>KML/KMZ</b> must end in a <code>.kml</code> or <code>.kmz</code> extension.
        </li>
        <li>
          <b>Shapefiles</b> need a <code>.shp</code> and <code>.dbf</code> file for each
          "shapefile". You may drag in other associated files (e.g. <code>.shx</code>) and the
          importer will simply ignore them.
        </li>
      </Box>
    </Typography>
  )
}
